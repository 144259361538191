import * as zod from 'zod';

export const NotificationKind = zod.enum([
  'waitForConfirmation',
  'robotFailure',
  'externalControl',
]);

export type NotificationKind = zod.infer<typeof NotificationKind>;

export const NotificationStatus = zod.enum(['dismissed', 'viewed', 'archived']);

export type NotificationStatus = zod.infer<typeof NotificationStatus>;

export const NotificationData = zod.object({
  id: zod.string(),
  createdAt: zod.string(),
  statusByUserID: zod.record(NotificationStatus).optional(),
  kind: NotificationKind,
  robotID: zod.string(),
  robotName: zod.string().optional(),
  routineID: zod.string().optional(),
  stepID: zod.string().optional(),
  failureReason: zod.string().optional(),
  failureKind: zod.string().optional(),
  failureTraceID: zod.string().optional(),
});

export type NotificationData = zod.infer<typeof NotificationData>;
