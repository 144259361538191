import type { RoutineStepConfiguration } from './RoutineStepConfiguration';
import type { RoutineStepVariables } from './RoutineStepVariables';
import type { StepClass } from './StepClass';
import type { StepKind } from './StepKind';

export namespace StepRegistry {
  export const stepConstructors: Partial<
    Record<StepKind, StepClass<any, any>>
  > = {};

  /**
   * Called by Step implementations, this adds a class to the possible steps that can be
   * instantiated.
   *
   * Example:
   *
   * ```typescript
   * // at the end of a NewStep.ts file
   *
   * Step.registerStepKind('new', NewStep);
   * ```
   *
   * The types get erased internally, but this declaration makes sure we catch type
   * errors when passing the step through this.
   *
   * @internal
   */
  export function registerStepKind<
    Arguments extends RoutineStepConfiguration['args'],
    Variables extends RoutineStepVariables,
  >(stepKind: StepKind, entry: StepClass<Arguments, Variables>): void {
    StepRegistry.stepConstructors[stepKind] = entry;
  }
}
