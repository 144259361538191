import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { CameraIntrinsics } from '@sb/integrations/types/cameraTypes';

import type {
  Blob2D,
  Blob2DParams,
  Shape2DParams,
  TemplateImage,
} from './LocateTypes';

export const Point2D = zod.object({ x: zod.number(), y: zod.number() });
export type Point2D = zod.infer<typeof Point2D>;

export const ClassifyClass = zod.object({
  name: zod.string(),
  storageID: zod.string(),
});

export type ClassifyClass = zod.infer<typeof ClassifyClass>;

export const ClassifyResult = zod.object({
  name: zod.string(),
  score: zod.number(),
});

export type ClassifyResult = zod.infer<typeof ClassifyResult>;

export const RegionOfInterest = zod.object({
  top: zod.number(),
  bottom: zod.number(),
  left: zod.number(),
  right: zod.number(),
});

export type RegionOfInterest = zod.infer<typeof RegionOfInterest>;

export const ChessboardParameters = zod.object({
  rows: zod.number(),
  cols: zod.number(),
  squareSize: zod.number(),
});

export type ChessboardParameters = zod.infer<typeof ChessboardParameters>;

export const CalibrationEntry = zod.object({
  storageID: zod.string(), // image of chessboard
  wristPose: CartesianPose,
});

export type CalibrationEntry = zod.infer<typeof CalibrationEntry>;

export const CalculateIntrinsicsResult = zod.object({
  intrinsics: CameraIntrinsics,
  wristToCameraTransform: CartesianPose.nullable(),
});

export type CalculateIntrinsicsResult = zod.infer<
  typeof CalculateIntrinsicsResult
>;

export const ChessboardCornersResult = zod.object({
  isDetected: zod.boolean(),
  corners: zod.array(Point2D),
});

export type ChessboardCornersResult = zod.infer<typeof ChessboardCornersResult>;

export const DEFAULT_REGION_OF_INTEREST: RegionOfInterest = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export interface VisionInterface {
  classify(
    classes: ClassifyClass[],
    currentFrame: ArrayBuffer,
    regionOfInterest: RegionOfInterest,
  ): Promise<ClassifyResult[]>;
  detect2DBlobs(
    image: ArrayBuffer,
    regionOfInterest: RegionOfInterest,
    params: Blob2DParams,
  ): Promise<Blob2D[]>;
  detect2DShapes(
    image: ArrayBuffer,
    templateImage: TemplateImage,
    regionOfInterest: RegionOfInterest,
    params: Shape2DParams,
  ): Promise<Blob2D[]>;
  getChessboardCorners(
    image: ArrayBuffer,
    rows: number,
    cols: number,
  ): Promise<ChessboardCornersResult>;
  getCameraChessboardTransform(
    image: ArrayBuffer,
    chessboard: ChessboardParameters,
    intrinsics: CameraIntrinsics,
  ): Promise<CartesianPose>;
  calculateIntrinsics(
    calibration: CalibrationEntry[],
    chessboard: ChessboardParameters,
  ): Promise<CalculateIntrinsicsResult>;
}
