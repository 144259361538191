import cx from 'classnames';
import { forwardRef } from 'react';

import { SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT } from '@sb/ui';
import { margin } from '@sb/ui/styles';

import HelperText from './HelperText';
import InputLabel from './InputLabel';
import type { InputProps } from './types';

import sharedStyles from './shared.module.css';
import styles from './TextArea.module.css';

interface TextAreaFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    InputProps {}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (props, ref) => {
    const {
      children,
      className,
      hasBottomMargin,
      hasError,
      helperText,
      inputClassName,
      isHighlighted,
      labelClassName,
      labelPosition = 'top',
      sizeVariant = SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT,
      ...textAreaProps
    } = props;

    return (
      <div
        className={cx(
          sharedStyles.inputGroup,
          sharedStyles[sizeVariant],
          sharedStyles[`${labelPosition}Label`],
          hasBottomMargin && margin.bottom.small,
          className,
        )}
      >
        {children && (
          <InputLabel labelPosition={labelPosition} className={labelClassName}>
            {children}
          </InputLabel>
        )}

        <div
          className={cx(
            styles.textareaContainer,
            sharedStyles.inputContainer,
            hasError && sharedStyles.hasError,
            isHighlighted && !hasError && sharedStyles.highlighted,
            textAreaProps.disabled && sharedStyles.isDisabled,
          )}
        >
          <textarea
            ref={ref}
            className={cx(
              inputClassName,
              styles.textareaElement,
              sharedStyles.input,
            )}
            {...textAreaProps}
          />
        </div>

        <HelperText hasError={hasError} helperText={helperText} />
      </div>
    );
  },
);

export default TextArea;
