import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ClearIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 12 12" {...props}>
      <path d="M5.293 6L3.146 8.146l.707.707L6 6.707l2.146 2.146.707-.707L6.707 6l2.146-2.146-.707-.707L6 5.293 3.854 3.146l-.707.707L5.293 6zM6 12A6 6 0 1 1 6 0a6 6 0 1 1 0 12z" />
    </Icon>
  );
}
