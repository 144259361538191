import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import type { OnRobotVGP20ChannelCommand, OnRobotVGP20Command } from '../types';

export namespace ActuateVacuumStepDatabase {
  export const name = 'Actuate vacuum';
  export const description = 'Control attached vacuum';
  export const deviceKinds = ['OnRobotVGP20'] as const;
  export const librarySection = Step.LibrarySection.Basic;
  export const librarySort = '2';
  export const argumentKind = 'ActuateVacuum';

  export const ActuationCommandKind = zod.union([
    zod.literal('RELEASE'),
    zod.literal('GRIP'),
    zod.literal('IDLE'),
  ]);

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    oneCommandKind: ActuationCommandKind.default('IDLE'),
    twoCommandKind: ActuationCommandKind.default('IDLE'),
    threeCommandKind: ActuationCommandKind.default('IDLE'),
    fourCommandKind: ActuationCommandKind.default('IDLE'),
    overallSuctionPercentage: zod.number().default(1),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const getChannelCommand = (
      channel: keyof OnRobotVGP20Command['suctionActions'],
    ): OnRobotVGP20ChannelCommand => {
      const commandKind = args[`${channel}CommandKind`];

      return {
        commandKind,
        suctionPercentage:
          commandKind === 'GRIP' ? args.overallSuctionPercentage : 0,
      };
    };

    return {
      ...stepData,
      stepKind: 'ActuateVacuum',
      args: {
        gripperCommand: {
          kind: 'OnRobotVGP20Command',
          suctionActions: {
            one: getChannelCommand('one'),
            two: getChannelCommand('two'),
            three: getChannelCommand('three'),
            four: getChannelCommand('four'),
          },
        },
      },
    };
  };
}

ActuateVacuumStepDatabase satisfies Step.StepKindInfo;
