import { memoize } from 'lodash';

import { WebSocketMotionPlanner } from '@sb/motion-planning';
import { API_ENDPOINT } from '@sbrc/utils';

export const getMotionPlanner = memoize(() => {
  if (typeof window === 'undefined') {
    throw new Error('Cannot create server-side motion planner!');
  }

  // the backend proxies through to real motion planner
  const motionPlannerEndpoint = `${API_ENDPOINT}motion-planner`;

  // TODO: Update the backend for the visualizer
  return new WebSocketMotionPlanner(motionPlannerEndpoint, null);
});
