import cx from 'classnames';
import type { SVGAttributes } from 'react';
import { forwardRef } from 'react';

import { textColor } from '../styles';
import type { ColorVariant } from '../variants';

import styles from './Icon.module.css';

enum IconSize {
  extraSmall = 12,
  small = 16,
  medium = 24,
  large = 48,
  extraLarge = 72,
}

export interface IconProps extends SVGAttributes<SVGSVGElement> {
  size?: keyof typeof IconSize;
  variant?: ColorVariant;
  disabled?: boolean;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const {
    children,
    className,
    disabled,
    fontSize,
    size = 'medium',
    variant,
    viewBox,
    ...other
  } = props;

  const iconSize = IconSize[size];
  const customFontSize = `${iconSize}px`;
  const customViewBox = `0 0 ${iconSize} ${iconSize}`;

  return (
    <svg
      ref={ref}
      className={cx(styles.icon, variant && textColor[variant], className)}
      fontSize={fontSize || customFontSize}
      viewBox={viewBox || customViewBox}
      {...other}
    >
      {children}
    </svg>
  );
});
