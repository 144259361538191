import cx from 'classnames';

import { useRouter } from '@sbrc/hooks';

export function SBLogo() {
  const { push } = useRouter();

  return (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      className={cx('tw-fill-metal-70', 'tw-cursor-pointer')}
      onClick={() => push('/')}
    >
      <path d="M37.198 0L41.339 7.2H6.66125L10.8022 0H37.198Z" />
      <path d="M29.948 24.8C33.4273 19.1146 37.7329 13.9899 42.7419 9.63952L46.4 16C41.4139 20.5472 37.2345 25.9618 34.0886 32H13.9113C10.7654 25.9618 6.58606 20.5472 1.59998 16L5.25821 9.63952C10.2673 13.9899 14.5728 19.1144 18.0521 24.8H29.948Z" />
    </svg>
  );
}
