import { NotAuthenticated, Timeout } from '@feathersjs/errors';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { timer, throwError, EMPTY } from 'rxjs';
import { catchError, retryWhen, switchMap } from 'rxjs/operators';

// on timeout, retry after a second
export function retryOnTimeout<T>(): MonoTypeOperatorFunction<T> {
  return retryWhen((error$) =>
    error$.pipe(
      switchMap((error) => {
        if (error instanceof Timeout) {
          return timer(1000);
        }

        // re-throw any other error
        return throwError(error);
      }),
    ),
  );
}

// on not authenticated, complete without error (handler in currentUserID.ts will navigate back to login)
export function completeOnNotAuthenticated<T>(): MonoTypeOperatorFunction<T> {
  return catchError((error) => {
    if (error instanceof NotAuthenticated) {
      return EMPTY;
    }

    // re-throw any other error
    return throwError(error);
  });
}
