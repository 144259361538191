import * as zod from 'zod';

import type {
  DeviceCommand,
  DeviceConfiguration,
  EquipmentItem,
} from '@sb/integrations/types';
import { ActionRequiredError, Step } from '@sb/remote-control/types';
import { getDistanceUnitInfo } from '@sb/remote-control/util/distance';

import { EWELLIX_CONFIGURATIONS } from '../../EwellixLiftTLT/implementation/consts';

export namespace MoveVerticalLiftStepDatabase {
  export const name = 'Move vertical lift';
  export const description = 'Move vertical lift to position';
  export const deviceKinds = ['EwellixLiftTLT'] as const;
  export const librarySection = Step.LibrarySection.Basic;
  export const librarySort = '3';
  export const argumentKind = 'MoveVerticalLift';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    targetHeight: zod.number().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export function getVerticalLiftInfo(equipment: EquipmentItem[]): {
    config: DeviceConfiguration;
    minHeight: number;
    maxHeight: number;
  } | null {
    for (const device of equipment) {
      switch (device.config.kind) {
        case 'EwellixLiftTLT': {
          const { strokeVariant } = device.config;
          const ewellixConfiguration = EWELLIX_CONFIGURATIONS[strokeVariant];

          return {
            config: device.config,
            minHeight: ewellixConfiguration.baseHeightMeters,
            maxHeight:
              ewellixConfiguration.baseHeightMeters +
              ewellixConfiguration.strokeMeters,
          };
        }

        default:
          continue;
      }
    }

    return null;
  }

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const { targetHeight } = args;

    const getDeviceCommand = (): DeviceCommand => {
      if (targetHeight === undefined) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          fieldId: 'targetHeight',
          message: 'Target height is not configured',
        });
      }

      const dynamicBaseInfo = getVerticalLiftInfo(equipment);

      if (dynamicBaseInfo) {
        if (targetHeight < dynamicBaseInfo.minHeight) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            fieldId: 'targetHeight',
            message: 'Target height below minimum',
          });
        }

        if (targetHeight > dynamicBaseInfo?.maxHeight) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            fieldId: 'targetHeight',
            message: 'Target height above maximum',
          });
        }
      }

      switch (dynamicBaseInfo?.config.kind) {
        case 'EwellixLiftTLT': {
          return {
            kind: 'EwellixLiftTLTCommand',
            heightMeters: targetHeight,
            speedPercentage: 1,
          };
        }
        default:
          throw new ActionRequiredError({
            kind: 'missingEquipment',
            message: 'No compatible equipment configured',
          });
      }
    };

    return {
      ...stepData,
      stepKind: 'MoveVerticalLift',
      args: {
        deviceCommand: getDeviceCommand(),
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    distanceUnitInfo = getDistanceUnitInfo('millimeter'),
    includeStepName,
  }) => {
    if (
      args?.argumentKind !== argumentKind ||
      args.targetHeight === undefined
    ) {
      return null;
    }

    const target = distanceUnitInfo.toString(args.targetHeight);

    return `${includeStepName ? 'Move vertical lift ' : ''}to ${target}`;
  };
}

MoveVerticalLiftStepDatabase satisfies Step.StepKindInfo;
