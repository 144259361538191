import cx from 'classnames';
import { forwardRef } from 'react';

import type {
  ComponentColor,
  ComponentColorOrDefault,
  ComponentSize,
} from '../../utility';
import { useComponentColorClassName } from '../../utility';

import { BUTTON_SIZE_CLASSES } from './buttonSize';

const BUTTON_COLOR_CLASSES: Record<ComponentColor, string> = {
  Orange: 'peer-enabled:peer-checked:tw-text-orange',
  Red: 'peer-enabled:peer-checked:tw-text-red',
  Green: 'peer-enabled:peer-checked:tw-text-green',
  Blue: 'peer-enabled:peer-checked:tw-text-blue',
  Primary: '',
};

type SegmentedButtonVariant = 'Elevated' | 'Gray';

const BUTTON_VARIANT_CLASSES: Record<SegmentedButtonVariant, string> = {
  Elevated: 'peer-checked:tw-surface-elevated peer-checked:tw-shadow-30',
  Gray: 'peer-checked:tw-bg-fill-primary',
};

export interface SegmentedButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: 'radio' | 'checkbox';
  size?: ComponentSize;
  variant?: SegmentedButtonVariant;
  color?: ComponentColorOrDefault;
  children?: React.ReactNode;
  buttonClassName?: string;
}

/**
 * A radio or checkbox input which looks like a button
 */
export const SegmentedButton = forwardRef<
  HTMLInputElement,
  SegmentedButtonProps
>(
  (
    {
      className,
      buttonClassName,
      type = 'radio',
      size = 32,
      variant = 'Elevated',
      color,
      children,
      ...rest
    },
    ref,
  ) => {
    const colorClassName = useComponentColorClassName(
      color,
      BUTTON_COLOR_CLASSES,
    );

    return (
      <div className={cx(className, 'tw-inline-grid')}>
        <input
          type={type}
          className={cx(
            'tw-peer',
            'tw-opacity-0',
            'tw-col-start-1',
            'tw-row-start-1',
            'tw-w-full', // safari needs this to stretch the checkbox
            'tw-h-full', // safari needs this to stretch the checkbox
            'tw-cursor-pointer',
            'disabled:tw-cursor-not-allowed',
          )}
          {...rest}
          ref={ref}
        />
        <button
          type="button"
          className={cx(
            'tw-col-start-1',
            'tw-row-start-1',
            buttonClassName,
            BUTTON_SIZE_CLASSES[size],
            colorClassName,
            BUTTON_VARIANT_CLASSES[variant],
            'peer-checked:tw-font-medium',
            'peer-enabled:peer-active:tw-brightness-90',
            'peer-enabled:peer-hover:tw-brightness-110',
            'peer-enabled:tw-text-label-secondary',
            'peer-disabled:tw-text-label-quaternary',
            'peer-focus-visible:tw-outline',
            'tw-pointer-events-none',
          )}
          tabIndex={-1}
        >
          {children}
        </button>
      </div>
    );
  },
);
