import * as zod from 'zod';

/**
 * Pause the heartbeat sent to arm-control-bot
 */
export const PauseBotmanHeartbeat = zod.object({
  kind: zod.literal('pauseBotmanHeartbeat'),
  durationMS: zod.number(),
});

export const TestAutomationData = zod.union([
  zod.never(), // this is here only to make a valid union
  PauseBotmanHeartbeat,
]);

export type TestAutomationData = zod.infer<typeof TestAutomationData>;
