import * as zod from 'zod';

import { ModbusTCPRequestFunctionCode } from '../types';

const CommandResponseKind = zod.union([
  zod.literal('success'),
  zod.literal('failure'),
]);

export const ModbusTCPCommandBaseResponse = zod.object({
  requestCode: ModbusTCPRequestFunctionCode,
  responseKind: CommandResponseKind,
  responseData: zod.number().optional(),
  errorMessage: zod.string().optional(),
});

export type ModbusTCPCommandBaseResponse = zod.infer<
  typeof ModbusTCPCommandBaseResponse
>;

export type NetworkRequestCommandResponse = ModbusTCPCommandBaseResponse;
