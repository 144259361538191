export const wifi = (
  <>
    <path
      d="M26.3566 10.8109C26.1367 10.563 25.908 10.3202 25.6705 10.0827C19.2269 3.63909 8.77968 3.63909 2.33603 10.0827C2.0986 10.3202 1.86992 10.563 1.64999 10.8109L3.77603 12.937C3.99241 12.6864 4.21951 12.4419 4.45735 12.2041C9.72942 6.93198 18.2772 6.93198 23.5492 12.2041C23.7871 12.4419 24.0142 12.6864 24.2305 12.937L26.3566 10.8109Z"
      fill="currentColor"
    />
    <path
      d="M22.1018 15.0657C21.891 14.811 21.6664 14.5639 21.4279 14.3254C17.3274 10.2249 10.6792 10.2249 6.57867 14.3254C6.34017 14.5639 6.11555 14.811 5.90479 15.0657L8.03961 17.2005C8.24011 16.9384 8.46024 16.6864 8.69999 16.4467C11.6289 13.5178 16.3777 13.5178 19.3066 16.4467C19.5463 16.6864 19.7665 16.9384 19.967 17.2005L22.1018 15.0657Z"
      fill="currentColor"
    />
    <path
      d="M17.8135 19.354C17.6377 19.075 17.4283 18.811 17.1853 18.568C15.4279 16.8107 12.5787 16.8107 10.8213 18.568C10.5783 18.811 10.3689 19.075 10.193 19.354L13.2962 22.4571C13.6867 22.8476 14.3199 22.8476 14.7104 22.4571L17.8135 19.354Z"
      fill="currentColor"
    />
  </>
);

export const wifiSlash = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.29291 4.70712L22.2929 22.7071L23.7071 21.2929L5.70712 3.29291L4.29291 4.70712ZM23.5492 12.2041C20.7857 9.44048 17.122 8.12555 13.5019 8.25926L10.804 5.56139C16.0178 4.53598 21.6309 6.0431 25.6705 10.0827C25.908 10.3202 26.1367 10.563 26.3566 10.8109L24.2305 12.937C24.0142 12.6864 23.7871 12.4419 23.5492 12.2041ZM4.80523 8.04787C3.93405 8.63405 3.10642 9.31234 2.33603 10.0827C2.0986 10.3202 1.86992 10.563 1.64999 10.8109L3.77603 12.937C3.99241 12.6864 4.21951 12.4419 4.45735 12.2041C5.23312 11.4283 6.07981 10.7667 6.97656 10.2192L4.80523 8.04787ZM9.17839 12.421C8.24226 12.9055 7.36377 13.5403 6.57867 14.3254C6.34017 14.5639 6.11555 14.811 5.90479 15.0657L8.03961 17.2005C8.24011 16.9384 8.46024 16.6864 8.69999 16.4467C9.50518 15.6415 10.4479 15.0577 11.4525 14.6952L9.17839 12.421ZM16.9624 20.2051L14.0074 17.25C12.8544 17.249 11.701 17.6883 10.8213 18.568C10.5783 18.811 10.3689 19.075 10.193 19.354L13.2962 22.4571C13.6867 22.8476 14.3199 22.8476 14.7104 22.4571L16.9624 20.2051ZM21.2051 15.9624L16.8974 11.6547C18.5563 12.1287 20.1214 13.0189 21.4279 14.3254C21.6664 14.5639 21.891 14.811 22.1018 15.0657L21.2051 15.9624Z"
    fill="currentColor"
  />
);

export const bluetooth = (
  <path
    d="M14 14V3L19.5 8.5L14 14ZM14 14V25L19.5 19.5L14 14ZM14 14L8 20M14 14L8 8"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const connectorEthernet = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.5 5L2.5 6V18L3.5 19H8V22.5L9 23.5H19L20 22.5V19H24.5L25.5 18V6L24.5 5H3.5ZM4.5 17V7H7V11H9V7H10V11H12V7H13V11H15V7H16V11H18V7H19V11H21V7H23.5V17H19L18 18V21.5H10V18L9 17H4.5Z"
    fill="currentColor"
  />
);

export const connectorSevenPinRound = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14C5.25 9.16751 9.16751 5.25 14 5.25C18.8325 5.25 22.75 9.16751 22.75 14C22.75 18.8325 18.8325 22.75 14 22.75C9.16751 22.75 5.25 18.8325 5.25 14ZM14 3.25C8.06294 3.25 3.25 8.06294 3.25 14C3.25 19.9371 8.06294 24.75 14 24.75C19.9371 24.75 24.75 19.9371 24.75 14C24.75 8.06294 19.9371 3.25 14 3.25ZM9 15.75C9.9665 15.75 10.75 14.9665 10.75 14C10.75 13.0335 9.9665 12.25 9 12.25C8.0335 12.25 7.25 13.0335 7.25 14C7.25 14.9665 8.0335 15.75 9 15.75ZM20.75 14C20.75 14.9665 19.9665 15.75 19 15.75C18.0335 15.75 17.25 14.9665 17.25 14C17.25 13.0335 18.0335 12.25 19 12.25C19.9665 12.25 20.75 13.0335 20.75 14ZM14 15.75C14.9665 15.75 15.75 14.9665 15.75 14C15.75 13.0335 14.9665 12.25 14 12.25C13.0335 12.25 12.25 13.0335 12.25 14C12.25 14.9665 13.0335 15.75 14 15.75ZM12.375 11.1854C11.538 11.6687 10.4677 11.3819 9.98447 10.5449C9.50123 9.70789 9.78801 8.63761 10.625 8.15436C11.462 7.67111 12.5323 7.95789 13.0156 8.7949C13.4988 9.63191 13.212 10.7022 12.375 11.1854ZM14.9845 19.2051C15.4677 20.0421 16.538 20.3289 17.375 19.8457C18.212 19.3624 18.4988 18.2921 18.0156 17.4551C17.5323 16.6181 16.462 16.3313 15.625 16.8146C14.788 17.2978 14.5012 18.3681 14.9845 19.2051ZM15.625 11.1854C14.788 10.7022 14.5012 9.63191 14.9845 8.7949C15.4677 7.95789 16.538 7.67111 17.375 8.15436C18.212 8.63761 18.4988 9.70789 18.0156 10.5449C17.5323 11.3819 16.462 11.6687 15.625 11.1854ZM9.98448 17.4551C9.50123 18.2921 9.78801 19.3624 10.625 19.8457C11.462 20.3289 12.5323 20.0421 13.0156 19.2051C13.4988 18.3681 13.212 17.2978 12.375 16.8146C11.538 16.3313 10.4677 16.6181 9.98448 17.4551Z"
    fill="currentColor"
  />
);

export const connectorNinePinD = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.49999 6.5L1.5238 7.28307L0.523804 11.7831L0.557 12.3328L3.557 20.8328L4.49999 21.5H23.5L24.443 20.8328L27.443 12.3328L27.4762 11.7831L26.4762 7.28307L25.5 6.5H2.49999ZM2.53832 11.9373L3.30216 8.5H24.6978L25.4617 11.9373L22.7925 19.5H5.20751L2.53832 11.9373ZM5.99999 13.5C6.82842 13.5 7.49999 12.8284 7.49999 12C7.49999 11.1716 6.82842 10.5 5.99999 10.5C5.17156 10.5 4.49999 11.1716 4.49999 12C4.49999 12.8284 5.17156 13.5 5.99999 13.5ZM9.99999 13.5C10.8284 13.5 11.5 12.8284 11.5 12C11.5 11.1716 10.8284 10.5 9.99999 10.5C9.17156 10.5 8.49999 11.1716 8.49999 12C8.49999 12.8284 9.17156 13.5 9.99999 13.5ZM15.5 12C15.5 12.8284 14.8284 13.5 14 13.5C13.1716 13.5 12.5 12.8284 12.5 12C12.5 11.1716 13.1716 10.5 14 10.5C14.8284 10.5 15.5 11.1716 15.5 12ZM18 13.5C18.8284 13.5 19.5 12.8284 19.5 12C19.5 11.1716 18.8284 10.5 18 10.5C17.1716 10.5 16.5 11.1716 16.5 12C16.5 12.8284 17.1716 13.5 18 13.5ZM23.5 12C23.5 12.8284 22.8284 13.5 22 13.5C21.1716 13.5 20.5 12.8284 20.5 12C20.5 11.1716 21.1716 10.5 22 10.5C22.8284 10.5 23.5 11.1716 23.5 12ZM7.99999 17.5C8.82842 17.5 9.49999 16.8284 9.49999 16C9.49999 15.1716 8.82842 14.5 7.99999 14.5C7.17156 14.5 6.49999 15.1716 6.49999 16C6.49999 16.8284 7.17156 17.5 7.99999 17.5ZM13.5 16C13.5 16.8284 12.8284 17.5 12 17.5C11.1716 17.5 10.5 16.8284 10.5 16C10.5 15.1716 11.1716 14.5 12 14.5C12.8284 14.5 13.5 15.1716 13.5 16ZM16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5ZM21.5 16C21.5 16.8284 20.8284 17.5 20 17.5C19.1716 17.5 18.5 16.8284 18.5 16C18.5 15.1716 19.1716 14.5 20 14.5C20.8284 14.5 21.5 15.1716 21.5 16Z"
    fill="currentColor"
  />
);

export const connectorUSBC = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6 8C2.68629 8 0 10.6863 0 14C0 17.3137 2.68629 20 6 20H22C25.3137 20 28 17.3137 28 14C28 10.6863 25.3137 8 22 8H6ZM2 14C2 11.7909 3.79086 10 6 10H22C24.2091 10 26 11.7909 26 14C26 16.2091 24.2091 18 22 18H6C3.79086 18 2 16.2091 2 14ZM6 13C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15H22C22.5523 15 23 14.5523 23 14C23 13.4477 22.5523 13 22 13H6Z"
    fill="currentColor"
  />
);
