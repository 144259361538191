import type * as zod from 'zod';

import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepFailure } from '@sb/routine-runner/types';
import { wait } from '@sb/utilities';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const MIN_ITERATION_DURATION = 200;

export default class LoopStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  protected initializeVariableState(): void {
    const { currentIteration = 1 } = this.variablesForInitialization;

    const expectedTotalIterations = this.args.times ?? Infinity;

    this.variables = {
      currentIteration,
      expectedTotalIterations,
    };
  }

  public async _play({
    hasEncounteredStartStep,
    playSubSteps,
    hasBeenPreempted,
    pauseRoutine,
    fail,
  }: StepPlayArguments): Promise<void> {
    let isFirstIteration = true;

    while (true) {
      const timeAtStartOfIteration = Date.now();

      if (!isFirstIteration && hasEncounteredStartStep()) {
        this.iterate();
      }

      const shouldContinueIteration = await this.shouldContinueIteration(fail);

      if (!shouldContinueIteration) {
        this.reset();
        break;
      }

      if (!isFirstIteration) {
        const routineRunnerState = this.routineContext.getRoutineRunnerState();

        if (
          routineRunnerState.kind === 'RoutineRunning' &&
          routineRunnerState.isPreflightTestRun &&
          this.routineContext.loadedRoutineState?.mainLoop === this.id
        ) {
          this.routineContext.setRoutineRunnerState({
            ...routineRunnerState,
            isPreflightTestRun: false,
          });

          await pauseRoutine({
            kind: 'preflightTestRunCompleted',
            reason: 'The test run completed.',
          });
        }
      }

      isFirstIteration = false;

      if (await super.shouldStopPlaying()) {
        return;
      }

      // give the substeps an opportunity to break this loop
      // passed in through the recursive playSteps call
      let shouldBreak = false;
      let shouldContinue = false;

      await playSubSteps({
        breakOutOfLoop() {
          shouldBreak = true;
        },
        continueLoop() {
          shouldContinue = true;
        },
        hasBeenPreempted() {
          return shouldContinue || shouldBreak || hasBeenPreempted();
        },
      });

      if (hasBeenPreempted() || (await super.shouldStopPlaying())) {
        return;
      }

      if (shouldBreak) {
        break;
      }

      // prevent loop from block the event loop by waiting here
      const iterationDuration = Date.now() - timeAtStartOfIteration;
      const waitTime = Math.max(MIN_ITERATION_DURATION - iterationDuration, 0);
      await wait(waitTime);
    }
  }

  /**
   * Increment the loop at the beginning of each iteration.
   */
  private iterate(): void {
    this.setVariable('currentIteration', this.variables.currentIteration + 1);
  }

  /**
   * Increment the loop at the beginning of each iteration.
   */
  private reset(): void {
    this.setVariable('currentIteration', 1);
  }

  /**
   * Check whether the loop is valid with the current variable state.
   *
   * Basically the same as `next()` but doesn't actually modify state.
   *
   * Used when traversing through routine steps while trying to avoid modifying state.
   *
   * @returns Whether the loop should continue
   */
  private async shouldContinueIteration(
    onFail: (failure: StepFailure) => Promise<void>,
  ): Promise<boolean> {
    if (this.args.condition) {
      try {
        const result = await this.routineContext.evaluateConditional(
          this.args.condition,
        );

        if (!result) {
          return false;
        }
      } catch (error) {
        await onFail({
          failure: {
            kind: FailureKind.InvalidRoutineLoadedFailure,
          },
          failureReason: error.message,
          error,
        });

        return false;
      }
    }

    const expectedTotalIterations = this.args.times ?? Infinity;

    // 1 based index, so check less than or equal to
    return this.variables.currentIteration <= expectedTotalIterations;
  }
}
