import cx from 'classnames';
import { forwardRef } from 'react';

export interface SwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, ...rest }, ref) => {
    return (
      <div
        className={cx(
          className,
          'tw-h-32',
          'tw-w-52',
          'tw-rounded-full',
          'tw-overflow-hidden',
          'tw-grid',
          '[&:has(>input:disabled)]:tw-opacity-40',
          '[&:has(>input:checked)]:tw-outline-green',
          '[&:has(>input:focus-visible)]:tw-outline',
        )}
      >
        <input
          type="checkbox"
          className={cx(
            'tw-peer',
            'tw-opacity-0',
            'tw-col-start-1',
            'tw-row-start-1',
            'tw-w-full', // safari needs this to stretch the checkbox
            'tw-h-full', // safari needs this to stretch the checkbox
            'tw-cursor-pointer',
            'disabled:tw-cursor-not-allowed',
          )}
          {...rest}
          ref={ref}
        />
        <div
          className={cx(
            'tw-bg-fill-secondary',
            'peer-checked:tw-bg-green',
            'tw-rounded-full',
            'tw-col-start-1',
            'tw-row-start-1',
            'tw-pointer-events-none',
            'tw-transition-colors',
          )}
        />
        <div
          className={cx(
            'tw-h-[27px]',
            'tw-w-[27px]',
            'tw-bg-white',
            'tw-rounded-full',
            'tw-col-start-1',
            'tw-row-start-1',
            'tw-pointer-events-none',
            'tw-self-center',
            'tw-justify-self-center',
            'tw-translate-x-[-10px]',
            'peer-checked:tw-translate-x-[10px]',
            'tw-transition-transform',
            'tw-shadow-i1',
          )}
        />
      </div>
    );
  },
);
