/**
 * Inverse kinematics solution:
 *
 * The payload for a response from the motion planner reporting one solution
 * for joint angles to achieve a target pose.
 */

import * as zod from 'zod';

import { ArmJointPositions } from './ArmJointPositions';
import { NUMBER_OF_JOINTS } from './JointNumber';

export const InverseKinematicsSolution = zod.object({
  jointAngles: ArmJointPositions,
  // Array<{ elementNames: [string, string] }>
  // One for each collision
  collisions: zod.array(
    zod.object({
      elementNames: zod.tuple([zod.string(), zod.string()]),
    }),
  ),
  isColliding: zod.boolean(),
  // Is this solution out of joint limits?
  violatesLimits: zod.boolean(),

  limitViolations: zod
    .array(zod.number().or(zod.null()))
    .min(NUMBER_OF_JOINTS)
    .max(NUMBER_OF_JOINTS),
});

export type InverseKinematicsSolution = zod.infer<
  typeof InverseKinematicsSolution
>;
