import { clamp } from 'lodash';

import type { ActuateGripperStepDatabase } from '@sb/integrations/implementations/gripper-general/step-wizard/ActuateGripperStepDatabase';

import { DH_MAX_FORCE_PERCENT, DH_MIN_FORCE_PERCENT } from '../constants';
import type { DHCommand, DHConfiguration } from '../types';

export function dhActuateGripperStepGenerateCommand<K>(
  commandKind: K,
  configuration: DHConfiguration,
  stepArgs: ActuateGripperStepDatabase.Arguments,
): DHCommand & { kind: K } {
  return {
    kind: commandKind,
    targetForce: clamp(
      stepArgs.forcePercent,
      DH_MIN_FORCE_PERCENT,
      DH_MAX_FORCE_PERCENT,
    ),
    targetSpeed: 0.5,
    targetDiameter: clamp(
      stepArgs.diameterMM / 1000,
      configuration.minGripWidth,
      configuration.maxGripWidth,
    ),
    targetDiameterTolerance: stepArgs.targetDiameterToleranceMeters,
    waitForGripToContinue: stepArgs.waitForGripToContinue,
  };
}
