/**
 * Forward kinematics request:
 *
 * The payload for a request to the motion planner requesting the pose
 * of the tool frame for a given set of joint angles.
 *
 * This endpoint can also be used to check the validity of a set of angles,
 * returning collision details if it is requested.
 */

import * as zod from 'zod';

import { ArmJointPositions } from './ArmJointPositions';
import { GripperOpenness } from './GripperOpenness';

export const ForwardKinematicsRequest = zod.object({
  gripperOpenness: GripperOpenness,
  jointAngles: ArmJointPositions,
  checkValidity: zod.boolean(),
});

export type ForwardKinematicsRequest = zod.infer<
  typeof ForwardKinematicsRequest
>;
