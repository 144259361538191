/**
 * Augment + format logs for specific types during JSON serialization
 * @param value
 * @returns
 */
const jsonLogReplacer = (_key: string, value: any): any => {
  if (value instanceof Error) {
    return {
      ...value,
      stack: value.stack,
      message: value.message,
      name: value.name,
    };
  }

  return value;
};

export const jsonFormat = (input: any) =>
  JSON.stringify(input, jsonLogReplacer);
