export enum Severity {
  /** Log error that is fatal to the operation, or the serivce/application. */
  Error = 'ERROR',
  /** Issue that can potentially cause application oddities, but for which I am automatically recovering. (Such as switching from a primary to backup server, retrying an operation, missing secondary data, etc.) */
  Warning = 'WARN',
  /** Log generally useful information to log (service start/stop, configuration assumptions, etc). Generally used to understand normal operations while diagnosing an issue, or gathering metrics. */
  Information = 'INFO',
  /** Diagnostic data. */
  Debug = 'DEBUG',
}

export const allSeverities = [
  Severity.Error,
  Severity.Warning,
  Severity.Information,
  Severity.Debug,
] as const;

export const testSeverities = [Severity.Error, Severity.Warning] as const;

export enum LogSinkFormat {
  Stdout,
  Splunk,
  Json,
  Browser,
}

export type Formatter = (
  severity: Severity,
  key: string,
  message: string,
  context: any,
) => string[] | string;

export interface LogData {
  severity: Severity;
  key: string;
  message: string;
  context: { [key: string]: string };
}
