import * as zod from 'zod';

import { HaasAddress, HaasSerialTarget } from '../../types';

export const HaasRunProgramArguments = zod.object({
  address: zod.union([HaasAddress, HaasSerialTarget]),
  programName: zod.string().nonempty(),
  waitUntilIdle: zod.boolean(),
  // disabling the cell safe signal may allow the program to run faster
  disableCellSafe: zod.boolean(),
});

export type HaasRunProgramArguments = zod.infer<typeof HaasRunProgramArguments>;
