import * as zod from 'zod';

import type { ArmTarget } from './ArmTarget';

export const BlendRadiusConfig = zod.object({
  kind: zod.literal('blendRadius'),
  radius: zod.number(),
});

// Add more config types for future blending methods
export const BlendConfig = BlendRadiusConfig;

export type BlendConfig = zod.infer<typeof BlendConfig>;

export type Waypoint = {
  armTarget: ArmTarget;
  blend?: BlendConfig;
};
