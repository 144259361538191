import * as zod from 'zod';

/**
 * This file is a bit of tech debt.
 * Ideally types don't call into registries, these types should be refactored to
 * expose the minimal common interface between all devices, etc.
 */

import { DeviceCommandsRegistry } from '../registry/deviceCommands';
import { DeviceConfigurationRegistry } from '../registry/deviceConfiguration';
import { DeviceStateRegistry } from '../registry/deviceState';

// convert readonly tuple into a regular tuple so can be used as a discriminatedUnion parameter
type Tuple<T> = T extends readonly [...infer U] ? [...U] : never;

export const DeviceCommand = zod.discriminatedUnion(
  'kind',
  DeviceCommandsRegistry as Tuple<typeof DeviceCommandsRegistry>,
);

export type DeviceCommand = zod.infer<typeof DeviceCommand>;

export const DeviceConfiguration = zod.discriminatedUnion(
  'kind',
  DeviceConfigurationRegistry as Tuple<typeof DeviceConfigurationRegistry>,
);

export type DeviceConfiguration = zod.infer<typeof DeviceConfiguration>;

export const DeviceState = zod.discriminatedUnion(
  'kind',
  DeviceStateRegistry as Tuple<typeof DeviceStateRegistry>,
);

export type DeviceState = zod.infer<typeof DeviceState>;

// typecheck: this ensures all Configuration types have a non-optional `name` property.
// ts will error if `name` is optional or missing:
// "Type 'string | undefined' is not assignable to type 'string'. ts(2322)"
((name: DeviceConfiguration['name']): string => name as string)('');

export type DeviceKind = DeviceConfiguration['kind'];

export type DeviceFamily =
  | Extract<DeviceConfiguration, { family: unknown }>['family']
  | null;

export const EquipmentItem = zod.object({
  id: zod.string(),
  config: DeviceConfiguration,
  isEnabled: zod.boolean(),
  simulatorPort: zod.number().optional(),
});

export type EquipmentItem = zod.infer<typeof EquipmentItem>;
