import type { Integrations } from '@sb/remote-control/types';

import type {
  DeviceConfiguration,
  DeviceKind,
  EquipmentItem,
} from '../../types';
import { isEndEffector } from '../util';

export const getEndEffector = (equipment: DeviceConfiguration[]) => {
  return equipment.find((item) => isEndEffector(item.kind));
};

export const canAddIntegration = (
  kind: DeviceKind,
  existingEquipment: EquipmentItem[],
  getEquipmentByKind: Integrations.GetEquipmentByKind,
): boolean => {
  const proposedIntegration = getEquipmentByKind(kind);

  if (proposedIntegration.canAddAsNewIntegration) {
    const existingIntMapped = existingEquipment
      .filter((item) => item.isEnabled)
      .map((item) => getEquipmentByKind(item.config.kind));

    return proposedIntegration.canAddAsNewIntegration(existingIntMapped);
  }

  return true;
};
