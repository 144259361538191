import type { CartesianPose } from '@sb/geometry';
import {
  averagePositions,
  distanceBetweenPoses,
  ZERO_ROTATION,
} from '@sb/geometry';

import type { Space } from '../types';

export function getNearestCalibrationEntries(
  pose: CartesianPose,
  calibrationData: Space.AccuracyCalibrationEntry[],
  n: number,
): {
  rawPose: CartesianPose;
  calibratedPose: CartesianPose;
  offset: CartesianPose;
}[] {
  const minN = [...calibrationData]
    .sort(
      (a, b) =>
        distanceBetweenPoses(pose, a.rawPose) -
        distanceBetweenPoses(pose, b.rawPose),
    )
    .slice(0, n);

  return minN;
}

/**
 * This uses calibration data to find the offset to apply to the current pose
 * It finds the offset by finding the nearest raw poses in the calibration data
 * and then returning the weighted average of these using the distance between
 * the located point and the raw pose as the weight.
 * @param pose - The located pose to find the offset for
 * @param CalibrationData - The calibration data to use to find the apporpriate offset
 * @returns The offset to apply to the current pose
 */
export function getCalibrationOffset(
  pose: CartesianPose,
  calibrationData: Space.AccuracyCalibrationEntry[],
): CartesianPose {
  const nearestEntries = getNearestCalibrationEntries(pose, calibrationData, 4);

  const nearestOffsets = nearestEntries.map((entry) => ({
    x: entry.offset.x,
    y: entry.offset.y,
    z: entry.offset.z,
  }));

  const distances = nearestEntries.map((entry) =>
    distanceBetweenPoses(pose, entry.rawPose),
  );

  return {
    ...averagePositions(nearestOffsets, distances, true),
    ...ZERO_ROTATION,
  };
}
