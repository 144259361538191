import * as zod from 'zod';

import { HaasAddress, HaasSerialTarget } from '../../types';

export const HaasControlRegionArguments = zod.object({
  address: zod.union([HaasAddress, HaasSerialTarget]),
  shouldSendCellSafe: zod.boolean(),
});

export type HaasControlRegionArguments = zod.infer<
  typeof HaasControlRegionArguments
>;
