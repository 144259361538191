import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';
import { buildConditional } from '@sb/remote-control/util/conditionalsBuilder';

/**
 * Types matching the routine runner schema for "IF" statements.
 */
export namespace IfStep {
  export const name = 'If';
  export const description = 'Run substeps if condition is met';
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '3';
  export const argumentKind = 'If';

  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    /**
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: zod.string(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'If',
      args: {
        condition: buildConditional(JSON.parse(args.condition)),
      },
    };
  };
}

IfStep satisfies Step.StepKindInfo;
