/* eslint-disable max-classes-per-file, no-bitwise */
import stableJSON from 'json-stringify-deterministic';

import type { Snapshot, Patch } from './types';
import { applyPatch } from './util';

export class StatePatchClient<T extends object> {
  private snapshot: Snapshot<T> | null = null;

  public get hash() {
    return this.snapshot?.hash ?? 0;
  }

  public get state() {
    return this.snapshot?.state;
  }

  public applyPatch(patch: Patch) {
    if (this.snapshot) {
      this.snapshot = applyPatch(this.snapshot, patch);
    }
  }

  public applySnapshot(snapshot: Snapshot<T>) {
    this.snapshot = JSON.parse(stableJSON(snapshot));
  }
}
