export const bell = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 3.5C10.6334 3.5 7.77906 5.97553 7.30294 9.30833L6.51735 14.8075L6.40492 15.2572C6.22392 15.9812 5.77955 16.6118 5.15859 17.0258L4.9453 17.1679L4.5 18V20L5.5 21H10C10 22.2365 10.4231 23.2689 11.2012 23.9856C11.9659 24.6898 12.976 25 14 25C15.024 25 16.0341 24.6898 16.7988 23.9856C17.5769 23.2689 18 22.2365 18 21H22.5L23.5 20V18L23.0547 17.1679L22.8414 17.0258C22.2204 16.6118 21.7761 15.9812 21.5951 15.2572L21.4827 14.8075L20.6971 9.30833C20.2209 5.97553 17.3666 3.5 14 3.5ZM16 21H12C12 21.7635 12.2484 22.2311 12.5561 22.5144C12.8773 22.8102 13.3671 23 14 23C14.6329 23 15.1227 22.8102 15.4439 22.5144C15.7516 22.2311 16 21.7635 16 21ZM9.28284 9.59117C9.6182 7.24367 11.6287 5.5 14 5.5C16.3713 5.5 18.3818 7.24367 18.7172 9.59117L19.5101 15.1414L19.5299 15.2425L19.6548 15.7423C19.9334 16.8568 20.5865 17.8384 21.5 18.5255V19H6.5V18.5255C7.41354 17.8384 8.06658 16.8568 8.34521 15.7423L8.47014 15.2425L8.48995 15.1414L9.28284 9.59117Z"
    fill="currentColor"
  />
);

export const document = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6 3L5 4V24L6 25H22L23 24V13L22.7474 12.3356L14.7474 3.33564L14 3H6ZM7 23V5H13V13L14 14H21V23H7ZM19.7732 12L15 6.6302V12H19.7732Z"
    fill="currentColor"
  />
);

export const house = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.3598 2.23175L1.3598 12.2318L2.64017 13.7682L4.49999 12.2183V24L5.49999 25H22.5L23.5 24V12.2183L25.3598 13.7682L26.6402 12.2318L14.6402 2.23175H13.3598ZM21.5 10.5517L14 4.30168L6.49999 10.5517V23H11V15H17V23H21.5V10.5517Z"
    fill="currentColor"
  />
);

export const shield = (
  <path
    d="M6 16V7L14 4L22 7V16C22 17.259 21.4072 18.4446 20.4 19.2L14 24L7.6 19.2C6.59278 18.4446 6 17.259 6 16Z"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const tag = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20.1584 3.51196L19.8635 3.21708L19.4465 3.21907L14.5203 3.24264L14.1089 3.24461L13.818 3.53553L2.15076 15.2028V16.617L11.3431 25.8094H12.7574L24.4246 14.1421L24.7155 13.8512L24.7175 13.4398L24.7411 8.51363L24.7431 8.09661L24.4482 7.80174L20.1584 3.51196ZM14.9413 5.24065L19.0391 5.22105L22.7391 8.92107L22.7195 13.0188L12.0503 23.6881L4.27208 15.9099L14.9413 5.24065ZM17.1716 10.7885C17.7574 11.3743 18.7072 11.3743 19.293 10.7885C19.8787 10.2027 19.8787 9.25297 19.293 8.66719C18.7072 8.0814 17.7574 8.0814 17.1716 8.66719C16.5859 9.25297 16.5859 10.2027 17.1716 10.7885Z"
    fill="currentColor"
  />
);

export const trash = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9.5 2.5L8.5 3.5V6H4V8H6.05877L7.00183 23.5605L8 24.5H20L20.9982 23.5605L21.9412 8H24V6H19.5V3.5L18.5 2.5H9.5ZM17.5 6V4.5H10.5V6H17.5ZM8.06244 8H19.9376L19.0588 22.5H8.94123L8.06244 8Z"
    fill="currentColor"
  />
);

export const wrench = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.4261 2.80871C20.0793 1.98632 18.6471 1.61624 17.2166 1.79345C15.787 1.97054 14.4611 2.6815 13.3024 3.84016C12.1812 4.9614 11.4786 6.2396 11.2742 7.6186C11.1058 8.7541 11.2846 9.89514 11.765 10.996C9.17908 13.39 6.30778 15.3943 4.61141 16.505L4.55662 16.5409L5.1042 17.3777L4.55661 16.5409L4.45908 16.6046C3.56683 17.187 2.29314 18.0184 1.79083 19.4212C1.16008 21.1828 1.54874 23.2313 2.9626 24.6452L3.10402 24.7866C4.51789 26.2005 6.56645 26.5891 8.32802 25.9583C9.73081 25.456 10.5622 24.1824 11.1445 23.2901L11.2082 23.1926L11.2441 23.1378C12.3548 21.4414 14.3592 18.5701 16.7532 15.9842C17.854 16.4646 18.9951 16.6434 20.1306 16.475C21.5096 16.2705 22.7878 15.568 23.909 14.4468C25.0677 13.2881 25.7786 11.9622 25.9557 10.5326C26.1329 9.10205 25.7629 7.66986 24.9405 6.32303L23.3799 6.13706L19.8408 9.67611L18.0731 7.90834L21.6121 4.36929L21.4261 2.80871ZM14.7166 5.25437C15.6226 4.34844 16.5551 3.89067 17.4625 3.77828C18.0134 3.71003 18.5926 3.76441 19.194 3.95902L15.9517 7.20124V8.61545L19.1337 11.7974H20.5479L23.7902 8.55521C23.9848 9.15653 24.0391 9.73582 23.9709 10.2867C23.8585 11.1941 23.4007 12.1266 22.4948 13.0325C21.6175 13.9099 20.7155 14.3664 19.8372 14.4966C18.9614 14.6265 18.0168 14.4457 17.0256 13.8875L15.8146 14.0651C13.0741 16.9109 10.7862 20.1861 9.57088 22.0423L9.53477 22.0974L10.3715 22.645L9.53476 22.0974C8.8585 23.1308 8.36286 23.8215 7.65378 24.0754C6.59299 24.4553 5.36485 24.219 4.51824 23.3724L4.37682 23.231C3.53021 22.3843 3.29393 21.1562 3.67377 20.0954C3.92767 19.3863 4.61841 18.8907 5.65179 18.2144L5.70696 18.1783L5.15968 17.3424L5.70697 18.1783C7.5631 16.963 10.8383 14.6751 13.684 11.9346L13.8617 10.7236C13.3035 9.73242 13.1227 8.78777 13.2525 7.91194C13.3828 7.0337 13.8393 6.13168 14.7166 5.25437ZM5.50816 22.241C6.09395 22.8268 7.04369 22.8268 7.62948 22.241C8.21527 21.6552 8.21527 20.7055 7.62948 20.1197C7.04369 19.5339 6.09395 19.5339 5.50816 20.1197C4.92237 20.7055 4.92237 21.6552 5.50816 22.241Z"
    fill="currentColor"
  />
);
