import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

import { DefaultTooltipTransform } from '../constants';

export const DHConfiguration = zod.object({
  family: zod.literal('DHBase'),
  name: zod.string().default('DH Gripper'),
  tcpTransform: CartesianPose.default(DefaultTooltipTransform),
  minGripWidth: zod.number().default(0),
  maxGripWidth: zod.number().default(145 / 1_000.0),
});

export type DHConfiguration = zod.infer<typeof DHConfiguration>;
