import * as zod from 'zod';

import { OR2FG7GripKind } from '../constants';

import { OnRobot2FG7FingerConfiguration } from './OnRobot2FG7FingerConfiguration';

const widthState = zod.object({
  // meters
  inner: zod.number(),
  // meters
  outer: zod.number(),
});

export const OnRobot2FG7State = zod.object({
  kind: zod.literal('OnRobot2FG7'),
  error: zod.string().optional(),
  isConnected: zod.boolean(),
  isBusy: zod.boolean(),

  isGripDetected: zod.boolean(),
  // newtons
  force: zod.number().nonnegative(),
  // newtons
  maxForce: zod.number().nonnegative(),

  width: widthState,
  maxWidth: widthState,
  minWidth: widthState,

  gripKind: OR2FG7GripKind.optional(),

  fingerConfiguration: OnRobot2FG7FingerConfiguration,
  // meters
  fingerLength: zod.number().nonnegative(),
  // meters
  fingerHeight: zod.number().nonnegative(),

  // errors
  linearSensorError: zod.boolean(),
  uncalibratedError: zod.boolean(),
});

export type OnRobot2FG7State = zod.infer<typeof OnRobot2FG7State>;
