import * as zod from 'zod';

import { IntegrationActionKind } from '@sb/integrations/io/types';

export const CNCOperateWorkholdingArguments = zod.object({
  deviceID: zod.string(),
  workholdingID: zod.number(),
  actionKind: IntegrationActionKind,
  actionName: zod.string(),
});

export type CNCOperateWorkholdingArguments = zod.infer<
  typeof CNCOperateWorkholdingArguments
>;
