import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function TapIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10C6.27614 10 6.5 10.2239 6.5 10.5V12C6.5 12.2761 6.27614 12.5 6 12.5C5.72386 12.5 5.5 12.2761 5.5 12V10.5C5.5 10.2239 5.72386 10 6 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 5.5C5.5 4.67157 6.17157 4 7 4C7.82843 4 8.5 4.67157 8.5 5.5V8.58271L12.1532 9.24693C13.2192 9.44073 13.937 10.448 13.7722 11.5188L12.9942 16.576C12.9567 16.8199 12.7468 17 12.5 17H6C5.84811 17 5.70445 16.931 5.60957 16.8123L4.26698 15.1341C3.77049 14.5135 3.5 13.7424 3.5 12.9477V11.5C3.5 10.6716 4.17157 10 5 10H5.5V5.5ZM7 5C6.72386 5 6.5 5.22386 6.5 5.5V10.5C6.5 10.7761 6.27614 11 6 11H5C4.72386 11 4.5 11.2239 4.5 11.5V12.9477C4.5 13.5153 4.69321 14.0661 5.04785 14.5094L6.24031 16H12.071L12.7838 11.3667C12.8662 10.8313 12.5073 10.3277 11.9744 10.2308L7.91056 9.49193C7.67281 9.4487 7.5 9.24164 7.5 9V5.5C7.5 5.22386 7.27614 5 7 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.5C5.06805 1.5 3.5 3.06993 3.5 5.00888C3.5 5.30914 3.53751 5.59991 3.60782 5.87702C3.67574 6.14468 3.51382 6.41672 3.24616 6.48464C2.9785 6.55256 2.70646 6.39064 2.63854 6.12298C2.54799 5.76612 2.5 5.39276 2.5 5.00888C2.5 2.51974 4.51368 0.5 7 0.5C9.48632 0.5 11.5 2.51974 11.5 5.00888C11.5 5.39276 11.452 5.76612 11.3615 6.12298C11.2935 6.39064 11.0215 6.55256 10.7538 6.48464C10.4862 6.41672 10.3243 6.14468 10.3922 5.87702C10.4625 5.59991 10.5 5.30914 10.5 5.00888C10.5 3.06993 8.93195 1.5 7 1.5Z"
        fillOpacity="0.3"
      />
    </Icon>
  );
}
