import * as zod from 'zod';

import { ExternalControlSettings } from '@sb/routine-runner';

export const ExternalControlSettingsData = ExternalControlSettings.extend({
  id: zod.string(),
  robotID: zod.string(),
});

export type ExternalControlSettingsData = zod.infer<
  typeof ExternalControlSettingsData
>;
