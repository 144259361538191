import * as zod from 'zod';

import { StepSummary } from './step';

export const RoutineData = zod.object({
  id: zod.string(),
  configurationUpdatedAt: zod.string().nullable(),
  createdAt: zod.string(),
  createdByID: zod.string(),
  createdByName: zod.string().nullable(),
  createdByPhoto: zod.string().nullable(),
  description: zod.string(),
  environmentVariables: zod
    .array(
      zod.object({
        id: zod.string(),
        name: zod.string(),
        initialValue: zod.any().optional(),
      }),
    )
    .nullable(),
  isTemplate: zod.boolean(),
  name: zod.string(),
  mainLoopStepID: zod.string().nullable(),
  space: zod
    .array(
      zod
        .object({
          description: zod.string(),
          id: zod.string(),
          kind: zod.string(),
          name: zod.string(),
        })
        .passthrough(), // additional props are valid
    )
    .nullable(),
  steps: zod.array(StepSummary),
  stepConfigurations: zod
    .record(
      zod.object({
        args: zod.any().optional(),
        description: zod.string().optional(),
      }),
    )
    .nullable(),
  updatedAt: zod.string(),
});

export type RoutineData = zod.infer<typeof RoutineData>;
