import * as zod from 'zod';

import { Conditional } from '../../types';

export default zod.union([
  zod.object({
    milliseconds: zod.number().int().nonnegative(),
  }),
  zod.object({
    condition: Conditional,
  }),
]);
