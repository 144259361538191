export const square = (
  <path
    d="M5 23V5H23V23H5Z"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const squareGrid2x2 = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5 4L4 5V12L5 13H12L13 12V5L12 4H5ZM6 11V6H11V11H6ZM16 4L15 5V12L16 13H23L24 12V5L23 4H16ZM17 11V6H22V11H17ZM4 16L5 15H12L13 16V23L12 24H5L4 23V16ZM6 17V22H11V17H6ZM16 15L15 16V23L16 24H23L24 23V16L23 15H16ZM17 22V17H22V22H17Z"
    fill="currentColor"
  />
);

export const squareGrid3x3 = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.5 4.5L4.5 3.5H8.5L9.5 4.5V8.5L8.5 9.5H4.5L3.5 8.5V4.5ZM5.5 5.5V7.5H7.5V5.5H5.5ZM11 4.5L12 3.5H16L17 4.5V8.5L16 9.5H12L11 8.5V4.5ZM13 5.5V7.5H15V5.5H13ZM19.5 3.5L18.5 4.5V8.5L19.5 9.5H23.5L24.5 8.5V4.5L23.5 3.5H19.5ZM20.5 7.5V5.5H22.5V7.5H20.5ZM3.5 12L4.5 11H8.5L9.5 12V16L8.5 17H4.5L3.5 16V12ZM5.5 13V15H7.5V13H5.5ZM12 11L11 12V16L12 17H16L17 16V12L16 11H12ZM13 15V13H15V15H13ZM18.5 12L19.5 11H23.5L24.5 12V16L23.5 17H19.5L18.5 16V12ZM20.5 13V15H22.5V13H20.5ZM4.5 18.5L3.5 19.5V23.5L4.5 24.5H8.5L9.5 23.5V19.5L8.5 18.5H4.5ZM5.5 22.5V20.5H7.5V22.5H5.5ZM11 19.5L12 18.5H16L17 19.5V23.5L16 24.5H12L11 23.5V19.5ZM13 20.5V22.5H15V20.5H13ZM19.5 18.5L18.5 19.5V23.5L19.5 24.5H23.5L24.5 23.5V19.5L23.5 18.5H19.5ZM20.5 22.5V20.5H22.5V22.5H20.5Z"
    fill="currentColor"
  />
);

export const squareOnSquare = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 3L3 4V19L4 20L8 20V24L9 25H24L25 24V9L24 8H20L20 4L19 3H4ZM18 8L18 5H5V18L8 18V9L9 8H18ZM10 23V10H23V23H10Z"
    fill="currentColor"
  />
);

export const squareGrid3x3Camera = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.5 4.5L4.5 3.5H8.5L9.5 4.5V8.5L8.5 9.5H4.5L3.5 8.5V4.5ZM5.5 5.5V7.5H7.5V5.5H5.5ZM11 4.5L12 3.5H16L17 4.5V8.5L16 9.5H12L11 8.5V4.5ZM13 5.5V7.5H15V5.5H13ZM19.5 3.5L18.5 4.5V8.5L19.5 9.5H23.5L24.5 8.5V4.5L23.5 3.5H19.5ZM20.5 7.5V5.5H22.5V7.5H20.5ZM3.5 12L4.5 11H8.5L9.5 12V16L8.5 17H4.5L3.5 16V12ZM5.5 13V15H7.5V13H5.5ZM12 11L11 12V16L12 17H14V16.3787L15.3787 15H17V12L16 11H12ZM11 19.5L12 18.5H14V20.5H13V22.5H14V24.5H12L11 23.5V19.5ZM15.5 18.5V17L16 16.5H16.5H18.2324L18.5394 16.0394L19.084 15.2226L19.5 15H20.5H22.5H23.5L23.916 15.2226L24.4606 16.0394L24.7676 16.5H27L27.5 17V24.5L27 25H16L15.5 24.5V18.5ZM24.5 12V13.8339L23.8761 13.5H22.5V13H20.5V13.5H19.1239L18.5 13.8339V12L19.5 11H23.5L24.5 12ZM13 15V13H15V15H13ZM4.5 18.5L3.5 19.5V23.5L4.5 24.5H8.5L9.5 23.5V19.5L8.5 18.5H4.5ZM5.5 22.5V20.5H7.5V22.5H5.5ZM23.3875 18.5C22.895 18.035 22.2308 17.75 21.5 17.75C20.7692 17.75 20.105 18.035 19.6125 18.5C19.0814 19.0014 18.75 19.712 18.75 20.5C18.75 22.0188 19.9812 23.25 21.5 23.25C22.9772 23.25 24.1824 22.0852 24.2473 20.624C24.2489 20.5859 24.2499 20.5477 24.25 20.5092C24.25 20.5062 24.25 20.5031 24.25 20.5C24.25 19.8352 24.0141 19.2255 23.6214 18.75C23.5487 18.6619 23.4705 18.5784 23.3875 18.5ZM23.25 20.5C23.25 21.0947 22.9534 21.6201 22.5 21.9363C22.2165 22.134 21.8718 22.25 21.5 22.25C21.1282 22.25 20.7835 22.134 20.5 21.9363C20.0466 21.6201 19.75 21.0947 19.75 20.5C19.75 19.5335 20.5335 18.75 21.5 18.75C22.4665 18.75 23.25 19.5335 23.25 20.5ZM25.25 19.375C25.5952 19.375 25.875 19.0952 25.875 18.75C25.875 18.4048 25.5952 18.125 25.25 18.125C24.9048 18.125 24.625 18.4048 24.625 18.75C24.625 19.0952 24.9048 19.375 25.25 19.375Z"
    fill="currentColor"
  />
);
