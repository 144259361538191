import * as zod from 'zod';

import { DeviceCommand } from '@sb/integrations/types';
import { PayloadState } from '@sb/routine-runner/types/PayloadState';

export const ActuateGripperArguments = zod.object({
  gripperCommand: DeviceCommand,
  payload: PayloadState.optional(),
  retry: zod.boolean().optional(),
  stopRoutineOnFailure: zod.boolean(),
});

export type ActuateGripperArguments = zod.infer<typeof ActuateGripperArguments>;
