import * as zod from 'zod';

export const CNCRunProgramArguments = zod.object({
  deviceID: zod.string(),
  haasProgram: zod.string(),
  haasCellSafe: zod.boolean(),
  waitUntilComplete: zod.boolean(),
});

export type CNCRunProgramArguments = zod.infer<typeof CNCRunProgramArguments>;
