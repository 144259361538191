/**
 * External to robot port: A label corresponding to an input port on the IO board
 */
import * as zod from 'zod';

export const INPUT_PORT_IDS = [
  'Input 1',
  'Input 2',
  'Input 3',
  'Input 4',
  'Input 5',
  'Input 6',
  'Input 7',
  'Input 8',
  'Input 9',
  'Input 10',
  'Input 11',
  'Input 12',
  'Input 13',
  'Input 14',
  'Input 15',
  'Input 16',
] as const;

export const INPUT_PORT_COUNT = INPUT_PORT_IDS.length;

export const InputPortKind = zod.literal('Input');

export type InputPortKind = zod.infer<typeof InputPortKind>;

export const InputPortID = zod.enum(INPUT_PORT_IDS);

export type InputPortID = zod.infer<typeof InputPortID>;
