import { roundToDecimalPlaces } from '@sb/utilities';

import type { MotionPlanRequest } from './MotionPlanRequest';

/**
 * Function to get a cache key for a motion plan request.
 * Separated into another file for cleanliness and easier testing.
 *
 * @param request
 * @param decimalDigits
 */
export function getMotionPlanRequestCacheKey(
  request: MotionPlanRequest,
  decimalDigits = 3,
): string {
  return JSON.stringify(request, (key: string, val: any) => {
    if (key === 'startingJointPositions' && Array.isArray(val)) {
      return val.map((el) => {
        return roundToDecimalPlaces(el, decimalDigits);
      });
    }

    return val;
  });
}
