/**
 * Recovery plan request: The full payload for a request for a recovery plan
 * from the motion planner.
 */
import type * as zod from 'zod';

import { ArmJointPositions } from './ArmJointPositions';
import { GripperOpenness } from './GripperOpenness';
import { MotionPlanOptions } from './MotionPlanOptions';

export const RecoveryPlanRequest = MotionPlanOptions.extend({
  gripperOpenness: GripperOpenness,
  startingJointPositions: ArmJointPositions,
});

export type RecoveryPlanRequest = zod.infer<typeof RecoveryPlanRequest>;
