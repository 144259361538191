import { NotAuthenticated } from '@feathersjs/errors';
import { BehaviorSubject } from 'rxjs';

import { globalCache } from '@sbrc/utils';

import { getApp } from '../../utils';

export function currentUserID() {
  return globalCache('currentUserID', () => {
    const userID$ = new BehaviorSubject<string | null | undefined>(undefined);

    const api = {
      set(value: string | null) {
        userID$.next(value);
      },
      get() {
        return userID$.value;
      },
      asObservable() {
        return userID$.asObservable();
      },
    };

    const reauth = async () => {
      try {
        const result = await getApp().reAuthenticate();
        api.set(result.user.id);
      } catch {
        api.set(null);
      }
    };

    reauth();

    getApp().hooks({
      error(context) {
        // listen for any NotAuthenticated errors which happen
        // when calling any service (except authentication itself)
        if (
          context.error instanceof NotAuthenticated &&
          context.path !== 'authentication'
        ) {
          // Clear current user
          // Inside a <PrivatePage> this will navigate to the login page
          api.set(null);
        }

        return context;
      },
    });

    return api;
  });
}
