import cx from 'classnames';

export type InputVariant = 'Elevated' | 'Gray' | 'Flat';

export const INPUT_VARIANT_CLASSES: Record<InputVariant, string> = {
  Elevated: cx(
    'tw-surface-elevated',
    'tw-shadow-30',
    'disabled:tw-bg-fill-primary',
    'disabled:tw-shadow-none',
    '[&:has(>:disabled)]:tw-bg-fill-primary',
    '[&:has(>:disabled)]:tw-shadow-none',
  ),
  Gray: cx('tw-bg-fill-primary'),
  Flat: cx('tw-bg-transparent', 'tw-p-0'),
};

export const INPUT_VARIANT_DEFAULT = 'Elevated';
