import { clamp } from 'lodash';

import type { ActuateGripperStepDatabase } from '@sb/integrations/implementations/gripper-general/step-wizard/ActuateGripperStepDatabase';

import type { SchunkEGxConfiguration } from '../types/SchunkEGxConfiguration';
import { schunkEGxVariantInfo } from '../utils/schunkEGxVariantInfo';

export function generateCommandInfo(
  gripperConfiguration: SchunkEGxConfiguration,
  stepArgs: ActuateGripperStepDatabase.Arguments,
) {
  const {
    isSoftGripSupported,
    isStrongGripSupported,
    isGPESupported,
    maxStrongGripForce,
    minSpeed,
    maxSpeed: absoluteMaxSpeed,
    minPosition,
    maxPosition,
  } = schunkEGxVariantInfo(
    gripperConfiguration.variant,
    gripperConfiguration.zeroPositionOffset,
  );

  const isGrip = stepArgs.waitForGripToContinue || stepArgs.isFlexGrip;

  const isSoftGrip = (isSoftGripSupported && stepArgs.isSoftGrip) ?? false;

  const isStrongGrip =
    (isStrongGripSupported && stepArgs.isStrongGrip) ?? false;

  const minPositionMM = Math.ceil(minPosition * 1000);
  const maxPositionMM = Math.floor(maxPosition * 1000);

  const bPosition =
    stepArgs.gripKind === 'inward' ? minPositionMM : maxPositionMM;

  const diameterMM = clamp(
    stepArgs.isFlexGrip ? bPosition : stepArgs.diameterMM,
    minPositionMM,
    maxPositionMM,
  );

  const minForcePercent = isStrongGrip ? 1.01 : 0.5;
  const maxForcePercent = isStrongGrip ? maxStrongGripForce : 1;

  const forcePercent = isGrip
    ? clamp(stepArgs.forcePercent ?? 0, minForcePercent, maxForcePercent)
    : 0;

  const maxSpeed = isSoftGrip
    ? absoluteMaxSpeed * forcePercent
    : absoluteMaxSpeed;

  const speed = clamp(
    isGrip && !isSoftGrip
      ? absoluteMaxSpeed * forcePercent
      : stepArgs.speed ?? 0,
    minSpeed,
    maxSpeed,
  );

  return {
    isSoftGripSupported,
    isStrongGripSupported,
    isGPESupported,
    isGrip,
    isSoftGrip,
    isStrongGrip,
    minPositionMM,
    maxPositionMM,
    diameterMM,
    minForcePercent,
    maxForcePercent,
    forcePercent,
    minSpeed,
    maxSpeed,
    speed,
  };
}
