import { FailureKind } from '@sb/routine-runner/FailureKind';
import Step from '@sb/routine-runner/Step/Step';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';

import { HaasControlRegionArguments } from './Arguments';
import { HaasControlRegionVariables } from './Variables';

export class HaasControlRegionStep extends Step<
  HaasControlRegionArguments,
  HaasControlRegionVariables
> {
  public static areSubstepsRequired = true;

  public static Arguments = HaasControlRegionArguments;

  public static Variables = HaasControlRegionVariables;

  public substeps: Array<Step<object, object>> = [];

  protected initializeVariableState(): void {
    this.variables = {
      haasProgram: '',
      haasStatus: 'disconnected',
      haasPartCount: NaN,
    };
  }

  private offMachineStatus?: () => void;

  public async _play({
    fail,
    playSubSteps,
    hasBeenPreempted,
  }: StepPlayArguments): Promise<void> {
    const haas = await this.routineContext.equipment.getHaas(this.args.address);

    if (this.args.shouldSendCellSafe) {
      haas.startCellSafe();
    }

    this.offMachineStatus = haas.onMachineStatus((status) => {
      this.variables = {
        haasPartCount: status.parts,
        haasProgram: status.program,
        haasStatus: status.status,
      };
    });

    let haasError: Error | undefined;

    haas.onError((error) => {
      haasError = error;
    });

    await playSubSteps({
      hasBeenPreempted() {
        return !!haasError || hasBeenPreempted();
      },
    });

    haas.stopCellSafe();

    if (haasError) {
      return fail({
        failure: {
          kind: FailureKind.HaasFailure,
        },
        failureReason: `Haas encountered an error: ${haasError.message}`,
        error: haasError,
      });
    }

    return undefined;
  }

  public _stop(): void {
    if (this.offMachineStatus) {
      this.offMachineStatus();
    }

    this.initializeVariableState();
  }
}
