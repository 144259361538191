import cx from 'classnames';
import { forwardRef } from 'react';

import { TickIcon } from '@sb/ui/icons';

import Typography from './Typography';

import styles from './Checkbox.module.css';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isDarkMode?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    checked,
    children,
    className,
    disabled,
    isDarkMode,
    onChange,
    ...other
  } = props;

  return (
    <Typography
      className={cx(styles.checkbox, className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.dark]: isDarkMode,
      })}
      component="label"
    >
      <span className={styles.checkmark}>
        <TickIcon className={styles.tickIcon} />
      </span>
      {children}
      <input
        ref={ref}
        checked={checked}
        className={styles.input}
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        {...other}
      />
    </Typography>
  );
});

export default Checkbox;
