import * as zod from 'zod';

import { TeleopMessageIn } from '@sb/skills/TeleopTypes';

export const StandardBotsRO1State = zod.object({
  kind: zod.literal('StandardBotsRO1'),
  teleopState: TeleopMessageIn.optional(),
});

export type StandardBotsRO1State = zod.infer<typeof StandardBotsRO1State>;
