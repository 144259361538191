import type { RoutineData } from '@sb/feathers-types';
import * as log from '@sb/log/log';
import { StepConfigurationByKind } from '@sb/remote-control/step/stepConfigurationByKind';
import type { Step } from '@sb/remote-control/types';

import { listAllSteps } from './listAllSteps';
import { getStepKindInfo } from './stepKindInfo';

/**
 * Convert step configurations in the db for use in the front end
 * Ensure there is a step configuration for every step in the structure
 */
export function convertStepConfigurations(
  routineID: string,
  steps: Step.ConvertedSummary[],
  configurations: RoutineData['stepConfigurations'],
): Record<string, Step.ConvertedConfiguration> {
  const allSteps = listAllSteps(steps);

  const convertedStepConfigurations: Record<
    string,
    Step.ConvertedConfiguration
  > = {};

  for (const step of allSteps) {
    const configuration = configurations?.[step.id];

    const parsedConfiguration = StepConfigurationByKind.safeParse({
      stepKind: step.stepKind,
      args: configuration?.args,
    });

    if (!parsedConfiguration.success) {
      log.warn(
        'convertStepConfiguration.error',
        'Error parsing step configuration',
        {
          stepID: step.id,
          stepKind: step.stepKind,
          args: configuration?.args,
          error: parsedConfiguration.error.issues,
        },
      );
    }

    const configurationByKind = parsedConfiguration.success
      ? parsedConfiguration.data
      : ({ stepKind: step.stepKind } as StepConfigurationByKind);

    convertedStepConfigurations[step.id] = {
      ...configurationByKind,
      description: configuration?.description || step.description,
      id: step.id,
      name: getStepKindInfo(step.stepKind).name,
      routineID,
    };
  }

  return convertedStepConfigurations;
}
