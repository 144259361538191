import * as zod from 'zod';

import { IntegrationAction } from './IntegrationAction';

export const IntegrationActionGroup = zod.object({
  name: zod.string(),
  actions: IntegrationAction.array().default([]),
});

export type IntegrationActionGroup = zod.infer<typeof IntegrationActionGroup>;
