import * as zod from 'zod';

export const HaasControlRegionVariables = zod.object({
  haasProgram: zod.string(),
  haasStatus: zod.string(),
  haasPartCount: zod.number().int().nonnegative(),
});

export type HaasControlRegionVariables = zod.infer<
  typeof HaasControlRegionVariables
>;
