import type { EquipmentInterface, StepFailure } from '@sb/routine-runner';
import { FailureKind } from '@sb/routine-runner/FailureKind';

/**
 * Wraps a function to set Haas Cell safe to desired value before, and reset after
 */
export function haasCellSafeWrapper(
  fn: () => Promise<void>,
  args: { deviceID: string; haasCellSafe: boolean },
  equipment: EquipmentInterface,
  fail: (failure: StepFailure) => Promise<void>,
): () => Promise<void> {
  const initialState = equipment
    .getEquipmentState()
    .find((device) => device.id === args.deviceID);

  if (
    initialState?.state.kind !== 'CNCMachine' ||
    !initialState.state.isCellSafeSupported ||
    initialState.state.isCellSafe === args.haasCellSafe
  ) {
    return fn;
  }

  return async () => {
    try {
      await equipment.executeCommand({
        kind: 'CNCMachineCommand',
        deviceID: args.deviceID,
        subCommand: {
          kind: 'haasCellSafe',
          isEnabled: args.haasCellSafe,
        },
      });
    } catch (error) {
      fail({
        failure: { kind: FailureKind.HaasFailure },
        failureReason: 'Set cell safe failed',
        error,
      });

      return;
    }

    await fn();

    try {
      await equipment.executeCommand({
        kind: 'CNCMachineCommand',
        deviceID: args.deviceID,
        subCommand: {
          kind: 'haasCellSafe',
          isEnabled: !args.haasCellSafe,
        },
      });
    } catch (error) {
      fail({
        failure: { kind: FailureKind.HaasFailure },
        failureReason: 'Reset cell safe failed',
        error,
      });
    }
  };
}
