import { SoftwareUpdateData } from '@sb/feathers-types';

import { getService } from '../utils';

export async function getSoftwareUpdateData(): Promise<SoftwareUpdateData | null> {
  const dataService = getService('softwareUpdate')();

  const response = await dataService.find({ query: {} });

  const parsedResponse = SoftwareUpdateData.safeParse(response);

  // i.e. feathers-bot is running a different version that remote-control (during upgrade)
  if (!parsedResponse.success) {
    return null;
  }

  return parsedResponse.data;
}
