import * as zod from 'zod';

import {
  OR3FG15FingerPosition,
  OR_3FG15_DEFAULT_FINGER_LENGTH,
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_FINGER_POSITION_DEFAULT,
} from '../constants';

export const OnRobot3FG15FingerConfiguration = zod
  .object({
    lengthInMeters: zod.number().default(OR_3FG15_DEFAULT_FINGER_LENGTH),
    mountingPosition: OR3FG15FingerPosition.default(
      OR_3FG15_FINGER_POSITION_DEFAULT,
    ),
    offsetInMeters: zod.number().default(OR_3FG15_FINGERTIP_DIAMETER_DEFAULT),
  })
  .default({});

export type OnRobot3FG15FingerConfiguration = zod.infer<
  typeof OnRobot3FG15FingerConfiguration
>;
