import cx from 'classnames';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './SquareButton.module.css';

interface SquareButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const SquareButton = forwardRef<HTMLButtonElement, SquareButtonProps>(
  (props, ref) => {
    const { children, className } = props;

    return (
      <button
        {...props}
        className={cx(styles.squareButton, className)}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

export default SquareButton;
