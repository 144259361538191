import cx from 'classnames';
import { forwardRef } from 'react';

import type { InputVariant } from '../inputVariant';
import { INPUT_VARIANT_CLASSES, INPUT_VARIANT_DEFAULT } from '../inputVariant';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: InputVariant;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, variant = INPUT_VARIANT_DEFAULT, ...rest }, ref) => {
    return (
      <textarea
        className={cx(
          className,
          'tw-input-auto',
          INPUT_VARIANT_CLASSES[variant],
          variant === 'Flat' && 'tw-p-0',
          'tw-inline-block',
          'tw-text-17',
          'tw-text-label-primary',
          'disabled:tw-bg-fill-primary',
          'disabled:tw-shadow-none',
          'placeholder:tw-text-label-quaternary',
          'disabled:tw-text-label-quaternary',
          'tw-select-auto',
          'tw-resize-none',
        )}
        {...rest}
        ref={ref}
      />
    );
  },
);
