export const star = (
  <path
    d="M14 2L16.6942 10.2918H25.4127L18.3593 15.4164L21.0534 23.7082L14 18.5836L6.94658 23.7082L9.64074 15.4164L2.58732 10.2918H11.3058L14 2Z"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
    fill="none"
  />
);

export const starFill = (
  <path
    d="M13.0489 1.69092L10.5793 9.29173H2.58733L1.99954 11.1007L8.46518 15.7983L5.99553 23.3991L7.53437 24.5172L14 19.8196L20.4656 24.5172L22.0045 23.3991L19.5348 15.7983L26.0005 11.1007L25.4127 9.29173H17.4207L14.9511 1.69092H13.0489Z"
    fill="currentColor"
  />
);

export const starHalfFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.5793 9.29173L13.0489 1.69092H14L14.9511 1.69092L17.4207 9.29173H25.4127L26.0005 11.1008L19.5348 15.7983L22.0045 23.3991L20.4656 24.5172L14 19.8196L7.53437 24.5172L5.99553 23.3991L8.46518 15.7983L1.99954 11.1007L2.58732 9.29173L2.58733 9.29173H10.5793ZM14 17.7745H14.5878L19.1513 21.0901L17.4082 15.7254L17.7715 14.6073L22.335 11.2917H16.6942L15.7431 10.6008L14 5.23601L14 17.7745Z"
    fill="currentColor"
  />
);
