export const convertRadiansToDegrees = (radians: number): number =>
  (radians * 180) / Math.PI;

export const convertDegreesToRadians = (degrees: number): number =>
  (degrees * Math.PI) / 180;

export const convertMsToHHMMSS = (ms: number) => {
  const pad = (time: number) => time.toString().padStart(2, '0');

  const seconds = pad(Math.floor((ms / 1000) % 60));
  const minutes = pad(Math.floor((ms / 1000 / 60) % 60));
  const hours = pad(Math.floor(ms / 1000 / 3600));

  return `${hours}:${minutes}:${seconds}`;
};

export const getUnitStringConverter =
  (unit: string, places: number = 0) =>
  (value: number) => {
    if (Number.isNaN(value)) {
      return '';
    }

    return `${value.toFixed(places)}${unit}`;
  };

export const getPercentageToUnitStringConverter =
  (unit: string, places: number = 0) =>
  (value: number) => {
    return `${(value * 100).toFixed(places)}${unit}`;
  };

export const diameterMMToString = getUnitStringConverter('mm');

export const forceToString = getUnitStringConverter('N');

export const massToString = getUnitStringConverter('kg');

export function convertMetersToMillimeterString(meters: number) {
  return diameterMMToString(meters * 1000);
}
