export const exclamationBrake = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6 14C6 9.58172 9.58173 6 14 6C18.4183 6 22 9.58172 22 14C22 18.4183 18.4183 22 14 22C9.58173 22 6 18.4183 6 14ZM14 4C8.47716 4 4 8.47715 4 14C4 19.5228 8.47716 24 14 24C19.5229 24 24 19.5228 24 14C24 8.47715 19.5229 4 14 4ZM14 19.75C14.8284 19.75 15.5 19.0784 15.5 18.25C15.5 17.4216 14.8284 16.75 14 16.75C13.1716 16.75 12.5 17.4216 12.5 18.25C12.5 19.0784 13.1716 19.75 14 19.75ZM13 15L12.75 8.5H15.25L15 15H13ZM2 13.9898C2.0026 10.9206 3.17398 7.85546 5.51472 5.51472L4.1005 4.10051C1.37007 6.83094 0.00303624 10.4107 5.08044e-06 13.9881C-0.00303253 17.5734 1.36399 21.163 4.1005 23.8995L5.51472 22.4853C3.16878 20.1393 1.9974 17.0658 2 13.9898ZM22.4853 5.51472C24.826 7.85546 25.9974 10.9206 26 13.9898C26.0026 17.0658 24.8312 20.1393 22.4853 22.4853L23.8995 23.8995C26.636 21.163 28.003 17.5734 28 13.9881C27.997 10.4107 26.6299 6.83094 23.8995 4.10051L22.4853 5.51472Z"
    fill="currentColor"
  />
);

export const exclamationCircle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14C5.25 9.16751 9.16751 5.25 14 5.25C18.8325 5.25 22.75 9.16751 22.75 14C22.75 18.8325 18.8325 22.75 14 22.75C9.16751 22.75 5.25 18.8325 5.25 14ZM14 3.25C8.06294 3.25 3.25 8.06294 3.25 14C3.25 19.9371 8.06294 24.75 14 24.75C19.9371 24.75 24.75 19.9371 24.75 14C24.75 8.06294 19.9371 3.25 14 3.25ZM13 15L12.75 8.5H15.25L15 15H13ZM15.5 18.25C15.5 19.0784 14.8284 19.75 14 19.75C13.1716 19.75 12.5 19.0784 12.5 18.25C12.5 17.4216 13.1716 16.75 14 16.75C14.8284 16.75 15.5 17.4216 15.5 18.25Z"
    fill="currentColor"
  />
);

export const exclamationCircleFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM13 15L12.75 8.5H15.25L15 15H13ZM15.5 18.25C15.5 19.0784 14.8284 19.75 14 19.75C13.1716 19.75 12.5 19.0784 12.5 18.25C12.5 17.4216 13.1716 16.75 14 16.75C14.8284 16.75 15.5 17.4216 15.5 18.25Z"
    fill="currentColor"
  />
);

export const exclamationTriangle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.13736 22.4942L13.1374 3.73419H14.8626L25.8626 22.4942L25 24H3L2.13736 22.4942ZM23.2544 22L14 6.21702L4.74558 22H23.2544ZM15.5 19.25C15.5 20.0784 14.8284 20.75 14 20.75C13.1716 20.75 12.5 20.0784 12.5 19.25C12.5 18.4216 13.1716 17.75 14 17.75C14.8284 17.75 15.5 18.4216 15.5 19.25ZM12.75 10.5L13 16.5H15L15.25 10.5H12.75Z"
    fill="currentColor"
  />
);
