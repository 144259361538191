import { Euler, Quaternion } from 'three';

export const OR_DUAL_QUCK_CHANGER_SHORTNAME = 'Dual Quick Changer';

const primaryRotation = new Quaternion().setFromEuler(
  new Euler(0, Math.PI / 3, 0),
);

const secondaryRotation = new Quaternion().setFromEuler(
  new Euler(0, -Math.PI / 3, 0),
);

export const OR_DUAL_QUICK_CHANGER_PRIMARY_POSE = {
  i: primaryRotation.x,
  j: primaryRotation.y,
  k: primaryRotation.z,
  w: primaryRotation.w,
  x: 0.0464,
  y: 0,
  z: 0.0638,
};

export const OR_DUAL_QUICK_CHANGER_SECONDARY_POSE = {
  i: secondaryRotation.x,
  j: secondaryRotation.y,
  k: secondaryRotation.z,
  w: secondaryRotation.w,
  x: -0.0464,
  y: 0,
  z: 0.0638,
};
