import { getService } from '../utils';

export async function requestSoftwareInstall(
  joints: number[],
  offlineUpdatePath?: string,
  buildId?: string,
): Promise<void> {
  const dataService = getService('softwareUpdate')();

  await dataService.create({
    isSoftwareInstallRequested: true,
    requestFirmwareInstallData: {
      joints,
    },
    targetBuildID: buildId,
    offlineUpdatePath,
  });
}
