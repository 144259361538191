export enum FailureKind {
  /**
   * Planning a motion failed. Recovery is just acknowledgement from the user.
   */
  PlanningFailure = 'PlanningFailure',

  /**
   * Executing a motion failed. Recovery is just acknowledgement from the user.
   */
  ExecutionFailure = 'ExecutionFailure',

  /**
   * General control system error
   */
  ControlSystemFailure = 'ControlSystemFailure',

  /**
   * The E-Stop button was pressed/triggered
   */
  EStopTriggered = 'EStopTriggered',

  /**
   * The gripper encountered some kind of failure
   */
  GripperFailure = 'GripperFailure',

  /**
   * Robot violated the joint limits. Recovery is a recovery path that slowly the arm
   * back into its limits.
   */
  OutOfJointLimitsFailure = 'OutOfJointLimitsFailure',

  /**
   * Robot is in (self) collision detected through kinematics
   */
  CollisionFailure = 'CollisionFailure',

  /**
   * Detected a torque discrepancy greater than collision limit
   */
  TorqueCollisionFailure = 'TorqueCollisionFailure',

  /**
   * In boxed position: nothing else is possible until unboxed
   */
  InBoxingPositionFailure = 'InBoxingPositionFailure',

  /**
   * The power board failed it's health check
   */
  PowerBoardCheckFailure = 'PowerBoardCheckFailure',

  /**
   * The motion planner encountered some form of failure, ranging from
   * the routine runner not being able to connect to encountering a
   * runtime error.
   */
  MotionPlannerFailure = 'MotionPlannerFailure',

  /**
   * The motion planner fails to plan because the starting position is in collision
   */
  MotionPlannerFailureStartPositionCollision = 'MotionPlannerFailureStartPositionCollision',

  /**
   * There is currently a triggered IO safeguard rule that prevents
   * the routine from running.
   */
  IOFailure = 'IOFailure',

  /**
   * An invalid routine has been loaded. Recovery will unload the currently loaded routine.
   */
  InvalidRoutineLoadedFailure = 'InvalidRoutineLoadedFailure',

  /**
   * Inference failure. Recovery just acknowledges the problem.
   */
  InferenceFailure = 'InferenceFailure',

  /**
   * Camera failure. Recovery just acknowledges the problem
   */
  CameraFailure = 'CameraFailure',

  /**
   * Failures with a Haas machine. Recovery just acknowledges the problem.
   */
  HaasFailure = 'HaasFailure',

  /**
   * The heartbeat sent from botman to arm-control-bot was lost.
   */
  BotmanHeartbeatLost = 'BotmanHeartbeatLost',

  /**
   * An internal error occured; primarily programming errors. Not recoverable.
   * If problem persists, user should contact support.
   */
  InternalFailure = 'InternalFailure',

  TorqueLimitExceeded = 'TorqueLimitExceeded',

  /**
   * Any failure occurred while running a step.
   */
  StepPlayFailure = 'StepPlayFailure',

  /**
   * Unbraking failed
   */
  UnbrakeFailure = 'UnbrakeFailure',

  /**
   * Weld step failed
   */
  WeldFailure = 'WeldFailure',
}
