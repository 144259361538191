export const questionmarkCircle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14C5.25 9.16751 9.16751 5.25 14 5.25C18.8325 5.25 22.75 9.16751 22.75 14C22.75 18.8325 18.8325 22.75 14 22.75C9.16751 22.75 5.25 18.8325 5.25 14ZM14 3.25C8.06294 3.25 3.25 8.06294 3.25 14C3.25 19.9371 8.06294 24.75 14 24.75C19.9371 24.75 24.75 19.9371 24.75 14C24.75 8.06294 19.9371 3.25 14 3.25ZM12.8179 10.284C12.6347 10.4927 12.48 10.8157 12.48 11.3398H10.48C10.48 10.3815 10.779 9.57494 11.315 8.96445C11.8409 8.36556 12.5358 8.02168 13.2282 7.86465C14.5468 7.56564 16.1585 7.89057 17.0758 8.87247C17.9249 9.78126 18.1442 10.8564 17.9582 11.8224C17.7801 12.7468 17.2378 13.5532 16.5845 14.0564C16.2623 14.3045 15.9286 14.5316 15.7071 14.6822L15.7071 14.6822L15.7071 14.6822C15.6155 14.7445 15.5432 14.7937 15.4988 14.8259C15.3184 14.957 15.2383 15.0372 15.1411 15.2034C14.9992 15.4462 14.9994 15.6775 14.9999 16.3582L15 16.4999H13C13 16.4392 12.9991 16.3739 12.9981 16.3047L12.9981 16.3045C12.9902 15.7488 12.9787 14.9396 13.4145 14.194C13.6866 13.7287 13.9835 13.4546 14.3234 13.2077C14.4684 13.1024 14.585 13.0242 14.7027 12.9451L14.7027 12.9451C14.8843 12.8232 15.0686 12.6995 15.364 12.472C15.6531 12.2493 15.9137 11.8621 15.9943 11.4441C16.0668 11.0674 16.0004 10.651 15.6144 10.2378C15.2966 9.89771 14.4758 9.63252 13.6706 9.81512C13.3011 9.8989 13.0113 10.0638 12.8179 10.284ZM15.25 18.75C15.25 19.4403 14.6904 20 14 20C13.3096 20 12.75 19.4403 12.75 18.75C12.75 18.0596 13.3096 17.5 14 17.5C14.6904 17.5 15.25 18.0596 15.25 18.75Z"
    fill="currentColor"
  />
);

export const questionmarkCircleFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM12.818 10.284C12.6347 10.4927 12.48 10.8157 12.48 11.3398H10.48C10.48 10.3815 10.779 9.57495 11.315 8.96445C11.8409 8.36556 12.5358 8.02168 13.2283 7.86465C14.5468 7.56564 16.1585 7.89057 17.0758 8.87247C17.9249 9.78126 18.1442 10.8564 17.9582 11.8224C17.7801 12.7468 17.2378 13.5532 16.5845 14.0564C16.2623 14.3045 15.9286 14.5316 15.7071 14.6822L15.7071 14.6822L15.707 14.6823C15.6155 14.7445 15.5432 14.7937 15.4988 14.8259C15.3184 14.957 15.2383 15.0373 15.1411 15.2034C14.9992 15.4462 14.9994 15.6775 15 16.3582L15 16.4999H13C13 16.4391 12.9991 16.3738 12.9981 16.3045C12.9902 15.7488 12.9787 14.9396 13.4145 14.194C13.6866 13.7287 13.9835 13.4546 14.3234 13.2077C14.4684 13.1024 14.585 13.0242 14.7027 12.9451C14.8843 12.8233 15.0686 12.6995 15.364 12.472C15.6531 12.2493 15.9137 11.8621 15.9943 11.4441C16.0668 11.0674 16.0004 10.651 15.6144 10.2378C15.2966 9.89771 14.4758 9.63252 13.6706 9.81513C13.3011 9.8989 13.0114 10.0638 12.818 10.284ZM15.25 18.75C15.25 19.4403 14.6904 20 14 20C13.3097 20 12.75 19.4403 12.75 18.75C12.75 18.0596 13.3097 17.5 14 17.5C14.6904 17.5 15.25 18.0596 15.25 18.75Z"
    fill="currentColor"
  />
);
