import type { Unsubscribe } from '@sb/remote-control/types';
import { EventEmitter } from '@sb/utilities';

interface UserSessionEmitterState {
  isMovingRobot: boolean;
}

const userSessionEmitter = new EventEmitter<UserSessionEmitterState>();

/**
 * The Remote Control displays a list of active user sessions in some pages.
 *
 * Each session can have additional information based on which actions a user
 * is performing. For example, we can display connected users only when they're
 * controlling the live robot or a different layout when they're running an
 * ad-hoc command.
 */
export class UserSession {
  /** Tell us if a user is moving the robot. */
  public static moveRobot(isMovingRobot: boolean): void {
    userSessionEmitter.emit('isMovingRobot', isMovingRobot);
  }

  /** Listen to updates when the user starts/stops moving the robot. */
  public static onMoveRobotChange(
    onChange: (isMovingRobot: boolean) => void,
  ): Unsubscribe {
    return userSessionEmitter.on('isMovingRobot', onChange);
  }
}
