import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import { getDevice } from '../toRoutineRunnerHelpers';

export namespace CNCMonitorMachineStepDatabase {
  export const name = 'Monitor machine';
  export const description =
    'Designate a section of the routine as controlling a CNC machine';
  export const deviceKinds = ['CNCMachine'] as const;
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'CNCMonitorMachine';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    deviceID: zod.string().default(''),
    haasCellSafe: zod.boolean().default(true),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    getDevice(args.deviceID, equipment);

    return {
      ...stepData,
      stepKind: 'CNCMonitorMachine',
      args,
    };
  };
}

CNCMonitorMachineStepDatabase satisfies Step.StepKindInfo;
