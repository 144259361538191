import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';
import { CameraIntegration } from '@sb/integrations/types/cameraTypes';
import { ActionRequiredError, Step } from '@sb/remote-control/types';
import {
  DEFAULT_REGION_OF_INTEREST,
  LocateMethod,
  RegionOfInterest,
} from '@sb/routine-runner';

export namespace LocateStep {
  export const name = 'Locate';
  export const description = 'Locate via vision';
  export const librarySection = Step.LibrarySection.Vision;
  export const argumentKind = 'Locate';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    camera: CameraIntegration,
    regionOfInterest: RegionOfInterest.default(DEFAULT_REGION_OF_INTEREST),
    method: LocateMethod,
    positionListName: zod.string().default('Locate step results'),
    resultsLimit: zod.number().default(10),
    transform: CartesianPose.default(ZERO_POSE),
    planeID: zod.string().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({
    stepConfiguration,
    routine: { space },
  }) => {
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    if (!args.planeID) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'No workplane selected',
        fieldId: 'planeID',
      });
    }

    if (
      !space.some((item) => item.id === args.planeID && item.kind === 'plane')
    ) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Selected workplane is not valid',
        fieldId: 'planeID',
      });
    }

    if (!args.positionListName) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Position list name is not configured',
        fieldId: 'positionListName',
      });
    }

    const { method } = args;

    switch (method.kind) {
      case 'BlobDetection2D': {
        if (method.settings.maxThreshold < method.settings.minThreshold) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            message: 'Max threshold may not be smaller than min threshold',
          });
        }

        if (method.settings.maxArea < method.settings.minArea) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            message: 'Max area may not be smaller than min area',
          });
        }

        if (
          method.settings.filterByCircularity &&
          method.settings.maxCircularity < method.settings.minCircularity
        ) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            message: 'Max circularity may not be smaller than min circularity',
          });
        }

        break;
      }

      case 'ShapeDetection2D': {
        if (method.settings.maxScale < method.settings.minScale) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            message: 'Max scale may not be smaller than min scale',
          });
        }

        if (!method.templateImage.storageID) {
          throw new ActionRequiredError({
            kind: 'invalidConfiguration',
            message: 'Template image is not set',
          });
        }

        break;
      }

      default:
        break;
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'Locate',
      args: {
        camera: args.camera,
        regionOfInterest: args.regionOfInterest,
        method: args.method,
        positionListID: stepData.id,
        filters: {
          resultsLimit: args.resultsLimit,
        },
        transform: args.transform,
        planeID: args.planeID!,
      },
    };
  };
}

LocateStep satisfies Step.StepKindInfo;
