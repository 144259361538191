import { useCallback, useState, useEffect } from 'react';

export const useLocalStorageState = <T>(key: string, initialValue: T) => {
  const savedValue = localStorage.getItem(key);

  if (!savedValue) {
    localStorage.setItem(key, JSON.stringify(initialValue));
  }

  const [value, setValue] = useState<T>(
    savedValue ? JSON.parse(savedValue) : initialValue,
  );

  const setLocalStorageValue = useCallback(
    (newValue: T) => {
      const serialized = JSON.stringify(newValue);
      localStorage.setItem(key, serialized);

      window.dispatchEvent(
        new StorageEvent('storage', {
          key,
          newValue: serialized as any,
          storageArea: window.localStorage,
        }),
      );
    },
    [key],
  );

  useEffect(() => {
    const listener = (ev: StorageEvent) => {
      if (ev.storageArea === window.localStorage && ev.key === key) {
        setValue(JSON.parse(ev.newValue ?? '') as T);
      }
    };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key]);

  return [value, setLocalStorageValue] as const;
};
