import type * as zod from 'zod';

import { namespace, info } from '@sb/log';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const ns = namespace('PushModeStep');

export default class PushModeStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  public hasPushed = false;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ playSubSteps }: StepPlayArguments): Promise<void> {
    const { collisionThresholds } = this.args;

    info(
      ns`_play`,
      `setting pushing to true with thresholds ${collisionThresholds}`,
    );

    await this.routineContext.setPushMode(true, collisionThresholds);
    this.hasPushed = false;

    try {
      info(
        ns`_play`,
        `playing steps: pushMode: true hasPushed: ${this.hasPushed}`,
      );

      await playSubSteps({ pushMode: true });
    } finally {
      info(ns`_play`, `setting pushing to false`);
      await this.routineContext.setPushMode(false);
    }

    info(ns`_play`, `done playing push mode`);
  }

  public async _stop() {
    info(ns`_play`, `setting pushing to false`);
    await this.routineContext.setPushMode(false);
  }
}
