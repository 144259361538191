import * as zod from 'zod';

import { DHConfiguration } from '@sb/integrations/implementations/dh/comon/types/DHConfiguration';

export const DHPGC30060Configuration = DHConfiguration.extend({
  kind: zod.literal('DHPGC30060'),
  name: zod.string().default('DH PGC 300/60'),
});

export type DHPGC30060Configuration = zod.infer<typeof DHPGC30060Configuration>;

export const DefaultConfiguration = DHPGC30060Configuration.parse({
  kind: 'DHPGC30060',
  family: 'DHBase',
  maxGripWidth: 60 / 1_000,
});
