import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { ArmJointPositions } from '@sb/motion-planning';

export const ArmPosition = zod.object({
  pose: CartesianPose,
  jointAngles: ArmJointPositions,
});

export type ArmPosition = zod.infer<typeof ArmPosition>;

export const ArmPositionList = zod.array(ArmPosition);

export type ArmPositionList = zod.infer<typeof ArmPositionList>;
