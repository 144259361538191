import { Euler, MathUtils, Quaternion } from 'three';

import { getPlaneNormal, getPlaneOrientation, type Plane } from './Plane';

export function tooltipToUnitPlaneTransform() {
  return new Quaternion().setFromEuler(
    new Euler(Math.PI, -Math.PI / 2, 0, 'XYZ'),
  );
}

/**
 * Calculates the tooltip orientation so that it is perpendicular to a given plane.
 *
 * To visualize the problem, set the tooltip to have an identity orientation.
 * This will have the tooltip facing in the +X direction, making it perpendicular to the YZ plane.
 * A plane with identity pose is facing +Z, making it perpendicular to the XY plane.
 * Expected behavior:
 * - For an identity plane and 0 rotation, the tooltip will be rotated by pi/2 about the Y and Z axes.
 * - For a plane parallel to YZ and 0 rotation, the tooltip will have an identity orientation.
 *
 * @param {Plane} plane - The plane to which the tooltip should be perpendicular.
 * @param {number} rotationDegrees - The rotation angle about the plane's normal.
 * @returns {Quaternion} - The resulting tooltip orientation.
 */
export function getTooltipOrientationPerpendicularToPlane(
  plane: Plane,
  rotationDegrees: number,
): Quaternion {
  const planeOrientation = getPlaneOrientation(plane);

  const transform = tooltipToUnitPlaneTransform();

  const tooltipOrientation = planeOrientation.multiply(transform);

  const rotationAboutPlane = new Quaternion().setFromAxisAngle(
    getPlaneNormal(plane),
    -MathUtils.degToRad(rotationDegrees),
  );

  return rotationAboutPlane.multiply(tooltipOrientation).normalize();
}
