/**
 * Any joint motion plan request:
 * Any motion plan request that can be given to the motion planner on any endpoint
 * that will result in a MotionPlanResponse.
 *
 * This is a common type used for test/debug fixtures but likely should not be used
 * in production code paths.
 */
import * as zod from 'zod';

import { MotionPlanRequest } from './MotionPlanRequest';
import { RecoveryPlanRequest } from './RecoveryPlanRequest';
import { RelativeCartesianMotionPlanRequest } from './RelativeCartesianMotionPlanRequest';
import { RelativeJointMotionPlanRequest } from './RelativeJointMotionPlanRequest';

export const AnyMotionPlanRequest = zod.union([
  MotionPlanRequest,
  RecoveryPlanRequest,
  RelativeJointMotionPlanRequest,
  RelativeCartesianMotionPlanRequest,
]);

export type AnyMotionPlanRequest = zod.infer<typeof AnyMotionPlanRequest>;
