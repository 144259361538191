import * as zod from 'zod';

export const DHState = zod.object({
  family: zod.literal('DH'),
  error: zod.string().optional(),
  isConnected: zod.boolean().default(false),
  isBusy: zod.boolean().default(false),

  isGripDetected: zod.boolean().default(false),
  // newtons
  force: zod.number().nonnegative().default(0.0),
  // newtons
  maxForce: zod.number().nonnegative().default(1.0),

  speed: zod.number().nonnegative().default(1.0),

  width: zod.number().nonnegative().default(0.0),
  maxWidth: zod.number().default(1.0),
  minWidth: zod.number().default(0.0),
});

export type DHState = zod.infer<typeof DHState>;
