import type * as zod from 'zod';

import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepFailure } from '@sb/routine-runner/types';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class IfStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async evaluateCondition(
    onFail: (failure: StepFailure) => Promise<void>,
  ): Promise<boolean> {
    try {
      const result = await this.routineContext.evaluateConditional(
        this.args.condition,
      );

      return Boolean(result);
    } catch (error) {
      await onFail({
        failure: {
          kind: FailureKind.InvalidRoutineLoadedFailure,
        },
        failureReason: error.message,
        error,
      });

      return false;
    }
  }

  public async _play({ playSubSteps, fail }: StepPlayArguments): Promise<void> {
    const shouldRunSteps = await this.evaluateCondition(fail);

    if (await super.shouldStopPlaying()) {
      return;
    }

    if (shouldRunSteps) {
      await playSubSteps();
    }
  }
}
