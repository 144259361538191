/* eslint-disable no-console, global-require */
import type { Formatter } from '../types';
import { Severity } from '../types';

import * as color from './helpers/color';
import { jsonFormat } from './helpers/jsonFormat';

const chalk = (() => {
  try {
    return require('chalk');
  } catch (e) {
    console.log('chalk not installed or not supported');

    return false;
  }
})();

const colorizeJson = (() => {
  try {
    return require('json-colorizer');
  } catch (e) {
    console.log('json-colorizer not installed or not supported');

    return (str: string) => str;
  }
})();

const formatSeverity = (sev: Severity): [string, string] => {
  if (!chalk) return [sev, sev];

  const sevIcon = {
    [Severity.Error]: '!',
    [Severity.Warning]: '⚠',
    [Severity.Information]: 'i',
    [Severity.Debug]: '>',
  }[sev];

  const sevColor = color.getIconColor(sev);
  let builder = chalk;
  if (sevColor.bg) builder = builder.bgHex(sevColor.bg);

  return [builder.hex(sevColor.text).bold(` ${sevIcon} `), ` ${sevIcon} `];
};

const timestamp = (): [string, string] => {
  const date = new Date();
  let hour = date.getHours() % 12;
  hour = hour === 0 ? 12 : hour;
  const hourStr = hour.toString().padStart(2, ' ');
  const ampm = date.getHours() < 12 ? 'AM' : 'PM';
  const mins = `${date.getMinutes()}`.padStart(2, '0');
  const sec = `${date.getSeconds()}`.padStart(2, '0');
  const ms = `${date.getTime() % 1000}`.padStart(3, '0');

  const timeMajor = `${hourStr}:${mins} ${ampm} `;

  const timeMillis = `${sec}.${ms}s`;

  return [
    chalk.hex(color.secondaryText)(timeMajor) +
      chalk.hex(color.secondaryTextDark)(timeMillis),
    timeMajor + timeMillis,
  ];
};

const formatKey = (k: string, logLen: number) => {
  const prefix = Math.max(1, 50 - logLen);

  return ''.padStart(prefix, ' ') + chalk.hex(color.secondaryText)(k);
};

export const formatter: Formatter = (
  severity: Severity,
  key: string,
  message: string,
  context: any,
) => {
  const segments: [string, string][] = [
    timestamp(),
    formatSeverity(severity),
    [message, message],
  ];

  const line = segments.map(([formated]) => formated).join(' ');
  const lineLength = segments.reduce((pr, v) => pr + v[1].length, 0);

  return [
    `${line}${formatKey(key, lineLength)}\t${colorizeJson(
      jsonFormat(context),
    )}`,
  ];
};
