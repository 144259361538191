import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

export namespace RunSkillStep {
  export const name = 'Run learned skill';
  export const description = 'Run an AI learned skill';
  export const librarySection = Step.LibrarySection.Control;
  export const argumentKind = 'RunSkill';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    skill: zod.string().nullable().default(null),
    botIDs: zod.string().array().default([]),
    cameraIDs: zod.string().array().default([]),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.skill) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Skill not specified',
        fieldId: 'skill',
      });
    }

    if (args.botIDs.length === 0) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'No robots selected',
        fieldId: 'botIDs',
      });
    }

    if (args.cameraIDs.length === 0) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'No cameras selected',
        fieldId: 'cameraIDs',
      });
    }

    return {
      ...stepData,
      stepKind: 'RunSkill',
      args: {
        taskName: args.skill,
        botIDs: args.botIDs,
        cameraIDs: args.cameraIDs,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    return `Run “${args.skill}” skill`;
  };
}

RunSkillStep satisfies Step.StepKindInfo;
