import * as zod from 'zod';

import { SchunkEGxCommandDirection } from './SchunkEGxCommandDirection';

export const SchunkEGxCommand = zod.object({
  kind: zod.literal('SchunkEGxCommand'),
  subCommand: zod.discriminatedUnion('kind', [
    zod.object({
      kind: zod.literal('jog'),
      direction: SchunkEGxCommandDirection,
      speed: zod.number(), // meters per second
    }),
    zod.object({
      kind: zod.literal('moveToAbsolutePosition'),
      position: zod.number(), // meters
      speed: zod.number(), // meters per second
    }),
    zod.object({
      kind: zod.literal('moveToRelativePosition'),
      position: zod.number(), // meters
      speed: zod.number(), // meters per second
    }),
    zod.object({
      kind: zod.literal('gripWorkpiece'),
      force: zod.number(), // fraction 0.5 - 1.0
      direction: SchunkEGxCommandDirection,
    }),
    zod.object({
      kind: zod.literal('gripWorkpieceAtPosition'),
      force: zod.number(), // fraction 0.5 - 1.0
      position: zod.number(), // meters
      direction: SchunkEGxCommandDirection,
    }),
    zod.object({
      kind: zod.literal('softGripWorkpiece'),
      force: zod.number(), // fraction 0.5 - 1.0
      speed: zod.number(), // meters per second
      direction: SchunkEGxCommandDirection,
    }),
    zod.object({
      kind: zod.literal('softGripWorkpieceAtPosition'),
      force: zod.number(), // fraction 0.5 - 1.0
      speed: zod.number(), // meters per second
      position: zod.number(), // meters
      direction: SchunkEGxCommandDirection,
    }),
    zod.object({
      kind: zod.literal('strongGripWorkpiece'),
      force: zod.number(), // fraction 1.01+
      direction: SchunkEGxCommandDirection,
    }),
    zod.object({
      kind: zod.literal('strongGripWorkpieceAtPosition'),
      force: zod.number(), // fraction 1.01+
      position: zod.number(), // meters
      direction: SchunkEGxCommandDirection,
    }),
    zod.object({
      kind: zod.literal('releaseWorkpiece'),
    }),
    zod.object({
      kind: zod.literal('stop'),
    }),
  ]),
  activateGPE: zod.boolean().optional(),
});

export type SchunkEGxCommand = zod.infer<typeof SchunkEGxCommand>;
