import { namespace, info, warn } from '@sb/log';
import { wait } from '@sb/utilities';

const ns = namespace('profiling');

interface BasicSentryInterface {
  startTransaction: (args: any) => {
    finish: () => void;
  };
}

let sentry: BasicSentryInterface | undefined;

export const setSentry = (s: BasicSentryInterface) => {
  info(ns`sentry.set`, 'Set Sentry service');
  sentry = s;
};

export const getSentry = (): BasicSentryInterface | undefined => sentry;

const makeProfiler =
  (op: string, name: string) =>
  (instanceOp?: string, instanceName?: string) => {
    let profiler:
      | ReturnType<BasicSentryInterface['startTransaction']>
      | undefined;

    const config = {
      op: instanceOp ? `${op}.${instanceOp}` : op,
      name: instanceName ? `${name}: ${instanceName}` : name,
    };

    try {
      profiler = sentry?.startTransaction(config);
    } catch (e) {
      warn(ns`sentry.start.fail`, 'Failed to start profiling transaction', {
        error: e,
        ...config,
      });
    }

    return {
      finish: () => {
        try {
          profiler?.finish();
        } catch (e) {
          warn(
            ns`sentry.finish.fail`,
            'Failed to finish profiling transaction',
            e,
          );
        }
      },
    };
  };

export const profilers = {
  botmanSetup: makeProfiler('setup', 'Botman Setup'),
  step: makeProfiler('step', 'Routine Runner Step'),
};

export const startBackgroundProfiling = (
  duration: number,
  pause: number,
  op: string,
  name: string,
) => {
  const profiler = makeProfiler(op, name);

  let running = true;

  (async () => {
    while (running) {
      const prof = profiler();
      await wait(duration);
      prof.finish();
      await wait(pause);
    }
  })();

  return () => {
    running = false;
  };
};
