/**
 * IO level: The possible values of an IO port
 */
import * as zod from 'zod';

export const IO_LEVEL_VALUES = ['high', 'low'] as const;

export const IOLevel = zod.enum(IO_LEVEL_VALUES);

export type IOLevel = zod.infer<typeof IOLevel>;
