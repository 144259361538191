import { useEffect, useState } from 'react';

import { makeNamespacedLog } from '@sb/log';
import { StandardBotsSDK } from '@sb/sb-robot-sdk';
import { loadApiAuthToken } from '@sbrc/services/feathers-client';
import { useRobotIdentity } from '@sbrc/services/feathers-client/robot-identity';
import { HTTP_API_ENDPOINT, globalCache } from '@sbrc/utils';

const log = makeNamespacedLog('useStandardBotsSdk');

type CombinedSdks = {
  live: StandardBotsSDK | null;
  simulated: StandardBotsSDK | null;
  token: string | null;
};

type SdkHandler = (sdks: CombinedSdks) => void;

export function getStandardBotsSdkSubscriber() {
  return globalCache('standardBotsSDK.subscriber', () => {
    const sdks: CombinedSdks = {
      live: null,
      simulated: null,
      token: null,
    };

    let updateHandlers: SdkHandler[] = [];

    const updateToken = () => {
      loadApiAuthToken()
        .then((payload) => {
          if (!payload) {
            throw new Error('No token');
          }

          const { expiry, token } = payload;

          sdks.live = new StandardBotsSDK({
            url: HTTP_API_ENDPOINT,
            token,
            robotKind: 'live',
          });

          sdks.simulated = new StandardBotsSDK({
            url: HTTP_API_ENDPOINT,
            token,
            robotKind: 'live',
          });

          sdks.token = token;

          updateHandlers.forEach((handler) => handler(sdks));

          // refresh half way through expiry period
          setTimeout(updateToken, (expiry - Date.now()) / 2);
        })
        .catch((e) => {
          log.warn('token.load.fail', 'Failed to load token', e);
          setTimeout(updateToken, 500);
        });
    };

    updateToken();

    const onUpdate = (handler: SdkHandler): (() => void) => {
      updateHandlers.push(handler);
      handler(sdks);

      return () => {
        updateHandlers = updateHandlers.filter((h) => h !== handler);
      };
    };

    return {
      sdks,
      onUpdate,
      updateToken,
    };
  });
}

function useStandardBotsSdkSubscriber() {
  const robotIdentity = useRobotIdentity();

  return robotIdentity?.robotOperationMode === 'assembly' ||
    robotIdentity?.robotOperationMode === undefined
    ? null
    : getStandardBotsSdkSubscriber();
}

export function useStandardBotsSdk(
  mode: 'simulated' | 'live',
): StandardBotsSDK | null {
  const [sdk, setSdk] = useState<StandardBotsSDK | null>(null);
  const sbSdkSubscriber = useStandardBotsSdkSubscriber();

  useEffect(() => {
    if (!sbSdkSubscriber) {
      setSdk(null);

      return;
    }

    sbSdkSubscriber.onUpdate((sdks) => {
      setSdk(mode === 'simulated' ? sdks.simulated : sdks.live);
    });
  }, [mode, setSdk, sbSdkSubscriber]);

  return sdk;
}

export function useStandardBotsSdkToken(): string | null {
  const [token, setToken] = useState<string | null>(null);
  const sbSdkSubscriber = useStandardBotsSdkSubscriber();

  useEffect(() => {
    if (!sbSdkSubscriber) {
      setToken(null);

      return;
    }

    sbSdkSubscriber.onUpdate((sdks) => {
      setToken(sdks.token);
    });
  }, [setToken, sbSdkSubscriber]);

  return token;
}
