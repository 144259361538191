import type * as FSPromises from 'node:fs/promises';

import { memoize } from 'lodash';
// @ts-ignore

import { makeLogNamespace } from '@sb/log/log';

const log = makeLogNamespace('node:fs');

export const loadFS = memoize(async (): Promise<typeof FSPromises> => {
  try {
    // @ts-ignore
    const { default: fsPromises } = await import('node:fs/promises');

    return fsPromises;
  } catch (error) {
    log.error('init.error', 'FSPRomises did not initialize', { error });

    throw error;
  }
});
