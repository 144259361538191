import * as zod from 'zod';

export const IntegrationSensorKind = zod.enum([
  'sensor',
  'fault',
  'moving',
  'diameter',
  'position',
  'force',
  'pressure',
  'partSeated',
  'proximity',
  'custom',
]);

export type IntegrationSensorKind = zod.infer<typeof IntegrationSensorKind>;
