import { isEqual } from 'lodash';
import { create } from 'zustand';

import type { EquipmentItem } from '@sb/integrations/types';
import { onGetAllEquipment } from '@sbrc/services';

interface EquipmentStore {
  enabledEquipment: EquipmentItem[];
  allEquipment: EquipmentItem[];
  unsubscribe?: () => void;
}

const useEquipmentStore = create<EquipmentStore>(() => {
  return {
    enabledEquipment: [],
    allEquipment: [],
  };
});

function subscribeToEquipment() {
  if (useEquipmentStore.getState().unsubscribe) {
    // already subscribed
    return;
  }

  const unsubscribe = onGetAllEquipment((equipment) => {
    if (!isEqual(useEquipmentStore.getState().allEquipment, equipment)) {
      useEquipmentStore.setState({
        allEquipment: equipment,
        enabledEquipment: equipment.filter((device) => device.isEnabled),
      });
    }
  });

  useEquipmentStore.setState({ unsubscribe });
}

interface UseEquipmentArgs {
  includeDisabled?: boolean;
}

/**
 * returns undefined while loading
 */
export function useEquipment({
  includeDisabled,
}: UseEquipmentArgs = {}): EquipmentItem[] {
  subscribeToEquipment();

  const equipment = useEquipmentStore();

  if (includeDisabled) {
    return equipment.allEquipment;
  }

  return equipment.enabledEquipment;
}
