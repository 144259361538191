import { createContext } from 'react';

interface ModalState {
  isOpen: boolean;
  onClose?: React.MouseEventHandler;
}

export const ModalContext = createContext<ModalState>({
  isOpen: false,
});
