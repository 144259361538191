import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';

import { haasCellSafeWrapper } from '../haasCellSafeWrapper';

import { CNCRunProgramArguments } from './Arguments';
import { CNCRunProgramVariables } from './Variables';

export class CNCRunProgramStep extends Step<
  CNCRunProgramArguments,
  CNCRunProgramVariables
> {
  public static areSubstepsRequired = true;

  public static Arguments = CNCRunProgramArguments;

  public static Variables = CNCRunProgramVariables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const play = haasCellSafeWrapper(
      async () => {
        try {
          await this.routineContext.equipment.executeCommand({
            kind: 'CNCMachineCommand',
            deviceID: this.args.deviceID,
            subCommand: {
              kind: 'runProgram',
              haasProgram: this.args.haasProgram,
            },
          });
        } catch (error) {
          fail({
            failure: {
              kind: FailureKind.StepPlayFailure,
              stepKind: 'CNCRunProgram',
            },
            failureReason: 'Run program failed',
            error,
          });
        }
      },
      this.args,
      this.routineContext.equipment,
      fail,
    );

    if (this.args.waitUntilComplete) {
      await play();
    } else {
      play().catch((error) => {
        fail({
          failure: {
            kind: FailureKind.InternalFailure,
          },
          failureReason: 'CNCRunProgram step failed unexpectedly',
          error,
          asynchronous: true,
        });
      });
    }
  }
}
