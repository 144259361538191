import type { IntegrationAction, IntegrationActionKind } from '../types';

export function isIntegrationActionMatch(
  action: IntegrationAction,
  matchKind: IntegrationActionKind,
  matchName?: string,
) {
  return (
    action.kind === matchKind &&
    (matchKind !== 'custom' || action.name === matchName)
  );
}
