import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';
import { buildConditional } from '@sb/remote-control/util/conditionalsBuilder';

/**
 * Types matching the routine runner schema for "Loop" operations.
 */
export namespace LoopStep {
  export const name = 'Loop';
  export const description = 'Perform steps repeatedly';
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '1';
  export const argumentKind = 'Loop';

  /** This interface is used when either "loop forever" or "loop N times" are selected. */
  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    /**
     * If null or Infinity then "Loop forever"; if non-null then "loop N times"
     * (note - Feathers cannot store Infinity, which is why this is nullable)
     */
    times: zod.number().nullable().default(null),
    /**
     * "Use rules": will override `times` if non-null
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: zod.string().nullable().default(null),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export function isFixedTimesArguments(
    args: Arguments,
  ): args is Arguments & { times: number } {
    return Number.isFinite(args.times);
  }

  export function isConditionArguments(
    args: Arguments,
  ): args is Arguments & { condition: string } {
    return typeof args.condition === 'string';
  }

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (isConditionArguments(args)) {
      return {
        ...stepData,
        stepKind: 'Loop',
        args: { condition: buildConditional(JSON.parse(args.condition)) },
      };
    }

    if (isFixedTimesArguments(args)) {
      return {
        ...stepData,
        stepKind: 'Loop',
        args: { times: args.times },
      };
    }

    // Loop forever
    return {
      ...stepData,
      stepKind: 'Loop',
      args: {},
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    includeStepName,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    if (args.condition) {
      // TODO(dymk) - pretty print expressions according to APPS-2198
      return null;
    }

    const { times } = args;

    if (times == null) {
      return `${includeStepName ? 'Loop ' : ''}forever`;
    }

    if (times === 1) {
      return `${includeStepName ? 'Loop ' : ''}once`;
    }

    const SMALL_NUMBERS_TO_WORD: Record<number, string> = {
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six',
      7: 'seven',
      8: 'eight',
      9: 'nine',
    };

    const word = SMALL_NUMBERS_TO_WORD[times];

    if (word) {
      return `${includeStepName ? 'Loop ' : ''}${word} times`;
    }

    return `${includeStepName ? 'Loop ' : ''}${times} times`;
  };
}

LoopStep satisfies Step.StepKindInfo;
