import * as zod from 'zod';

export const WeldMachineCommand = zod.object({
  kind: zod.literal('WeldMachineCommand'),
  subCommand: zod.discriminatedUnion('kind', [
    zod.object({
      kind: zod.literal('startArc'),
      mode: zod.string(),
      voltage: zod.number(),
    }),
    zod.object({
      kind: zod.literal('stopArc'),
    }),
    zod.object({
      kind: zod.literal('startWireFeed'),
      speed: zod.number(),
    }),
    zod.object({
      kind: zod.literal('stopWireFeed'),
    }),
    zod.object({
      kind: zod.literal('setTestMode'),
      isTestMode: zod.boolean(),
    }),
  ]),
});

export type WeldMachineCommand = zod.infer<typeof WeldMachineCommand>;
