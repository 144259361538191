import { registerIntegrationSteps } from '@sb/integrations/loaders/steps';

import AddOffsetStep from './AddOffset/Step';
import AnticipatePayloadStep from './AnticipatePayload/Step';
import ClassifyStep from './Classify/Step';
import IfStep from './If/Step';
import LocateStep from './Locate/Step';
import LoopStep from './Loop/Step';
import LoopControlStep from './LoopControl/Step';
import MoveArmToStep from './MoveArmTo/Step';
import NetworkRequestStep from './NetworkRequest/Step';
import PressButtonStep from './PressButton/Step';
import PushModeStep from './PushMode/Step';
import {
  type BaseRoutineStepConfiguration,
  RoutineStepConfiguration,
} from './RoutineStepConfiguration';
import {
  RoutineStepVariables,
  TaggedRoutineStepVariables,
} from './RoutineStepVariables';
import RunInBackgroundStep from './RunInBackground/Step';
import RunSkillStep from './RunSkill/Step';
import SetEnvironmentVariableStep from './SetEnvironmentVariable/Step';
import SetIOStep from './SetIO/Step';
import type { StepArguments } from './StepArguments';
import type { StepClass } from './StepClass';
import { StepKind } from './StepKind';
import { StepRegistry } from './StepRegistry';
import type { StepValidationMessage } from './StepValidationMessage';
import type { StepVariables } from './StepVariables';
import WaitStep from './Wait/Step';
import WaitForConfirmationStep from './WaitForConfirmation/Step';

export * from './aliases';
export type {
  BaseRoutineStepConfiguration,
  StepArguments,
  StepVariables,
  StepClass,
  StepValidationMessage,
};
export {
  RoutineStepConfiguration,
  RoutineStepVariables,
  TaggedRoutineStepVariables,
  StepKind,
  StepRegistry,
  AddOffsetStep,
  AnticipatePayloadStep,
  ClassifyStep,
  LocateStep,
  IfStep,
  LoopStep,
  LoopControlStep,
  MoveArmToStep,
  NetworkRequestStep,
  PressButtonStep,
  PushModeStep,
  RunInBackgroundStep,
  RunSkillStep,
  SetEnvironmentVariableStep,
  SetIOStep,
  WaitForConfirmationStep,
  WaitStep,
};

StepRegistry.registerStepKind('AnticipatePayload', AnticipatePayloadStep);
StepRegistry.registerStepKind('Classify', ClassifyStep);
StepRegistry.registerStepKind('Locate', LocateStep);
StepRegistry.registerStepKind('If', IfStep);
StepRegistry.registerStepKind('AddOffset', AddOffsetStep);
StepRegistry.registerStepKind('Loop', LoopStep);
StepRegistry.registerStepKind('LoopControl', LoopControlStep);
StepRegistry.registerStepKind('MoveArmTo', MoveArmToStep);
StepRegistry.registerStepKind('NetworkRequest', NetworkRequestStep);
StepRegistry.registerStepKind('PressButton', PressButtonStep);
StepRegistry.registerStepKind('PushMode', PushModeStep);
StepRegistry.registerStepKind('RunInBackground', RunInBackgroundStep);
StepRegistry.registerStepKind('RunSkill', RunSkillStep);

StepRegistry.registerStepKind(
  'SetEnvironmentVariable',
  SetEnvironmentVariableStep,
);

StepRegistry.registerStepKind('SetIO', SetIOStep);
StepRegistry.registerStepKind('WaitForConfirmation', WaitForConfirmationStep);
StepRegistry.registerStepKind('Wait', WaitStep);

registerIntegrationSteps(StepRegistry);
