import * as zod from 'zod';

import { OutputPortID } from '@sb/routine-runner/types/OutputPort';
import { RelayPortID } from '@sb/routine-runner/types/RelayPort';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationActionControlBoxIOOutputs = zod
  .object({
    ioPort: zod.union([OutputPortID, RelayPortID]).nullable().default(null),
    isHigh: zod.boolean().default(true),
    isPulse: zod.boolean().default(true),
  })
  .array()
  .default([{}]);

export type IntegrationActionControlBoxIOOutputs = zod.infer<
  typeof IntegrationActionControlBoxIOOutputs
>;

export const IntegrationActionControlBoxIOInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.controlBoxIO),
  outputs: IntegrationActionControlBoxIOOutputs,
  pulseSeconds: zod.number().default(0.5),
});

export type IntegrationActionControlBoxIOInterface = zod.infer<
  typeof IntegrationActionControlBoxIOInterface
>;
