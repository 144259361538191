import { ActionRequiredError } from '@sb/remote-control/types';

import type { HaasAddress, HaasSerialTarget } from '../types';

export function validateHaasAddress(args: {
  address: HaasAddress | HaasSerialTarget;
}) {
  if (args?.address.kind === 'HaasEthernet') {
    if (!args.address.endpoint) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Address not specified',
        fieldId: 'address',
      });
    }

    try {
      // eslint-disable-next-line no-new
      new URL(args.address.endpoint);
    } catch (_error) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Invalid Ethernet Address (e.g. http://192.168.0.109:5551)',
        fieldId: 'address',
      });
    }
  }

  if (args?.address.kind === 'HaasRS232') {
    if (!args.address.path) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'HaasRS232 Path not specified',
        fieldId: 'address',
      });
    }

    if (/^(\/?[A-Za-z0-9]+)+$/.test(args.address.path)) {
      return;
    }

    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Invalid path (example: /dev/ttyUSB0)',
      fieldId: 'address',
    });
  }
}
