export const programVariable = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.5 4.5L4.5 3.5H10.5V5.5H5.5V22.5H10.5V24.5H4.5L3.5 23.5V4.5ZM17.5 3.5H23.5L24.5 4.5V23.5L23.5 24.5H17.5V22.5H22.5V5.5H17.5V3.5ZM17 19H16.5L16.2 18.6L14 15.6667L11.8 18.6L11.5 19H11H10C9.17157 19 8.5 18.3284 8.5 17.5C8.5 16.6716 9.17157 16 10 16C10.3737 16 10.7154 16.1366 10.978 16.3626L12.75 14L10.5 11L9 11V9H11H11.5L11.8 9.4L14 12.3333L16.2 9.4L16.5 9H17H18C18.8284 9 19.5 9.67157 19.5 10.5C19.5 11.3284 18.8284 12 18 12C17.6263 12 17.2846 11.8634 17.022 11.6374L15.25 14L17.5 17H19V19H17Z"
    fill="currentColor"
  />
);

export const programFunction = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.5 4.5C16.5 5.32843 15.8284 6 15 6C14.3469 6 13.7913 5.5826 13.5854 5H13.0414C12.0637 5 11.2293 5.70683 11.0686 6.6712L10.6805 9H13.5V11H10.3471L8.57086 21.6576C8.2494 23.5863 6.58064 25 4.62529 25H3C2.17157 25 1.5 24.3284 1.5 23.5C1.5 22.6716 2.17157 22 3 22C3.65311 22 4.20873 22.4174 4.41465 23H4.62529C5.60296 23 6.43734 22.2932 6.59807 21.3288L8.31954 11H5.5V9H8.65287L9.09581 6.3424C9.41726 4.41365 11.086 3 13.0414 3H15C15.8284 3 16.5 3.67157 16.5 4.5ZM25.5 10.5C25.5 11.3284 24.8284 12 24 12C23.6263 12 23.2846 11.8634 23.022 11.6374L21.25 14L23.5 17H25V19H23H22.5L22.2 18.6L20 15.6667L17.8 18.6L17.5 19H17H16C15.1716 19 14.5 18.3284 14.5 17.5C14.5 16.6716 15.1716 16 16 16C16.3737 16 16.7154 16.1366 16.978 16.3627L18.75 14L16.5 11H15V9.00001L17 9.00001H17.5L17.8 9.40001L20 12.3333L22.2 9.40001L22.5 9.00001H23H23.9955L24 9C24.8284 9 25.5 9.67157 25.5 10.5Z"
    fill="currentColor"
  />
);
