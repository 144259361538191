import cx from 'classnames';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { TickIcon } from '@sb/ui/icons';

import styles from './SelectItem.module.css';

interface SelectItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isSelected?: boolean;
}

const SelectItem = forwardRef<HTMLButtonElement, SelectItemProps>(
  (props, ref) => {
    const { children, className, isSelected, ...other } = props;

    return (
      <button
        ref={ref}
        className={cx(styles.selectItem, className, {
          [styles.selected]: isSelected,
        })}
        {...other}
      >
        <div className={styles.content}>{children}</div>
        <TickIcon className={styles.tickIcon} />
      </button>
    );
  },
);

export default SelectItem;
