import * as zod from 'zod';

export const IntegrationActionKind = zod.enum([
  'actuate',
  'reset',
  'clamp',
  'unclamp',
  'open',
  'close',
  'on',
  'off',
  'start',
  'stop',
  'custom',
]);

export type IntegrationActionKind = zod.infer<typeof IntegrationActionKind>;
