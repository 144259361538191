import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import type * as zod from 'zod';

import { error, warn, namespace, info } from '@sb/log';
import { profilers } from '@sb/profiling';
import type { Profilers } from '@sb/profiling';
import { Timer } from '@sb/utilities';

import type { RoutineContext } from '../RoutineContext';
import type { RoutineRunningPauseDetails } from '../RoutineRunnerState';
import type { StepFailure } from '../types';

import type { RoutineStepConfiguration } from './RoutineStepConfiguration';
import type { TaggedRoutineStepVariables } from './RoutineStepVariables';
import type { StepClass } from './StepClass';
import type { StepKind } from './StepKind';
import { StepRegistry } from './StepRegistry';
import type { StepValidationMessage } from './StepValidationMessage';

const ns = namespace('routine.runner');

type Config<Kind extends StepKind> = RoutineStepConfiguration & {
  stepKind: Kind;
};

type StepState = 'playing' | 'paused' | 'stopped';

type StepTimers = {
  self: Timer;
  full: Timer;
};

export interface StepPlayArguments {
  guidedMode: boolean;
  pushMode?: boolean;
  hasPushed?: boolean;
  breakOutOfLoop?: () => void;
  continueLoop?: () => void;
  hasBeenPreempted: () => boolean;
  hasEncounteredStartStep: () => boolean;
  fail: (failure: StepFailure) => Promise<void>;
  playSubSteps: (args?: {
    guidedMode?: boolean;
    pushMode?: boolean;
    breakOutOfLoop?: () => void;
    continueLoop?: () => void;
    hasBeenPreempted?: () => boolean;
    skipSettingCurrentStepID?: boolean;
  }) => Promise<void>;
  pauseRoutine: (pauseDetails: RoutineRunningPauseDetails) => Promise<void>;
}

export const DEFAULT_SELF_TIMEOUT_BUFFER_MS = 250;

/**
 * An abstract class that each routine substep implements in order to validate and run.
 *
 * The template arguments are only necessary for internal consistency to ensure the
 * subclass Steps are implemented correctly. The Step static class treats Steps like these
 * template parameters are `any`.
 */
export default abstract class Step<
  // Args: The type of the arguments for the step. The type is based on the Kind and the schema.
  // Variables: The type of the variable state for the step as stored in the routine context.
  Args extends object = object,
  Variables extends object = object,
> {
  /**
   * Recursively constructs a step from its JSON configuration.
   *
   * Basically a factory function.
   *
   * @param configuration     The routine step configuration as specified in the Routine JSON
   * @param routineContext    The routine's full context including other steps' variables,
   *                          the robot, the camera, and more.
   */
  public static constructStep<Kind extends StepKind>(
    { id, stepKind, args, steps }: Config<Kind>,
    routineContext: RoutineContext,
  ):
    | { errors: Array<StepValidationMessage> }
    | { step: Step<Config<Kind>['args'], object> } {
    const Klass = StepRegistry.stepConstructors[stepKind];

    if (!Klass) {
      return {
        errors: [
          {
            id,
            messages: [`No step kind "${stepKind}" exists`],
          },
        ],
      };
    }

    // Combined errors for the step and its substeps.
    // Without this, errors for substeps won't get propagated down to the flat
    // array that eventually gets returned.
    let nestedErrors: Array<StepValidationMessage> = [];

    // errors corresponding specifically to this step
    const errorMessages: Array<string | zod.ZodIssue> = [];

    if (!routineContext.validateStepID(id)) {
      errorMessages.push(`Duplicate step ID ${id}`);
    }

    const substeps: Array<Step> = [];

    // if substeps have been specified
    if (steps) {
      if (steps.length === 0 && Klass.areSubstepsRequired) {
        errorMessages.push(`"${stepKind}" step must have substeps`);
      } else {
        steps.forEach((step) => {
          const value = Step.constructStep(step, routineContext);

          if ('errors' in value) {
            nestedErrors = [...nestedErrors, ...value.errors];
          } else {
            substeps.push(value.step);
          }
        });
      }
    } else if (Klass.areSubstepsRequired) {
      errorMessages.push(`"${stepKind}" step must have substeps`);
    }

    const argsValidationResult = Klass.Arguments.safeParse(args);

    if (
      !argsValidationResult.success ||
      nestedErrors.length + errorMessages.length > 0
    ) {
      if (!argsValidationResult.success) {
        for (const issue of argsValidationResult.error.issues) {
          errorMessages.push(issue);
        }
      }

      if (errorMessages.length > 0) {
        nestedErrors.push({
          id,
          // TypeScript doesn't believe the check above
          messages: errorMessages as [string, ...string[]],
        });
      }

      return { errors: nestedErrors };
    }

    // for some reason, TypeScript doesn't recognize the above check that Klass exists
    const step = new (Klass as StepClass<any, any>)({
      args: argsValidationResult.data,
      routineContext,
      id,
    });

    if (step.substeps) {
      step.substeps = substeps;

      for (const substep of step.substeps) {
        substep.parentSteps = [...step.parentSteps, step];
      }
    } else if (substeps.length > 0) {
      return {
        errors: [
          {
            id,
            messages: [`"${stepKind}" step cannot have substeps`],
          },
        ],
      };
    }

    step.id = id;
    step.args = args;
    step.routineContext = routineContext;
    step.resetVariables({ excludeSubSteps: true });

    return { step };
  }

  public parentSteps: Array<Step<object, object>> = [];

  /**
   * The substeps of the Step.
   *
   * If substeps are not allowed, subclasses of Step should leave this empty
   * and a validation error will occur if the frontend tries to create that kind of
   * step with substeps.
   */
  public substeps?: Array<Step<object, object>>;

  /**
   * The ID of this step, used by other steps' configurations, the database, and more.
   *
   * Implementation note: This gets overridden in constructStep immediately after construction.
   * Steps cannot access their own id in their constructor.
   */
  public id: string = '';

  private profiler: ReturnType<Profilers['step']> | undefined;

  public timers: StepTimers = { self: new Timer(), full: new Timer() };

  private isPlayingSubSteps = false;

  /**
   * The routine context, used by steps for managing output variables.
   *
   * This gets populated *after* construction, so subclasses should not use it during
   * the constructor.
   *
   * If a step needs the routine context during construction, the constructor can get it
   * from the arguments passed to it.
   */
  protected routineContext: RoutineContext = null as any;

  protected setVariable<K extends keyof Variables>(
    variable: K,
    property: Variables[K],
  ) {
    this.routineContext.setVariableState(this.id, {
      ...this.variables,
      [variable]: property,
      stepKind: this.getStepKind(),
    } as TaggedRoutineStepVariables);
  }

  /**
   * Every time we set the variables, we want to set them on the routine context.
   * Therefore, `variables` is a virtual property.
   *
   * These protected methods are only used by substeps and therefore do not need
   * to include `stepKind` as that can be deduced.
   */
  protected set variables(variables: Variables) {
    // @rivertam: I spent a lot of time trying to avoid this cast but TypeScript
    // can't seem to figure out that this is always the right type. I think it
    // might be because technically Kind can be a union type so you could technically
    // write like a `Step<'If' | 'Wait'>` and then pass back something like
    // `{ stepKind: 'If', ...WaitStepVariables }` and this would be an invalid
    // TaggedRoutineStepVariables.
    this.routineContext.setVariableState(this.id, {
      ...variables,
      stepKind: this.getStepKind(),
    } as TaggedRoutineStepVariables);
  }

  /**
   * There's a single source of truth for variables, so we can just reference the context's
   * copy of this step's variables.
   *
   * These protected methods are only used by substeps and therefore do not need
   * to include `stepKind`.
   */
  protected get variables(): Variables {
    if (this.isInitializingVariableState) {
      throw new Error(
        'Steps cannot get `this.variables` during `initializeVariableState` as it is essentially recursive',
      );
    }

    const variables: Partial<TaggedRoutineStepVariables> = {
      ...this.routineContext.getVariableState(this.id),
    };

    if (variables.stepKind !== this.getStepKind()) {
      // this should never happen; programming error where we stored the wrong
      // variables or didn't clear them at the right time or something.
      throw new Error(
        `Step variables were of the wrong step kind ("${
          variables.stepKind
        }" vs. expected "${this.getStepKind()}")`,
      );
    }

    delete variables.stepKind;

    return variables as any; // the types are more complicated than they're worth
  }

  /**
   * Use to get variables in the initializeVariableState methods when needed,
   * it will contain the variables data passed in the startConditions when loading the routine
   */
  protected get variablesForInitialization(): Record<string, any> {
    return this.routineContext.getAllVariables()[this.id] ?? {};
  }

  /**
   * The arguments for a step.
   *
   * This gets populated *after* construction, so subclasses should not use it during
   * the constructor.
   *
   * If a step needs the args during construction, the constructor can get it
   * from the arguments passed to it.
   */
  protected args: Args = null as any;

  public getStepKind(): StepKind {
    const entry = Object.entries(StepRegistry.stepConstructors).find(
      // not sure why this doesn't type-check with a new () operator
      ([_stepKind, stepClass]) => this instanceof (stepClass as any),
    );

    if (!entry) {
      throw new Error(
        `Step class ${this.constructor.name} needs to call Step.registerStepKind`,
      );
    }

    // not sure why the Object.entries(Step.stepConstructors) doesn't
    // already give back StepKinds
    return entry[0] as StepKind;
  }

  public hasSelfTimeout(): boolean {
    return true;
  }

  public getSelfTimeout(): number {
    return DEFAULT_SELF_TIMEOUT_BUFFER_MS;
  }

  public getSelfTimeoutWarning(): number {
    return this.getSelfTimeout() * 0.75;
  }

  /**
   * If we try to call `this.variables` during `initializeVariableState`, a relatively confusing
   * error message pops up, so this allows us to more nicely inform the programmer they
   * made a mistake.
   */
  private isInitializingVariableState = false;

  /**
   * Called by the [[RoutineRunner]] to initialize the variables for this Step.
   * The implementation should at some point do: `this.variables = {...}`
   */
  protected abstract initializeVariableState(): void;

  private stepState$ = new BehaviorSubject<StepState>('stopped');

  /**
   * Run a step.
   *
   * This should resolve when the step has completed and the next step is good
   * to go. Make sure not to implement it in a way where it completes when `pause()`
   * is called.
   *
   * If this function rejects (throws an error), this will cause the Failure kind of
   * the routine runner to become Internal, indicating a programming error whose specifics
   * we don't want to reveal to the user.
   *
   * The error will be logged and (TODO?) reported to some bug-catching tool, while the
   * `failureKind` and `failureReason` will be reported to the user/remote control for
   * recovery.
   */
  protected async _play(_args: StepPlayArguments): Promise<void> {
    // empty
  }

  public async play(args: StepPlayArguments): Promise<void> {
    this.timers.full.restart();
    this.timers.self.restart();

    this.startProfiling();
    this.stepState$.next('playing');

    await this._play({
      ...args,
      playSubSteps: async (subArgs) => {
        this.isPlayingSubSteps = true;
        this.timers.self.pause();
        await args.playSubSteps(subArgs);
        this.timers.self.play();
        this.isPlayingSubSteps = false;
      },
    });

    this.timers.self.pause();
    this.timers.full.pause();

    const selfTime = this.timers.self.getTime();
    const fullTime = this.timers.full.getTime();

    info(ns`play.time`, 'Step times', {
      selfTime,
      fullTime,
      stepId: this.id,
      stepKind: this.getStepKind(),
    });

    if (this.hasSelfTimeout()) {
      if (selfTime > this.getSelfTimeout()) {
        error(ns`play.time.exceeded`, 'Step self time exceeded threshold', {
          selfTime,
          fullTime,
          stepId: this.id,
          stepKind: this.getStepKind(),
        });
      } else if (selfTime > this.getSelfTimeoutWarning()) {
        warn(ns`play.time.high`, 'Step self time nearing threshold', {
          selfTime,
          fullTime,
          stepId: this.id,
          stepKind: this.getStepKind(),
        });
      }
    }
  }

  /**
   * Pause the step
   */
  protected _pause(): void | Promise<void> {
    // empty
  }

  public async pause(): Promise<void> {
    this.stepState$.next('paused');

    await this._pause();

    if (!this.isPlayingSubSteps) {
      this.timers.self.pause();
    }

    this.stopProfiling();
  }

  /**
   * Resume the step
   */
  protected _resume(): void {
    // empty
  }

  public resume(): void {
    if (!this.isPlayingSubSteps) {
      this.timers.self.play();
    }

    this.startProfiling();
    this.stepState$.next('playing');

    return this._resume();
  }

  /**
   * Stop the step (cleans up data and running processes)
   */
  protected _stop(): void | Promise<void> {
    // empty
  }

  public async stop(): Promise<void> {
    this.stepState$.next('stopped');

    await this._stop();
    this.stopProfiling();
  }

  /**
   * return a promise which will be unresolved while paused, and resolved with true if stopped.
   */
  protected async shouldStopPlaying() {
    const stepState = await this.stepState$
      .pipe(first((state) => state !== 'paused'))
      .toPromise();

    return stepState === 'stopped';
  }

  toJSON() {
    return {
      id: this.id,
      stepKind: this.getStepKind(),
      args: this.args,
      variables: this.variables,
    };
  }

  private startProfiling() {
    if (this.profiler) this.stopProfiling();

    this.profiler = profilers.step(this.getStepKind(), this.getStepKind());
  }

  private stopProfiling() {
    if (this.profiler) {
      this.profiler.finish();
    }
  }

  public resetVariables(args?: { excludeSubSteps: boolean }) {
    this.isInitializingVariableState = true;
    this.initializeVariableState();
    this.isInitializingVariableState = false;

    if (!args?.excludeSubSteps && this.substeps) {
      for (const substep of this.substeps) {
        substep.resetVariables();
      }
    }
  }
}
