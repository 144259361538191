// @ts-ignore
import type * as RCL from 'rclnodejs';

type ROSSettings = {
  nodeName: string;
  namespace: string;
  getNodeContext: (rcl: typeof RCL) => RCL.Context;
  getNodeOptions: (rcl: typeof RCL) => RCL.NodeOptions;
};

export const rosSettings: ROSSettings = {
  // apps must set `rosSettings.nodeName` somewhere in their initialization code
  nodeName: '',
  namespace: '',
  getNodeContext: (rcl) => {
    return rcl.Context.defaultContext();
  },
  getNodeOptions: (rcl) => {
    // https://github.com/RobotWebTools/rclnodejs/blob/develop/docs/EFFICIENCY.md
    const options = new rcl.NodeOptions();
    options.startParameterServices = false;

    return options;
  },
};
