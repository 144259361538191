import type { ActuateGripperStepDatabase } from '@sb/integrations/implementations/gripper-general/step-wizard/ActuateGripperStepDatabase';

import type { SchunkEGxCommand } from '../types/SchunkEGxCommand';
import type { SchunkEGxConfiguration } from '../types/SchunkEGxConfiguration';

import { generateCommandInfo } from './generateCommandInfo';

export function schunkEGxActuateGripperStepGenerateCommand(
  gripperConfiguration: SchunkEGxConfiguration,
  stepArgs: ActuateGripperStepDatabase.Arguments,
): SchunkEGxCommand {
  const {
    isGPESupported,
    isGrip,
    isSoftGrip,
    isStrongGrip,
    diameterMM,
    forcePercent,
    speed,
  } = generateCommandInfo(gripperConfiguration, stepArgs);

  const targetPosition = diameterMM / 1000;

  const getSubCommand = (): SchunkEGxCommand['subCommand'] => {
    if (isGrip && isSoftGrip) {
      if (stepArgs.isFlexGrip) {
        return {
          kind: 'softGripWorkpiece',
          direction: stepArgs.gripKind,
          force: forcePercent,
          speed,
        };
      }

      return {
        kind: 'softGripWorkpieceAtPosition',
        direction: stepArgs.gripKind,
        force: forcePercent,
        speed,
        position: targetPosition,
      };
    }

    if (isGrip && isStrongGrip) {
      if (stepArgs.isFlexGrip) {
        return {
          kind: 'strongGripWorkpiece',
          direction: stepArgs.gripKind,
          force: forcePercent,
        };
      }

      return {
        kind: 'strongGripWorkpieceAtPosition',
        direction: stepArgs.gripKind,
        force: forcePercent,
        position: targetPosition,
      };
    }

    if (isGrip) {
      if (stepArgs.isFlexGrip) {
        return {
          kind: 'gripWorkpiece',
          direction: stepArgs.gripKind,
          force: forcePercent,
        };
      }

      return {
        kind: 'gripWorkpieceAtPosition',
        direction: stepArgs.gripKind,
        force: forcePercent,
        position: targetPosition,
      };
    }

    return {
      kind: 'moveToAbsolutePosition',
      position: targetPosition,
      speed,
    };
  };

  const command: SchunkEGxCommand = {
    kind: 'SchunkEGxCommand',
    subCommand: getSubCommand(),
    activateGPE: isGPESupported && isGrip ? stepArgs.activateGPE : false,
  };

  return command;
}
