import cx from 'classnames';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './Card.module.css';

type CardBorderSize = 'small' | 'medium' | 'large';
type CardVariant = 'dark' | 'light' | 'gray';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  borderSize?: CardBorderSize;
  children: React.ReactNode;
  className?: string;
  hasBorder?: boolean;
  hasShadow?: boolean;
  variant?: CardVariant;
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const {
    borderSize = 'large',
    children,
    className,
    hasBorder,
    hasShadow,
    variant = 'light',
    ...other
  } = props;

  return (
    <div
      ref={ref}
      className={cx(
        styles.card,
        styles[variant],
        styles[borderSize],
        {
          [styles.shadow]: hasShadow,
          [styles.hasBorder]: hasBorder,
        },
        className,
      )}
      {...other}
    >
      {children}
    </div>
  );
});

export default Card;
