export const speedometerSlow = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 24.25C12.3906 24.25 10.8678 23.8791 9.51252 23.2181L11.1865 21.7578C12.0645 22.0763 13.012 22.25 14 22.25C18.5563 22.25 22.25 18.5563 22.25 14C22.25 9.44365 18.5563 5.75 14 5.75C9.44365 5.75 5.75 9.44365 5.75 14C5.75 14.988 5.92368 15.9355 6.24218 16.8135L4.78193 18.4875C4.12092 17.1322 3.75 15.6094 3.75 14C3.75 8.33908 8.33908 3.75 14 3.75C19.6609 3.75 24.25 8.33908 24.25 14C24.25 19.6609 19.6609 24.25 14 24.25ZM5.69148 21.2478L6.75214 22.3085L15.0606 15.0607C15.6464 14.4749 15.6464 13.5251 15.0606 12.9393C14.4749 12.3536 13.5251 12.3536 12.9393 12.9393L5.69148 21.2478Z"
    fill="currentColor"
  />
);

export const speedometerMedium = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.75 14C3.75 12.3906 4.12092 10.8678 4.78194 9.51252L6.24219 11.1865C5.92369 12.0645 5.75 13.012 5.75 14C5.75 18.5563 9.44365 22.25 14 22.25C18.5563 22.25 22.25 18.5563 22.25 14C22.25 9.44365 18.5563 5.75 14 5.75C13.012 5.75 12.0645 5.92368 11.1865 6.24218L9.51254 4.78193C10.8678 4.12092 12.3906 3.75 14 3.75C19.6609 3.75 24.25 8.33908 24.25 14C24.25 19.6609 19.6609 24.25 14 24.25C8.33908 24.25 3.75 19.6609 3.75 14ZM6.75215 5.69148L5.69149 6.75214L12.9393 15.0606C13.5251 15.6464 14.4749 15.6464 15.0607 15.0606C15.6464 14.4749 15.6464 13.5251 15.0607 12.9393L6.75215 5.69148Z"
    fill="currentColor"
  />
);

export const speedometerFast = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 3.75C15.6094 3.75 17.1322 4.12092 18.4875 4.78194L16.8135 6.24219C15.9355 5.92369 14.988 5.75 14 5.75C9.44365 5.75 5.75 9.44365 5.75 14C5.75 18.5563 9.44365 22.25 14 22.25C18.5563 22.25 22.25 18.5563 22.25 14C22.25 13.012 22.0763 12.0645 21.7578 11.1865L23.2181 9.51254C23.8791 10.8678 24.25 12.3906 24.25 14C24.25 19.6609 19.6609 24.25 14 24.25C8.33908 24.25 3.75 19.6609 3.75 14C3.75 8.33908 8.33908 3.75 14 3.75ZM22.3085 6.75215L21.2479 5.69149L12.9394 12.9393C12.3536 13.5251 12.3536 14.4749 12.9394 15.0607C13.5251 15.6464 14.4749 15.6464 15.0607 15.0607L22.3085 6.75215Z"
    fill="currentColor"
  />
);

export const speedometerVeryFast = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24.25 14C24.25 15.6094 23.8791 17.1322 23.2181 18.4875L21.7578 16.8135C22.0763 15.9355 22.25 14.988 22.25 14C22.25 9.44365 18.5564 5.75 14 5.75C9.44365 5.75 5.75 9.44365 5.75 14C5.75 18.5563 9.44365 22.25 14 22.25C14.988 22.25 15.9355 22.0763 16.8135 21.7578L18.4875 23.2181C17.1322 23.8791 15.6094 24.25 14 24.25C8.33908 24.25 3.75 19.6609 3.75 14C3.75 8.33908 8.33908 3.75 14 3.75C19.6609 3.75 24.25 8.33908 24.25 14ZM21.2478 22.3085L22.3085 21.2479L15.0607 12.9394C14.4749 12.3536 13.5251 12.3536 12.9393 12.9394C12.3536 13.5251 12.3536 14.4749 12.9393 15.0607L21.2478 22.3085Z"
    fill="currentColor"
  />
);
