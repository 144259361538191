import * as zod from 'zod';

export const InferenceCommand = zod.discriminatedUnion('command', [
  zod.object({
    command: zod.literal('inference'),
    metadata: zod.object({
      task_name: zod.string(),
      bot_ids: zod.string().array(),
      camera_ids: zod.string().array(),
    }),
  }),
  zod.object({
    command: zod.literal('stop'),
  }),
]);

export type InferenceCommand = zod.infer<typeof InferenceCommand>;

export const InferenceStateKind = zod.union([
  zod.literal('idle'),
  zod.literal('inference'),
]);

export type InferenceStateKind = zod.infer<typeof InferenceStateKind>;

export const InferenceState = zod.object({
  state: InferenceStateKind,
  inference_skills: zod.string().array(),
});

export type InferenceState = zod.infer<typeof InferenceState>;
