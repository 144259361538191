import * as zod from 'zod';

export const EwellixLiftTLTStrokeVariant = zod.enum([
  '300',
  '400',
  '500',
  '600',
  '700',
]);

export type EwellixLiftTLTStrokeVariant = zod.infer<
  typeof EwellixLiftTLTStrokeVariant
>;

export const EwellixLiftTLTConfiguration = zod.object({
  kind: zod.literal('EwellixLiftTLT'),
  name: zod.string().default('Lift TLT'),
  serialPortPath: zod.string().default('/dev/ttyUSB0'),
  strokeVariant: EwellixLiftTLTStrokeVariant.default('700'),
});

export type EwellixLiftTLTConfiguration = zod.infer<
  typeof EwellixLiftTLTConfiguration
>;
