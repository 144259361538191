import type { DistanceUnit, DistanceUnitInfo } from '@sb/remote-control/types';
import { roundToDecimalPlaces } from '@sb/utilities';

function using({
  abbreviation = 'm',
  decimalPlaces = 4,
  multiplier = 1,
}): Pick<
  DistanceUnitInfo,
  'decimalPlaces' | 'multiplier' | 'fromMeters' | 'toMeters' | 'toString'
> {
  return {
    decimalPlaces,
    multiplier,
    fromMeters: (value, roundingKind?: 'round' | 'floor' | 'ceil') =>
      typeof value === 'number'
        ? roundToDecimalPlaces(value * multiplier, decimalPlaces, roundingKind)
        : value,
    toMeters: (value) =>
      typeof value === 'number' ? value / multiplier : value,
    toString: (value, roundingKind?: 'round' | 'floor' | 'ceil') =>
      typeof value === 'number'
        ? `${roundToDecimalPlaces(value * multiplier, decimalPlaces, roundingKind)} ${abbreviation}`
        : `${value} ${abbreviation}`,
  };
}

export const DISTANCE_UNIT_INFOS: Array<DistanceUnitInfo> = [
  {
    unit: 'meter',
    name: 'Meter',
    abbreviation: 'm',
    ...using({ abbreviation: 'm', decimalPlaces: 4, multiplier: 1 }),
  },
  {
    unit: 'millimeter',
    name: 'Millimeter',
    abbreviation: 'mm',
    ...using({ abbreviation: 'mm', decimalPlaces: 1, multiplier: 1000 }),
  },
  {
    unit: 'inch',
    name: 'Inch',
    abbreviation: 'inch',
    ...using({
      abbreviation: 'inch',
      decimalPlaces: 3,
      multiplier: 39.37007874,
    }),
  },
];

export function getDistanceUnitInfo(unit?: DistanceUnit): DistanceUnitInfo {
  return (
    DISTANCE_UNIT_INFOS.find((info) => info.unit === unit) ??
    DISTANCE_UNIT_INFOS[0]
  );
}
