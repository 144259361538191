export const infoCircle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 5.25C9.16751 5.25 5.25 9.16751 5.25 14C5.25 18.8325 9.16751 22.75 14 22.75C18.8325 22.75 22.75 18.8325 22.75 14C22.75 9.16751 18.8325 5.25 14 5.25ZM3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM13 19.5V13H15V19.5H13ZM14 11.25C14.8284 11.25 15.5 10.5784 15.5 9.75C15.5 8.92157 14.8284 8.25 14 8.25C13.1716 8.25 12.5 8.92157 12.5 9.75C12.5 10.5784 13.1716 11.25 14 11.25Z"
    fill="currentColor"
  />
);

export const inforCircleFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM15.5 9.75C15.5 10.5784 14.8284 11.25 14 11.25C13.1716 11.25 12.5 10.5784 12.5 9.75C12.5 8.92157 13.1716 8.25 14 8.25C14.8284 8.25 15.5 8.92157 15.5 9.75ZM13 19.5V13H15V19.5H13Z"
    fill="currentColor"
  />
);
