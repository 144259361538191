import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

export namespace WaitForConfirmationStep {
  export const name = 'Wait for confirmation';
  export const description =
    'Routines will pause and a confirmation pop up will prompt the user to resume the routine';
  export const librarySection = Step.LibrarySection.InterfaceWithOperators;

  export const argumentKind = 'WaitForConfirmation';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    delay: zod.number().default(5),
    message: zod.string(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.message) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Messsage is not configured',
        fieldId: 'message',
      });
    }

    return {
      ...stepData,
      stepKind: 'WaitForConfirmation',
      args: {
        delayMS: args.delay * 1000,
        message: args.message,
      },
    };
  };
}

WaitForConfirmationStep satisfies Step.StepKindInfo;
