export const play = (
  <path
    d="M8 22.5V5.5L22.5 14L8 22.5Z"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const playFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.50572 4.6373L7 5.5V22.5L8.50572 23.3627L23.0057 14.8627V13.1373L8.50572 4.6373Z"
    fill="currentColor"
  />
);

export const pause = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 5V23H11V5H9ZM17 5V23H19V5H17Z"
    fill="currentColor"
  />
);

export const pauseFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.4 5H8.1L7 6V22L8.1 23H11.4L12.5 22V6L11.4 5ZM19.9 5H16.6L15.5 6V22L16.6 23H19.9L21 22V6L19.9 5Z"
    fill="currentColor"
  />
);

export const stop = (
  <path
    d="M6 22V6H22V22H6Z"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const stopFill = (
  <path d="M6 5L5 6V22L6 23H22L23 22V6L22 5H6Z" fill="currentColor" />
);

export const power = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 2.5V13.5H15V2.5H13ZM8.67361 7.05732C6.58996 8.65855 5.25 11.1726 5.25 14C5.25 18.8325 9.16751 22.75 14 22.75C18.8325 22.75 22.75 18.8325 22.75 14C22.75 11.1726 21.41 8.65855 19.3264 7.05732L20.5451 5.47149C23.1001 7.43498 24.75 10.525 24.75 14C24.75 19.937 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.937 3.25 14C3.25 10.525 4.8999 7.43498 7.45495 5.47149L8.67361 7.05732Z"
    fill="currentColor"
  />
);
