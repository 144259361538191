/**
 * Flange port: A label corresponding to a flange on the Tool Flange IO board
 */
import * as zod from 'zod';

export const FLANGE_OUTPUT_PORT_IDS = [
  'Flange Output 1',
  'Flange Output 2',
] as const;

export const FlangeOutputPortKind = zod.literal('FlangeOutput');

export type FlangeOutputPortKind = zod.infer<typeof FlangeOutputPortKind>;

export const FLANGE_OUTPUT_PORT_COUNT = FLANGE_OUTPUT_PORT_IDS.length;

export const FlangeOutputPortID = zod.enum(FLANGE_OUTPUT_PORT_IDS);

export type FlangeOutputPortID = zod.infer<typeof FlangeOutputPortID>;
