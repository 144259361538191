import * as zod from 'zod';

import { FirmwareTypeSchema } from '../firmware-interface/types';

const DateSchema = zod.preprocess(
  (arg) => (typeof arg === 'string' ? new Date(arg) : arg),
  zod.date(),
);

export const InstallationInfo = zod.object({
  status: zod.enum(['installing', 'installed', 'failed']),
  // cacheHit is only relevant when status === 'installed'
  // It indicates that we didn't actually flash the firmware, because an
  // identical binary was already flashed.
  cacheHit: zod.boolean().optional(),
  updatedAt: DateSchema,
  // buildInfo can only be determined when installing from a bundle.
  // When we install from a specified file, we have no idea how it was built.
  buildInfo: zod
    .object({
      // This is filled by the firmware-bot when installing
      buildId: zod.string().optional(),
      // We rely on the caller of firmware-installer-bot to provide a build name
      // If they don't provide one, this will be undefined
      buildName: zod.string().optional(),
      // gitHash should come from the bundle metadata
      gitHash: zod.string().optional(),

      // firmwareType:
      // cmakeConfig should come from the bundle metadata
      cmakeConfig: zod.record(zod.string(), zod.string()).optional(),

      firmwareType: FirmwareTypeSchema.optional(),

      variant: zod.string().optional(),
    })
    .optional(),
  binaryInfo: zod
    .object({
      // checksum of the checksum table per OpenBLT CsAddTable utility
      // Number is between 0 and 65535
      checksum: zod.number().optional(),
      // Size in bytes of the data to be programmed
      // Note that this is NOT the size of the .srec file
      size: zod.number().optional(),

      cm7checksum: zod.number().optional(),
      cm7size: zod.number().optional(),
      cm4checksum: zod.number().optional(),
      cm4size: zod.number().optional(),
    })
    .optional(),
  error: zod.string().optional(),
});

export type InstallationInfo = zod.infer<typeof InstallationInfo>;

export const RequestFirmwareInstallData = zod.object({
  joints: zod.array(zod.number()),
});

export type RequestFirmwareInstallData = zod.infer<
  typeof RequestFirmwareInstallData
>;
