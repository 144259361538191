import * as zod from 'zod';

export const OnRobotScrewdriverCommandKind = zod.enum([
  'TIGHTEN',
  'LOOSEN',
  'PICKUP',
  'STOP',
  'SET_SHANK_POSITION',
]);

export const OnRobotScrewdriverCommand = zod.object({
  kind: zod.literal('OnRobotScrewdriverCommand'),

  commandKind: OnRobotScrewdriverCommandKind,

  targetForce: zod.number().min(18).max(30).optional(), // STORED AS N 18 to 30 N

  screwLength: zod.number().min(0).max(35).optional(), // STORED AS MM (0-35) // 0 to 35000 muM

  targetTorque: zod.number().min(0.1).max(5).optional(), // STORED AS Nm (0.1-5) // 100 to 5000 Nmm

  // Docs bound between 0-55, but onrobot sometimes overshoots end by a bit, which
  // causes errors on our side.
  shankPosition: zod.number().min(-5).max(60).optional(), // STORED AS // 0 to 55mm
});

export type OnRobotScrewdriverCommand = zod.infer<
  typeof OnRobotScrewdriverCommand
>;

export type CommandKind = OnRobotScrewdriverCommand['commandKind'];
