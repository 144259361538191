import * as zod from 'zod';

import { DH_MAX_FORCE_PERCENT, DH_MIN_FORCE_PERCENT } from '../constants';

export const DHCommand = zod.object({
  targetForce: zod
    .number()
    .min(DH_MIN_FORCE_PERCENT)
    .max(DH_MAX_FORCE_PERCENT)
    .optional(),
  targetDiameter: zod.number(),
  targetDiameterTolerance: zod.number().optional(),

  targetSpeed: zod.number().min(0).max(1).default(0.6),

  /**
   * This field will fail the actuation if the force grip is not detected
   * after actuating a grip command.
   */
  waitForGripToContinue: zod.boolean().optional(),
});

export type DHCommand = zod.infer<typeof DHCommand>;
