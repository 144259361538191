import cx from 'classnames';

import type { ColorVariant } from '@sb/ui';

import { font, textColor } from '../styles';

import styles from './Typography.module.css';

export type TypographyComponent =
  | 'p'
  | 'span'
  | 'label'
  | 'li'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6';

type TypographyTextAlign = 'center' | 'left' | 'right';
type TypographyVariant = 'extraSmall' | 'small' | 'medium' | 'large';

export interface TypographyProps {
  children: React.ReactNode;
  className?: string;
  color?: ColorVariant;
  component?: TypographyComponent;
  hasLineThrough?: boolean;
  hasNoLineHeight?: boolean;
  hasNoWrap?: boolean;
  isBold?: boolean;
  isDisabled?: boolean;
  isCapitalized?: boolean;
  isUppercase?: boolean;
  role?: string;
  textAlign?: TypographyTextAlign;
  title?: string;
  truncateMultiline?: 2 | 3 | 4;
  variant?: TypographyVariant;
  onClick?: (arg?: any) => any;
  testPrefix?: string;
  'data-testid'?: string;
}

const Typography = ({
  children,
  className,
  color,
  component = 'p',
  hasLineThrough,
  hasNoLineHeight,
  hasNoWrap,
  isBold,
  isCapitalized,
  isUppercase,
  role,
  textAlign,
  title,
  truncateMultiline,
  variant = 'small',
  onClick,
  testPrefix,
  'data-testid': testID,
}: TypographyProps) => {
  const Component = component;
  const optionalProps: { [key: string]: string } = {};

  if (testPrefix) {
    optionalProps['data-testid'] = `${testPrefix}-${component}`;
  }

  if (testID) {
    optionalProps['data-testid'] = testID;
  }

  return (
    <Component
      className={cx(
        styles.typography,
        styles[component],
        styles[`${variant}Variant`],
        {
          [styles.lineThrough]: hasLineThrough,
          [styles.noLineHeight]: hasNoLineHeight,
          [styles.noWrap]: hasNoWrap,
          [styles.capitalized]: isCapitalized,
          [styles.uppercase]: isUppercase,
          [styles.truncateMultiline]: truncateMultiline,
          [styles[`truncateMultiline-${truncateMultiline}`]]: truncateMultiline,
          [styles[`${textAlign}Align`]]: textAlign,
        },
        color && textColor[color],
        isBold && font.weight.bold,
        className,
      )}
      onClick={onClick}
      role={role}
      title={title}
      {...optionalProps}
    >
      {children}
    </Component>
  );
};

export default Typography;
