import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function TickIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 12 9" {...props}>
      <path d="M4.25 7.21L1.358 4.242.642 4.94l3.61 3.702 7.11-7.293-.716-.698z" />
    </Icon>
  );
}
