import * as zod from 'zod';

export const SchunkEGxVariant = zod.enum([
  'EGK 25-MB-M-B',
  'EGK 25-MB-N-B',
  'EGK 40-MB-M-B',
  'EGK 40-MB-N-B',
  'EGK 50-MB-M-B',
  'EGK 50-MB-N-B',
  'EGU 50-MB-M-B',
  'EGU 50-MB-M-SD',
  'EGU 50-MB-N-B',
  'EGU 50-MB-N-SD',
  'EGU 60-MB-M-B',
  'EGU 60-MB-M-SD',
  'EGU 60-MB-N-B',
  'EGU 60-MB-N-SD',
]);

export type SchunkEGxVariant = zod.infer<typeof SchunkEGxVariant>;
