import * as zod from 'zod';

import {
  MODBUS_TCP_SERVER_NAME_DEFAULT,
  ONROBOT_TCP_PORT_DEFAULT,
  TCP_CONNECTION_TIMEOUT_MS_DEFAULT,
} from '../constants';

import { ModbusTCPRegisterData } from './types';

export const ModbusTCPServerConfiguration = zod.object({
  kind: zod.literal('ModbusTCPServer'),
  id: zod.string().optional(),
  name: zod.string().default(MODBUS_TCP_SERVER_NAME_DEFAULT),
  connectionOptions: zod
    .object({
      host: zod.string().default('0.0.0.0'),
      port: zod.number().default(ONROBOT_TCP_PORT_DEFAULT),
      timeoutMS: zod.number().default(TCP_CONNECTION_TIMEOUT_MS_DEFAULT),
    })
    .default({}),
  registers: zod.array(ModbusTCPRegisterData).optional(),
});

export type ModbusTCPServerConfiguration = zod.infer<
  typeof ModbusTCPServerConfiguration
>;
