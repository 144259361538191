import cx from 'classnames';
import { useLayoutEffect, useRef } from 'react';

import {
  useComponentColorClassName,
  type ComponentColor,
} from '../../../utility';

type Placement =
  | 'Middle'
  | 'TopMiddle'
  | 'TopLeft'
  | 'TopRight'
  | 'BottomLeft'
  | 'BottomRight';

const PLACEMENT_CLASSES: Record<Placement, string> = {
  Middle: cx('tw-justify-center', 'tw-text-15', 'tw-self-center'),
  TopMiddle: cx('tw-justify-center', 'tw-text-13', 'tw-self-start'),
  TopLeft: cx('tw-justify-end', 'tw-text-13', 'tw-self-start'),
  TopRight: cx('tw-justify-start', 'tw-text-13', 'tw-self-start'),
  BottomLeft: cx('tw-justify-end', 'tw-text-13', 'tw-self-end'),
  BottomRight: cx('tw-justify-start', 'tw-text-13', 'tw-self-end'),
};

type LabelColor = ComponentColor | 'Gray';

const COLOR_CLASSES: Record<LabelColor, string> = {
  Orange: cx('tw-text-orange-80', 'dark:tw-text-orange-30'),
  Red: cx('tw-text-red-80', 'dark:tw-text-red-30'),
  Green: cx('tw-text-green-70', 'dark:tw-text-green-30'),
  Blue: cx('tw-text-blue-70', 'dark:tw-text-blue-30'),
  Gray: '',
  Primary: '',
};

interface ValueLabelProps {
  percent: number;
  value: string;
  color?: LabelColor | 'Default';
  placement?: Placement;
}

export function ValueLabel({
  percent,
  value,
  color,
  placement = 'Middle',
}: ValueLabelProps) {
  const colorClassName = useComponentColorClassName(color, COLOR_CLASSES);

  const labelRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!leftRef.current || !rightRef.current || !labelRef.current) {
      return;
    }

    const labelWidth = labelRef.current.getBoundingClientRect().width;

    switch (placement) {
      case 'BottomLeft':
      case 'TopLeft':
        leftRef.current.style.minWidth = `${labelWidth}px`;
        rightRef.current.style.minWidth = '0';
        break;
      case 'BottomRight':
      case 'TopRight':
        leftRef.current.style.minWidth = '0';
        rightRef.current.style.minWidth = `${labelWidth}px`;
        break;
      default:
        leftRef.current.style.minWidth = `${labelWidth / 2}px`;
        rightRef.current.style.minWidth = `${labelWidth / 2}px`;
        break;
    }
  });

  return (
    <div className={cx('tw-absolute', 'tw-inset-0', 'tw-flex')}>
      <div
        ref={leftRef}
        className={cx('tw-flex-none', 'tw-w-0')}
        style={{ flexGrow: percent }}
      />

      <div
        className={cx(
          colorClassName,
          PLACEMENT_CLASSES[placement],
          'tw-flex-none',
          'tw-py-4',
          'tw-w-0',
          'tw-overflow-visible',
          'tw-flex',
        )}
      >
        <div ref={labelRef} className={cx('tw-px-6', 'tw-tabular-nums')}>
          {value}
        </div>
      </div>

      <div
        ref={rightRef}
        className={cx('tw-flex-none', 'tw-w-0')}
        style={{ flexGrow: 100 - percent }}
      />
    </div>
  );
}
