import * as zod from 'zod';

export const OnRobotVGP20Action = zod
  .union([
    zod.object({
      kind: zod.literal('applyingSuction'),
      status: zod.enum(['busy', 'success', 'timeout', 'failed']),
    }),
    zod.object({
      kind: zod.literal('releasingSuction'),
      status: zod.enum(['busy', 'success', 'timeout', 'failed']),
    }),
  ])
  .optional();

export type OnRobotVGP20Action = zod.infer<typeof OnRobotVGP20Action>;

export const OnRobotVGP20State = zod.object({
  kind: zod.literal('OnRobotVGP20'),
  error: zod.string().optional(),
  isConnected: zod.boolean(),
  isBusy: zod.boolean(),

  currentAction: zod
    .object({
      one: OnRobotVGP20Action,
      two: OnRobotVGP20Action,
      three: OnRobotVGP20Action,
      four: OnRobotVGP20Action,
    })
    .optional(),

  suctionForcePercentage: zod.object({
    one: zod.number().min(0).max(1),
    two: zod.number().min(0).max(1),
    three: zod.number().min(0).max(1),
    four: zod.number().min(0).max(1),
  }),
});

export type OnRobotVGP20State = zod.infer<typeof OnRobotVGP20State>;
