import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

interface TableHeadProps extends HTMLAttributes<HTMLTableSectionElement> {}

const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  (props, ref) => {
    return <thead ref={ref} {...props} />;
  },
);

export default TableHead;
