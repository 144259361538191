export const STANDARD_BOTS_ROBOT_ICON_SRC =
  '/images/standard-bots-robot-icon.svg';

export const STANDARD_BOTS_ANTIGRAVITY_SRC =
  '/images/standard-bots-antigravity-mode.svg';

export const GUIDED_MODE_INTERVAL_MS = 30;

export const CHECKBOX_NO_HUMANS_LABEL =
  'I confirm that no humans are within the reach of the robot.';

export const CURRENT_ITERATION_DEFAULT = 1;

export const MIN_ROUTINE_NAME_LENGTH = 1;
export const MAX_ROUTINE_NAME_LENGTH = 256;
export const MAX_ROUTINE_DESCRIPTION_LENGTH = 256;
export const MAX_STEP_DESCRIPTION_LENGTH = 256;

export const METER_TO_MM_CONVERSION_FACTOR = 1000;
