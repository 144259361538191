/**
 * Color variants based on our style guide.
 */
export type ColorVariant =
  | 'primary'
  | 'primaryDark'
  | 'secondary'
  | 'secondaryDark'
  | 'alertPrimary'
  | 'alertPrimaryDark'
  | 'alertSecondary'
  | 'alertSecondaryDark'
  | 'warning'
  | 'success'
  | 'neutral'
  | 'neutralDark'
  | 'black'
  | 'gray'
  | 'white';

/**
 * Select and Input components have the same size variants.
 */
export type SelectAndInputSizeVariant = 'small' | 'medium' | 'large';
export const SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT = 'medium';
