import cx from 'classnames';
import type { ThHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './TableHeader.module.css';

type HeaderOrientation = 'horizontal' | 'vertical';

interface TableHeaderProps extends ThHTMLAttributes<HTMLTableCellElement> {
  orientation?: HeaderOrientation;
}

const TableHeader = forwardRef<HTMLTableCellElement, TableHeaderProps>(
  (props, ref) => {
    const { className, orientation = 'horizontal', ...other } = props;

    return (
      <th
        ref={ref}
        className={cx(styles.tableHeader, styles[orientation], className)}
        {...other}
      />
    );
  },
);

export default TableHeader;
