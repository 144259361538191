/**
 * Inverse kinematics request:
 *
 * The payload for a request to the motion planner requesting plausible joint
 * angles for a target pose.
 */

import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

import { ArmJointPositions } from './ArmJointPositions';
import { GripperOpenness } from './GripperOpenness';

export const InverseKinematicsRequest = zod.object({
  gripperOpenness: GripperOpenness,
  pose: CartesianPose,
  checkValidity: zod.boolean(),
  jointSeed: ArmJointPositions.optional(),
});

export type InverseKinematicsRequest = zod.infer<
  typeof InverseKinematicsRequest
>;
