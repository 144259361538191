import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';
import { CameraIntegration } from '@sb/integrations/types/cameraTypes';
import { ArmJointPositions, TCPOffsetOption } from '@sb/motion-planning';

export namespace Space {
  export const Position = zod.object({
    name: zod.string().optional(),
    jointAngles: ArmJointPositions,
    pose: CartesianPose,
    tcpOption: TCPOffsetOption.optional(),
    wristPose: CartesianPose.optional(),
  });

  export type Position = zod.infer<typeof Position>;

  const ItemBase = zod.object({
    description: zod.string(),
    id: zod.string(),
    kind: zod.string(),
    name: zod.string(),
    positions: zod.array(Position).default([]),
  });

  export const FreeformPositionList = ItemBase.extend({
    kind: zod.literal('freeformPositionList'),
  });

  export type FreeformPositionList = zod.infer<typeof FreeformPositionList>;

  export const SinglePosition = ItemBase.extend({
    kind: zod.literal('singlePosition'),
  });

  export type SinglePosition = zod.infer<typeof SinglePosition>;

  export const GridPositionList = ItemBase.extend({
    cornerA: Position.nullable().default(null),
    cornerB: Position.nullable().default(null),
    cornerC: Position.nullable().default(null),
    cornerD: Position.nullable().default(null),
    kind: zod.literal('gridPositionList'),
    numColumns: zod.number().default(3),
    numRows: zod.number().default(3),
  });

  export type GridPositionList = zod.infer<typeof GridPositionList>;

  export const VisionPositionList = ItemBase.extend({
    kind: zod.literal('visionPositionList'),
    planeID: zod.string().optional(),
  });

  export type VisionPositionList = zod.infer<typeof VisionPositionList>;

  export const AccuracyCalibrationEntry = zod.object({
    rawPose: CartesianPose,
    calibratedPose: CartesianPose,
    offset: CartesianPose,
  });

  export type AccuracyCalibrationEntry = zod.infer<
    typeof AccuracyCalibrationEntry
  >;

  export const Plane = ItemBase.extend({
    kind: zod.literal('plane'),
    capturedWithCameraKind: zod.string().optional(),
    calibrationHomePosition: Position.optional(),
    calibrationEntries: AccuracyCalibrationEntry.array().default([]),
    calibrationCameraSettings: CameraIntegration.default({
      kind: 'wristCamera',
    }),
  });

  export type Plane = zod.infer<typeof Plane>;

  export const EnvironmentObject = ItemBase.extend({
    kind: zod.literal('environmentObject'),
    fileURL: zod.string().nullable().default(null),
    fileName: zod.string().nullable().default(null),
    pose: CartesianPose.default(ZERO_POSE),
    scale: zod.number().optional(),
  });

  export type EnvironmentObject = zod.infer<typeof EnvironmentObject>;

  export const Item = zod.discriminatedUnion('kind', [
    FreeformPositionList,
    GridPositionList,
    SinglePosition,
    VisionPositionList,
    Plane,
    EnvironmentObject,
  ]);

  export type Item = zod.infer<typeof Item>;

  export type ItemKind = Item['kind'];
}
