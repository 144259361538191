import * as zod from 'zod';

import type { Bit, Sixteen } from '@sb/utilities';

import type { SafetyPortID } from './SafetyPort';

export const SafeguardPair = zod.enum([
  'DIGITAL_IN_1_2',
  'DIGITAL_IN_3_4',
  'DIGITAL_IN_5_6',
  'DIGITAL_IN_7_8',
  'DIGITAL_IN_9_10',
  'DIGITAL_IN_11_12',
  'DIGITAL_IN_13_14',
  'DIGITAL_IN_15_16',
]);

export type SafetySafeguardPair = {
  longLabel: string;
  shortLabel: string;
  ports: [SafetyPortID, SafetyPortID];
  kind: SafeguardKind;
};

export const EstopInputPair: SafetySafeguardPair = {
  longLabel: 'E-Stop In',
  shortLabel: 'ES IN',
  ports: ['E-Stop 1', 'E-Stop 2'],
  kind: 'estop',
};

export const SafeguardInputPair: SafetySafeguardPair = {
  longLabel: 'Safeguard In',
  shortLabel: 'S IN',
  ports: ['Safeguard 1', 'Safeguard 2'],
  kind: 'slow',
};

export const ControlBoxEStopPair: SafetySafeguardPair = {
  longLabel: 'Control Box E-Stop',
  shortLabel: 'CB ES',
  ports: ['CB E-Stop 1', 'CB E-Stop 2'],
  kind: 'estop',
};

export const TabletCaseEStopPair: SafetySafeguardPair = {
  longLabel: 'Tablet Case E-Stop',
  shortLabel: 'TC ES',
  ports: ['Tablet E-Stop 1', 'Tablet E-Stop 2'],
  kind: 'estop',
};

export const SAFETY_SAFEGUARD_PAIRS: SafetySafeguardPair[] = [
  EstopInputPair,
  SafeguardInputPair,
  ControlBoxEStopPair,
  TabletCaseEStopPair,
];

export type SafeguardPair = zod.infer<typeof SafeguardPair>;

export const SAFEGUARD_PAIR_PORTS: Record<SafeguardPair, [number, number]> = {
  DIGITAL_IN_1_2: [1, 2],
  DIGITAL_IN_3_4: [3, 4],
  DIGITAL_IN_5_6: [5, 6],
  DIGITAL_IN_7_8: [7, 8],
  DIGITAL_IN_9_10: [9, 10],
  DIGITAL_IN_11_12: [11, 12],
  DIGITAL_IN_13_14: [13, 14],
  DIGITAL_IN_15_16: [15, 16],
};

export const SafeguardKind = zod.enum([
  'none',
  'slow',
  'estop',
  'pauselow',
  'pausehigh',
  'reset',
]);

export type SafeguardKind = zod.infer<typeof SafeguardKind>;

export const SAFEGUARD_KIND_NAMES: Record<SafeguardKind, string> = {
  none: 'Unassigned',
  slow: 'Slow Speed',
  estop: 'E-Stop',
  pauselow: 'Pause on low',
  pausehigh: 'Pause on high',
  reset: 'Reset Safeguards',
};

export const SafeguardRule = zod.object({
  kind: SafeguardKind,
  pair: SafeguardPair,
  isAutoReset: zod.boolean(),
});

export type SafeguardRule = zod.infer<typeof SafeguardRule>;

// TODO: move this to firmware-interface lib (Connor is currently working on this)
export function safeguardRuleIsTriggeredBy(
  rule: SafeguardRule,
  digitalIn: Sixteen<Bit>,
): boolean {
  const [number1, number2] = SAFEGUARD_PAIR_PORTS[rule.pair];

  const port1 = digitalIn[number1 - 1];
  const port2 = digitalIn[number2 - 1];

  switch (rule.kind) {
    case 'none':
      return false;
    case 'slow':
    case 'estop':
    case 'pauselow':
      return !port1 || !port2;
    case 'pausehigh':
      return Boolean(port1) || Boolean(port2);
    case 'reset':
      return Boolean(port1 && port2);
  }
}

// TODO: move this to firmware-interface lib (Connor is currently working on this)
export function parseSafeguardRuleFromCAN(payload: Uint8Array): SafeguardRule {
  const pair: SafeguardPair = (() => {
    switch (payload[0]) {
      case 0:
        return 'DIGITAL_IN_1_2';
      case 1:
        return 'DIGITAL_IN_3_4';
      case 2:
        return 'DIGITAL_IN_5_6';
      case 3:
        return 'DIGITAL_IN_7_8';
      case 4:
        return 'DIGITAL_IN_9_10';
      case 5:
        return 'DIGITAL_IN_11_12';
      case 6:
        return 'DIGITAL_IN_13_14';
      case 7:
        return 'DIGITAL_IN_15_16';
    }

    throw new Error(`Invalid safeguard pair: ${payload[1]}`);
  })();

  const kind: SafeguardKind = (() => {
    switch (payload[1]) {
      case 0:
        return 'none';
      case 1:
        return 'slow';
      case 2:
        return 'estop';
      case 3:
        return 'pauselow';
      case 4:
        return 'pausehigh';
      case 5:
        return 'reset';
    }

    throw new Error(`Invalid safeguard kind: ${payload[0]}`);
  })();

  const isAutoReset = Boolean(payload[2]);

  return {
    kind,
    pair,
    isAutoReset,
  };
}

export const SafeguardState = zod.enum(['slowSpeed', 'fullSpeed', 'eStop']);

export type SafeguardState = zod.infer<typeof SafeguardState>;

export const SAFEGUARD_STATE_NAMES: Record<SafeguardState, string> = {
  fullSpeed: 'Full Speed',
  slowSpeed: 'Slow Speed',
  eStop: 'E-Stop',
};

export const EstopHealthCheckState = zod.enum([
  'idle',
  'failed',
  'runningMain',
  'runningSafety',
  'runningWaiting',
]);

export type EstopHealthCheckState = zod.infer<typeof EstopHealthCheckState>;
