import type { UserData } from '@sb/feathers-types';
import type { DistanceUnit, User } from '@sb/remote-control/types';

function asValidDistanceUnit(value: any): DistanceUnit {
  switch (value) {
    case 'meter':
    case 'millimeter':
    case 'inch':
      return value;
    default:
      return 'meter';
  }
}

export function convertUserResponse(data: UserData): User.ConvertedResponse {
  return {
    distanceUnit: asValidDistanceUnit(data.distanceUnit),
    email: data.email ?? '',
    id: data.id,
    isProfileSetup: data.isProfileSetup,
    name: data.name,
    permissions: {
      account: data.hasAccountPermission,
      connectToRobot: data.hasConnectToRobotPermission,
      controlPanel: data.hasControlPanelPermission,
    },
    phone: data.phone,
    photo: data.photo,
    position: data.position,
    disabledBy: data.disabledBy,
    invitedBy: data.invitedBy,
    isUserVerified: data.isUserVerified,
    pinChangedAt: data.pinChangedAt.map((d) => new Date(d)),
  };
}
