/**
 * The payload is the thing that's in or attached to the end-effector.
 * The mass is currently useful or necessary for dynamics and planning
 * calculations.
 *
 * In the future, it will likely include a collision object as well.
 *
 * (if you're holding a widget, you don't want the widget to collide
 * with a door as you go through it)
 */
import * as zod from 'zod';

export const PAYLOAD_MASS_KG_DEFAULT = 0;

// The payload can be negative -- "payloads" are a specific case of a more general
// request form.
//
// "Holding something" is just pushing the thing upwards -- telling the robot
// we need to apply a force in the +z direction. If it has negative payload,
// the force goes in the -z direction, which may be valid if we wanted to pull
// a lever downwards. Other forces and torques should be expressible besides just
// gravity, and when we have a more general API, a "negative" value won't make
// any sense because they'll be providing a full Force + Torque.
export const PAYLOAD_MASS_KG_ABSOLUTE_MIN = -10;
export const PAYLOAD_MASS_KG_ABSOLUTE_MAX = 18;

export const PayloadState = zod.object({
  mass: zod
    .number()
    .min(PAYLOAD_MASS_KG_ABSOLUTE_MIN)
    .max(PAYLOAD_MASS_KG_ABSOLUTE_MAX),
});

export type PayloadState = zod.infer<typeof PayloadState>;
