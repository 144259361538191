import { useEffect, useState } from 'react';

import { onGetCurrentUserID } from '@sbrc/services';

export default function useAuthState() {
  const [userID, setUserID] = useState<string | null | undefined>(undefined);

  useEffect(() => {
    return onGetCurrentUserID(setUserID);
  }, []);

  return { userID };
}
