import { Severity } from '../../types';

type Color = {
  bg?: string;
  text: string;
};

export const getIconColor = (severity: Severity): Color => {
  return {
    [Severity.Error]: {
      bg: `#b31412`,
      text: `#ffffff`,
    },
    [Severity.Warning]: {
      bg: `#d56e06`,
      text: `#ffffff`,
    },
    [Severity.Information]: {
      text: '#12b5cb',
    },
    [Severity.Debug]: {
      text: '#d2e3fc',
    },
  }[severity];
};

export const secondaryText = `#9aa0a6`;
export const secondaryTextDark = `#5f6368`;
