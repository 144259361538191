import type { Space } from '@sb/routine-runner';
import type { RoutineRunnerHandle } from '@sbrc/services';

export const getRobotPosition = (
  routineRunnerHandle: RoutineRunnerHandle,
): Space.Position | null => {
  const routineRunnerState = routineRunnerHandle.getState();

  if (!routineRunnerState) {
    return null;
  }

  return {
    jointAngles: routineRunnerState.kinematicState.jointAngles,
    pose: routineRunnerState.kinematicState.tooltipPoint,
    tcpOption: routineRunnerState.kinematicState.tcpOffsetOption,
    wristPose:
      routineRunnerState.kinematicState.tcpOffsetOption === 'wrist'
        ? undefined
        : routineRunnerState.kinematicState.wristPose,
  };
};
