import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

import { HaasRunProgramArguments } from '../steps/HaasRunProgram/Arguments';

import { validateHaasAddress } from './validateHaasAddress';

/**
 * Types matching the routine runner schema for "HaasRunProgram" Steps
 */
export namespace HaasRunProgramStepDatabase {
  export const name = 'Run Haas program';
  export const description =
    'Run a program on a Haas device connected over Ethernet';
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const argumentKind = 'HaasRunProgram';

  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    ...HaasRunProgramArguments.shape,
    programName: zod.string().default(''),
    waitUntilIdle: HaasRunProgramArguments.shape.waitUntilIdle.default(true),
    disableCellSafe:
      HaasRunProgramArguments.shape.disableCellSafe.default(false),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    validateHaasAddress(args);

    if (!args.programName.match(/^\d+\.nc$/)) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Program Name must contain numbers and end in .nc',
        fieldId: 'programName',
      });
    }

    return {
      ...stepData,
      stepKind: 'HaasRunProgram',
      args,
    };
  };
}

HaasRunProgramStepDatabase satisfies Step.StepKindInfo;
