import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function MinusIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 18 4">
      <path d="M.5.5h16v1H.5z" />
    </Icon>
  );
}
