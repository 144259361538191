import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import { convertMetersPerSecond } from '../../shared/convertMetersPerSecond';
import { WeldMachineMode } from '../../types/WeldMachineState';

export namespace WeldStepDatabase {
  export const name = 'Weld';
  export const description = 'Perform a weld';
  export const deviceKinds = ['WeldMachine'] as const;
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'Weld';

  export const defaultVoltage = 15;

  export const defaultWireFeedSpeed =
    convertMetersPerSecond.fromInchesPerMinute(250);

  export const defaultTravelSpeed =
    convertMetersPerSecond.fromInchesPerMinute(15);

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    mode: WeldMachineMode.default('constantVoltage'),
    voltage: zod.number().default(defaultVoltage),
    arcStartTime: zod.number().default(0), // seconds
    craterFillTime: zod.number().default(0), // seconds
    wireFeedSpeed: zod.number().default(defaultWireFeedSpeed), // meters per second
    travelSpeed: zod.number().default(defaultTravelSpeed), // meters per second
    stickoutOffset: zod.number().default(0), // meters
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'Weld',
      args,
    };
  };
}

WeldStepDatabase satisfies Step.StepKindInfo;
