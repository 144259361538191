/**
 * @example
 * const optionalNums: Array<number | undefined> = [1, 2, undefined, 3];
 * const nums = optionalNums.filter(isNotUndefined);
 * // inferred type of nums is number[]
 */
export function isNotUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

/**
 * @example
 * const nullableNums: Array<number | null> = [1, 2, null, 3];
 * const nums = nullableNums.filter(isNotNull);
 * // inferred type of nums is number[]
 */
export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}
