/**
 * Relative joint motion plan request:
 * The payload for a request to the motion planner for a motion that
 * moves in a relative joint direction. This is also known as "joint jogging".
 *
 * The offset is the "vector" in joint space to compute a path towards.
 *
 * The longest path found is returned.
 *
 * The offset can be in the tooltip frame or the in the robot base frame.
 */
import type * as zod from 'zod';

import { ArmJointPositions } from './ArmJointPositions';
import { GripperOpenness } from './GripperOpenness';
import { MotionPlanOptions } from './MotionPlanOptions';

export const RelativeJointMotionPlanRequest = MotionPlanOptions.extend({
  gripperOpenness: GripperOpenness,
  offset: ArmJointPositions,
  // where the line should actually start
  lineStartPosition: ArmJointPositions,
  // where the motion should start (not necessarily on the line)
  startingJointPositions: ArmJointPositions,
});

export type RelativeJointMotionPlanRequest = zod.infer<
  typeof RelativeJointMotionPlanRequest
>;
