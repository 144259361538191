import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

interface WizardState {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const WizardContext = createContext<WizardState>({
  isCollapsed: false,
  setIsCollapsed: () => {},
});

export const useWizardContext = (): WizardState => {
  return useContext(WizardContext);
};
