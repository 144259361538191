import * as zod from 'zod';

import { InputPortID } from '@sb/routine-runner/types/InputPort';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationSensorControlBoxIOInterface = zod.object({
  name: zod.string().default('Sensor'),
  kind: zod.literal(IntegrationInterfaceKind.Values.controlBoxIO),
  ioPort: InputPortID.nullable().default(null),
  highValueName: zod.string().default('high'),
  lowValueName: zod.string().default('low'),
});

export type IntegrationSensorControlBoxIOInterface = zod.infer<
  typeof IntegrationSensorControlBoxIOInterface
>;
