import cx from 'classnames';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './TableRow.module.css';

type TableRowBorderVariant = 'light' | 'dark' | 'none';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  borderVariant?: TableRowBorderVariant;
  hideBorder?: boolean;
  rowAlign?: 'baseline' | 'bottom' | 'center';
}

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  (props, ref) => {
    const {
      borderVariant = 'none',
      className,
      hideBorder,
      rowAlign = 'center',
      ...other
    } = props;

    return (
      <tr
        ref={ref}
        className={cx(
          styles.tableRow,
          styles.row,
          styles[rowAlign],
          {
            [styles[borderVariant]]: !hideBorder,
            [styles.noBorder]: hideBorder,
          },
          className,
        )}
        {...other}
      />
    );
  },
);

export default TableRow;
