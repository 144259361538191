export const plus = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13 15L13 23H15L15 15L23 15V13L15 13L15 5H13L13 13L5 13V15L13 15Z"
    fill="currentColor"
  />
);

export const plusCircle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 5.25C9.16751 5.25 5.25 9.16751 5.25 14C5.25 18.8325 9.16751 22.75 14 22.75C18.8325 22.75 22.75 18.8325 22.75 14C22.75 9.16751 18.8325 5.25 14 5.25ZM3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM15 13L19 13L19 15L15 15L15 19H13L13 15L9 15V13L13 13L13 9L15 9L15 13Z"
    fill="currentColor"
  />
);

export const plusCircleFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM13 19L13 15L9 15V13L13 13L13 9L15 9L15 13L19 13L19 15L15 15L15 19H13Z"
    fill="currentColor"
  />
);

export const plusRectangePortrait = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5 4L6 3H22L23 4V24L22 25H6L5 24V4ZM7 5V23H21V5H7ZM15 13H18.5V15H15V18.5H13V15H9.5V13H13V9.5H15V13Z"
    fill="currentColor"
  />
);

export const plusRectange = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 6L4 5H24L25 6V22L24 23H4L3 22V6ZM5 7V21H23V7H5ZM15 13H18.5V15H15V18.5H13V15H9.5V13H13V9.5H15V13Z"
    fill="currentColor"
  />
);
