/**
 * Relay port: A label corresponding to a relay input port on the IO board
 */
import * as zod from 'zod';

export const RELAY_PORT_IDS = ['Relay 1', 'Relay 2', 'Relay 3'] as const;

export const RELAY_PORT_COUNT = RELAY_PORT_IDS.length;

export const RelayPortKind = zod.literal('Relay');

export type RelayPortKind = zod.infer<typeof RelayPortKind>;

export const RelayPortID = zod.enum(RELAY_PORT_IDS);

export type RelayPortID = zod.infer<typeof RelayPortID>;
