import * as zod from 'zod';

import { SchunkEGxVariant } from './SchunkEGxVariant';

export const SchunkEGxState = zod.object({
  kind: zod.literal('SchunkEGx'),
  variant: SchunkEGxVariant.nullable(),
  isConnected: zod.boolean(),
  isBusy: zod.boolean(),
  readyForOperation: zod.boolean(),
  controlAuthorityFieldbus: zod.boolean(),
  readyForShutdown: zod.boolean(),
  commandSuccessfullyProcessed: zod.boolean(),
  commandReceivedToggle: zod.boolean(),
  releasedForManualMovement: zod.boolean(),
  softwareLimitReached: zod.boolean(),
  noWorkpieceDetected: zod.boolean(),
  workpieceGripped: zod.boolean(),
  positionReached: zod.boolean(),
  workpiecePregripStarted: zod.boolean(),
  gpeActivated: zod.boolean(),
  workpieceLost: zod.boolean(),
  wrongWorkpieceGripped: zod.boolean(),
  position: zod.number(), // meters
  jawPosition: zod.number(), // position excluding zero offset
  errorCode: zod.number(),
  warningCode: zod.number(),
  notFeasibleCode: zod.number(),
});

export type SchunkEGxState = zod.infer<typeof SchunkEGxState>;
