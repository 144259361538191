import * as zod from 'zod';

export const DockerImageData = zod.object({
  key: zod.string(),
  path: zod.string(),
  error: zod.optional(zod.string()),
  buildId: zod.string(),
  buildName: zod.string(),
  offlineUpdatePath: zod.string(),
});

export type DockerImageData = zod.infer<typeof DockerImageData>;
