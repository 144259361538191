import { ActionRequiredError } from '@sb/remote-control/types';
import type { Expression } from '@sb/routine-runner';

export function validateJSExpression(
  expression: Expression | undefined | null,
  fieldId?: string,
  name = fieldId,
): void {
  if (expression?.kind === 'JavaScript' && expression.errorMessage) {
    if (fieldId) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: `Error in ${name}: ${expression.errorMessage}`,
        fieldId,
      });
    } else {
      throw new Error(`Error in expression: ${expression.errorMessage}`);
    }
  }
}
