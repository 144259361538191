import cx from 'classnames';
import { forwardRef, useRef } from 'react';

import { roundToDecimalPlaces } from '@sb/utilities';

import Typography from './Typography';

import styles from './RangeSliderInput.module.css';

type RangeSliderIndicator = 'round' | 'tick';

export interface RangeSliderInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  indicator?: RangeSliderIndicator;
  maxLabel?: string;
  minLabel?: string;
  numberContext?: string;
  showCurrentValue?: boolean;
  upperLimit?: number;
  decimalPlaces?: number;
}

export const RangeSliderInput = forwardRef<
  HTMLInputElement,
  RangeSliderInputProps
>((props, ref) => {
  const {
    className,
    disabled,
    indicator = 'round',
    max: originalMax,
    maxLabel,
    min: originalMin,
    minLabel,
    numberContext,
    onChange,
    showCurrentValue,
    upperLimit = Infinity,
    value: originalValue,
    decimalPlaces = 0,
    ...otherProps
  } = props;

  // the props below are casted to numbers to simplify ts checks
  // throughout this component
  const max = Number(originalMax);
  const min = Number(originalMin);
  const value = Math.min(Number(originalValue), max, upperLimit);

  const parentRef = useRef<HTMLDivElement | null>(null);

  const hasReachedUpperLimit = value >= upperLimit;

  const upperLimitFraction = Math.min(1, (upperLimit - min) / (max - min));

  const currentValueFraction = Math.min(1, (value - min) / (max - min));

  const colorStopFraction = hasReachedUpperLimit
    ? upperLimitFraction
    : currentValueFraction;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange || disabled) {
      return;
    }

    if (event.target.valueAsNumber > upperLimit) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = String(upperLimit);
    }

    onChange(event);
  };

  const cssCustomProps: any = {
    '--range-slider--current-value-fraction': currentValueFraction,
    '--range-slider--upper-limit-fraction': upperLimitFraction,
    '--range-slider--color-stop-percent': `${colorStopFraction * 100}%`,
  };

  return (
    <div
      ref={parentRef}
      className={cx(
        styles.rangeSliderInput,
        className,
        indicator === 'round' && styles.roundIndicator,
      )}
    >
      {!showCurrentValue && (
        <Typography
          className={styles.noIndicatorLabel}
          color="primary"
          component="label"
        >
          {roundToDecimalPlaces(value, decimalPlaces)}
          {numberContext}
        </Typography>
      )}

      {showCurrentValue && (
        <div className={styles.rangeLimitContainer}>
          <Typography className={styles.rangeLimit}>
            {minLabel ??
              `${roundToDecimalPlaces(min, decimalPlaces)}${numberContext}`}
          </Typography>
        </div>
      )}

      <div
        className={cx(styles.sliderContainer, disabled && styles.disabled)}
        style={cssCustomProps}
      >
        {upperLimitFraction < 1 && <div className={styles.upperLimit} />}

        <input
          ref={ref}
          className={cx(styles.input, hasReachedUpperLimit && styles.filled)}
          disabled={disabled}
          max={max}
          min={min}
          onChange={handleOnChange}
          type="range"
          value={value}
          {...otherProps}
        />

        {showCurrentValue && (
          <span className={styles.currentValue}>
            {roundToDecimalPlaces(value, decimalPlaces)}
            {numberContext}
          </span>
        )}
      </div>

      <Typography className={styles.rangeLimit}>
        {maxLabel ??
          `${roundToDecimalPlaces(max, decimalPlaces)}${numberContext}`}
      </Typography>
    </div>
  );
});
