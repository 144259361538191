import { memoize } from 'lodash';
// @ts-ignore
import type * as RCL from 'rclnodejs';

import { makeLogNamespace } from '@sb/log/log';
import { loadHostIdentity } from '@sb/service-interfaces/host/identity';

import { getNode } from './getNode';
import { getRCL } from './getRCL';

const log = makeLogNamespace('speedScaleFactor');
let lastSpeedScaleFactor = 1.0;

export class SpeedScaleFactorPublisher {
  private speedScaleFactor: number = 1.0;

  private publishInterval: ReturnType<typeof setInterval> | undefined;

  private publishIntervalMS = 1000;

  private otherScaleFactorsGetter: () => [speedRestriction: number];

  private publisher: RCL.Publisher<'std_msgs/msg/Float64'>;

  public constructor(publisher: RCL.Publisher<'std_msgs/msg/Float64'>) {
    this.publisher = publisher;
    this.otherScaleFactorsGetter = () => [1.0];
  }

  public setOtherScaleFactorsGetter(getter: () => [speedRestriction: number]) {
    this.otherScaleFactorsGetter = getter;
  }

  public startPublishingSpeedScaleFactor() {
    if (this.publishInterval) {
      log.info('startPublishingSpeedScaleFactor', 'already publishing');

      return;
    }

    log.info('startPublishingSpeedScaleFactor', 'starting to publish');

    this.publishInterval = setInterval(async () => {
      await this.publishSpeedScaleFactor();
    }, this.publishIntervalMS);
  }

  public stopPublishingSpeedScaleFactor() {
    log.info('stopPublishingSpeedScaleFactor', 'stopping to publish');

    if (this.publishInterval) {
      clearInterval(this.publishInterval);
      this.publishInterval = undefined;
    }
  }

  public async setSpeedScaleFactor(speedScaleFactor: number) {
    log.info(
      'setSpeedScaleFactor',
      `setting speed scale factor: ${speedScaleFactor}`,
    );

    this.speedScaleFactor = speedScaleFactor;
    await this.publishSpeedScaleFactor();
  }

  private async publishSpeedScaleFactor() {
    const rcl = await getRCL();

    const factors = [this.speedScaleFactor];

    factors.push(...this.otherScaleFactorsGetter());

    const message = rcl.createMessageObject('std_msgs/msg/Float64');

    const newSpeedScaleFactor = Math.min(...factors);

    if (newSpeedScaleFactor !== lastSpeedScaleFactor) {
      log.info(
        'publishSpeedScaleFactor',
        `Changing published speed scale factor from: ${lastSpeedScaleFactor} to: ${newSpeedScaleFactor} `,
      );
    }

    lastSpeedScaleFactor = newSpeedScaleFactor;

    message.data = newSpeedScaleFactor;

    this.publisher.publish(message);
  }
}

export const getSpeedScaleFactorPublisher = memoize(async () => {
  const node = await getNode();

  const robotID = (await loadHostIdentity())?.robotId ?? 'local';
  const topic = `/${robotID}/ro1/hardware/scaling_factor`;

  log.info('getSpeedScaleFactorPublisher', `publishing to ${topic}`);

  const publisher = node.createPublisher('std_msgs/msg/Float64', topic);

  const speedScaleFactorPublisher = new SpeedScaleFactorPublisher(publisher);

  return speedScaleFactorPublisher;
});
