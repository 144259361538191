import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { EquipmentItem } from '@sb/integrations/types';
import type { Unsubscribe } from '@sb/remote-control/types';
import { convertEquipmentResponse } from '@sb/remote-control/util/convertEquipmentResponse';

import { getEquipmentService } from './utils';

export function onGetAllEquipment(
  onSnapshot: (equipment: EquipmentItem[]) => void,
): Unsubscribe {
  const subscription = getEquipmentService()
    .watch()
    .find({ query: { $sort: { isEnabled: -1, updatedAt: -1 } } })
    .pipe(
      map((response) => {
        const equipment = mapPaginated(response, convertEquipmentResponse);

        return equipment;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}

export function onGetAllEnabledEquipment(
  onSnapshot: (equipment: EquipmentItem[]) => void,
): Unsubscribe {
  const subscription = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(
      map((response) => {
        const equipment = mapPaginated(response, convertEquipmentResponse);

        return equipment;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
