import * as zod from 'zod';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationActionHaasNGCInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.haasNGC),
  program: zod.string().default(''),
});

export type IntegrationActionHaasNGCInterface = zod.infer<
  typeof IntegrationActionHaasNGCInterface
>;
