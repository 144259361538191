import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

/**
 * Types matching the routine runner schema for "LoopControl" operations.
 */
export namespace LoopControlStep {
  export const name = 'Restart loop';
  export const description = 'Jump to beginning of a Loop';
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '4';
  export const argumentKind = 'LoopControl';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    kind: zod
      .union([zod.literal('break'), zod.literal('continue')])
      .default('continue'),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({ step }) => {
    if (step.stepKind !== argumentKind) {
      return;
    }

    // Check if this step is a child of a 'Loop' step.
    const isParentALoopStep = step.parentSteps.some(
      (parentStep) => parentStep?.stepKind === 'Loop',
    );

    // If we can't find any 'Loop' steps in parent nodes, then
    // this step is invalid.
    if (!isParentALoopStep) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Move Restart Loop inside a Loop step',
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'LoopControl',
      args: { kind: args.kind },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    if (args.kind === 'break') {
      return 'Break out of parent Loop';
    }

    return 'Jump to beginning of parent Loop';
  };
}

LoopControlStep satisfies Step.StepKindInfo;
