export const pencil = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M23.3692 5.69151L22.3085 4.63084L20.8943 4.63084L19.4801 6.04506L21.9549 8.51994L23.3692 7.10572V5.69151ZM20.8943 9.5806L18.4194 7.10572L7.25128 18.2738L4.80761 22.4853L5.51472 23.1924L9.72616 20.7487L20.8943 9.5806Z"
    fill="currentColor"
  />
);

export const pencilSquare = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24.3403 2.90436L25.0979 3.66197V4.67212L24.1888 5.58126L22.421 3.8135L23.3302 2.90436H24.3403ZM21.7139 4.52062L23.4817 6.28839L14.5416 15.2285L11.5334 16.9739L11.0283 16.4689L12.7738 13.4607L21.7139 4.52062ZM5 4.99999H18.4061L16.4061 6.99999H6V22H21V11.5985L23 9.59848V23L22 24H5L4 23V5.99999L5 4.99999Z"
    fill="currentColor"
  />
);
