import { useCallback, useEffect, useState } from 'react';

import * as log from '@sb/log';
import type { RoutineRunnerHandle } from '@sbrc/services';
import { getLiveRoutineRunnerHandle } from '@sbrc/services';
import { useRobotIdentity } from '@sbrc/services/feathers-client/robot-identity';
import { globalCache } from '@sbrc/utils';

import { getStandardBotsSdkSubscriber } from './useStandardBotsSdk';

/**
 * Get a RoutineRunnerHandle with a listener for connection changes
 * that refreshes the api token when the connection changes
 *
 * @returns RoutineRunnerHandle
 */
function getRoutineRunnerHandle(): RoutineRunnerHandle {
  return globalCache(
    'useLiveRobotRunnerHandle.getRoutineRunnerHandle',
    ({ reset }: { reset: () => void }) => {
      const handle = getLiveRoutineRunnerHandle();

      handle.onDestroy(() => reset());

      log.info('useLiveRoutineRunnerHandle', 'adding update token handler');

      handle.onConnectionChange(() => {
        if (handle.getConnectionStatus().kind === 'connected') {
          log.info('useLiveRoutineRunnerHandle', 'updating api token');
          getStandardBotsSdkSubscriber().updateToken();
        }
      });

      return handle;
    },
  );
}

/**
 * Connect to a live running robot.
 * Re-renders when connection state changes
 *
 * @returns A RoutineRunnerHandle
 */
export function useLiveRoutineRunnerHandle(): RoutineRunnerHandle {
  const robotIdentity = useRobotIdentity();
  const robotOperationMode = robotIdentity?.robotOperationMode ?? 'live';

  const handle = getRoutineRunnerHandle();
  handle.setDisabled(robotOperationMode === 'assembly');

  // used to force a re-render when the routine runner handle changes
  const [, updateToken] = useState({});
  const rerender = useCallback(() => updateToken({}), []);

  // whenever the handle has a change that requires a re-render, re-render
  useEffect(() => {
    return handle.onChange(rerender);
  }, [handle, rerender]);

  return handle;
}
