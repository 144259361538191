import * as zod from 'zod';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationFlangeIOInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.flangeIO),
  voltage: zod.enum(['24V', '12V']).default('12V'),
  circuitKind: zod.enum(['pnp', 'npn']).default('pnp'),
});

export type IntegrationFlangeIOInterface = zod.infer<
  typeof IntegrationFlangeIOInterface
>;

export const INTEGRATION_FLANGE_IO_INTERFACE_DEFAULT: IntegrationFlangeIOInterface =
  {
    kind: 'flangeIO',
    voltage: '12V',
    circuitKind: 'pnp',
  };
