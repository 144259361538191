/**
 * Motion plan request: The full payload to send to the motion planner on a plan path
 */

import * as zod from 'zod';

import { ArmJointPositions } from './ArmJointPositions';
import { ArmTarget } from './ArmTarget';
import { GripperOpenness } from './GripperOpenness';
import { MotionPlanOptions } from './MotionPlanOptions';
import { PlannerType } from './PlannerType';
import { SmoothingType } from './SmoothingType';

/**
 * Motion plan specification without the speed specifications
 */
export const MotionPlanRequestShape = zod.object({
  gripperOpenness: GripperOpenness.optional(),
  targets: zod.array(ArmTarget).nonempty(),
  startingJointPositions: ArmJointPositions,
  plannerType: zod.optional(PlannerType),
  isCacheable: zod.boolean().optional(),
  smoothingType: zod.optional(SmoothingType),
});

export type MotionPlanRequestShape = zod.infer<typeof MotionPlanRequestShape>;

export const MotionPlanRequest = MotionPlanOptions.and(MotionPlanRequestShape);

export type MotionPlanRequest = zod.infer<typeof MotionPlanRequest>;
