/**
 * Common options for motion plans
 */

import * as zod from 'zod';

import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_TOOLTIP_SPEED,
  ArmJointAccelerations,
  ArmJointVelocities,
  DEFAULT_BASE_ACCELERATION_SCALING,
  DEFAULT_BASE_VELOCITY_SCALING,
  DEFAULT_SCALING_FACTOR,
} from '@sb/motion-planning';

export const SpeedProfile = zod.object({
  maxJointSpeeds: ArmJointVelocities,
  // Maximum tooltip velocity in meters per second
  maxTooltipSpeed: zod.number(),
  maxJointAccelerations: ArmJointAccelerations,
  // ROS2 uses scaling factors as a percent of the absolute max
  // instead raw acceleration / velocity values
  // percentage of the absolute max acceleration and velocity
  baseAccelerationScaling: zod
    .number()
    .default(DEFAULT_BASE_ACCELERATION_SCALING),
  baseVelocityScaling: zod.number().default(DEFAULT_BASE_VELOCITY_SCALING),
  // percentage of the base acceleration and velocity scaling
  scalingFactor: zod.number().default(DEFAULT_SCALING_FACTOR),
});

export type SpeedProfile = zod.infer<typeof SpeedProfile>;

export const ABSOLUTE_MAX_SPEED_PROFILE = {
  maxJointSpeeds: ABSOLUTE_MAX_JOINT_SPEEDS,
  maxJointAccelerations: ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  maxTooltipSpeed: ABSOLUTE_MAX_TOOLTIP_SPEED,
  baseAccelerationScaling: 1,
  baseVelocityScaling: 1,
  scalingFactor: 1,
};
