/* eslint-disable no-underscore-dangle */

import { STILL_GOING_CHECK_INTERVAL_MS } from '@sb/routine-runner/constants';
import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';
import { wait } from '@sb/utilities';

import { ActuateGripperArguments as Arguments } from './Arguments';
import { ActuateGripperVariables as Variables } from './Variables';

export class ActuateGripperStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  // the play loop knows to clean up after `stop()` is called
  private stillGoing: boolean = false;

  // while we're paused, we don't want to send another gripper command
  private isPaused: boolean = false;

  public initializeVariableState(): void {
    this.variables = {
      succeeded: false,
    };
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const { gripperCommand, payload, retry, stopRoutineOnFailure } = this.args;
    this.setVariable('succeeded', false);
    this.stillGoing = true;

    // To start, this will basically just call `actuateGripper`.
    while (this.stillGoing) {
      // If `actuateGripper` gets interrupted by `pause()`'s `stopGripper`,
      // we want to loop until we've resumed, and we'll call `actuateGripper` again
      // when we resume.
      if (!this.isPaused) {
        try {
          await this.routineContext.actuateDevice(gripperCommand, { retry });

          if (payload) {
            this.routineContext.setPayload(payload);
          }

          this.setVariable('succeeded', true);
        } catch (error) {
          if (this.isPaused) {
            continue;
          }

          if (!stopRoutineOnFailure) {
            break;
          }

          return fail({
            failure: {
              kind: FailureKind.GripperFailure,
              message: error.message,
            },
            failureReason: `Failed to actuate gripper during “Actuate Gripper” step: ${error.message}`,
            error,
          });
        }

        // If pause stopped it, we want to keep looping.
        // If pause didn't stop it, it either succeeded or `stop()` stopped it.
        // In either case, `play()` should now resolve.
        if (!this.isPaused) {
          break;
        }
      }

      await wait(STILL_GOING_CHECK_INTERVAL_MS);
    }

    this.stillGoing = false;

    return undefined;
  }

  public _pause(): void {
    this.isPaused = true;
    this.routineContext.stopGripper();
  }

  public _resume(): void {
    this.isPaused = false;
  }

  public _stop(): void {
    this.routineContext.stopGripper();
    this.stillGoing = false;

    this.initializeVariableState();
  }

  public getSelfTimeout(): number {
    return 10_000;
  }
}
