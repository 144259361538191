import * as zod from 'zod';

import { SpeedProfile } from '@sb/routine-runner';

/**
 * Users can customize the motion speed for some steps.
 */
export const MotionSpeed = zod.object({
  /**
   * Some users can customize speed limits and accelerations for each joint.
   * We're using percents here because these values need to be updated when
   * the routine speed limit changes.
   */
  customLimits: SpeedProfile.nullable().optional(),

  /**
   * When this value is `null` or `undefined`, we're using the
   * robot speed limit.
   */
  motionSpeedPercent: zod.number().nullable().optional(),
});

export type MotionSpeed = zod.infer<typeof MotionSpeed>;
