/**
 * Robot to external port: A label corresponding to an output port on the IO board
 */
import * as zod from 'zod';

import {
  FLANGE_OUTPUT_PORT_IDS,
  FlangeOutputPortKind,
} from './FlangeOutputPort';
import { OUTPUT_PORT_IDS, OutputPortKind } from './OutputPort';
import { RELAY_PORT_IDS, RelayPortKind } from './RelayPort';

export const ANY_OUTPUT_PORT_IDS = [
  ...OUTPUT_PORT_IDS,
  ...RELAY_PORT_IDS,
  ...FLANGE_OUTPUT_PORT_IDS,
] as const;

export const AnyOutputPortKind = zod.union([
  OutputPortKind,
  RelayPortKind,
  FlangeOutputPortKind,
]);

export type AnyOutputPortKind = zod.infer<typeof AnyOutputPortKind>;

export const AnyOutputPortID = zod.enum(ANY_OUTPUT_PORT_IDS);

export type AnyOutputPortID = zod.infer<typeof AnyOutputPortID>;
