/**
 * Cartesian direction: a direction along an axis in Cartesian 6-space
 */
import * as zod from 'zod';

export const CARTESIAN_DIRECTIONS = [
  '+x',
  '-x',
  '+y',
  '-y',
  '+z',
  '-z',
  '+roll',
  '-roll',
  '+pitch',
  '-pitch',
  '+yaw',
  '-yaw',
] as const;

export const CartesianDirection = zod.enum(CARTESIAN_DIRECTIONS);

export type CartesianDirection = zod.infer<typeof CartesianDirection>;
