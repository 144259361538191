export const clock = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14C5.25 9.16751 9.16751 5.25 14 5.25C18.8325 5.25 22.75 9.16751 22.75 14C22.75 18.8325 18.8325 22.75 14 22.75C9.16751 22.75 5.25 18.8325 5.25 14ZM14 3.25C8.06294 3.25 3.25 8.06294 3.25 14C3.25 19.9371 8.06294 24.75 14 24.75C19.9371 24.75 24.75 19.9371 24.75 14C24.75 8.06294 19.9371 3.25 14 3.25ZM8.5 13H13L13 7L15 7L15 14L14 15H8.5V13Z"
    fill="currentColor"
  />
);

export const clockArrowCirclePath = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M22.75 14C22.75 9.16751 18.8325 5.25 14 5.25C9.50567 5.25 5.80274 8.63843 5.30652 13H7.24998L7.66598 13.7774L4.66533 18.2774L3.83328 18.2773L0.833931 13.7773L1.24998 13H3.2959C3.80031 7.53178 8.40012 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C10.833 24.75 7.98582 23.3805 6.01846 21.2013L7.17166 19.4719C8.77529 21.4705 11.2382 22.75 14 22.75C18.8325 22.75 22.75 18.8325 22.75 14ZM15 7V13.5858L18.5971 17.1829L17.1829 18.5971L13.2929 14.7071L13 14V7H15Z"
    fill="currentColor"
  />
);
