import type { RoutineRunnerHandle } from '@sbrc/services';

import { useLiveRoutineRunnerHandle } from './useLiveRoutineRunnerHandle';
import { useVizbotRoutineRunnerHandle } from './useVizbotRoutineRunnerHandle';

export interface UseRoutineRunnerHandleArguments {
  isVizbot?: boolean;
}

export function useRoutineRunnerHandle({
  isVizbot,
}: UseRoutineRunnerHandleArguments): RoutineRunnerHandle {
  const vizbot = useVizbotRoutineRunnerHandle();

  const livebot = useLiveRoutineRunnerHandle();

  return isVizbot ? vizbot : livebot;
}
