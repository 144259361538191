import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AddIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 14 14">
      <path d="M6.5 6.5H0v1h6.5V14h1V7.5H14v-1H7.5V0h-1v6.5z" />
    </Icon>
  );
}
