/**
 * Expression:
 *
 * A dynamic (or constant) value that is specified in reference to and
 * evaluated by the routine context. Evaluating an expression results in
 * one output value when the routine is running.
 *
 * Expressions can be used as operands for conditionals.
 */
import * as zod from 'zod';

import { ConditionalExpression } from './Conditional';
import { JavaScriptExpression } from './JavaScriptExpression';

const VariableExpression = zod.object({
  kind: zod.literal('variable'),
  // The ID of the step that outputs this variable
  stepID: zod.string(),
  // The key of the variable in the step's variable set
  name: zod.string(),
});

const ConstantExpression = zod.object({
  kind: zod.literal('constant'),
  value: zod.union([zod.number(), zod.string(), zod.boolean()]),
});

const StateVariableExpression = zod.object({
  kind: zod.literal('stateVariable'),
  // The path used to access into the RoutineRunnerState
  path: zod.string(),
});

// Because this is ultimately recursive, we must specify the type manually and
// can't use `zod.infer<typeof Expression>` like we do with most types
/**
 * A dynamic (or constant) value that is specified in reference to the routine context.
 *
 * For example, this can be used as an operand for conditionals
 *
 * This interface was referenced by `Routine`'s JSON-Schema
 * via the `definition` "Expression".
 */
export type Expression =
  | zod.infer<typeof VariableExpression>
  | zod.infer<typeof ConstantExpression>
  | ConditionalExpression
  | zod.infer<typeof StateVariableExpression>
  | JavaScriptExpression;

export const Expression: zod.ZodSchema<Expression> = zod.lazy(() =>
  zod.union([
    VariableExpression,
    ConstantExpression,
    ConditionalExpression,
    StateVariableExpression,
    JavaScriptExpression,
  ]),
);

export function describeExpression(expression: Expression): string {
  switch (expression.kind) {
    case 'constant':
      return JSON.stringify(expression.value);
    case 'variable':
      return expression.name;
    case 'stateVariable':
      return `State @ ${expression.path}`;
    case 'conditional':
      return expression.operator;
    case 'JavaScript':
      return `${expression.expression}`;
  }
}
