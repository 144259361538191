import stableJSON from 'json-stringify-deterministic';
import { patch as computePatch } from 'jsondiffpatch';

import type { Snapshot, Patch } from '../types';

import { hashStr } from './hash';

export const applyPatch = <T extends object>(
  previousSnapshot: Snapshot<T>,
  patch: Patch,
): Snapshot<T> | null => {
  if (!patch.delta) {
    // occasionally server will a snapshot with no delta
    // if nothing has changed. We'll still check the hash
    if (patch.hash !== previousSnapshot.hash) {
      return null;
    }

    return {
      hash: patch.hash,
      state: previousSnapshot.state,
    };
  }

  // clone state before applying patch
  const newState = JSON.parse(JSON.stringify(previousSnapshot.state));

  computePatch(newState, patch.delta);

  const newStateJSON = stableJSON(newState);
  const newStateHash = hashStr(newStateJSON);

  if (newStateHash !== patch.hash) {
    return null;
  }

  return {
    hash: newStateHash,
    state: newState,
  };
};
