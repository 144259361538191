import { isArray } from 'lodash';
import { map } from 'rxjs/operators';

import type { FeatureFlagData, FeatureFlagKey } from '@sb/feathers-types';
import { FeatureFlagDataZod } from '@sb/feathers-types';

import { getService } from '../utils';

export function onLoadFeatureFlag(
  subscribe: (flags: FeatureFlagData) => void,
): () => void {
  const featureFlagService = getService('featureFlags')();

  const sub = featureFlagService
    .watch()
    .find({ query: {} })
    .pipe(
      map((data) => {
        const parsedResponse = FeatureFlagDataZod.safeParse(
          isArray(data) ? data[0] : data,
        );

        if (parsedResponse.success) {
          return parsedResponse.data;
        }

        return FeatureFlagDataZod.parse({});
      }),
    )
    .subscribe(subscribe);

  return () => sub.unsubscribe();
}

export async function updateFeatureFlag(
  key: FeatureFlagKey,
  value: boolean,
): Promise<void> {
  const featureFlagService = getService('featureFlags')();

  await featureFlagService.patch('1', {
    [key]: value,
  });
}
