export const iPadLandscape = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 5L2 6V22L3 23H25L26 22V6L25 5H3ZM4 21V7H24V21H4ZM10.125 18.75C9.77982 18.75 9.5 19.0298 9.5 19.375C9.5 19.7202 9.77982 20 10.125 20H17.875C18.2202 20 18.5 19.7202 18.5 19.375C18.5 19.0298 18.2202 18.75 17.875 18.75H10.125Z"
    fill="currentColor"
  />
);

export const iPadPortrait = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.5 2.5L5.5 3.5V24.5L6.5 25.5H21.5L22.5 24.5V3.5L21.5 2.5H6.5ZM7.5 23.5V4.5H20.5V23.5H7.5ZM11.125 21.25C10.7798 21.25 10.5 21.5298 10.5 21.875C10.5 22.2202 10.7798 22.5 11.125 22.5H16.875C17.2202 22.5 17.5 22.2202 17.5 21.875C17.5 21.5298 17.2202 21.25 16.875 21.25H11.125Z"
    fill="currentColor"
  />
);

export const iPadLandscapeGen1 = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16 3C16 3.37315 15.8978 3.72244 15.7199 4.0214C15.546 4.33108 15.4635 4.66776 15.4638 5H25L26 6V11.5H27L27.5 12V16L27 16.5H26V22L25 23H17V25H15V23H13V25H11V23H3L2 22V16.5H1L0.5 16V12L1 11.5H2V6L3 5H12.5362C12.5365 4.66791 12.4541 4.33139 12.2804 4.02182C12.1023 3.72277 12 3.37333 12 3C12 1.89543 12.8954 1 14 1C15.1046 1 16 1.89543 16 3ZM4 21V7H24V21H4ZM10.125 18.75C9.77982 18.75 9.5 19.0298 9.5 19.375C9.5 19.7202 9.77982 20 10.125 20H17.875C18.2202 20 18.5 19.7202 18.5 19.375C18.5 19.0298 18.2202 18.75 17.875 18.75H10.125Z"
    fill="currentColor"
  />
);
