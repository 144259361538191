import styles from './padding.module.css';

export default {
  none: styles.none,
  extraSmall: styles.paddingExtraSmall,
  small: styles.paddingSmall,
  medium: styles.paddingMedium,
  large: styles.paddingLarge,
  top: {
    none: styles.paddingTopNone,
    extraSmall: styles.paddingTopExtraSmall,
    small: styles.paddingTopSmall,
    medium: styles.paddingTopMedium,
    large: styles.paddingTopLarge,
  },
  bottom: {
    none: styles.paddingBottomNone,
    extraSmall: styles.paddingBottomExtraSmall,
    small: styles.paddingBottomSmall,
    medium: styles.paddingBottomMedium,
    large: styles.paddingBottomLarge,
  },
  left: {
    none: styles.paddingLeftNone,
    extraSmall: styles.paddingLeftExtraSmall,
    small: styles.paddingLeftSmall,
    medium: styles.paddingLeftMedium,
    large: styles.paddingLeftLarge,
  },
  right: {
    none: styles.paddingRightNone,
    extraSmall: styles.paddingRightExtraSmall,
    small: styles.paddingRightSmall,
    medium: styles.paddingRightMedium,
    large: styles.paddingRightLarge,
  },
};
