import type { SchunkEGxVariant } from '../types/SchunkEGxVariant';

interface VariantInfo {
  variant: SchunkEGxVariant;
  zeroPositionOffset: number;
  maxPhysicalStroke: number;
  minPosition: number;
  maxPosition: number;
  minSpeed: number;
  maxSpeed: number;
  maxGripSpeed: number;
  isGPESupported: boolean;
  isSoftGripSupported: boolean;
  isStrongGripSupported: boolean;
  maxStrongGripForce: number;
  isDustProof: boolean;
  length: number;
  width: number;
  height: number;
}

export function schunkEGxVariantInfo(
  variant: SchunkEGxVariant,
  zeroPositionOffset = 0,
): VariantInfo {
  const isGPESupported = variant.includes('-M-');
  const isSoftGripSupported = variant.startsWith('EGK ');
  const isStrongGripSupported = variant.startsWith('EGU ') && isGPESupported;
  const isDustProof = variant.endsWith('-SD');

  const result: VariantInfo = {
    variant,
    zeroPositionOffset,
    maxPhysicalStroke: 0,
    minPosition: zeroPositionOffset,
    maxPosition: zeroPositionOffset,
    minSpeed: 0,
    maxSpeed: 0,
    maxGripSpeed: 0,
    isGPESupported,
    isSoftGripSupported,
    isStrongGripSupported,
    maxStrongGripForce: 0,
    isDustProof,
    length: 0,
    width: 0,
    height: 0,
  };

  if (variant.startsWith('EGK 25-')) {
    result.maxPhysicalStroke = 0.053;
    result.minSpeed = 0.005;
    result.maxSpeed = 0.12;
    result.maxGripSpeed = 0.02;
    result.length = 0.083;
    result.width = 0.062;
    result.height = 0.078;
  } else if (variant.startsWith('EGK 40-')) {
    result.maxPhysicalStroke = 0.083;
    result.minSpeed = 0.0055;
    result.maxSpeed = 0.115;
    result.maxGripSpeed = 0.022;
    result.length = 0.117;
    result.width = 0.068;
    result.height = 0.082;
  } else if (variant.startsWith('EGK 50-')) {
    result.maxPhysicalStroke = 0.103;
    result.minSpeed = 0.00625;
    result.maxSpeed = 0.13;
    result.maxGripSpeed = 0.025;
    result.length = 0.147;
    result.width = 0.078;
    result.height = 0.09;
  } else if (variant.startsWith('EGU 50-')) {
    result.minSpeed = 0.01;
    result.maxSpeed = 0.11;
    result.maxGripSpeed = 0.025;
    result.maxStrongGripForce = 2;
    result.length = 0.122;
    result.width = 0.072;
    result.height = 0.088;

    if (isDustProof) {
      result.maxPhysicalStroke = 0.082;
    } else {
      result.maxPhysicalStroke = 0.102;
    }
  } else if (variant.startsWith('EGU 60-')) {
    result.minSpeed = 0.01;
    result.maxSpeed = 0.11;
    result.maxGripSpeed = 0.025;
    result.maxStrongGripForce = 2;
    result.length = 0.154;
    result.width = 0.092;

    if (isDustProof) {
      result.maxPhysicalStroke = 0.1;
      result.height = 0.115;
    } else {
      result.maxPhysicalStroke = 0.12;
      result.height = 0.108;
    }
  } else if (variant.startsWith('EGU 70-')) {
    result.minSpeed = 0.01;
    result.maxSpeed = 0.07;
    result.maxGripSpeed = 0.025;
    result.maxStrongGripForce = 1.5;
    result.length = 0.18;
    result.width = 0.11;
    result.height = 0.122;

    if (isDustProof) {
      result.maxPhysicalStroke = 0.12;
    } else {
      result.maxPhysicalStroke = 0.14;
    }
  } else if (variant.startsWith('EGU 80-')) {
    result.minSpeed = 0.01;
    result.maxSpeed = 0.07;
    result.maxGripSpeed = 0.025;
    result.maxStrongGripForce = 2;
    result.length = 0.22;
    result.width = 0.132;

    if (isDustProof) {
      result.maxPhysicalStroke = 0.14;
      result.height = 0.138;
    } else {
      result.maxPhysicalStroke = 0.16;
      result.height = 0.13;
    }
  }

  result.maxPosition = result.maxPhysicalStroke + zeroPositionOffset;

  return result;
}
