/**
 * Flange port: A label corresponding to a flange on the Tool Flange IO board
 */
import * as zod from 'zod';

export const FLANGE_INPUT_PORT_IDS = [
  'Flange Input 1',
  'Flange Input 2',
] as const;

export const FlangeInputPortKind = zod.literal('FlangeInput');

export type FlangeInputPortKind = zod.infer<typeof FlangeInputPortKind>;

export const FLANGE_INPUT_PORT_COUNT = FLANGE_INPUT_PORT_IDS.length;

export const FlangeInputPortID = zod.enum(FLANGE_INPUT_PORT_IDS);

export type FlangeInputPortID = zod.infer<typeof FlangeInputPortID>;
