import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import {
  COMMAND_FORCE_N_DEFAULT,
  COMMAND_KIND_DEFAULT,
  COMMAND_SCREW_LENGTH_MM_DEFAULT,
  COMMAND_SHANK_POSITION_MM_DEFAULT,
  COMMAND_TORQUE_NM_DEFAULT,
} from '../constants';
import { OnRobotScrewdriverCommandKind } from '../types';

export namespace ActuateScrewdriverStepDatabase {
  export const name = 'Actuate screwdriver';
  export const description = 'Control attached screwdriver';
  export const deviceKinds = ['OnRobotScrewdriver'] as const;
  export const librarySection = Step.LibrarySection.Basic;
  export const librarySort = '2';
  export const argumentKind = 'ActuateScrewdriver';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    commandKind: OnRobotScrewdriverCommandKind.default(COMMAND_KIND_DEFAULT),
    targetTorque: zod.number().default(COMMAND_TORQUE_NM_DEFAULT), // STORED AS Nm (0.1-5) // 100 to 5000 Nmm
    targetForce: zod.number().default(COMMAND_FORCE_N_DEFAULT), // STORED AS N 18 to 30 N
    screwLength: zod.number().default(COMMAND_SCREW_LENGTH_MM_DEFAULT), // STORED AS MM (0-35) // 0 to 35000 muM
    shankPosition: zod.number().default(COMMAND_SHANK_POSITION_MM_DEFAULT), // 0 to 55mm
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'ActuateScrewdriver',
      args: {
        gripperCommand: {
          kind: 'OnRobotScrewdriverCommand',
          commandKind: args.commandKind,
          targetForce: args.targetForce,
          screwLength: args.screwLength,
          targetTorque: args.targetTorque,
        },
      },
    };
  };
}

ActuateScrewdriverStepDatabase satisfies Step.StepKindInfo;
