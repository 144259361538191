import type * as zod from 'zod';

import { FailureKind } from '@sb/routine-runner/FailureKind';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class RunInBackgroundStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  public initializeVariableState(): void {
    this.variables = {
      isRunning: false,
    };
  }

  public async _play({ fail, playSubSteps }: StepPlayArguments): Promise<void> {
    if (this.variables.isRunning) {
      return fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason:
          'Tried to replay Run in Background step when it was already playing',
      });
    }

    const run = async () => {
      try {
        this.setVariable('isRunning', true);

        await playSubSteps({
          skipSettingCurrentStepID: true,
        });
      } catch (error) {
        fail({
          failure: { kind: FailureKind.InternalFailure },
          failureReason: `RunInBackground playSteps failed internally`,
          error,
          asynchronous: true,
        });
      } finally {
        this.setVariable('isRunning', false);
      }
    };

    // don't await this: the subsequent steps will run simultaneously
    run();
  }
}
