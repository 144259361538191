import * as zod from 'zod';

import { FlangeInputPortID } from '@sb/routine-runner/types/FlangeInputPort';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationSensorFlangeIOInterface = zod.object({
  name: zod.string().default('Sensor'),
  kind: zod.literal(IntegrationInterfaceKind.Values.flangeIO),
  ioPort: FlangeInputPortID.nullable().default(null),
  highValueName: zod.string().default('high'),
  lowValueName: zod.string().default('low'),
});

export type IntegrationSensorFlangeIOInterface = zod.infer<
  typeof IntegrationSensorFlangeIOInterface
>;
