import cx from 'classnames';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './ModalContent.module.css';

interface ModalContentProps extends HTMLAttributes<HTMLDivElement> {}

const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  (props, ref) => {
    const { children, className, ...other } = props;

    return (
      <div ref={ref} className={cx(styles.modalContent, className)} {...other}>
        {children}
      </div>
    );
  },
);

export default ModalContent;
