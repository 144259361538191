import { ZERO_ROTATION } from '@sb/geometry';

import type { SchunkEGxState } from './types/SchunkEGxState';

export const DefaultTooltipTransform = {
  ...ZERO_ROTATION,
  x: 0,
  y: 0,
  z: 0.05,
} as const;

export const SCHUNK_EGK_INITIAL_STATE: SchunkEGxState = {
  kind: 'SchunkEGx',
  variant: null,
  isConnected: false,
  isBusy: false,

  readyForOperation: false,
  controlAuthorityFieldbus: false,
  readyForShutdown: false,
  commandSuccessfullyProcessed: false,
  commandReceivedToggle: false,
  releasedForManualMovement: false,
  softwareLimitReached: false,
  noWorkpieceDetected: false,
  workpieceGripped: false,
  positionReached: false,
  workpiecePregripStarted: false,
  gpeActivated: false,
  workpieceLost: false,
  wrongWorkpieceGripped: false,

  position: 0,
  jawPosition: 0,
  errorCode: 0,
  warningCode: 0,
  notFeasibleCode: 0,
};

export const SCHUNK_MODBUS_TARGET_ADDRESS = 0x0c;

// The plc_sync_input register is off by 1 according to
// 5.3.3, example 1 in the [Schunk manual](https://schunk.com/us/en/gripping-systems/parallel-gripper/egk/egk-40-mb-m-b/p/000000000001491762)
export const SCHUNK_EGK_MODBUS_PLC_SYNC_INPUT = 0x40 - 1;
export const SCHUNK_EGK_MODBUS_PLC_SYNC_OUTPUT = 0x48 - 1;
export const SCHUNK_EGK_MODBUS_ZERO_POS_OFS = 0x0610 - 1;
