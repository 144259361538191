import cx from 'classnames';
import type { ButtonHTMLAttributes } from 'react';
import { cloneElement, forwardRef, useMemo } from 'react';

import Typography from './Typography';

import styles from './IconButton.module.css';

type IconButtonVariant = 'unstyled' | 'white';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactElement;
  disabled?: boolean;
  isActive?: boolean;
  label?: string;
  variant?: IconButtonVariant;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      disabled,
      isActive,
      label,
      variant = 'unstyled',
      ...other
    } = props;

    const childIcon = useMemo(() => {
      return cloneElement(children, {
        className: cx(
          { [styles.styledButtonIcon]: variant !== 'unstyled' },
          children.props.className,
        ),
      });
    }, [children, variant]);

    return (
      <div className={cx(styles.iconButton, styles[variant])}>
        {label && (
          <Typography className={styles.buttonLabel}>{label}</Typography>
        )}

        <button
          ref={ref}
          type="button"
          disabled={disabled}
          className={cx(
            styles.button,
            styles[variant],
            { [styles.selected]: isActive },
            className,
          )}
          {...other}
        >
          {childIcon}
        </button>
      </div>
    );
  },
);

export default IconButton;
