import { getNode } from './getNode';

export async function getDevicesInNetwork() {
  const node = await getNode();

  const robotIDs = new Set<string>();
  const cameraIDs = new Set<string>();

  for (const topic of node.getTopicNamesAndTypes()) {
    const [, topic1, topic2, topic3, topic4] = topic.name.split('/');

    if (topic1) {
      // Assumes format /bot_<id>/...
      if (topic1.startsWith('bot_') || topic1.startsWith('local')) {
        robotIDs.add(topic1);
      }

      // Format: /bot<id>/cameras/<camera_name>/image_raw
      if (topic2 === 'cameras' && topic4 === 'image_raw') {
        cameraIDs.add(`${topic1}/${topic3}`);
      }
    }
  }

  return {
    robotIDs: [...robotIDs],
    cameraIDs: [...cameraIDs],
  };
}
