import type { Paginated } from '@feathersjs/feathers';

function isPaginated<T>(value: T | Paginated<T>): value is Paginated<T> {
  if (typeof value === 'object' && value !== null) {
    return 'data' in value && Array.isArray(value.data) && 'total' in value;
  }

  return false;
}

/**
 * Feathers `find` response may be a plain array or paginated
 * This utility normalizes any response to 'paginated'
 */
export function asPaginated<T>(value: T | T[] | Paginated<T>): Paginated<T> {
  if (Array.isArray(value)) {
    return {
      data: value,
      limit: value.length,
      skip: 0,
      total: value.length,
    };
  }

  if (isPaginated(value)) {
    return value;
  }

  return {
    data: [value],
    limit: 1,
    skip: 0,
    total: 1,
  };
}

/**
 * Run `mapper` over each item in a `find` response,
 * returning an array of mapped items
 */
export function mapPaginated<T, U>(
  data: T | T[] | Paginated<T>,
  mapper: (data: T) => U,
): U[] {
  if (Array.isArray(data)) {
    return data.map(mapper);
  }

  if (isPaginated(data)) {
    return data.data.map(mapper);
  }

  return [mapper(data)];
}
