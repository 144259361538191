export class Timer {
  private lastStarted: number | null = null;

  private timeElapsed: number = 0;

  public static start() {
    const timer = new Timer();
    timer.play();

    return timer;
  }

  public play() {
    this.lastStarted = this.lastStarted ?? Date.now();
  }

  public pause() {
    if (this.lastStarted) {
      this.timeElapsed += Date.now() - this.lastStarted;
    }

    this.lastStarted = null;
  }

  public getTime() {
    return this.timeElapsed;
  }

  public reset() {
    this.timeElapsed = 0;
    this.lastStarted = null;
  }

  public restart() {
    this.reset();
    this.play();
  }
}
