import * as zod from 'zod';

import { Expression } from '../../types';

export default zod.object({
  multiplier: zod.number(),
  translationX: zod.optional(Expression),
  translationY: zod.optional(Expression),
  translationZ: zod.optional(Expression),
  frame: zod.string(),
});
