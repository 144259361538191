import type { Step } from '@sb/remote-control/types';
import type { Space } from '@sb/routine-runner';

export function getVirtualSpaceItems(
  stepConfigurations: Record<string, Step.ConvertedConfiguration>,
): Space.Item[] {
  const space: Space.Item[] = [];

  for (const step of Object.values(stepConfigurations)) {
    if (step.stepKind === 'Locate' && step.args) {
      space.push({
        kind: 'visionPositionList',
        id: step.id,
        name: step.args.positionListName,
        description: '',
        positions: [],
        planeID: step.args.planeID,
      });
    }
  }

  return space;
}

export function removeVirtualSpaceItems(
  space: Space.Item[] | undefined,
): Space.Item[] | undefined {
  if (!space) {
    return undefined;
  }

  return space.filter((s) => s.kind !== 'visionPositionList');
}
