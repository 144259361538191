import cx from 'classnames';
import { useEffect } from 'react';

import { CloseIcon } from '@sb/ui/icons';

import IconButton from './IconButton';

import styles from './Toast.module.css';

const TOAST_DURATION = 6000;

export type ToastKind = 'success' | 'error' | 'progress' | 'top';
interface ToastStateString {
  kind: ToastKind;
  error?: Error;
  message: string;
  isClosed?: boolean;
}
interface ToastStateReact {
  kind: ToastKind;
  message: React.ReactNode;
  error?: Error;
  isClosed?: boolean;
  renderAsComponent: true;
}
export type ToastState = ToastStateReact | ToastStateString;

interface ToastProps {
  children: React.ReactNode;
  kind?: ToastKind;
  isOpen: boolean;
  onClose: () => void;
}

const Toast = ({
  children,
  kind = 'progress',
  isOpen,
  onClose,
}: ToastProps) => {
  useEffect(() => {
    // When the toast is in a progress state (i.e. saving an item),
    // then we shouldn't dismiss it.
    if (kind === 'progress') {
      return undefined;
    }

    const timer = setTimeout(() => onClose(), TOAST_DURATION);

    return () => clearTimeout(timer);
  }, [kind, onClose]);

  return (
    <div
      className={cx(
        styles.toast,
        styles[kind],
        isOpen ? styles.open : styles.closed,
        kind !== 'progress' && styles.closeable,
      )}
      data-testid="toast-message"
    >
      {kind !== 'progress' && (
        <IconButton
          className={styles.closeButton}
          onClick={onClose}
          data-testid="notification-close-button"
        >
          <CloseIcon size="extraSmall" />
        </IconButton>
      )}

      {children}
    </div>
  );
};

export default Toast;
