import * as zod from 'zod';

export const JOINT_NUMBERS = [0, 1, 2, 3, 4, 5] as const;

export const NUMBER_OF_JOINTS: 6 = JOINT_NUMBERS.length;

export const JointNumber = zod
  .literal(0)
  .or(zod.literal(1))
  .or(zod.literal(2))
  .or(zod.literal(3))
  .or(zod.literal(4))
  .or(zod.literal(5));

export type JointNumber = zod.infer<typeof JointNumber>;
