import * as zod from 'zod';

export default zod.object({
  completedCount: zod.number().int().nonnegative(),
  currentActivity: zod.enum([
    'none',
    'requestingPlan',
    'planning',
    'receivingPlan',
    'moving',
    'paused',
  ]),
});
