const CACHE = new Map<string, any>();

interface FactoryArgs {
  reset: () => void;
}

/**
 * The globalCache holds values for the lifetime of the application.
 * The `factory` passed in the arguments will be run only if there isn't a current value in the cache.
 * The current value in the cache can be cleared by using the `reset` function passed to the factory.
 * This is useful for things you only want a single instance of at a time.
 * Because this module has no dependencies, it won't recreate values after Hot Module Refresh.
 */
export function globalCache<T>(
  key: string,
  factory: (args: FactoryArgs) => T,
): T {
  if (CACHE.has(key)) {
    return CACHE.get(key);
  }

  const item = factory({
    reset: () => CACHE.delete(key),
  });

  CACHE.set(key, item);

  return item;
}
