export const xmark = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 12.5858L7.20712 5.79289L5.79291 7.20711L12.5858 14L5.79291 20.7929L7.20712 22.2071L14 15.4142L20.7929 22.2071L22.2071 20.7929L15.4142 14L22.2071 7.20711L20.7929 5.79289L14 12.5858Z"
    fill="currentColor"
  />
);

export const xmarkCircle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14C5.25 9.16751 9.16751 5.25 14 5.25C18.8325 5.25 22.75 9.16751 22.75 14C22.75 18.8325 18.8325 22.75 14 22.75C9.16751 22.75 5.25 18.8325 5.25 14ZM14 3.25C8.06294 3.25 3.25 8.06294 3.25 14C3.25 19.9371 8.06294 24.75 14 24.75C19.9371 24.75 24.75 19.9371 24.75 14C24.75 8.06294 19.9371 3.25 14 3.25ZM10.8389 9.42464L14 12.5858L17.1612 9.42464L18.5754 10.8389L15.4142 14L18.5754 17.1612L17.1612 18.5754L14 15.4142L10.8389 18.5754L9.42464 17.1612L12.5858 14L9.42464 10.8389L10.8389 9.42464Z"
    fill="currentColor"
  />
);

export const xmarkCircleFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM10.8388 9.42462L14 12.5858L17.1612 9.42462L18.5754 10.8388L15.4142 14L18.5754 17.1612L17.1612 18.5754L14 15.4142L10.8388 18.5754L9.42462 17.1612L12.5858 14L9.42462 10.8388L10.8388 9.42462Z"
    fill="currentColor"
  />
);

export const xmarkCircleStar = (
  <path
    d="M5.25 14a8.75 8.75 0 1 1 17.5 0 8.75 8.75 0 0 1-17.5 0ZM14 3.25C8.063 3.25 3.25 8.063 3.25 14S8.063 24.75 14 24.75 24.75 19.937 24.75 14 19.937 3.25 14 3.25Zm-1.71 8.396 1.235-3.8h.95l1.235 3.8h3.996l.294.904-3.233 2.35 1.235 3.8-.77.559L14 16.909l-3.233 2.35-.77-.56 1.236-3.8L8 12.55l.294-.904h3.996Z"
    fill="currentColor"
  />
);

export const xmarkCircleTransparentFill = (
  <g fill="currentColor">
    <circle cx="14" cy="14" r="12" fillOpacity=".1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 12.586 14 l -3.579 3.579 l 1.414 1.414 L 14 15.414 l 3.579 3.579 l 1.414 -1.414 L 15.414 14 l 3.579 -3.579 l -1.414 -1.414 l -3.58 3.579 l -3.578 -3.579 l -1.414 1.414 L 12.586 14 Z"
      fillOpacity=".5"
    />
  </g>
);
