import * as zod from 'zod';

export const StorageData = zod.object({
  _id: zod.string(),
  buffer: zod.any().optional(),
  contentType: zod.string(),
  size: zod.number(),
  uri: zod.string().optional(),
});

export type StorageData = zod.infer<typeof StorageData>;
