/**
 * Conditional:
 *
 * A type that evaluates at run-time to a boolean given a set of conditions
 * specified in the Routine format.
 */
import * as zod from 'zod';

import { BinaryConditionalOperator } from './BinaryConditionalOperator';
import { Expression } from './Expression';

// Because this is ultimately recursive, we must specify the type manually
export type Conditional = {
  operator: BinaryConditionalOperator;
  leftOperand: Expression;
  rightOperand: Expression;
};

export const Conditional: zod.Schema<Conditional> = zod.lazy(() =>
  zod.object({
    operator: BinaryConditionalOperator,
    leftOperand: Expression,
    rightOperand: Expression,
  }),
);

export const ConditionalExpression = zod.intersection(
  zod.object({
    kind: zod.literal('conditional'),
  }),
  Conditional,
);

export type ConditionalExpression = zod.infer<typeof ConditionalExpression>;
