import * as zod from 'zod';

import { IntegrationInterfaceKind } from '@sb/integrations/io/types';
import { InputPortID } from '@sb/routine-runner/types/InputPort';

export const IntegrationInputConfig = zod.discriminatedUnion('kind', [
  zod.object({
    kind: zod.literal(IntegrationInterfaceKind.Values.controlBoxIO),
    ioPort: InputPortID.nullable().default(null),
    isHigh: zod.boolean().default(true),
  }),
  zod.object({
    kind: zod.literal(IntegrationInterfaceKind.Values.haasNGC),
  }),
]);

export type IntegrationInputConfig = zod.infer<typeof IntegrationInputConfig>;

export const MachineStatusConfig = zod.object({
  running: IntegrationInputConfig,
  faulted: IntegrationInputConfig.nullable(),
});

export type MachineStatusConfig = zod.infer<typeof MachineStatusConfig>;
