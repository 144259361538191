import type * as zod from 'zod';

import { FailureKind } from '../../FailureKind';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

/**
 * LoopControlSteps offer a way to interact with a loop while it's running. It
 * represents the same thing as the programming constructs `continue` and `break`.
 *
 * To specify breaking out of a loop, use `{ kind: 'break' }` and to specify
 * continuing a loop, use `{ kind: 'continue' }`
 */
export default class LoopControlStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public isContinue(): boolean {
    return this.args.kind === 'continue';
  }

  public isBreak(): boolean {
    return this.args.kind === 'break';
  }

  public async _play({
    breakOutOfLoop,
    continueLoop,
    fail,
  }: StepPlayArguments): Promise<void> {
    if (this.isBreak()) {
      if (!breakOutOfLoop) {
        return fail({
          failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
          failureReason: 'Tried to break out of a loop without being in a loop',
        });
      }

      breakOutOfLoop();

      return;
    }

    if (this.isContinue()) {
      if (!continueLoop) {
        return fail({
          failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
          failureReason: 'Tried to continue a loop without being in a loop',
        });
      }

      continueLoop();
    }
  }
}
