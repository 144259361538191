import {
  ErrorBoundary as SentryErrorBoundary,
  type ErrorBoundaryProps as SentryErrorBoundaryProps,
} from '@sentry/react';
import { Fragment } from 'react';

import { Button, Icon } from '@sb/design-system';

interface ErrorBoundaryProps
  extends Omit<SentryErrorBoundaryProps, 'fallback'> {
  ErrorContainer?: React.ComponentType<React.PropsWithChildren>;
}

export function ErrorBoundary({
  ErrorContainer = Fragment,
  children,
  ...rest
}: ErrorBoundaryProps) {
  return (
    <SentryErrorBoundary
      {...rest}
      fallback={
        // eslint-disable-next-line react/no-unstable-nested-components
        ({ resetError }) => (
          <ErrorContainer>
            <div className="tw-p-16 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
              <Icon
                kind="xmarkCircle"
                className="tw-text-red tw-icon-42 tw-mb-12"
              />

              <p>Error rendering component</p>

              <Button variant="Flat" onClick={() => resetError()}>
                Tap to reload
              </Button>
            </div>
          </ErrorContainer>
        )
      }
    >
      {children}
    </SentryErrorBoundary>
  );
}
