import cx from 'classnames';
import type { TdHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './TableCell.module.css';

interface TableCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  cellAlign?: 'bottom' | 'center' | 'top';
  hasNoWrap?: boolean;
}

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  (props, ref) => {
    const { className, cellAlign = 'center', hasNoWrap, ...other } = props;

    return (
      <td
        ref={ref}
        className={cx(
          styles.tableCell,
          styles[cellAlign],
          { [styles.noWrap]: hasNoWrap },
          className,
        )}
        {...other}
      />
    );
  },
);

export default TableCell;
