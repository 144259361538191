import * as zod from 'zod';

export const WeldMachineMode = zod.enum(['pulse', 'constantVoltage']);

export const WeldMachineState = zod.object({
  kind: zod.literal('WeldMachine'),
  isReady: zod.boolean(),
  voltage: zod.number(),
  current: zod.number(),
  wireFeedSpeed: zod.number(),
  mode: WeldMachineMode,
  isTestMode: zod.boolean(),
});

export type WeldMachineState = zod.infer<typeof WeldMachineState>;
