export const lock = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.5 8.5C10.5 6.567 12.067 5 14 5C15.933 5 17.5 6.567 17.5 8.5V11H10.5V8.5ZM8.5 11V8.5C8.5 5.46243 10.9624 3 14 3C17.0376 3 19.5 5.46243 19.5 8.5V11H20.5L21.5 12V23L20.5 24H7.5L6.5 23V12L7.5 11H8.5ZM9.5 13H8.5V22H19.5V13H18.5H9.5Z"
    fill="currentColor"
  />
);

export const lockOpen = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.5 7.5C10.5 5.567 12.067 4 14 4C15.933 4 17.5 5.567 17.5 7.5V11H7.5L6.5 12V23L7.5 24H20.5L21.5 23V12L20.5 11H19.5V7.5C19.5 4.46243 17.0376 2 14 2C10.9624 2 8.5 4.46243 8.5 7.5V8.5H10.5V7.5ZM8.5 22V13H19.5V22H8.5Z"
    fill="currentColor"
  />
);
