import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import { ArmJointPositions } from '@sb/motion-planning';

import { SpeedProfile } from '../../speed-profile';

export default zod.object({
  prePressTarget: CartesianPose.or(ArmJointPositions),
  pressTarget: CartesianPose.or(ArmJointPositions),
  pressDurationMS: zod.number().positive(),
  speedProfile: SpeedProfile,
});
