import * as zod from 'zod';

import { CameraIntegration } from '@sb/integrations/types/cameraTypes';
import { ActionRequiredError, Step } from '@sb/remote-control/types';
import {
  ClassifyClass,
  DEFAULT_REGION_OF_INTEREST,
  RegionOfInterest,
} from '@sb/routine-runner';

export namespace ClassifyStep {
  export const name = 'Classify';
  export const description = 'Classify a state via vision';
  export const librarySection = Step.LibrarySection.Vision;
  export const argumentKind = 'Classify';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    camera: CameraIntegration,
    regionOfInterest: RegionOfInterest.default(DEFAULT_REGION_OF_INTEREST),
    method: zod
      .object({
        kind: zod.literal('templateMatch'),
      })
      .default({ kind: 'templateMatch' }),
    classes: zod.array(ClassifyClass).default([]),
    confidence: zod.number().default(0.5),
    fallbackValue: zod.string().default('No class'),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.fallbackValue) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Fallback value is not configured',
        fieldId: 'fallbackValue',
      });
    }

    // TODO: Add support for other camera types and method
    return {
      ...stepData,
      stepKind: 'Classify',
      args: {
        // camera: configuration.camera,
        regionOfInterest: args.regionOfInterest,
        // method: args.method,
        classes: args.classes,
        confidence: args.confidence,
        camera: args.camera,
        fallbackValue: args.fallbackValue,
      },
    };
  };
}

ClassifyStep satisfies Step.StepKindInfo;
