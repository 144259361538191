import { loadFS } from './fs';

export const readJSONFile = async <T>(path: string): Promise<T | void> => {
  const fs = await loadFS();

  try {
    return JSON.parse(await fs.readFile(path, 'utf-8')) as T;
  } catch (e) {
    return undefined;
  }
};

export const readJSONFileWithFallback = async <T>(
  path: string,
  fallback: T,
): Promise<T> => {
  try {
    const fs = await loadFS();

    return JSON.parse(await fs.readFile(path, 'utf-8')) as T;
  } catch (e) {
    return fallback;
  }
};

export const writeJSONFileWithDefaults = async <T>(
  path: string,
  data: Partial<T>,
  fallback: T,
) => {
  const fs = await loadFS();
  const file = await readJSONFileWithFallback<T>(path, fallback);

  if (!file) {
    throw new Error(`Could not load file at ${path}`);
  }

  const newFile = {
    ...file,
    ...data,
  };

  await fs.writeFile(path, JSON.stringify(newFile));

  return newFile;
};
