import * as zod from 'zod';

import type { ArmJointPositions } from './ArmJointPositions';

export const DHParams = zod.object({
  d1: zod.number(),
  a2: zod.number(),
  a3: zod.number(),
  d4: zod.number(),
  d5: zod.number(),
  d6: zod.number(),
});

export type DHParams = zod.infer<typeof DHParams>;

// 0 until an OnRobot gripper is mounted
export const TOOL_OFFSET_METERS: number = 0.0;
// const TOOL_OFFSET_METERS: f64 = 0.1265; // distance to 3FG fingertips

// See [this notion plage](https://www.notion.so/standardbots/Importing-Robot-URDF-5cdb322cf8af40a382b67300e8201a14?pvs=4#1dd5567c33d849eeb4b98e71a8be1db3)
// for how to calculate and update these values
export const DEFAULT_DH_PARAMS: DHParams = {
  d1: 0.172,
  a2: -0.5907,
  a3: -0.5494,
  d4: 0.19195,
  d5: 0.1498,
  d6: 0.1767 + TOOL_OFFSET_METERS,
};

// offsets for the model given these DH parameters
export type Offsets = ArmJointPositions;

export const DEFAULT_OFFSETS: Offsets = [0, Math.PI / 2, 0, Math.PI / 2, 0, 0];
