import * as zod from 'zod';

import { DBBackupData } from './dbBackup';
import { DockerImageData } from './dockerImage';

export const USBDriveData = zod.object({
  mountPath: zod.string(),
  label: zod.string(),
  backups: zod.array(DBBackupData),
  dockerImages: zod.array(DockerImageData),
});

export type USBDriveData = zod.infer<typeof USBDriveData>;
