import * as zod from 'zod';

export const WeldMachineConfiguration = zod.object({
  kind: zod.literal('WeldMachine'),
  name: zod.string().default('WeldMachine'),
  connectionOptions: zod
    .object({
      hostname: zod.string().default(''),
      port: zod.number().default(502),
    })
    .default({}),
});

export type WeldMachineConfiguration = zod.infer<
  typeof WeldMachineConfiguration
>;
