import * as zod from 'zod';

import {
  COMPUTEBOX_HOST_DEFAULT,
  ONROBOT_TCP_PORT_DEFAULT,
} from '../constants';

const OnRobotConnectionKind = zod
  .enum(['tooltipModbus', 'modbusTCP'])
  .default('tooltipModbus');

export const OnRobotConnectionConfiguration = zod
  .object({
    kind: OnRobotConnectionKind,
    modbusTCP: zod
      .object({
        host: zod.string().default(COMPUTEBOX_HOST_DEFAULT),
        port: zod.number().default(ONROBOT_TCP_PORT_DEFAULT),
      })
      .default({}),
  })
  .default({});

export type OnRobotConnectionKind = zod.infer<typeof OnRobotConnectionKind>;

export type OnRobotConnectionConfiguration = zod.infer<
  typeof OnRobotConnectionConfiguration
>;
