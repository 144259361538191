import * as zod from 'zod';

import { DeviceCommand } from '@sb/integrations/types';

export const MoveVerticalLiftArguments = zod.object({
  deviceCommand: DeviceCommand,
});

export type MoveVerticalLiftArguments = zod.infer<
  typeof MoveVerticalLiftArguments
>;
