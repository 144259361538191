export const rectangleSplit = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 5L3 6V22L4 23H24L25 22V6L24 5H4ZM5 21V7H13V21H5ZM15 21H23V7H15V21Z"
    fill="currentColor"
  />
);

export const rectangleLineBelow = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.5 3.5L4.5 4.5V17.5L5.5 18.5H13V21.208C12.4233 21.46 11.96 21.9233 11.708 22.5H4.5V24.5H11.708C12.0938 25.383 12.9748 26 14 26C15.0252 26 15.9062 25.383 16.292 24.5H23.5V22.5H16.292C16.04 21.9233 15.5767 21.46 15 21.208V18.5H22.5L23.5 17.5V4.5L22.5 3.5H5.5ZM6.5 16.5V5.5H21.5V16.5H6.5Z"
    fill="currentColor"
  />
);
