import cx from 'classnames';
import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './Link.module.css';

type LinkVariant = 'default' | 'light';

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  decorate?: boolean;
  variant?: LinkVariant;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    children,
    className,
    decorate,
    variant = 'default',
    ...other
  } = props;

  return (
    <a
      ref={ref}
      className={cx(
        styles.link,
        styles[variant],
        decorate && styles.decorate,
        className,
      )}
      {...other}
    >
      {children}
    </a>
  );
});

export default Link;
