import * as zod from 'zod';

export const GRIPPER_OPENNESS_MIN = 0;
export const GRIPPER_OPENNESS_MAX = 1;

export const GripperOpenness = zod
  .number()
  .min(GRIPPER_OPENNESS_MIN)
  .max(GRIPPER_OPENNESS_MAX);

export type GripperOpenness = zod.infer<typeof GripperOpenness>;
