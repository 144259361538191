import * as zod from 'zod';

import type { ArmJointPositions } from './ArmJointPositions';
import type { ArmJointVelocities } from './ArmJointVelocities';
import { GripperOpenness } from './GripperOpenness';
import { NUMBER_OF_JOINTS } from './JointNumber';

/**
 * A snapshot of a desired position and velocity of a single
 * joint at one moment in time in a motion plan.
 *
 * Properties are shortened to a single letter for more efficient packing.
 */
export const MotionPlanJointStep = zod.object({
  // desired velocity in rad/s
  v: zod.number().optional(),
  // desired velocity in rad/s
  p: zod.number(),
});

export type MotionPlanJointStep = zod.infer<typeof MotionPlanJointStep>;

/**
 * The desires of the robotic arm at one moment in time during a motion plan.
 */
export const RawMotionPlanArmStep = zod.object({
  // desires for each joint
  joints: zod
    .array(MotionPlanJointStep)
    .min(NUMBER_OF_JOINTS)
    .max(NUMBER_OF_JOINTS),

  // The timestamp in seconds since the start of the motion plan
  timestamp: zod.number().min(0),
});

export type RawMotionPlanArmStep = zod.infer<typeof RawMotionPlanArmStep>;

export type MotionPlanArmStep = RawMotionPlanArmStep & {
  readonly angles: ArmJointPositions;
  readonly velocities: ArmJointVelocities;
};

export const MotionPlanArmStep = RawMotionPlanArmStep.transform(
  (step): MotionPlanArmStep => {
    return Object.assign(step, {
      angles: step.joints.map(({ p }) => p) as ArmJointPositions,
      velocities: step.joints.map(({ v }) => v) as ArmJointVelocities,
    });
  },
);

/**
 * The desires of the gripper at one moment in time during a motion plan.
 *
 * Used to delineate between multiple arm motion plans.
 */
export const MotionPlanGripperStep = zod.object({
  kind: zod.literal('gripper'),
  gripperOpenness: GripperOpenness,
});

export type MotionPlanGripperStep = zod.infer<typeof MotionPlanGripperStep>;

/**
 * The data describing a motion plan from the motion planning server.
 *
 * Stored as an array of moments serialized as simple objects.
 */
export const RawMotionPlan = zod.array(RawMotionPlanArmStep).nonempty();

export type RawMotionPlan = zod.infer<typeof RawMotionPlan>;

export type MotionPlan = Array<MotionPlanArmStep> & {
  raw(): RawMotionPlan;
  closestStep(angles: ArmJointPositions): MotionPlanArmStep;
  // returns a more legibile version of the JSON.
  // good for diffs, logging, and general debugging.
  pretty(): string;

  readonly start: ArmJointPositions;
  readonly end: ArmJointPositions;
};

/**
 * A representation for a motion plan that has come back from the
 * motion planning server. It's stored as an array of moments.
 *
 * This transformed model extends the raw motion plan array with helpers
 * to avoid duplication.
 */
export const MotionPlan = RawMotionPlan.transform(
  (plan: RawMotionPlan): MotionPlan => {
    // convert to transformed moments with helpers
    const moments = plan.map((rawMoment) => {
      // this is safe because these moments will have already parsed properly as
      // `RawMotionPlanArmStep` by the time the transform occurs
      return MotionPlanArmStep.parse(rawMoment);
    }) as [MotionPlanArmStep, ...MotionPlanArmStep[]];

    return Object.assign(moments, {
      closestStep(angles: ArmJointPositions): MotionPlanArmStep {
        let bestScore = Infinity;
        let bestIndex = 0;

        const scores = moments.map((moment: MotionPlanArmStep) => {
          const diffs = moment.angles.map((p, joint) =>
            Math.abs(p - angles[joint]),
          );

          return Math.max(...diffs);
        });

        for (let joint = 0; joint < scores.length; joint += 1) {
          const score = scores[joint];

          if (bestScore > score) {
            bestIndex = joint;
            bestScore = score;
          }
        }

        return moments[bestIndex];
      },

      raw(): RawMotionPlan {
        return plan;
      },
      pretty(): string {
        let str = '';

        for (const step of moments) {
          str += `\n${step.timestamp.toFixed(3).padStart(6, '0')}s:\t`;
          str += step.angles.map((p) => `${p.toFixed(2)}`).join('\t');

          str += `\n${' '.repeat(6)}\t${step.velocities
            .map((v) => (typeof v === 'number' ? v.toFixed(2) : '-'))
            .join('\t')}`;
        }

        return str;
      },

      start: moments[0].angles,
      end: moments[moments.length - 1].angles,
    });
  },
);
