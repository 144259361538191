import * as zod from 'zod';

import { DHConfiguration } from '@sb/integrations/implementations/dh/comon/types/DHConfiguration';

export const DHAG105145Configuration = DHConfiguration.extend({
  kind: zod.literal('DHAG105145'),
  name: zod.string().default('DH AG 105/145'),
});

export type DHAG105145Configuration = zod.infer<typeof DHAG105145Configuration>;

export const DefaultConfiguration = DHAG105145Configuration.parse({
  kind: 'DHAG105145',
  family: 'DHBase',
});
