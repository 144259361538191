import type * as zod from 'zod';

import { getCommandSuccessResponse } from '@sb/integrations/implementations/ModbusTCPServer/command/util';
import { ModbusFunctionCodeEnum } from '@sb/integrations/utils/modbus/ModbusFunctionCode';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import { NetworkRequestArguments } from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof NetworkRequestArguments>;

type Variables = zod.infer<typeof Variables>;

export default class NetworkRequestStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = NetworkRequestArguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ pauseRoutine }: StepPlayArguments): Promise<void> {
    const { command } = this.args;

    const { request } = command;

    try {
      const responseData =
        await this.routineContext.sendNetworkRequest(command);

      const response = getCommandSuccessResponse(
        command.request.kind,
        responseData,
      );

      if (
        request.kind === ModbusFunctionCodeEnum.ReadHoldingRegisters &&
        response.responseData
      ) {
        // TODO: APPS-4523 Add support for batch requests
        const [variableID] = request.variableIDList;

        this.routineContext.setVariableState(variableID, {
          stepKind: 'NetworkRequest',
          value: response.responseData,
        });
      }
    } catch (error) {
      return pauseRoutine({
        kind: 'networkRequestFailure',
        reason: error.message,
      });
    }

    return undefined;
  }
}
