import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

import { COMPUTEBOX_HOST_DEFAULT } from '../../shared/constants';
import {
  OR_SCREWDRIVER_DEFAULT_TCP_TRANSFORM,
  OR_SCREWDRIVER_SHORTNAME,
} from '../constants';

export const OnRobotScrewdriverConfiguration = zod.object({
  kind: zod.literal('OnRobotScrewdriver'),
  name: zod.string().default(OR_SCREWDRIVER_SHORTNAME),
  connectionOptions: zod
    .object({
      host: zod.string().default(COMPUTEBOX_HOST_DEFAULT),
      port: zod.number().default(502),
    })
    .default({}),
  tcpTransform: CartesianPose.default(OR_SCREWDRIVER_DEFAULT_TCP_TRANSFORM),
});

export type OnRobotScrewdriverConfiguration = zod.infer<
  typeof OnRobotScrewdriverConfiguration
>;
