import { useCallback, useMemo } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export function useRouter() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  const query = useMemo(() => {
    return {
      ...params,
      ...Object.fromEntries(searchParams),
    };
  }, [params, searchParams]);

  const back = useCallback(() => navigate(-1), [navigate]);

  const push = useCallback((url: string) => navigate(url), [navigate]);

  const replace = useCallback(
    (url: string) => navigate(url, { replace: true }),
    [navigate],
  );

  return {
    query,
    back,
    push,
    replace,
    asPath: location.pathname,
  };
}
