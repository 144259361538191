import * as zod from 'zod';

import { StepKind } from '@sb/routine-runner';

export interface StepSummary {
  id: string;
  stepKind: StepKind;
  /** Default description (this can be overwritten by each robot). */
  description?: string;
  /** Nested steps */
  steps?: StepSummary[];
}

export const StepSummary: zod.ZodSchema<StepSummary> = zod.lazy(() =>
  zod.object({
    description: zod.string().optional(),
    id: zod.string(),
    stepKind: StepKind,
    steps: zod.array(StepSummary).optional(),
  }),
);
