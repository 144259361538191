export const cloud = (
  <path
    d="M19.3382 11.129L18.3973 11.4677L18.6966 12.2991L19.5586 12.1044L19.3382 11.129ZM5.76808 14.0071L5.82898 15.0052L6.83769 14.9437L6.76553 13.9357L5.76808 14.0071ZM6 20.5C4.48122 20.5 3.25 19.2688 3.25 17.75H1.25C1.25 20.3734 3.37665 22.5 6 22.5V20.5ZM20.5 20.5H6V22.5H20.5V20.5ZM24.75 16.25C24.75 18.5972 22.8472 20.5 20.5 20.5V22.5C23.9518 22.5 26.75 19.7018 26.75 16.25H24.75ZM20.5 12C22.8472 12 24.75 13.9028 24.75 16.25H26.75C26.75 12.7982 23.9518 10 20.5 10V12ZM19.5586 12.1044C19.8605 12.0362 20.1754 12 20.5 12V10C20.0261 10 19.5634 10.0529 19.1179 10.1536L19.5586 12.1044ZM12.75 7.5C15.3488 7.5 17.5641 9.15274 18.3973 11.4677L20.2792 10.7903C19.1694 7.70707 16.2187 5.5 12.75 5.5V7.5ZM6.75 13.5C6.75 10.1863 9.43629 7.5 12.75 7.5V5.5C8.33172 5.5 4.75 9.08172 4.75 13.5H6.75ZM6.76553 13.9357C6.75524 13.7919 6.75 13.6467 6.75 13.5H4.75C4.75 13.6944 4.75695 13.8873 4.77064 14.0785L6.76553 13.9357ZM3.25 17.75C3.25 16.2887 4.39034 15.093 5.82898 15.0052L5.70718 13.0089C3.21973 13.1607 1.25 15.2249 1.25 17.75H3.25Z"
    fill="currentColor"
  />
);

export const cloudFilled = (
  <path
    d="M12.75 5.5C8.44412 5.5 4.93278 8.9018 4.75691 13.1645C2.73618 13.711 1.25 15.5567 1.25 17.75C1.25 20.3734 3.37665 22.5 6 22.5H20.5C23.9518 22.5 26.75 19.7018 26.75 16.25C26.75 12.7982 23.9518 10 20.5 10C20.3172 10 20.136 10.0079 19.9568 10.0233C18.6636 7.34756 15.9236 5.5 12.75 5.5Z"
    fill="currentColor"
  />
);

export const cloudSlash = (
  <>
    <path
      d="M23.2929 24.7071L24.7071 23.2929L4.70712 3.29291L3.29291 4.70712L23.2929 24.7071Z"
      fill="currentColor"
    />
    <path
      d="M4.75691 13.1645C4.80579 11.9798 5.11228 10.8616 5.62186 9.8645L7.13585 11.3785C6.88648 12.0381 6.75 12.7531 6.75 13.5C6.75 13.6467 6.75524 13.7919 6.76553 13.9357L6.83769 14.9437L5.82898 15.0052C4.39034 15.093 3.25 16.2887 3.25 17.75C3.25 19.2688 4.48122 20.5 6 20.5H16.2574L18.2574 22.5H6C3.37665 22.5 1.25 20.3734 1.25 17.75C1.25 15.5567 2.73618 13.711 4.75691 13.1645Z"
      fill="currentColor"
    />
    <path
      d="M24.75 16.25C24.75 17.4255 24.2728 18.4895 23.5015 19.2589L24.9157 20.6731C26.0489 19.5418 26.75 17.9778 26.75 16.25C26.75 12.7982 23.9518 10 20.5 10C20.3172 10 20.136 10.0079 19.9568 10.0233C18.6636 7.34756 15.9236 5.5 12.75 5.5C11.8467 5.5 10.9783 5.64972 10.1683 5.92571L11.8151 7.57241C12.1197 7.52474 12.432 7.5 12.75 7.5C15.3488 7.5 17.5641 9.15274 18.3973 11.4677L18.6966 12.2991L19.5586 12.1044C19.8605 12.0362 20.1754 12 20.5 12C22.8472 12 24.75 13.9028 24.75 16.25Z"
      fill="currentColor"
    />
  </>
);
