import * as zod from 'zod';

import { OnRobot2FG7Command } from '../../OnRobot2FG7/types';
import { OnRobot3FG15Command } from '../../OnRobot3FG15/types';

const OnRobotDualQuickChangerCompatibleCommand = zod.union([
  OnRobot2FG7Command,
  OnRobot3FG15Command,
]);

export type OnRobotDualQuickChangerCompatibleCommand = zod.infer<
  typeof OnRobotDualQuickChangerCompatibleCommand
>;

export const OnRobotDualQuickChangerCommand = zod.object({
  kind: zod.literal('OnRobotDualQuickChangerCommand'),

  active: zod.enum(['primary', 'secondary']),

  command: OnRobotDualQuickChangerCompatibleCommand,
});

export type OnRobotDualQuickChangerCommand = zod.infer<
  typeof OnRobotDualQuickChangerCommand
>;
