import { create } from 'zustand';

interface TooltipStore {
  activeTooltipID: string | null;
  isAnyTooltipOpen: boolean;
  openTooltip(id: string, delayMS: number): void;
  closeTooltip(id: string, delayMS: number): void;
}

export const useTooltipStore = create<TooltipStore>((set, get) => {
  let timerID: any;

  const setTimer = (callback: () => void, delayMS: number) => {
    clearTimeout(timerID);
    timerID = setTimeout(callback, delayMS);
  };

  return {
    activeTooltipID: null,
    isAnyTooltipOpen: false,
    openTooltip(id, delayMS) {
      set({ activeTooltipID: id });
      setTimer(() => set({ isAnyTooltipOpen: true }), delayMS);
    },
    closeTooltip(id, delayMS) {
      if (id === get().activeTooltipID) {
        setTimer(() => set({ isAnyTooltipOpen: false }), delayMS);
      }
    },
  };
});
