export const headphones = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.5 14C5.5 9.30558 9.30558 5.5 14 5.5C18.6944 5.5 22.5 9.30558 22.5 14V15.5H19.5L18.5 16.5V23.5L19.5 24.5H21.5C23.1569 24.5 24.5 23.1569 24.5 21.5V15.5V14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14V15.5V21.5C3.5 23.1569 4.84315 24.5 6.5 24.5H8.5L9.5 23.5V16.5L8.5 15.5H5.5V14Z"
    fill="currentColor"
  />
);

export const headphonesWithMic = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.5 12.5C6.5 8.35786 9.85786 5 14 5C18.1421 5 21.5 8.35786 21.5 12.5V13.5H18.8333L18 14.3889V20.6111L18.8333 21.5H20.75C21.01 21.5 21.2616 21.4639 21.5 21.3965V22.5C21.5 23.6046 20.6046 24.5 19.5 24.5H16L15 23.5H13L12 24.5V25.5L13 26.5H16H19.5C21.7091 26.5 23.5 24.7091 23.5 22.5V18.75V17.5V13.5V12.5C23.5 7.25329 19.2467 3 14 3C8.75329 3 4.5 7.25329 4.5 12.5V13.5V18.75C4.5 20.2688 5.73122 21.5 7.25 21.5H9.16667L10 20.6111V14.3889L9.16667 13.5H6.5V12.5Z"
    fill="currentColor"
  />
);
