export const plane = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.4855 6.14252H14.5145L27.5145 13.9425V15.6575L14.5145 23.4575H13.4855L0.485504 15.6575V13.9425L13.4855 6.14252ZM9.44365 10.9L14 13.6338L18.5563 10.9L14 8.1662L9.44365 10.9ZM20.5 12.0662L15.9437 14.8L20.5 17.5338L25.0564 14.8L20.5 12.0662ZM18.5563 18.7L14 15.9662L9.44365 18.7L14 21.4338L18.5563 18.7ZM7.5 17.5338L12.0563 14.8L7.5 12.0662L2.94365 14.8L7.5 17.5338Z"
    fill="currentColor"
  />
);

export const plane3DCamera = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.4855 5.59998H14.5145L27.5145 13.4V15H25.5703L25.06 14.26L25.0557 14.2579L25.0563 14.2575L20.5 11.5237L9.44362 18.1575L14 20.8913V22.915H13.4855L0.485474 15.115V13.4L13.4855 5.59998ZM23.916 15.2226L24.7676 16.5H25.2061H27L27.5 17V24.5L27 25H16L15.5 24.5V22.3237V19.9913V17L16 16.5H18.2324L19.0839 15.2226L19.5 15H23.5L23.6685 15.0902L23.916 15.2226ZM18.7529 20.3719C18.751 20.4143 18.75 20.457 18.75 20.5C18.75 22.0188 19.9812 23.25 21.5 23.25C23.0188 23.25 24.25 22.0188 24.25 20.5C24.25 19.4037 23.6084 18.4572 22.6803 18.0155C22.3226 17.8452 21.9224 17.75 21.5 17.75C20.0241 17.75 18.8198 18.9126 18.7529 20.3719ZM21.4552 18.7505C20.8211 18.7665 20.2708 19.1197 19.9769 19.6375C19.8325 19.892 19.75 20.1864 19.75 20.5C19.75 21.4665 20.5335 22.25 21.5 22.25C22.4665 22.25 23.25 21.4665 23.25 20.5C23.25 19.5335 22.4665 18.75 21.5 18.75C21.485 18.75 21.4701 18.7502 21.4552 18.7505ZM9.44362 10.3575L14 13.0913L18.5563 10.3575L14 7.62366L9.44362 10.3575ZM7.49997 16.9913L12.0563 14.2575L7.49997 11.5237L2.94362 14.2575L7.49997 16.9913ZM25.25 19.375C25.5951 19.375 25.875 19.0952 25.875 18.75C25.875 18.4048 25.5951 18.125 25.25 18.125C24.9048 18.125 24.625 18.4048 24.625 18.75C24.625 19.0952 24.9048 19.375 25.25 19.375Z"
    fill="currentColor"
  />
);
