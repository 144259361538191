import * as zod from 'zod';

import { TaggedStepConfigUnion } from './RoutineStepConfiguration';

const stepKinds = [...TaggedStepConfigUnion.options.values()].map(
  (o) => o.shape.stepKind.value,
);

export type StepKind = (typeof stepKinds)[number];

export const StepKind = zod.enum(stepKinds as [StepKind, ...StepKind[]]);
