export const arrowDownSquare = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.2262 16.6332H14.7738L14.7739 16.6333L14.7739 16.6333L14.774 16.6332L14.7747 16.6323L14.7787 16.6275L14.7961 16.6064C14.8118 16.5874 14.8358 16.5586 14.8673 16.5209C14.9305 16.4456 15.0241 16.335 15.1438 16.1963C15.3833 15.9187 15.7264 15.5299 16.1381 15.0877C16.9679 14.1965 18.0508 13.1162 19.1139 12.2894L17.8861 10.7106C16.8433 11.5217 15.8159 12.5231 15 13.3791V1L13 1V13.3791C12.1841 12.5231 11.1567 11.5217 10.1139 10.7106L8.88606 12.2894C9.94919 13.1162 11.0321 14.1965 11.8619 15.0877C12.2736 15.5299 12.6167 15.9187 12.8562 16.1963C12.9759 16.335 13.0695 16.4456 13.1327 16.5209C13.1642 16.5586 13.1882 16.5874 13.2039 16.6064L13.2213 16.6275L13.2253 16.6323L13.226 16.6332L13.2261 16.6333L13.2261 16.6333L13.2262 16.6332ZM5.5 6L4.5 7V23L5.5 24H22.5L23.5 23V7L22.5 6H17V8H21.5V22H6.5V8H11V6H5.5Z"
    fill="currentColor"
  />
);

export const arrowUpSquare = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.3639 6.5394C11.4855 5.66709 12.3461 4.89615 13 4.24736V16H15V4.24736C15.6539 4.89615 16.5145 5.66709 17.6361 6.5394L18.8639 4.96069C17.2223 3.68385 16.2075 2.66491 15.6129 1.9833C15.3156 1.64249 15.1232 1.38584 15.0099 1.22318C14.9532 1.14183 14.9162 1.08392 14.8957 1.05067C14.8855 1.03404 14.8793 1.02356 14.8769 1.0194L14.8776 1.02072L14.8789 1.02303L14.8796 1.02441L14.88 1.02516L14.8789 1.02595L13.1211 1.02595L13.12 1.02516L13.1204 1.02441L13.1211 1.02303L13.1224 1.02072L13.1231 1.0194L13.1239 1.01789L13.1242 1.01733C13.1242 1.01733 13.1239 1.01802 13.1231 1.0194C13.1207 1.02356 13.1145 1.03404 13.1043 1.05067C13.0838 1.08392 13.0468 1.14183 12.9901 1.22317C12.8768 1.38584 12.6844 1.64249 12.3871 1.9833C11.7925 2.66491 10.7777 3.68385 9.13606 4.96069L10.3639 6.5394ZM6.5 8L5.5 9V24L6.5 25H21.5L22.5 24V9L21.5 8H16.8125V10H20.5V23H7.5V10H11.1875V8H6.5Z"
    fill="currentColor"
  />
);

export const arrowUpRightSquare = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5 4L4 5V23L5 24H23L24 23V5L23 4H5ZM6 22V6H22V22H6ZM17.5885 9.29561L18.7044 10.4115L18.7046 10.4115L18.7046 10.4117L18.7045 10.4126L18.7037 10.4191L18.7002 10.4484C18.6971 10.4752 18.6924 10.516 18.6866 10.5701C18.6749 10.6782 18.6584 10.8391 18.64 11.045C18.6032 11.4572 18.5588 12.049 18.5292 12.76C18.4697 14.1871 18.4709 16.0718 18.7046 17.9418L16.7201 18.1899C16.4796 16.2664 16.4693 14.3562 16.5225 12.8917L10.9948 18.4194L9.5806 17.0052L15.1083 11.4775C13.6438 11.5307 11.7336 11.5204 9.81012 11.28L10.0582 9.2954C11.9282 9.52915 13.8129 9.53031 15.24 9.47085C15.9511 9.44122 16.5428 9.39681 16.955 9.36C17.1609 9.34161 17.3218 9.32515 17.4299 9.31343C17.484 9.30758 17.5248 9.30291 17.5516 9.29979L17.5809 9.2963L17.5874 9.29551L17.5883 9.2954L17.5885 9.29538L17.5885 9.29561Z"
    fill="currentColor"
  />
);
