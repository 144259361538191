import type * as zod from 'zod';

import {
  getDevicesInNetwork,
  getInferenceState,
  publishInferenceCommand,
} from '@sb/ros';
import { FailureKind } from '@sb/routine-runner/FailureKind';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const RUN_SKILL_TIMEOUT = 60_000;

export default class RunSkillStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    if (this.routineContext.robot.robotKind !== 'live') {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'RunSkill',
        },
        failureReason: 'Run skill step not supported in visualizer',
      });
    }

    const missingDevices = await this.getMissingDevices();

    if (missingDevices.length > 0) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'RunSkill',
        },
        failureReason: `Required devices not found in network: ${missingDevices}`,
      });
    }

    const inferenceState = await getInferenceState();

    try {
      const inferenceSkills = await inferenceState.getInferenceSkills();

      if (!inferenceSkills.includes(this.args.taskName)) {
        return fail({
          failure: {
            kind: FailureKind.StepPlayFailure,
            stepKind: 'RunSkill',
          },
          failureReason: `Required skill not available: ${this.args.taskName}`,
        });
      }

      await inferenceState.waitForStateKind('idle');

      await publishInferenceCommand({
        command: 'inference',
        metadata: {
          task_name: this.args.taskName,
          bot_ids: this.args.botIDs,
          camera_ids: this.args.cameraIDs,
        },
      });

      await inferenceState.waitForStateKind('inference');

      await inferenceState.waitForStateKind('idle', RUN_SKILL_TIMEOUT);
    } catch (error) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'RunSkill',
        },
        failureReason: `Failed to run skill: ${error?.message}`,
        error,
      });
    } finally {
      inferenceState.destroy();
    }
  }

  public async _stop() {
    await publishInferenceCommand({ command: 'stop' });
  }

  private async getMissingDevices(): Promise<string[]> {
    const devicesInNetwork = await getDevicesInNetwork();
    const missingDevices: string[] = [];

    for (const botID of this.args.botIDs) {
      if (!devicesInNetwork.robotIDs.includes(botID)) {
        missingDevices.push(botID);
      }
    }

    for (const cameraID of this.args.cameraIDs) {
      if (!devicesInNetwork.cameraIDs.includes(cameraID)) {
        missingDevices.push(cameraID);
      }
    }

    return missingDevices;
  }
}
