import * as zod from 'zod';

import { six } from '@sb/utilities';

// A set of joint connection states, in order from joint 0 to joint 5.
export const ArmJointConnectionStates = zod.tuple(
  six(zod.enum(['disconnected', 'connected', 'ready'])),
);

export type ArmJointConnectionStates = zod.infer<
  typeof ArmJointConnectionStates
>;
