import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';
import type { OutputPortID, RelayPortID } from '@sb/routine-runner';
import { IOLevel } from '@sb/routine-runner';

export namespace SetIOStep {
  export const name = 'Set I/O output';
  export const description =
    "Sets the robot's I/O interface, allowing it to send messages to external devices";
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '1';
  export const argumentKind = 'SetIO';

  export const OutputConfiguration = zod.object({
    level: IOLevel,
    port: zod.number(),
    kind: zod.enum(['Output', 'Relay', 'FlangeOutput']).default('Output'),
  });

  export type OutputConfiguration = zod.infer<typeof OutputConfiguration>;

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    outputs: zod.array(OutputConfiguration).default([]),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'SetIO',
      args: {
        changes: args.outputs.map((output) => {
          const label = `${output.kind} ${output.port}` as
            | OutputPortID
            | RelayPortID;

          return { label, level: output.level, kind: output.kind };
        }),
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    robot,
    includeStepName,
  }) => {
    if (args?.argumentKind !== 'SetIO' || !robot) {
      return null;
    }

    const outputsDescriptions = args.outputs
      .map(({ port, level }) => {
        const ioPort = robot.ioOutputs.find((p) => p.port === port);

        if (!ioPort) {
          return null;
        }

        const levelName =
          level === 'high' ? ioPort.highSignalName : ioPort.lowSignalName;

        return `${ioPort.name} to ${levelName}`;
      })
      .filter((p) => p != null);

    if (outputsDescriptions.length > 0) {
      return `${includeStepName ? 'Set ' : ''}${outputsDescriptions.join(', ')}`;
    }

    return null;
  };
}

SetIOStep satisfies Step.StepKindInfo;
