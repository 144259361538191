import * as zod from 'zod';

import { MotionKind, TCPOptionOrAuto } from '@sb/motion-planning';
import { BlendConfig } from '@sb/motion-planning/Waypoint';

import { SpeedProfile } from '../../speed-profile';
import { Expression, Space } from '../../types';

export default zod.object({
  motionKind: MotionKind,
  shouldMatchJointAngles: zod.boolean(),
  reduceSmoothing: zod.boolean().optional(),
  tcpOption: TCPOptionOrAuto,
  target: zod.union([
    Space.Position,
    zod.object({
      positionListID: zod.string(),
      positionListIndex: zod.number().int().nonnegative().optional(),
    }),
    zod.object({
      expression: Expression,
    }),
  ]),
  speedProfile: SpeedProfile,
  isWaypoint: zod.boolean().optional(),
  isCacheable: zod.boolean().optional(),
  blend: zod.optional(BlendConfig),
  moveDynamicBaseToReachPosition: zod.boolean().optional(),
});
