import * as zod from 'zod';

// limits for one joint
const JointLimits = zod.object({
  min: zod.number(),
  max: zod.number(),
});

// A set of joint position limits in radians, in order from joint 0 to joint 5.
export const ArmJointLimits = zod.tuple([
  JointLimits,
  JointLimits,
  JointLimits,
  JointLimits,
  JointLimits,
  JointLimits,
]);

export type ArmJointLimits = zod.infer<typeof ArmJointLimits>;
