import { memoize } from 'lodash';
// @ts-ignore
import type * as RCL from 'rclnodejs';

import { makeLogNamespace } from '@sb/log/log';

const log = makeLogNamespace('ROS.getRCL');

// get ROS2 Client Library and initialize
export const getRCL = memoize(async (): Promise<typeof RCL> => {
  try {
    // @ts-ignore
    const { default: rcl } = await import('rclnodejs');

    await rcl.init();

    return rcl;
  } catch (error) {
    log.error('init.error', 'ROS did not initialize', { error });
    throw error;
  }
});
