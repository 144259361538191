import * as zod from 'zod';

import { isIntegrationHaasNGCInterface } from '@sb/integrations/io/types';
import { ActionRequiredError, Step } from '@sb/remote-control/types';

import { getDevice } from '../toRoutineRunnerHelpers';

export namespace CNCRunProgramStepDatabase {
  export const name = 'Run program';
  export const description = 'Run a program on a CNC machine';
  export const deviceKinds = ['CNCMachine'] as const;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'CNCRunProgram';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    deviceID: zod.string().default(''),
    haasProgram: zod.string().default(''),
    haasCellSafe: zod.boolean().default(false),
    waitUntilComplete: zod.boolean().default(true),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const machineConfig = getDevice(args.deviceID, equipment);

    if (machineConfig.interfaces.find(isIntegrationHaasNGCInterface)) {
      if (!args.haasProgram) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Haas program not set',
          fieldId: 'haasProgram',
        });
      }
    }

    return {
      ...stepData,
      stepKind: 'CNCRunProgram',
      args,
    };
  };
}

CNCRunProgramStepDatabase satisfies Step.StepKindInfo;
