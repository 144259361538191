import cx from 'classnames';
import type { TableHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { textColor } from '../styles';

import styles from './Table.module.css';

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {}

const Table = forwardRef<HTMLTableElement, TableProps>((props, ref) => {
  const { className, ...other } = props;

  return (
    <table
      ref={ref}
      className={cx(textColor.white, styles.table, className)}
      {...other}
    />
  );
});

export default Table;
