import { debounce, memoize } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { makeNamespacedLog } from '@sb/log';
import { wait } from '@sb/utilities';
import { API_ENDPOINT, globalCache } from '@sbrc/utils';

import type { ConnectionStatus } from './ConnectionStatus';
import { RoutineRunnerHandle } from './RoutineRunnerHandle';

const log = makeNamespacedLog('WebSocketRoutineRunnerHandle');

export class WebSocketRoutineRunnerHandle extends RoutineRunnerHandle {
  private connectionStatus = new BehaviorSubject<ConnectionStatus>({
    kind: 'constructing',
  });

  public constructor(private robotKind: 'simulated' | 'live') {
    super();

    this.connect();
  }

  private connect() {
    // don't do anything when running on server
    if (typeof window === 'undefined') {
      return;
    }

    // don't do anything when disabled (e.g. in assembly mode)
    if (this.isDisabled) {
      log.info(
        `uncategorized`,
        'skipping routine-runner connection (disabled)',
      );

      return;
    }

    if (this.isDestroyed) {
      return;
    }

    log.info(`uncategorized`, `Connecting...`);

    this.connectionStatus.next({ kind: 'connecting' });

    const url = this.robotKind === 'simulated' ? 'vizbot-ws' : 'routine-runner';

    const ws = new WebSocket(`${API_ENDPOINT}${url}`);

    const connectingTimeoutID = setTimeout(() => {
      if (ws.readyState === WebSocket.CONNECTING) {
        log.info(`uncategorized`, 'Timed out while connecting');
        ws.close(4001, 'Connecting timeout');
      }
    }, 5_000);

    const destructors: Array<() => void> = [];

    // teardown is memoized so it is only called once
    const teardownAndReconnect = memoize(async () => {
      log.info(`uncategorized`, 'Teardown', { readyState: ws.readyState });

      if (ws.readyState !== ws.CLOSING && ws.readyState !== ws.CLOSED) {
        ws.close(4000, 'Teardown');
      }

      for (const destructor of destructors) {
        destructor();
      }

      if (this.getConnectionStatus().kind !== 'connecting') {
        this.connectionStatus.next({ kind: 'disconnected' });
      }

      await wait(1000);
      this.connect();
    });

    // Destroy the connection if nothing received for a while
    const scheduleAutoDisconnect = debounce(() => {
      log.info(`uncategorized`, 'Auto disconnect (nothing received for 5s)');
      teardownAndReconnect();
    }, 5_000);

    ws.onopen = () => {
      log.info(`uncategorized`, 'Connected');
      clearTimeout(connectingTimeoutID);
      this.connectionStatus.next({ kind: 'connected' });
    };

    ws.onmessage = (ev) => {
      scheduleAutoDisconnect();
      this.receivePacket(ev.data);
    };

    if (this.arePacketsHandled()) {
      log.warn(
        `uncategorized`,
        'Multiple outgoing packet handlers being attached. Multiple peers may have been created, duplicating bandwidth',
      );
    }

    destructors.push(
      this.onPacket((packet) => {
        this.emitSBDevToolsEvent({
          kind: 'outgoingPacket',
          packet,
        });

        if (ws.readyState === WebSocket.OPEN) {
          ws.send(packet);
        }
      }),
    );

    destructors.push(
      this.onDestroy(() => {
        ws.close();
      }),
    );

    ws.onerror = () => {
      log.warn(`uncategorized`, 'Connection failed');
    };

    ws.onclose = () => {
      teardownAndReconnect();
    };
  }

  public getName(): Promise<string> {
    return Promise.resolve('Live (Web Socket)');
  }

  public getConnectionStatus(): ConnectionStatus {
    return this.connectionStatus.value;
  }

  public onConnectionChange(
    cb: (connectionStatus: ConnectionStatus) => void,
  ): () => void {
    const subscription = this.connectionStatus.subscribe(cb);

    return () => subscription.unsubscribe();
  }
}

function getWebSocketRoutineRunnerHandle(
  robotKind: 'simulated' | 'live',
): WebSocketRoutineRunnerHandle {
  return globalCache(
    `webSocketRoutineRunnerHandle.${robotKind}`,
    ({ reset }) => {
      const handle = new WebSocketRoutineRunnerHandle(robotKind);

      handle.onDestroy(() => reset());

      return handle;
    },
  );
}

export const getVizbotRoutineRunnerHandle = () => {
  return getWebSocketRoutineRunnerHandle('simulated');
};

export const getLiveRoutineRunnerHandle = () => {
  return getWebSocketRoutineRunnerHandle('live');
};
