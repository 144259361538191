export const ellipsisCircle = (
  <>
    <circle
      cx="14"
      cy="14"
      r="9.75"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <circle cx="9" cy="14" r="1.5" fill="currentColor" />
    <circle cx="14" cy="14" r="1.5" fill="currentColor" />
    <circle cx="19" cy="14" r="1.5" fill="currentColor" />
  </>
);
