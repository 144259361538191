import { ZERO_ROTATION } from '@sb/geometry';

export const DefaultTooltipTransform = {
  ...ZERO_ROTATION,
  x: 0,
  y: 0,
  z: 0.125,
} as const;

export const ModbusReadInitializationTimeoutMillis = 1_000;

export const DH_MIN_FORCE_PERCENT = 0.2;
export const DH_MAX_FORCE_PERCENT = 1;
