export const chevronDown = (
  <path
    d="M22 11C17.5 14.5 14 19 14 19C14 19 10.5 14.5 6 11"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const chevronUp = (
  <path
    d="M6 17C10.5 13.5 14 9 14 9C14 9 17.5 13.5 22 17"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const chevronLeft = (
  <path
    d="M17 6C13.5 10.5 9 14 9 14C9 14 13.5 17.5 17 22"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);
export const chevronRight = (
  <path
    d="M11 6C14.5 10.5 19 14 19 14C19 14 14.5 17.5 11 22"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);

export const chevronUpDown = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15.6806 7.79071C14.9777 7.21766 14.4059 6.72096 14 6.35803C13.5941 6.72096 13.0223 7.21766 12.3194 7.79071C10.857 8.98288 8.81317 10.5167 6.50387 11.8638L5.49612 10.1362C7.68682 8.85832 9.64303 7.39213 11.0556 6.24055C11.7605 5.66594 12.3266 5.17245 12.7151 4.82398C12.9092 4.64981 13.0588 4.51205 13.1589 4.4187C13.209 4.37203 13.2467 4.33648 13.2714 4.31306L13.2986 4.28714L13.3047 4.28123L13.3059 4.28016L13.3059 4.28009L13.306 4.28003L13.3061 4.28016L14.6939 4.28016L14.694 4.28003L14.6941 4.28009L14.6941 4.28016L14.6952 4.28123L14.7014 4.28714L14.7286 4.31306C14.7533 4.33648 14.791 4.37203 14.8411 4.4187C14.9412 4.51205 15.0908 4.64981 15.2849 4.82398C15.6734 5.17245 16.2395 5.66594 16.9444 6.24055C18.357 7.39213 20.3132 8.85832 22.5039 10.1362L21.4961 11.8638C19.1868 10.5167 17.143 8.98288 15.6806 7.79071ZM12.3194 20.2093C13.0223 20.7824 13.5941 21.2791 14 21.642C14.4059 21.2791 14.9777 20.7824 15.6806 20.2093C17.143 19.0171 19.1868 17.4833 21.4961 16.1362L22.5039 17.8638C20.3132 19.1417 18.357 20.6079 16.9444 21.7595C16.2395 22.3341 15.6734 22.8276 15.2849 23.176C15.0908 23.3502 14.9412 23.488 14.8411 23.5813C14.791 23.628 14.7533 23.6635 14.7286 23.687L14.7014 23.7129L14.6952 23.7188L14.6941 23.7199L14.6941 23.7199L14.694 23.72L14.6939 23.7199H13.3061L13.306 23.72L13.3059 23.7199L13.3059 23.7199L13.3047 23.7188L13.2986 23.7129L13.2714 23.687C13.2467 23.6635 13.209 23.628 13.1589 23.5813C13.0588 23.488 12.9092 23.3502 12.7151 23.176C12.3266 22.8276 11.7605 22.3341 11.0556 21.7595C9.64303 20.6079 7.68682 19.1417 5.49612 17.8638L6.50387 16.1362C8.81317 17.4833 10.857 19.0171 12.3194 20.2093Z"
    fill="currentColor"
  />
);
