import * as zod from 'zod';

export type Currency = 'USD';

export type Unsubscribe = () => void;

export const DistanceUnit = zod.union([
  zod.literal('meter'),
  zod.literal('millimeter'),
  zod.literal('inch'),
]);

export type DistanceUnit = zod.infer<typeof DistanceUnit>;

export interface DistanceUnitInfo {
  unit: DistanceUnit;
  name: string;
  abbreviation: string;
  decimalPlaces: number;
  multiplier: number;
  fromMeters: <V>(
    value: V,
    roundingKind?: 'round' | 'floor' | 'ceil',
  ) => V | number;
  toMeters: <V>(value: V) => V | number;
  /**
   * Converst the value from Meters to the current unit and rounds the value with the current unit
   * decimal places and appends the abbreviation.
   * @param value The value in Meters.
   * @param roundingKind the rounding kind between round floor and ceil.
   * @returns the value in the current unit with the abbreviation appended.
   */
  toString: <V>(value: V, roundingKind?: 'round' | 'floor' | 'ceil') => string;
}
