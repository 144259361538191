import * as zod from 'zod';

import { seven, six } from '@sb/utilities';

export const TeleopMessageOut = zod.discriminatedUnion('kind', [
  zod.object({ kind: zod.literal('stop_teleop') }),
  zod.object({ kind: zod.literal('stop_movement') }),
  zod.object({ kind: zod.literal('start_teleop') }),
  zod.object({ kind: zod.literal('move_to_start') }),
  zod.object({ kind: zod.literal('move_to_sync') }),
  zod.object({ kind: zod.literal('subscribe') }),
  zod.object({ kind: zod.literal('zero_leader') }),
  zod.object({ kind: zod.literal('zero_gripper') }),
  zod.object({ kind: zod.literal('reverse_gripper') }),
  zod.object({
    kind: zod.literal('set_finegrain_movement_state'),
    metadata: zod.object({
      enable: zod.boolean(),
      ratio: zod.number(),
    }),
  }),
  zod.object({ kind: zod.literal('unfreeze') }),
  zod.object({ kind: zod.literal('freeze') }),
  zod.object({ kind: zod.literal('reboot') }),
]);

export type TeleopMessageOut = zod.infer<typeof TeleopMessageOut>;

const TeleopState = zod.union([
  zod.literal('wait_for_teleop'),
  zod.literal('teleop'),
  zod.literal('stopped'),
  zod.literal('stopping'),
  zod.literal('moving'),
  zod.literal('unfrozen'),
]);

export type TeleopState = zod.infer<typeof TeleopState>;

export const TeleopMessageIn = zod.discriminatedUnion('kind', [
  zod.object({
    kind: zod.literal('state_update'),
    state: zod.object({
      teleop_state: TeleopState,
      bots_in_sync: zod.boolean(),
      sync_gap: zod.array(zod.number()),
      mini_arm_state: zod.object({
        joint_positions: zod.tuple(six(zod.number())),
        gripper_position: zod.number(),
        is_frozen: zod.boolean(),
        joint_connectivity: zod.tuple(seven(zod.boolean())),
        gripper_handedness: zod.union([
          zod.literal('left'),
          zod.literal('right'),
        ]),
        joint_errors: zod.tuple(seven(zod.array(zod.string()))),
      }),
      finegrain_movement_state: zod
        .object({
          is_enabled: zod.boolean(),
          ratio: zod.number(),
        })
        .default({ is_enabled: false, ratio: 0 }),
    }),
  }),
  zod.object({
    kind: zod.literal('message'),
    message: zod.string(),
  }),
  zod.object({
    kind: zod.literal('error'),
    message: zod.string(),
  }),
]);

export type TeleopMessageIn = zod.infer<typeof TeleopMessageIn>;
