import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './RadioInput.module.css';

interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (props, ref) => {
    const { checked, disabled, id, onChange, className, children, ...other } =
      props;

    return (
      <label
        htmlFor={id}
        className={cx(
          styles.radioInput,
          { [styles.disabled]: disabled },
          className,
        )}
      >
        <input
          ref={ref}
          id={id}
          checked={checked}
          className={styles.radioButton}
          disabled={disabled}
          onChange={onChange}
          type="radio"
          {...other}
        />
        {children}
      </label>
    );
  },
);

export default RadioInput;
