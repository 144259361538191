import { useEffect } from 'react';

import type { RoutineRunnerHandle } from '@sbrc/services';
import { getVizbotRoutineRunnerHandle } from '@sbrc/services';
import { useRobotIdentity } from '@sbrc/services/feathers-client/robot-identity';
import { getRobotPosition } from '@sbrc/utils';

import { useLiveRoutineRunnerHandle } from './useLiveRoutineRunnerHandle';

export function useVizbotRoutineRunnerHandle(): RoutineRunnerHandle {
  const robotIdentity = useRobotIdentity();
  const robotOperationMode = robotIdentity?.robotOperationMode ?? 'live';

  const handle = getVizbotRoutineRunnerHandle();
  handle.setDisabled(robotOperationMode === 'assembly');

  return handle;
}

export const useForceSyncPositionsToVizbot = () => {
  const vizbot = useVizbotRoutineRunnerHandle();
  const liveRunner = useLiveRoutineRunnerHandle();

  useEffect(() => {
    const positions = getRobotPosition(liveRunner)?.jointAngles;

    if (positions) {
      vizbot.vizbotOnlySetJointPositions(positions);
    }
  }, [vizbot, liveRunner]);
};
