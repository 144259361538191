import * as zod from 'zod';

// The jerk limit is a limit on the rate of change of acceleration.  It is a multiplier on the
// maximum acceleration.  Generally a range of 3x-10x is consider reasonable.
export const JerkLimit = zod.number();

export type JerkLimit = zod.infer<typeof JerkLimit>;

export const JERK_LIMIT_DEFAULT = 3;
export const JERK_LIMIT_MIN = 3;
export const JERK_LIMIT_MAX = 10;
