export const checkmark = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.5056 22.1987L13.5057 22.1985L13.5062 22.1975L13.5084 22.1932L13.5174 22.1751L13.5541 22.1022C13.5867 22.0375 13.6355 21.941 13.6996 21.8153C13.8277 21.5638 14.017 21.1951 14.2605 20.7285C14.7475 19.7952 15.451 18.4707 16.3155 16.909C18.0462 13.7826 20.4149 9.71907 22.9782 5.94391L21.3235 4.82043C18.7129 8.66527 16.3116 12.7867 14.5658 15.9403C13.692 17.5187 12.9806 18.8579 12.4873 19.8033L12.3891 19.9919C12.0335 19.5982 11.5956 19.121 11.0999 18.5965C9.76011 17.1791 7.97585 15.393 6.24353 13.9777L4.97818 15.5266C6.61198 16.8613 8.32772 18.5752 9.64642 19.9703C10.303 20.665 10.8557 21.2747 11.2438 21.7104C11.4378 21.9281 11.5905 22.1021 11.6942 22.2211C11.7461 22.2806 11.7857 22.3264 11.8121 22.357L11.8417 22.3914L11.8489 22.3998L11.8505 22.4017L11.8508 22.402L11.8508 22.402L11.8508 22.4021L11.9364 22.5022H13.3542L13.5056 22.1987Z"
    fill="currentColor"
  />
);

export const checkmarkCircle = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.25 14C5.25 9.16751 9.16751 5.25 14 5.25C18.8325 5.25 22.75 9.16751 22.75 14C22.75 18.8325 18.8325 22.75 14 22.75C9.16751 22.75 5.25 18.8325 5.25 14ZM14 3.25C8.06294 3.25 3.25 8.06294 3.25 14C3.25 19.9371 8.06294 24.75 14 24.75C19.9371 24.75 24.75 19.9371 24.75 14C24.75 8.06294 19.9371 3.25 14 3.25ZM14.2002 18.3226L14.2004 18.3222L14.2014 18.3203L14.2057 18.3117L14.2236 18.2761C14.2395 18.2444 14.2636 18.1968 14.2953 18.1346C14.3587 18.0102 14.4526 17.8273 14.5735 17.5956C14.8155 17.1319 15.1653 16.4733 15.5952 15.6966C16.4568 14.1403 17.6329 12.123 18.9027 10.2528L17.2481 9.12933C15.931 11.0692 14.7221 13.1443 13.8454 14.728C13.5519 15.2583 13.2948 15.7349 13.0824 16.1347C13.0277 16.0762 12.9713 16.0161 12.9133 15.9548C12.2382 15.2405 11.3289 14.3294 10.4381 13.6016L9.17276 15.1505C9.96503 15.7977 10.8058 16.6366 11.4599 17.3286C11.7841 17.6717 12.0571 17.9729 12.2486 18.1878C12.3443 18.2951 12.4194 18.3808 12.4701 18.439L12.5275 18.5051L12.5415 18.5215L12.5448 18.5252L12.5454 18.5259L12.5454 18.526L12.5455 18.526L12.5455 18.526L12.737 18.75H13.9869L14.2002 18.3226L14.2002 18.3226L14.2002 18.3226Z"
    fill="currentColor"
  />
);

export const checkmarkCircleFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.25 14C3.25 8.06294 8.06294 3.25 14 3.25C19.9371 3.25 24.75 8.06294 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C8.06294 24.75 3.25 19.9371 3.25 14ZM14.2002 18.3226L14.2004 18.3222L14.2014 18.3203L14.2057 18.3117L14.2236 18.2761C14.2395 18.2444 14.2636 18.1968 14.2953 18.1346C14.3587 18.0102 14.4526 17.8273 14.5735 17.5956C14.8155 17.1319 15.1653 16.4733 15.5952 15.6966C16.4568 14.1403 17.6329 12.123 18.9027 10.2528L17.2481 9.12933C15.931 11.0692 14.7221 13.1443 13.8454 14.728C13.5519 15.2583 13.2948 15.7349 13.0824 16.1347C13.0277 16.0762 12.9713 16.0161 12.9133 15.9548C12.2382 15.2405 11.3289 14.3294 10.4381 13.6016L9.17276 15.1505C9.96503 15.7977 10.8058 16.6366 11.4599 17.3286C11.7841 17.6717 12.0571 17.9729 12.2486 18.1878C12.3443 18.2951 12.4194 18.3808 12.4701 18.439L12.5275 18.5051L12.5415 18.5215L12.5448 18.5252L12.5454 18.5259L12.5454 18.526L12.5455 18.526L12.5455 18.526L12.737 18.75H13.9869L14.2002 18.3226L14.2002 18.3226L14.2002 18.3226Z"
    fill="currentColor"
  />
);

export const checkmarkSquareFill = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 5L5 4H23L24 5V23L23 24H5L4 23V5ZM14.2002 18.3226L14.2004 18.3222L14.2014 18.3203L14.2057 18.3117L14.2236 18.2761C14.2395 18.2444 14.2636 18.1968 14.2953 18.1346C14.3587 18.0102 14.4526 17.8273 14.5735 17.5956C14.8155 17.1319 15.1653 16.4733 15.5952 15.6966C16.4568 14.1403 17.6329 12.123 18.9027 10.2528L17.2481 9.12933C15.931 11.0692 14.7221 13.1443 13.8454 14.728C13.5519 15.2583 13.2948 15.7349 13.0824 16.1347C13.0277 16.0762 12.9713 16.0161 12.9133 15.9548C12.2382 15.2405 11.3289 14.3294 10.4381 13.6016L9.17276 15.1505C9.96503 15.7977 10.8058 16.6366 11.4599 17.3286C11.7841 17.6717 12.0571 17.9729 12.2486 18.1878C12.3443 18.2951 12.4194 18.3808 12.4701 18.439L12.5275 18.5051L12.5415 18.5215L12.5448 18.5252L12.5454 18.5259L12.5454 18.526L12.5455 18.526L12.5455 18.526L12.737 18.75H13.9869L14.2002 18.3226L14.2002 18.3226L14.2002 18.3226Z"
    fill="currentColor"
  />
);

export const checkmarkShield = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7 16V7.69299L14 5.06799L21 7.69299V16C21 16.9443 20.5554 17.8334 19.8 18.4L14 22.75L8.2 18.4C7.44458 17.8334 7 16.9443 7 16ZM13.6489 3.06366L5.64888 6.06366L5 6.99999V16C5 17.5738 5.74097 19.0557 7 20L13.4 24.8H14.6L21 20C22.259 19.0557 23 17.5738 23 16V6.99999L22.3511 6.06366L14.3511 3.06366H13.6489ZM14.2002 17.8232L14.2004 17.8229L14.2014 17.8209L14.2057 17.8123L14.2235 17.7768C14.2395 17.7451 14.2636 17.6975 14.2953 17.6353C14.3587 17.5109 14.4526 17.328 14.5735 17.0962C14.8154 16.6326 15.1653 15.974 15.5952 15.1973C16.4568 13.641 17.6329 11.6236 18.9027 9.75347L17.2481 8.63C15.931 10.5698 14.7221 12.645 13.8454 14.2287C13.5519 14.7589 13.2948 15.2356 13.0824 15.6354C13.0277 15.5768 12.9713 15.5168 12.9133 15.4554C12.2381 14.7412 11.3289 13.8301 10.4381 13.1023L9.17274 14.6512C9.96501 15.2984 10.8058 16.1373 11.4598 16.8293C11.7841 17.1724 12.0571 17.4735 12.2486 17.6884C12.3442 17.7958 12.4193 17.8814 12.4701 17.9397L12.5275 18.0058L12.5415 18.0221L12.5448 18.0259L12.5454 18.0266L12.5454 18.0266L12.5454 18.0267L12.5455 18.0267L12.737 18.2507H13.9869L14.2002 17.8233L14.2002 17.8232L14.2002 17.8232Z"
    fill="currentColor"
  />
);
