import * as zod from 'zod';

import { DHConfiguration } from '@sb/integrations/implementations/dh/comon/types/DHConfiguration';

export const DHCGI100170Configuration = DHConfiguration.extend({
  kind: zod.literal('DHCGI100170'),
  name: zod.string().default('DH CGI 100/170'),
});

export type DHCGI100170Configuration = zod.infer<
  typeof DHCGI100170Configuration
>;

export const DefaultConfiguration = DHCGI100170Configuration.parse({
  kind: 'DHCGI100170',
  family: 'DHBase',
  minGripWidth: 40 / 1_000,
  maxGripWidth: 170 / 1_000,
});
