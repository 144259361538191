import type * as zod from 'zod';

import { FailureKind } from '@sb/routine-runner/FailureKind';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class SetIOStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    try {
      await this.routineContext.robot.setOutputIO(this.args.changes);
    } catch (error) {
      return fail({
        failure: { kind: FailureKind.ExecutionFailure },
        failureReason: 'Changing output IO did not work',
        error,
      });
    }
  }
}
