import * as zod from 'zod';

export const RoutineRunnerVariablesData = zod.object({
  id: zod.string(),
  compositeID: zod.string(),
  robotID: zod.string(),
  routineID: zod.string().nullable(),
  stepID: zod.string(),
  variables: zod.record(zod.any()),
});

export type RoutineRunnerVariablesData = zod.infer<
  typeof RoutineRunnerVariablesData
>;
