import cx from 'classnames';
import { forwardRef } from 'react';

import { SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT } from '@sb/ui';
import { AddIcon, ClearIcon, MinusIcon } from '@sb/ui/icons';
import { padding, margin } from '@sb/ui/styles';

import Typography from '../Typography';

import HelperText from './HelperText';
import InputLabel from './InputLabel';
import type { InputProps } from './types';

import styles from './InputField.module.css';
import sharedStyles from './shared.module.css';

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    InputProps {
  onClear?: () => void;
  onDecrement?: () => void;
  isDecrementDisabled?: boolean;
  onIncrement?: () => void;
  isIncrementDisabled?: boolean;
  suffix?: React.ReactNode;
  inputContainerClassName?: string;
  'data-testid'?: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      children,
      className,
      helperText,
      hasBottomMargin,
      hasError,
      inputClassName,
      inputContainerClassName,
      isHighlighted,
      labelClassName,
      labelPosition = 'top',
      onClear,
      onDecrement,
      isDecrementDisabled,
      onIncrement,
      isIncrementDisabled,
      sizeVariant = SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT,
      suffix,
      'data-testid': testID,
      ...inputProps
    } = props;

    return (
      <div
        className={cx(
          sharedStyles.inputGroup,
          sharedStyles[sizeVariant],
          sharedStyles[`${labelPosition}Label`],
          hasBottomMargin && margin.bottom.small,
          className,
        )}
      >
        {children && (
          <InputLabel labelPosition={labelPosition} className={labelClassName}>
            {children}
          </InputLabel>
        )}

        <div
          className={cx(
            styles.inputContainer,
            sharedStyles.inputContainer,
            hasError && sharedStyles.hasError,
            isHighlighted && !hasError && sharedStyles.highlighted,
            inputProps.disabled && sharedStyles.isDisabled,
            inputContainerClassName,
          )}
        >
          <input
            ref={ref}
            className={cx(
              inputClassName,
              styles.inputElement,
              sharedStyles.input,
            )}
            data-testid={testID}
            {...inputProps}
          />

          {suffix && (
            <Typography
              variant="medium"
              className={padding.right.small}
              component="span"
              color="gray"
              hasNoWrap
            >
              {suffix}
            </Typography>
          )}

          {onClear && (
            <ClearIcon
              className={styles.clearButton}
              role="button"
              aria-label="Clear input"
              onClick={onClear}
              disabled={inputProps.disabled}
            />
          )}

          {onDecrement && (
            <button
              type="button"
              className={styles.incrementDecrementButton}
              onClick={onDecrement}
              disabled={inputProps.disabled || isDecrementDisabled}
            >
              <MinusIcon className={styles.incrementDecrementIcon} />
            </button>
          )}

          {onIncrement && (
            <button
              type="button"
              className={styles.incrementDecrementButton}
              onClick={onIncrement}
              disabled={inputProps.disabled || isIncrementDisabled}
            >
              <AddIcon className={styles.incrementDecrementIcon} />
            </button>
          )}
        </div>

        <HelperText
          hasError={hasError}
          helperText={helperText}
          data-testid={testID && `${testID}--error`}
        />
      </div>
    );
  },
);
