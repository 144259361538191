/**
 * Robot to external port: A label corresponding to an output port on the IO board
 */
import * as zod from 'zod';

import { FLANGE_INPUT_PORT_IDS, FlangeInputPortKind } from './FlangeInputPort';
import { INPUT_PORT_IDS, InputPortKind } from './InputPort';
import { SAFETY_PORT_IDS, SafetyPortKind } from './SafetyPort';

export const ANY_INPUT_PORT_IDS = [
  ...INPUT_PORT_IDS,
  ...SAFETY_PORT_IDS,
  ...FLANGE_INPUT_PORT_IDS,
] as const;

export const AnyInputPortKind = zod.union([
  InputPortKind,
  SafetyPortKind,
  FlangeInputPortKind,
]);

export type AnyInputPortKind = zod.infer<typeof AnyInputPortKind>;

export const AnyInputPortID = zod.enum(ANY_INPUT_PORT_IDS);

export type AnyInputPortID = zod.infer<typeof AnyInputPortID>;
