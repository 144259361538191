import type { HaasAddress, HaasSerialTarget } from '../types';

export const haasAddressHandle = (
  address: HaasAddress | HaasSerialTarget,
): string => {
  if (address.kind === 'HaasEthernet') {
    return `${address.endpoint}`;
  }

  if (address.kind === 'HaasRS232') {
    return `${address.path}`;
  }

  throw new Error(`Unknown Haas address kind: ${address}`);
};
