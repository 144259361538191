import { useEffect, useRef, useCallback } from 'react';

import { makeNamespacedLog } from '@sb/log';
import type { CommandResult } from '@sb/routine-runner';
import { TapIcon } from '@sb/ui/icons';
import { GUIDED_MODE_INTERVAL_MS, UserSession } from '@sbrc/utils';

import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import useToast from './useToast';

const log = makeNamespacedLog('useGuidedMode');

interface OnRunAdHocCommandArguments {
  onComplete?: () => void;
  onError?: (error?: string) => void;
  onGuidedModeStart?: () => void;
  onGuidedModeStop?: () => void;
  onRunCommand: () => Promise<void | CommandResult>;
  onSuccess?: () => void;
}

const MIN_HOLD_MS = 200;

type UseGuidedModeArguments = {
  isVizbot?: boolean;
};

/**
 * Ad-hoc commands can only be executed in the routine runner as long
 * as it is in Guided Mode. To let the routine runner know that Guided
 * Mode is still required, the remote control continually validates
 * the routine runner while the user holds down a Guided Mode action
 * on the page. Callbacks that handle this logic will create
 * intervals and store them here, and when the user releases the Guided
 * Mode action, the interval can be cancelled.
 */
const useGuidedMode = ({ isVizbot }: UseGuidedModeArguments) => {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const validationRafRef = useRef<number | null>(null);

  const guidedModeStoppedAt = useRef<number>(Infinity);

  const clearValidationInterval = () => {
    if (validationRafRef.current) {
      cancelAnimationFrame(validationRafRef.current);

      validationRafRef.current = null;
    }
  };

  /**
   * `stopGuidedMode` cancels the interval and sends a stop command.
   * Optional onGuidedModeStop callback for flexibility in adding logic
   */
  const stopGuidedMode = useCallback(
    async (onGuidedModeStop?: () => void) => {
      clearValidationInterval();
      onGuidedModeStop?.();

      if (guidedModeStoppedAt.current === Infinity) {
        guidedModeStoppedAt.current = Date.now();
      }

      if (routineRunnerHandle.getState()?.kind !== 'RoutineRunning') {
        await routineRunnerHandle.stop('Stopped by user');
      }
    },
    [routineRunnerHandle],
  );

  /**
   * Start sending `validateGuidedMode()` to the routine runner on an interval.
   * This is necessary to do during the moveJointRelative and moveToJointSpacePoint
   * methods, as well as when routines are run in Guided Mode.
   *
   * NB `startGuidedMode` is *sync*: we need to avoid any async code before the
   * interval is started, otherwise `stopGuidedMode` could run first, and then the
   * interval will continue indefinitely
   */
  const startGuidedMode = useCallback(
    (onGuidedModeStart?: () => void) => {
      let lastValidated = -Infinity;

      clearValidationInterval();

      const runInterval = () => {
        validationRafRef.current = requestAnimationFrame((requestTime) => {
          const intervalTime = requestTime - lastValidated;

          if (intervalTime >= GUIDED_MODE_INTERVAL_MS) {
            routineRunnerHandle.validateGuidedMode();
            lastValidated = requestTime;
          }

          runInterval();
        });
      };

      runInterval();

      onGuidedModeStart?.();
    },
    [routineRunnerHandle],
  );

  // cleans up interval when component dismounts
  useEffect(() => {
    return () => {
      clearValidationInterval();
    };
  }, []);

  const runAdHocCommand = useCallback(
    async ({
      onComplete,
      onError,
      onGuidedModeStart,
      onGuidedModeStop,
      onRunCommand,
      onSuccess,
    }: OnRunAdHocCommandArguments) => {
      startGuidedMode(onGuidedModeStart);
      const guidedModeStatedAt = Date.now();
      guidedModeStoppedAt.current = Infinity;

      const handleError = async (errorMessage: string) => {
        await routineRunnerHandle.stop(errorMessage);

        if (onError) {
          onError(errorMessage);
        } else {
          log.error(`runAdHocCommand.error`, 'Error running adhoc command', {
            message: errorMessage,
          });
        }
      };

      try {
        if (!isVizbot) {
          // Show the current user as running an ad-command command.
          UserSession.moveRobot(true);
        }

        const commandResult = await onRunCommand();

        /**
         * Some ad-hoc commands don't throw an error when they fail. Instead, they return
         * a string specifying the outcome of the command.
         */
        if (
          commandResult &&
          commandResult !== 'Success' &&
          commandResult !== 'Interrupted'
        ) {
          handleError(`Failure: ${commandResult}`);
        }

        if (commandResult === 'Success' && onSuccess) {
          onSuccess();
        }

        if (
          (commandResult === 'Interrupted' || commandResult === undefined) &&
          guidedModeStoppedAt.current - guidedModeStatedAt < MIN_HOLD_MS
        ) {
          setToast({
            kind: 'top',
            renderAsComponent: true,
            message: (
              <>
                <TapIcon size="small" className="tw-inline-block tw-mr-8" />
                Hold the button to apply changes
              </>
            ),
          });
        }
      } catch (e) {
        handleError(e instanceof Error ? e.message : String(e));
      } finally {
        stopGuidedMode(onGuidedModeStop);

        // wait up to 2 seconds for state to stop being RunningAdHocCommand
        await routineRunnerHandle.waitForState((state) => {
          return (
            state.kind !== 'RunningAdHocCommand' && state.kind !== 'Recovering'
          );
        }, 2000);

        if (onComplete) onComplete();

        if (!isVizbot) {
          // Makes sure this user is no longer marked as "running ad-hoc command".
          UserSession.moveRobot(false);
        }
      }
    },
    [isVizbot, routineRunnerHandle, startGuidedMode, stopGuidedMode, setToast],
  );

  return {
    startGuidedMode,
    stopGuidedMode,
    runAdHocCommand,
  };
};

export default useGuidedMode;
