import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';
import {
  CameraIntrinsics,
  CameraStreamSource,
} from '@sb/integrations/types/cameraTypes';
import { ArmJointPositions } from '@sb/motion-planning';

export const WristCameraAccuracyCalibrationEntry = zod.object({
  jointAngles: ArmJointPositions,
  cameraChessboardTransform: CartesianPose,
  timestamp: zod.string(),
  offset: CartesianPose,
});

export type WristCameraAccuracyCalibrationEntry = zod.infer<
  typeof WristCameraAccuracyCalibrationEntry
>;

const IntrinsicsCalibrationEntry = zod.object({
  wristPose: CartesianPose,
  storageID: zod.string(),
  timestamp: zod.string(),
});

export const WristCameraConfiguration = zod.object({
  kind: zod.literal('WristCamera'),
  name: zod.string().default('Built-in Wrist Camera'),
  accuracyCalibration: WristCameraAccuracyCalibrationEntry.array().default([]),
  isAccuracyCalibrationEnabled: zod.boolean().default(true),
  intrinsics: zod.nullable(CameraIntrinsics).default(null),
  intrinsicsCalibration: IntrinsicsCalibrationEntry.array().default([]),
  intrinsicsWristToCameraTransform: CartesianPose.nullable().default(null),
  isIntrinsicsCalibrationEnabled: zod.boolean().default(true),
  selectedCameraStreamSource: zod.nullable(CameraStreamSource).default(null),
  cameraStreamSources: zod.array(CameraStreamSource).default([]),
});

export type WristCameraConfiguration = zod.infer<
  typeof WristCameraConfiguration
>;

export const WRIST_CAMERA_CONFIGURATION_DEFAULT =
  WristCameraConfiguration.parse({ kind: 'WristCamera' });
