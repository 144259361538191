import styles from './font.module.css';

export default {
  style: {
    normal: styles.styleNormal,
    italic: styles.styleItalic,
  },
  weight: {
    normal: styles.weightNormal,
    semiBold: styles.weightSemiBold,
    bold: styles.weightBold,
  },
};
