import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function CloseIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 12 12">
      <path d="M5.29291431,5.9999585 L0.503594812,10.7892454 L1.21069919,11.4963546 L6.0000235,6.70706288 L10.7893478,11.4963546 L11.4964522,10.7892454 L6.70713269,5.9999585 L11.4964522,1.21067159 L10.7893478,0.503562406 L6.0000235,5.29285412 L1.21069919,0.503562406 L0.503594812,1.21067159 L5.29291431,5.9999585 Z" />
    </Icon>
  );
}
