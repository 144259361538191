/**
 * Frame of reference: Represents the frame of reference - tooltip or robotArm
 */
import * as zod from 'zod';

export const FRAMES_OF_REFERENCE = ['tooltip', 'robotArm'] as const;

export const FrameOfReference = zod.enum(FRAMES_OF_REFERENCE);

export type FrameOfReference = zod.infer<typeof FrameOfReference>;
