import * as zod from 'zod';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationControlBoxIOInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.controlBoxIO),
});

export type IntegrationControlBoxIOInterface = zod.infer<
  typeof IntegrationControlBoxIOInterface
>;

export const INTEGRATION_CONTROL_BOX_IO_INTERFACE_DEFAULT: IntegrationControlBoxIOInterface =
  { kind: 'controlBoxIO' };
