import styles from './margin.module.css';

export default {
  none: styles.marginNone,
  extraSmall: styles.marginExtraSmall,
  small: styles.marginSmall,
  medium: styles.marginMedium,
  large: styles.marginLarge,
  top: {
    none: styles.marginTopNone,
    extraSmall: styles.marginTopExtraSmall,
    small: styles.marginTopSmall,
    medium: styles.marginTopMedium,
    large: styles.marginTopLarge,
  },
  bottom: {
    none: styles.marginBottomNone,
    extraSmall: styles.marginBottomExtraSmall,
    small: styles.marginBottomSmall,
    medium: styles.marginBottomMedium,
    large: styles.marginBottomLarge,
  },
  x: {
    none: styles.marginXNone,
    auto: styles.marginXAuto,
    extraSmall: styles.marginXExtraSmall,
    small: styles.marginXSmall,
    medium: styles.marginXMedium,
    large: styles.marginXLarge,
  },
  y: {
    none: styles.marginYNone,
    extraSmall: styles.marginYExtraSmall,
    small: styles.marginYSmall,
    medium: styles.marginYMedium,
    large: styles.marginYLarge,
  },
  left: {
    none: styles.marginLeftNone,
    auto: styles.marginLeftAuto,
    extraSmall: styles.marginLeftExtraSmall,
    small: styles.marginLeftSmall,
    medium: styles.marginLeftMedium,
    large: styles.marginLeftLarge,
  },
  right: {
    none: styles.marginRightNone,
    auto: styles.marginRightAuto,
    extraSmall: styles.marginRightExtraSmall,
    small: styles.marginRightSmall,
    medium: styles.marginRightMedium,
    large: styles.marginRightLarge,
  },
};
