export const arrowDown = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.7891 23.6139L13.2109 23.6139C13.2108 23.6141 13.2109 23.6139 13.2109 23.6139L13.2093 23.6122L13.2029 23.604L13.1752 23.5688C13.1501 23.5371 13.1121 23.4893 13.0617 23.4266C12.9608 23.3013 12.8105 23.1167 12.6156 22.8836C12.2257 22.4172 11.658 21.7574 10.9516 20.9896C9.53528 19.4501 7.57678 17.4932 5.38606 15.7894L6.61394 14.2106C8.92323 16.0068 10.9647 18.0499 12.4234 19.6354C12.6269 19.8567 12.8194 20.0693 13 20.2715L13 4L15 4L15 20.2715C15.1806 20.0693 15.3731 19.8567 15.5766 19.6354C17.0353 18.0499 19.0768 16.0068 21.3861 14.2106L22.6139 15.7894C20.4232 17.4932 18.4647 19.4501 17.0484 20.9896C16.342 21.7574 15.7743 22.4172 15.3844 22.8836C15.1895 23.1167 15.0392 23.3013 14.9383 23.4266C14.8879 23.4893 14.8499 23.5371 14.8249 23.5688L14.7971 23.604L14.7907 23.6122L14.7891 23.6139C14.7891 23.6139 14.7893 23.6141 14.7891 23.6139Z"
    fill="currentColor"
  />
);

export const arrowUp = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.2109 4.38606L14.7891 4.38606C14.7892 4.38593 14.7891 4.38606 14.7891 4.38606L14.7907 4.3878L14.7971 4.39599L14.8248 4.4312C14.8499 4.46286 14.8879 4.5107 14.9383 4.57336C15.0392 4.6987 15.1895 4.88327 15.3844 5.11639C15.7743 5.58281 16.342 6.24258 17.0484 7.01044C18.4647 8.54989 20.4232 10.5068 22.6139 12.2106L21.3861 13.7894C19.0768 11.9932 17.0353 9.95011 15.5766 8.36456C15.3731 8.14335 15.1806 7.93071 15 7.72852L15 24L13 24L13 7.72852C12.8194 7.93071 12.6269 8.14335 12.4234 8.36456C10.9647 9.95011 8.92322 11.9932 6.61394 13.7894L5.38605 12.2106C7.57677 10.5068 9.53527 8.54989 10.9516 7.01044C11.658 6.24258 12.2257 5.58281 12.6156 5.11639C12.8105 4.88327 12.9608 4.6987 13.0617 4.57336C13.1121 4.5107 13.1501 4.46286 13.1751 4.4312L13.2029 4.39599L13.2093 4.3878L13.2109 4.38606C13.2109 4.38606 13.2107 4.38593 13.2109 4.38606Z"
    fill="currentColor"
  />
);

export const arrowLeft = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.38606 14.7891L4.38606 13.2109C4.38593 13.2108 4.38606 13.2109 4.38606 13.2109L4.3878 13.2093L4.39599 13.2029L4.4312 13.1752C4.46286 13.1501 4.5107 13.1121 4.57336 13.0617C4.69869 12.9608 4.88327 12.8105 5.11639 12.6156C5.58281 12.2257 6.24258 11.658 7.01044 10.9516C8.54989 9.53528 10.5068 7.57678 12.2106 5.38606L13.7894 6.61395C11.9932 8.92323 9.95011 10.9647 8.36456 12.4234C8.14335 12.6269 7.93071 12.8194 7.72852 13L24 13L24 15L7.72852 15C7.93071 15.1806 8.14335 15.3731 8.36455 15.5766C9.95011 17.0353 11.9932 19.0768 13.7893 21.3861L12.2106 22.6139C10.5068 20.4232 8.54989 18.4647 7.01044 17.0484C6.24258 16.342 5.58281 15.7743 5.11639 15.3844C4.88327 15.1895 4.69869 15.0392 4.57336 14.9383C4.5107 14.8879 4.46286 14.8499 4.43119 14.8249L4.39599 14.7971L4.3878 14.7907L4.38606 14.7891C4.38606 14.7891 4.38593 14.7893 4.38606 14.7891Z"
    fill="currentColor"
  />
);

export const arrowRight = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M23.6139 13.2109L23.6139 14.7891C23.6141 14.7892 23.6139 14.7891 23.6139 14.7891L23.6122 14.7907L23.604 14.7971L23.5688 14.8248C23.5371 14.8499 23.4893 14.8879 23.4266 14.9383C23.3013 15.0392 23.1167 15.1895 22.8836 15.3844C22.4172 15.7743 21.7574 16.342 20.9896 17.0484C19.4501 18.4647 17.4932 20.4232 15.7894 22.6139L14.2106 21.3861C16.0068 19.0768 18.0499 17.0353 19.6354 15.5766C19.8567 15.3731 20.0693 15.1806 20.2715 15L4 15L4 13L20.2715 13C20.0693 12.8194 19.8567 12.6269 19.6354 12.4234C18.0499 10.9647 16.0068 8.92322 14.2106 6.61394L15.7894 5.38606C17.4932 7.57677 19.4501 9.53527 20.9896 10.9516C21.7574 11.658 22.4172 12.2257 22.8836 12.6156C23.1167 12.8105 23.3013 12.9608 23.4266 13.0617C23.4893 13.1121 23.5371 13.1501 23.5688 13.1751L23.604 13.2029L23.6122 13.2093L23.6139 13.2109C23.6139 13.2109 23.6141 13.2107 23.6139 13.2109Z"
    fill="currentColor"
  />
);

export const arrowUturnDown = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.8132 24.0813L10.1868 24.0813C10.1864 24.0815 10.1865 24.0815 10.1865 24.0815L10.1863 24.0813L10.1862 24.0811L10.1825 24.076L10.1642 24.0509C10.1472 24.0277 10.1207 23.9918 10.0847 23.944C10.0128 23.8484 9.90307 23.7052 9.75665 23.5212C9.46373 23.1532 9.0242 22.6225 8.44597 21.9834C7.28856 20.7042 5.58075 18.9963 3.38606 17.2894L4.61394 15.7107C6.91926 17.5037 8.71146 19.2958 9.92904 20.6416C9.95292 20.668 9.97657 20.6942 10 20.7203L10 10.5C10 6.91016 12.9102 4.00002 16.5 4.00002C20.0899 4.00002 23 6.91016 23 10.5L23 12L21 12L21 10.5C21 8.01473 18.9853 6.00002 16.5 6.00002C14.0147 6.00002 12 8.01473 12 10.5L12 20.7202C12.0234 20.6942 12.0471 20.668 12.071 20.6416C13.2886 19.2958 15.0807 17.5037 17.3861 15.7107L18.6139 17.2894C16.4193 18.9963 14.7115 20.7042 13.554 21.9834C12.9758 22.6225 12.5363 23.1532 12.2434 23.5212C12.0969 23.7052 11.9873 23.8484 11.9153 23.944C11.8793 23.9918 11.8528 24.0277 11.8358 24.0509L11.8176 24.076L11.8138 24.0811L11.8137 24.0813L11.8136 24.0815C11.8136 24.0815 11.8136 24.0815 11.8132 24.0813Z"
    fill="currentColor"
  />
);
export const arrowUturnUp = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.1868 4.41875L17.8132 4.41875C17.8136 4.4185 17.8136 4.4185 17.8136 4.41849L17.8137 4.41875L17.8138 4.41888L17.8176 4.42404L17.8358 4.44911C17.8528 4.47227 17.8793 4.50818 17.9153 4.556C17.9873 4.65163 18.0969 4.79483 18.2434 4.9788C18.5363 5.34683 18.9758 5.87747 19.554 6.51657C20.7115 7.79582 22.4193 9.50366 24.614 11.2106L23.3861 12.7893C21.0808 10.9963 19.2886 9.20415 18.071 7.8584C18.0471 7.83201 18.0234 7.80579 18 7.77975L18 18C18 21.5898 15.0899 24.5 11.5 24.5C7.91015 24.5 5.00001 21.5898 5 18L5 16.5L7 16.5L7 18C7.00001 20.4853 9.01472 22.5 11.5 22.5C13.9853 22.5 16 20.4853 16 18L16 7.77975C15.9766 7.8058 15.9529 7.83201 15.929 7.8584C14.7115 9.20415 12.9193 10.9963 10.614 12.7893L9.38607 11.2106C11.5808 9.50366 13.2886 7.79582 14.446 6.51657C15.0242 5.87747 15.4637 5.34683 15.7566 4.9788C15.9031 4.79483 16.0128 4.65164 16.0847 4.556C16.1207 4.50818 16.1472 4.47227 16.1642 4.44911L16.1825 4.42404L16.1862 4.41888L16.1863 4.41875L16.1865 4.41849C16.1865 4.4185 16.1864 4.41851 16.1868 4.41875Z"
    fill="currentColor"
  />
);
export const arrowUturnLeft = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.91876 11.8132L3.91876 10.1868C3.91852 10.1864 3.91851 10.1864 3.9185 10.1864L3.91876 10.1863L3.9189 10.1862L3.92405 10.1824L3.94912 10.1642C3.97228 10.1472 4.0082 10.1207 4.05601 10.0847C4.15165 10.0127 4.29485 9.90306 4.47882 9.75663C4.84685 9.46371 5.37749 9.02419 6.01659 8.44596C7.29584 7.28854 9.00367 5.58073 10.7106 3.38605L12.2894 4.61393C10.4963 6.91925 8.70417 8.71144 7.35841 9.92903C7.33203 9.9529 7.30581 9.97656 7.27976 9.99999L17.5 9.99999C21.0899 10 24 12.9101 24 16.5C24 20.0898 21.0899 23 17.5 23L16 23L16 21L17.5 21C19.9853 21 22 18.9853 22 16.5C22 14.0147 19.9853 12 17.5 12L7.27977 12C7.30581 12.0234 7.33203 12.0471 7.35841 12.071C8.70417 13.2885 10.4963 15.0807 12.2894 17.386L10.7107 18.6139C9.00367 16.4192 7.29584 14.7114 6.01659 13.554C5.37749 12.9758 4.84685 12.5363 4.47882 12.2434C4.29485 12.0969 4.15165 11.9872 4.05601 11.9153C4.0082 11.8793 3.97228 11.8528 3.94912 11.8358L3.92405 11.8175L3.9189 11.8138L3.91876 11.8137L3.9185 11.8135C3.91851 11.8135 3.91852 11.8136 3.91876 11.8132Z"
    fill="currentColor"
  />
);
export const arrowUturnRight = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24.0812 11.8132L24.0812 10.1868C24.0815 10.1864 24.0815 10.1864 24.0815 10.1864L24.0812 10.1863L24.0811 10.1862L24.0759 10.1824L24.0509 10.1642C24.0277 10.1472 23.9918 10.1207 23.944 10.0847C23.8484 10.0127 23.7052 9.90306 23.5212 9.75663C23.1532 9.46371 22.6225 9.02419 21.9834 8.44596C20.7042 7.28854 18.9963 5.58073 17.2894 3.38605L15.7106 4.61393C17.5037 6.91925 19.2958 8.71144 20.6416 9.92903C20.668 9.9529 20.6942 9.97656 20.7202 9.99999L10.5 9.99999C6.91015 10 4 12.9101 4 16.5C4 20.0898 6.91015 23 10.5 23L12 23L12 21L10.5 21C8.01472 21 6 18.9853 6 16.5C6 14.0147 8.01472 12 10.5 12L20.7202 12C20.6942 12.0234 20.668 12.0471 20.6416 12.071C19.2958 13.2885 17.5037 15.0807 15.7106 17.386L17.2893 18.6139C18.9963 16.4192 20.7042 14.7114 21.9834 13.554C22.6225 12.9758 23.1532 12.5363 23.5212 12.2434C23.7052 12.0969 23.8483 11.9872 23.944 11.9153C23.9918 11.8793 24.0277 11.8528 24.0509 11.8358L24.0759 11.8175L24.0811 11.8138L24.0812 11.8137L24.0815 11.8135C24.0815 11.8135 24.0815 11.8136 24.0812 11.8132Z"
    fill="currentColor"
  />
);

export const arrowSquarePath = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.3859 8.03923L6.38584 8.03918L6.38581 8.03917L6 7.73909V6.76092L6.38581 6.46084L6.38584 6.46082L6.3859 6.46077L6.38606 6.46065L6.38668 6.46017L6.39152 6.45638L6.41353 6.43904C6.43358 6.42319 6.4642 6.39884 6.50454 6.36639C6.58523 6.30148 6.70468 6.20421 6.85589 6.07779C7.15849 5.82479 7.5873 5.45586 8.08662 4.99649C9.08896 4.07434 10.3583 2.80334 11.4606 1.38606L13.0394 2.61394C11.9324 4.03719 10.6871 5.30427 9.6761 6.25H18.75C20.4069 6.25 21.75 7.59315 21.75 9.25V15L19.75 13L19.75 9.25C19.75 8.69772 19.3023 8.25 18.75 8.25H9.6761C10.6871 9.19573 11.9324 10.4628 13.0394 11.8861L11.4606 13.1139C10.3583 11.6967 9.08896 10.4257 8.08662 9.50351C7.5873 9.04415 7.15849 8.67521 6.85589 8.42222C6.70468 8.2958 6.58523 8.19853 6.50454 8.13362C6.4642 8.10116 6.43358 8.07681 6.41353 8.06097L6.39152 8.04363L6.38668 8.03984L6.38606 8.03936L6.3859 8.03923ZM21.6141 19.9608L21.6142 19.9608L21.6142 19.9608L22 20.2609V21.2391L21.6142 21.5392L21.6142 21.5392L21.6141 21.5392L21.6139 21.5394L21.6133 21.5398L21.6085 21.5436L21.5865 21.561C21.5664 21.5768 21.5358 21.6012 21.4955 21.6336C21.4148 21.6985 21.2953 21.7958 21.1441 21.9222C20.8415 22.1752 20.4127 22.5441 19.9134 23.0035C18.911 23.9257 17.6417 25.1967 16.5394 26.6139L14.9606 25.3861C16.0676 23.9628 17.3129 22.6957 18.3239 21.75H9.25C7.59314 21.75 6.25 20.4069 6.25 18.75L6.25 13L8.25 15L8.25 18.75C8.25 19.3023 8.69771 19.75 9.25 19.75H18.3239C17.3129 18.8043 16.0676 17.5372 14.9606 16.1139L16.5393 14.8861C17.6417 16.3033 18.911 17.5743 19.9134 18.4965C20.4127 18.9559 20.8415 19.3248 21.1441 19.5778C21.2953 19.7042 21.4148 19.8015 21.4955 19.8664C21.5358 19.8988 21.5664 19.9232 21.5865 19.939L21.6085 19.9564L21.6133 19.9602L21.6139 19.9606L21.6141 19.9608Z"
    fill="currentColor"
  />
);

export const arrowClockwise = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.1139 5.71093V7.28905L19.1141 7.28922L19.1139 7.28934L19.1133 7.28982L19.1085 7.29361L19.0865 7.31095C19.0664 7.3268 19.0358 7.35115 18.9955 7.3836C18.9148 7.44852 18.7953 7.54578 18.6441 7.67221C18.3415 7.9252 17.9127 8.29413 17.4134 8.7535C16.411 9.67565 15.1417 10.9466 14.0394 12.3639L12.4606 11.136C13.5676 9.71281 14.8129 8.44572 15.8239 7.49999H14C9.85786 7.49999 6.5 10.8579 6.5 15C6.5 19.1421 9.85786 22.5 14 22.5C18.1421 22.5 21.5 19.1421 21.5 15H23.5C23.5 20.2467 19.2467 24.5 14 24.5C8.75329 24.5 4.5 20.2467 4.5 15C4.5 9.75328 8.75329 5.49999 14 5.49999H15.8239C14.8129 4.55426 13.5676 3.28717 12.4606 1.86393L14.0394 0.636047C15.1417 2.05333 16.411 3.32433 17.4134 4.24648C17.9127 4.70585 18.3415 5.07478 18.6441 5.32777C18.7953 5.45419 18.9148 5.55146 18.9955 5.61638C19.0358 5.64883 19.0664 5.67318 19.0865 5.68902L19.1085 5.70636L19.1133 5.71015L19.1139 5.71064L19.1141 5.71076L19.1139 5.71093Z"
    fill="currentColor"
  />
);

export const arrowAnticlockwise = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.88606 5.71093V7.28905L8.8859 7.28922L8.88606 7.28934L8.88668 7.28982L8.89152 7.29361L8.91353 7.31095C8.93358 7.3268 8.9642 7.35115 9.00454 7.3836C9.08523 7.44852 9.20468 7.54578 9.35589 7.67221C9.65849 7.9252 10.0873 8.29413 10.5866 8.7535C11.589 9.67565 12.8583 10.9466 13.9606 12.3639L15.5394 11.136C14.4324 9.71281 13.1871 8.44572 12.1761 7.49999H14C18.1421 7.49999 21.5 10.8579 21.5 15C21.5 19.1421 18.1421 22.5 14 22.5C9.85786 22.5 6.5 19.1421 6.5 15H4.5C4.5 20.2467 8.75329 24.5 14 24.5C19.2467 24.5 23.5 20.2467 23.5 15C23.5 9.75328 19.2467 5.49999 14 5.49999H12.1761C13.1871 4.55426 14.4324 3.28717 15.5394 1.86393L13.9606 0.636047C12.8583 2.05333 11.589 3.32433 10.5866 4.24648C10.0873 4.70585 9.65849 5.07478 9.35589 5.32777C9.20468 5.45419 9.08523 5.55146 9.00454 5.61638C8.9642 5.64883 8.93358 5.67318 8.91353 5.68902L8.89152 5.70636L8.88668 5.71015L8.88606 5.71064L8.8859 5.71076L8.88606 5.71093Z"
    fill="currentColor"
  />
);

export const arrowCirclePath = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.17165 8.52809C8.77528 6.52951 11.2382 5.25 14 5.25C18.4943 5.25 22.1972 8.63843 22.6935 13H20.75L20.334 13.7774L23.3347 18.2774L24.1667 18.2773L27.1661 13.7773L26.75 13H24.7041C24.1997 7.53178 19.5999 3.25 14 3.25C10.833 3.25 7.98581 4.61953 6.01844 6.79865L7.17165 8.52809ZM3.29588 15L1.24998 15L0.833923 14.2227L3.83327 9.72269L4.66532 9.72261L7.66597 14.2226L7.24998 15H5.3065C5.80273 19.3616 9.50565 22.75 14 22.75C16.7618 22.75 19.2247 21.4705 20.8283 19.4719L21.9815 21.2013C20.0142 23.3805 17.167 24.75 14 24.75C8.40011 24.75 3.8003 20.4682 3.29588 15Z"
    fill="currentColor"
  />
);

export const arrowDownFill = (
  <path
    d="M22.5 13.4961L23.2734 15.13L14.7734 25.5H13.2266L4.72662 15.13L5.50002 13.4961L10 13.4961L10 3.49609L11 2.49609L17 2.49609L18 3.49609L18 13.4961H22.5Z"
    fill="currentColor"
  />
);

export const arrowUpFill = (
  <path
    d="M5.50002 14.5L4.72662 12.8661L13.2266 2.49609H14.7734L23.2734 12.8661L22.5 14.5L18 14.5V24.5L17 25.5H11L10 24.5V14.5H5.50002Z"
    fill="currentColor"
  />
);

export const arrowLeftFill = (
  <path
    d="M14.5039 22.5L12.87 23.2734L2.5 14.7734V13.2266L12.87 4.72662L14.5039 5.50002L14.5039 10H24.5039L25.5039 11V17L24.5039 18H14.5039V22.5Z"
    fill="currentColor"
  />
);

export const arrowRightFill = (
  <path
    d="M13.5 5.50002L15.1339 4.72662L25.5039 13.2266V14.7734L15.1339 23.2734L13.5 22.5L13.5 18H3.5L2.5 17V11L3.5 10H13.5V5.50002Z"
    fill="currentColor"
  />
);

export const arrowUturnDownLeftFill = (
  <path
    d="M20.2768 15.13L19.5034 13.4961L15.0034 13.4961V11.5L15 11.5C15 9.567 16.567 8 18.5 8C20.433 8 22 9.567 22 11.5H26.0034C26.0034 6.25329 21.7501 2 16.5034 2C11.2567 2 7.00337 6.25329 7.00337 11.5V13.4961L2.50339 13.4961L1.73 15.13L10.23 25.5H11.7768L20.2768 15.13Z"
    fill="currentColor"
  />
);

export const arrowUturnDownRightFill = (
  <path
    d="M7.72324 15.13L8.49663 13.4961L12.9966 13.4961V11.5L13 11.5C13 9.567 11.433 8 9.50002 8C7.56702 8 6.00002 9.567 6.00002 11.5H1.99664C1.99664 6.25329 6.24994 2 11.4966 2C16.7433 2 20.9966 6.25329 20.9966 11.5V13.4961L25.4966 13.4961L26.27 15.13L17.77 25.5H16.2232L7.72324 15.13Z"
    fill="currentColor"
  />
);

export const arrowUpLine = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.7891 6H22.5V4H5.5V6H13.2109L13.2093 6.00175L13.2029 6.00994L13.1751 6.04514C13.1501 6.07681 13.1121 6.12464 13.0617 6.1873C12.9608 6.31264 12.8105 6.49721 12.6156 6.73033C12.2257 7.19675 11.658 7.85652 10.9516 8.62438C9.53527 10.1638 7.57677 12.1207 5.38605 13.8246L6.61394 15.4033C8.92322 13.6072 10.9647 11.564 12.4234 9.9785C12.6269 9.75729 12.8194 9.54465 13 9.34246L13 24.9999H15V9.34246C15.1806 9.54465 15.3731 9.75729 15.5766 9.9785C17.0353 11.564 19.0768 13.6072 21.3861 15.4033L22.6139 13.8246C20.4232 12.1207 18.4647 10.1638 17.0484 8.62438C16.342 7.85652 15.7743 7.19675 15.3844 6.73033C15.1895 6.49721 15.0392 6.31264 14.9383 6.1873C14.8879 6.12464 14.8499 6.07681 14.8248 6.04514L14.7971 6.00994L14.7907 6.00175L14.7891 6Z"
    fill="currentColor"
  />
);

export const arrowDownLine = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.2109 21.9999L5.49999 21.9999V23.9999L22.5 23.9999V21.9999H14.7891L14.7907 21.9982L14.7971 21.99L14.8248 21.9548C14.8499 21.9231 14.8879 21.8753 14.9383 21.8126C15.0392 21.6873 15.1895 21.5027 15.3844 21.2696C15.7743 20.8032 16.342 20.1434 17.0484 19.3756C18.4647 17.8361 20.4232 15.8792 22.6139 14.1754L21.3861 12.5966C19.0768 14.3928 17.0353 16.4359 15.5766 18.0214C15.3731 18.2427 15.1806 18.4553 15 18.6575L15 3H13L13 18.6575C12.8194 18.4553 12.6269 18.2427 12.4234 18.0214C10.9647 16.4359 8.92322 14.3928 6.61394 12.5966L5.38605 14.1754C7.57677 15.8792 9.53527 17.8361 10.9516 19.3756C11.658 20.1434 12.2257 20.8032 12.6156 21.2696C12.8105 21.5027 12.9608 21.6873 13.0617 21.8126C13.1121 21.8753 13.1501 21.9231 13.1751 21.9548L13.2029 21.99L13.2093 21.9982L13.2109 21.9999Z"
    fill="currentColor"
  />
);

export const arrowCirclePathAutomatic = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 5.25C11.2382 5.25 8.77528 6.52951 7.17165 8.52809L6.01844 6.79865C7.98581 4.61953 10.833 3.25 14 3.25C19.5999 3.25 24.1997 7.53178 24.7041 13H26.75L27.1661 13.7773L24.1667 18.2773L23.3347 18.2774L20.334 13.7774L20.75 13H22.6935C22.1972 8.63843 18.4943 5.25 14 5.25ZM1.24998 15L3.29588 15C3.8003 20.4682 8.40011 24.75 14 24.75C17.167 24.75 20.0142 23.3805 21.9815 21.2013L20.8283 19.4719C19.2247 21.4705 16.7618 22.75 14 22.75C9.50565 22.75 5.80273 19.3616 5.3065 15H7.24998L7.66597 14.2226L4.66532 9.72261L3.83327 9.72269L0.833923 14.2227L1.24998 15ZM12.8036 8.72146L13.5 8.25H14.5L15.1964 8.72146L17.3964 14.2215L18.9078 18H17.2922L16.1922 15.25H11.8078L10.7078 18H9.09222L10.6036 14.2215L12.8036 8.72146ZM15.5922 13.75L14 9.76944L12.4078 13.75H15.5922Z"
    fill="currentColor"
  />
);
