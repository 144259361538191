import * as zod from 'zod';

export const MotionPlanCacheData = zod.object({
  id: zod.string(),
  cacheKey: zod.string(),
  motionPlan: zod.string(),
  lastGetAt: zod.string(),
  createdAt: zod.string(),
  updatedAt: zod.string(),
});

export type MotionPlanCacheData = zod.infer<typeof MotionPlanCacheData>;
