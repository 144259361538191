import type { Formatter } from '../types';

import { jsonFormat } from './helpers/jsonFormat';
import { getUid } from './helpers/uid';

export const formatter: Formatter = (
  severity: string,
  key: string,
  message: string,
  context: any,
) =>
  jsonFormat({
    severity,
    message,
    timestamp: new Date().toISOString(),
    key,
    uid: getUid(),
    context,
  });
