import * as zod from 'zod';

import { SpeedProfile } from '../speed-profile';

export const PlayRoutineArgs = zod.object({
  /**
   * Run the first arm movement in guided mode, then switch guided mode off
   */
  firstArmMoveIsGuidedMode: zod.boolean().optional(),
  /**
   * Will pause after running main loop through once
   */
  isPreflightTestRun: zod.boolean().optional(),
  /**
   * Whether this routine should run in guided mode, where failing to call
   * validateGuidedMode often enough will stop the routine.
   */
  guidedMode: zod.boolean().optional(),
  speedProfile: SpeedProfile,
  /**
   * Should the routine go into pause state when completed, or return to Idle
   */
  shouldPauseWhenCompleted: zod.boolean().optional(),
});

export type PlayRoutineArgs = zod.infer<typeof PlayRoutineArgs>;
