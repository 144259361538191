import * as zod from 'zod';

export const EwellixLiftTLTState = zod.object({
  kind: zod.literal('EwellixLiftTLT'),
  heightMeters: zod.number(),
  isMoving: zod.boolean(),
  minHeightMeters: zod.number(),
  maxHeightMeters: zod.number(),
});

export type EwellixLiftTLTState = zod.infer<typeof EwellixLiftTLTState>;
