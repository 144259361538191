import auth from '@feathersjs/authentication-client';
import type { Application } from '@feathersjs/feathers';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import rx from 'feathers-reactive';
import io from 'socket.io-client';

import { API_ENDPOINT, globalCache } from '@sbrc/utils';

import { completeOnNotAuthenticated, retryOnTimeout } from './rxOperators';
import type { Services } from './Services';

export function getApp() {
  return globalCache('feathersApp', () => {
    const socket = io(API_ENDPOINT, {
      path: '/api',
      transports: ['websocket'],
    });

    const app = feathers<Services>();

    app.configure(socketio(socket));
    app.configure(auth());

    app.configure(
      rx({
        idField: 'id',
        pipe: [completeOnNotAuthenticated(), retryOnTimeout()],
      }),
    );

    return app as Omit<Application<Services>, 'io'> & {
      io: SocketIOClient.Socket;
    };
  });
}
