import * as zod from 'zod';

import { IntegrationActionInterface } from './IntegrationActionInterface';
import { IntegrationActionKind } from './IntegrationActionKind';

export const IntegrationAction = zod.object({
  kind: IntegrationActionKind,
  name: zod.string(),
  interface: IntegrationActionInterface,
});

export type IntegrationAction = zod.infer<typeof IntegrationAction>;
