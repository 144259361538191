import * as zod from 'zod';

import { DeviceCommand } from '@sb/integrations/types';

import { PayloadState } from './PayloadState';

export const ActuateDeviceArgs = zod.object({
  command: DeviceCommand,
  payload: PayloadState.optional(),
  retry: zod.boolean().optional(),
});

export type ActuateDeviceArgs = zod.infer<typeof ActuateDeviceArgs>;
