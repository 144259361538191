/**
 * Relative cartesian motion plan request:
 * The payload for a request to the motion planner for a motion that
 * moves in a relative cartesian direction.
 *
 * The offset is the "vector" in Cartesian space to compute a path towards.
 *
 * The longest path found is returned.
 *
 * The offset can be in the tooltip frame or the in the robot base frame.
 */
import type * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

import { ArmJointPositions } from './ArmJointPositions';
import { FrameOfReference } from './FrameOfReference';
import { GripperOpenness } from './GripperOpenness';
import { MotionPlanOptions } from './MotionPlanOptions';

export const RelativeCartesianMotionPlanRequest = MotionPlanOptions.extend({
  gripperOpenness: GripperOpenness,
  offset: CartesianPose,
  frame: FrameOfReference,
  // where the line should actually start
  lineStartPosition: ArmJointPositions,
  // where the motion should start (not necessarily on the line)
  startingJointPositions: ArmJointPositions,
});
export type RelativeCartesianMotionPlanRequest = zod.infer<
  typeof RelativeCartesianMotionPlanRequest
>;
