import cx from 'classnames';
import { forwardRef } from 'react';

export interface SliderProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  ({ className, ...rest }, ref) => {
    return (
      <input
        type="range"
        className={cx(
          className,
          'tw-block',
          'tw-bg-transparent',
          'tw-appearance-none',
          'tw-rounded-full',
          'focus-visible:tw-outline',
          '[&::-webkit-slider-runnable-track]:tw-bg-fill-secondary',
          '[&::-webkit-slider-runnable-track]:tw-rounded-full',
          '[&::-webkit-slider-runnable-track]:tw-overflow-hidden',
          '[&::-webkit-slider-runnable-track]:tw-h-[32px]',
          '[&::-webkit-slider-runnable-track]:tw-p-[2.5px]',
          '[&::-webkit-slider-thumb]:tw-appearance-none',
          '[&::-webkit-slider-thumb]:tw-rounded-full',
          '[&::-webkit-slider-thumb]:tw-h-[27px]',
          '[&::-webkit-slider-thumb]:tw-w-[27px]',
          '[&::-webkit-slider-thumb]:tw-bg-white',
          // this adds a subtle shadow on the thumb, plus a 2.5px outline, plus the shaded area to the left of it
          '[&::-webkit-slider-thumb]:[box-shadow:theme(boxShadow.i1),0_0_0_2.5px_var(--tw-shadow-color),-1014px_0_0_1000px_var(--tw-shadow-color)]',
          '[&::-webkit-slider-thumb]:tw-shadow-metal-50',
          '[&::-webkit-slider-thumb]:dark:tw-shadow-metal-70',
          'tw-cursor-pointer',
          'disabled:tw-cursor-not-allowed',
          'disabled:tw-opacity-40',
        )}
        {...rest}
        ref={ref}
      />
    );
  },
);
