/* eslint-disable max-classes-per-file */
import * as axiosAPI from './codegen/typescript-axios';

type StandardBotsAPIConfig = {
  url: string;
  token?: string;
  robotKind?: 'live' | 'simulated';
};

class BrakesAPI extends axiosAPI.MovementBrakesApi {
  public brake() {
    return this.setBrakesState({ state: 'engaged' });
  }

  public unbrake() {
    return this.setBrakesState({ state: 'disengaged' });
  }

  public estop(reason: string) {
    return this.engageEmergencyStop({ reason });
  }
}

class RoutinesVariablesAPI extends axiosAPI.RoutineEditorVariablesApi {}

class RoutinesAPI {
  public constructor(private config: axiosAPI.Configuration) {}

  public get routines() {
    return new axiosAPI.RoutineEditorRoutinesApi(this.config);
  }

  public get variables() {
    return new RoutinesVariablesAPI(this.config);
  }
}

class ChatGPTAPI extends axiosAPI.ChatGPTDataApi {}

class TeleopAPI {
  private teleoperationControlApi: axiosAPI.TeleoperationControlApi;

  private teleoperationStatusApi: axiosAPI.TeleoperationStatusApi;

  public constructor(config: axiosAPI.Configuration) {
    this.teleoperationControlApi = new axiosAPI.TeleoperationControlApi(config);
    this.teleoperationStatusApi = new axiosAPI.TeleoperationStatusApi(config);
  }

  // Method to start teleoperation
  public start() {
    const request: axiosAPI.TeleopStateUpdateRequest = { action: 'start' };

    return this.teleoperationControlApi.updateTeleopState(request);
  }

  // Method to stop teleoperation
  public stop() {
    const request: axiosAPI.TeleopStateUpdateRequest = { action: 'stop' };

    return this.teleoperationControlApi.updateTeleopState(request);
  }

  // Method to get the current state of teleoperation
  public getState() {
    return this.teleoperationStatusApi.getTeleopState();
  }
}

export default TeleopAPI;

export class StandardBotsSDK {
  public constructor(private readonly config: StandardBotsAPIConfig) {}

  private getAxiosConfig(): axiosAPI.Configuration {
    return {
      basePath: this.config.url,
      apiKey: this.config.token,
      isJsonMime: () => true,
      baseOptions: {
        headers: {
          robot_kind: this.config.robotKind ?? 'simulated',
        },
      },
    };
  }

  public get brakes(): BrakesAPI {
    return new BrakesAPI(this.getAxiosConfig());
  }

  public get routines(): RoutinesAPI {
    return new RoutinesAPI(this.getAxiosConfig());
  }

  public get teleop(): TeleopAPI {
    return new TeleopAPI(this.getAxiosConfig());
  }

  public get chatgpt(): ChatGPTAPI {
    return new ChatGPTAPI(this.getAxiosConfig());
  }
}
