import type { NetworkRequestResponseData } from '../../../types/NetworkDeviceInterface';
import type { ModbusTCPRequestFunctionCode } from '../types';

import type { NetworkRequestCommandResponse } from './CommandResponse';

export function getCommandSuccessResponse(
  request: ModbusTCPRequestFunctionCode,
  responseData?: NetworkRequestResponseData,
): NetworkRequestCommandResponse {
  return { requestCode: request, responseKind: 'success', responseData };
}

export function getCommandFailureResponse(
  request: ModbusTCPRequestFunctionCode,
  errorMessage: string,
): NetworkRequestCommandResponse {
  return { requestCode: request, responseKind: 'failure', errorMessage };
}
