import cx from 'classnames';

import styles from './HelperText.module.css';

interface HelperTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  hasError?: boolean;
  helperText?: string;
}

const HelperText = ({
  hasError,
  helperText,
  ...spanProps
}: HelperTextProps) => {
  if (!helperText) return null;

  return (
    <span
      {...spanProps}
      className={cx(styles.helperText, spanProps.className, {
        [styles.hasError]: hasError,
      })}
    >
      {helperText}
    </span>
  );
};

export default HelperText;
