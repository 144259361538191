export const flag = (
  <path
    d="M6.5 25V17M6.5 17V5.99999C6.5 5.99999 7.77537 4.99996 10.5 5C13 5.00003 15.5 6.99997 18 7C20.5 7.00003 22.5 5.99999 22.5 5.99999V17C22.5 17 20.5 18 18 18C15.5 18 13 16 10.5 16C8 16 6.5 17 6.5 17Z"
    fill="none"
    stroke="currentColor"
    strokeOpacity="0.9"
    strokeWidth="2"
    strokeLinejoin="bevel"
  />
);
