import * as zod from 'zod';

const COMMAND_KIND_DEFAULT = 'IDLE';
const SUCTION_PERCENTAGE_DEFAULT = 0;

export const OnRobotVGP20CommandKind = zod.enum(['GRIP', 'RELEASE', 'IDLE']);

export type OnRobotVGP20CommandKind = zod.infer<typeof OnRobotVGP20CommandKind>;

export const OnRobotVGP20ChannelCommand = zod.object({
  commandKind: OnRobotVGP20CommandKind,
  suctionPercentage: zod.number().min(0).max(1),
});

export type OnRobotVGP20ChannelCommand = zod.infer<
  typeof OnRobotVGP20ChannelCommand
>;

export const OnRobotVGP20Command = zod.object({
  kind: zod.literal('OnRobotVGP20Command'),

  suctionActions: zod.object({
    one: OnRobotVGP20ChannelCommand,
    two: OnRobotVGP20ChannelCommand,
    three: OnRobotVGP20ChannelCommand,
    four: OnRobotVGP20ChannelCommand,
  }),
});

export type OnRobotVGP20Command = zod.infer<typeof OnRobotVGP20Command>;

export const getORVGP20Default = (
  command?: OnRobotVGP20Command,
): OnRobotVGP20Command => {
  return {
    kind: 'OnRobotVGP20Command',
    suctionActions: {
      one: {
        commandKind:
          command?.suctionActions.one.commandKind ?? COMMAND_KIND_DEFAULT,
        suctionPercentage:
          command?.suctionActions.one.suctionPercentage ??
          SUCTION_PERCENTAGE_DEFAULT,
      },
      two: {
        commandKind:
          command?.suctionActions.two.commandKind ?? COMMAND_KIND_DEFAULT,
        suctionPercentage:
          command?.suctionActions.two.suctionPercentage ??
          SUCTION_PERCENTAGE_DEFAULT,
      },
      three: {
        commandKind:
          command?.suctionActions.three.commandKind ?? COMMAND_KIND_DEFAULT,
        suctionPercentage:
          command?.suctionActions.three.suctionPercentage ??
          SUCTION_PERCENTAGE_DEFAULT,
      },
      four: {
        commandKind:
          command?.suctionActions.four.commandKind ?? COMMAND_KIND_DEFAULT,
        suctionPercentage:
          command?.suctionActions.four.suctionPercentage ??
          SUCTION_PERCENTAGE_DEFAULT,
      },
    },
  };
};
