import * as zod from 'zod';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationHaasNGCInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.haasNGC),
  hostname: zod.string().default(''),
  port: zod.number().default(5051),
});

export type IntegrationHaasNGCInterface = zod.infer<
  typeof IntegrationHaasNGCInterface
>;

export const INTEGRATION_HAAS_NGC_INTERFACE_DEFAULT: IntegrationHaasNGCInterface =
  { kind: 'haasNGC', hostname: '', port: 5051 };

export function isIntegrationHaasNGCInterface(
  i: any,
): i is IntegrationHaasNGCInterface {
  return i != null && i.kind === 'haasNGC';
}
