import * as zod from 'zod';

export const OnRobotScrewdriverState = zod.object({
  kind: zod.literal('OnRobotScrewdriver'),
  error: zod.string().optional(),

  isConnected: zod.boolean(),
  isBusy: zod.boolean(),

  status: zod.number(),
  additionalResults: zod.number(),

  currentTorque: zod.number(),
  shankPosition: zod.number(),
  torqueAngleGradient: zod.number(),
  achievedTorque: zod.number(),
  targetForce: zod.number(),
  targetTorque: zod.number(),

  quickChangerVersion: zod.number().optional(),

  uncalibratedError: zod.boolean(),
});

export type OnRobotScrewdriverState = zod.infer<typeof OnRobotScrewdriverState>;
