import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

interface TableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {}

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  (props, ref) => {
    return <tbody ref={ref} {...props} />;
  },
);

export default TableBody;
