import * as zod from 'zod';

export const CNCMachineState = zod.object({
  kind: zod.literal('CNCMachine'),
  isConnected: zod.boolean(),
  isCellSafeSupported: zod.boolean(),
  isCellSafe: zod.boolean(),
  runningProgram: zod.string().nullable(),
  status: zod.enum(['idle', 'running', 'fault', 'unknown']),
});

export type CNCMachineState = zod.infer<typeof CNCMachineState>;
