import cx from 'classnames';

export type NavigationListItemVariant =
  | 'Flat'
  | 'Elevated'
  | 'FlatPrimary'
  | 'ElevatedPrimary';

export const NAVIGATION_LIST_ITEM_VARIANT_CLASSES: Record<
  NavigationListItemVariant,
  string
> = {
  Flat: cx(
    'tw-border-b',
    // the border is inset from the edge of the element by 16px
    '[border-image:linear-gradient(90deg,transparent_16px,var(--divider-primary)_16px_calc(100%_-_16px),transparent_calc(100%_-_16px))_1_0]',
    // hide the border if followed by an elevated item
    '[&:has(+.tw-surface-elevated)]:tw-border-none',
  ),
  Elevated: cx(
    'tw-surface-elevated',
    'tw-shadow-30',
    'tw-rounded-10',
    'aria-disabled:tw-shadow-none',
    'aria-disabled:tw-bg-fill-primary',
  ),

  // list item with hover surface-primary
  FlatPrimary: cx(
    'tw-border-b',
    // the border is inset from the edge of the element by 16px
    '[border-image:linear-gradient(90deg,transparent_16px,var(--divider-primary)_16px_calc(100%_-_16px),transparent_calc(100%_-_16px))_1_0]',
    // hide the border if followed by an elevated item
    '[&:has(+.tw-surface-primary)]:tw-border-none',
    'hover:tw-surface-primary',
    'hover:tw-rounded-10',
    'hover:tw-border-none',
    // to remove the 1 pх jump
    '[&:has(+.tw-surface-primary)]:tw-mb-[1px]',
    'hover:tw-mb-[1px]',
  ),
  ElevatedPrimary: cx(
    'tw-surface-primary',
    'tw-shadow-30',
    'tw-rounded-10',
    'aria-disabled:tw-shadow-none',
    'aria-disabled:tw-bg-fill-primary',
    'tw-mb-[1px]',
  ),
};
