import type { Step } from './step';

export class ActionRequiredError extends Error {
  public actionRequired: Step.ActionRequired;

  public constructor(actionRequired: Step.ActionRequired) {
    super(actionRequired.message);
    this.actionRequired = actionRequired;
  }
}
