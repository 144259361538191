import { Vector3 } from 'three';
import * as zod from 'zod';

export const CartesianPosition = zod.object({
  x: zod.number(),
  y: zod.number(),
  z: zod.number(),
});

export const identityTranslation = { x: 0, y: 0, z: 0 };

export type CartesianPosition = zod.infer<typeof CartesianPosition>;

export function convertPositionToVector3(pose: CartesianPosition) {
  return new Vector3(pose.x, pose.y, pose.z);
}

export function convertVector3ToPosition(vector: Vector3): CartesianPosition {
  return { x: vector.x, y: vector.y, z: vector.z };
}
