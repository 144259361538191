/**
 * Forward kinematics response:
 *
 * The payload for a response from the motion planner reporting the pose
 * of the tool frame for a given set of joint angles.
 *
 * This endpoint can also be used to check the validity of a set of angles,
 * returning collision details if it is requested.
 */

import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

export const ForwardKinematicsResponse = zod.object({
  pose: CartesianPose,
  // Array<{ elementNames: [string, string] }>
  // One for each collision
  collisions: zod
    .array(
      zod.object({
        elementNames: zod.tuple([zod.string(), zod.string()]),
      }),
    )
    .optional(),
  isColliding: zod.boolean(),
});

export type ForwardKinematicsResponse = zod.infer<
  typeof ForwardKinematicsResponse
>;
