import * as zod from 'zod';

import { OnRobotScrewdriverCommand } from '../../types';

export const ActuateScrewdriverArguments = zod.object({
  gripperCommand: OnRobotScrewdriverCommand,
});

export type ActuateScrewdriverArguments = zod.infer<
  typeof ActuateScrewdriverArguments
>;
