export type One<T> = [T];
export type Two<T> = [T, T];
export type Three<T> = [T, T, T];
export type Four<T> = [T, T, T, T];
export type Five<T> = [T, T, T, T, T];
export type Six<T> = [T, T, T, T, T, T];
export type Seven<T> = [T, T, T, T, T, T, T];
export type Eight<T> = [T, T, T, T, T, T, T, T];
export type Nine<T> = [T, T, T, T, T, T, T, T, T];
export type Ten<T> = [T, T, T, T, T, T, T, T, T, T];
export type Eleven<T> = [T, T, T, T, T, T, T, T, T, T, T];
export type Twelve<T> = [T, T, T, T, T, T, T, T, T, T, T, T];
export type Thirteen<T> = [T, T, T, T, T, T, T, T, T, T, T, T, T];
export type Fourteen<T> = [T, T, T, T, T, T, T, T, T, T, T, T, T, T];
export type Fifteen<T> = [T, T, T, T, T, T, T, T, T, T, T, T, T, T, T];
export type Sixteen<T> = [T, T, T, T, T, T, T, T, T, T, T, T, T, T, T, T];

export function one<T>(t: T): One<T> {
  return [t];
}

export function two<T>(t: T): Two<T> {
  return [t, t];
}

export function three<T>(t: T): Three<T> {
  return [t, t, t];
}

export function four<T>(t: T): Four<T> {
  return [t, t, t, t];
}

export function five<T>(t: T): Five<T> {
  return [t, t, t, t, t];
}

export function six<T>(t: T): Six<T> {
  return [t, t, t, t, t, t];
}

export function seven<T>(t: T): Seven<T> {
  return [t, t, t, t, t, t, t];
}

export function eight<T>(t: T): Eight<T> {
  return [t, t, t, t, t, t, t, t];
}

export function nine<T>(t: T): Nine<T> {
  return [t, t, t, t, t, t, t, t, t];
}

export function ten<T>(t: T): Ten<T> {
  return [t, t, t, t, t, t, t, t, t, t];
}

export function eleven<T>(t: T): Eleven<T> {
  return [t, t, t, t, t, t, t, t, t, t, t];
}

export function twelve<T>(t: T): Twelve<T> {
  return [t, t, t, t, t, t, t, t, t, t, t, t];
}

export function thirteen<T>(t: T): Thirteen<T> {
  return [t, t, t, t, t, t, t, t, t, t, t, t, t];
}

export function fourteen<T>(t: T): Fourteen<T> {
  return [t, t, t, t, t, t, t, t, t, t, t, t, t, t];
}

export function fifteen<T>(t: T): Fifteen<T> {
  return [t, t, t, t, t, t, t, t, t, t, t, t, t, t, t];
}

export function sixteen<T>(t: T): Sixteen<T> {
  return [t, t, t, t, t, t, t, t, t, t, t, t, t, t, t, t];
}
