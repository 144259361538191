import { Vector3 } from 'three';
import type * as zod from 'zod';

import {
  getPlaneOrientation,
  type CartesianPosition,
  type Plane,
} from '@sb/geometry';

import { FailureKind } from '../../FailureKind';
import type { Expression, StepFailure } from '../../types';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class AddOffset extends Step<Arguments, Variables> {
  private translation: CartesianPosition = { x: 0, y: 0, z: 0 };

  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail, playSubSteps }: StepPlayArguments): Promise<void> {
    const translation = await this.getTranslation(fail);

    if (await super.shouldStopPlaying()) {
      return;
    }

    this.translation = translation;
    this.routineContext.addMovementTranslation(this.translation);

    await playSubSteps();

    this.undoTranslation();
  }

  /**
   * Calculate translations
   */
  private async getTranslation(
    fail: (failure: StepFailure) => Promise<void>,
  ): Promise<CartesianPosition> {
    const { multiplier, translationX, translationY, translationZ } = this.args;

    const [x, y, z] = await Promise.all([
      this.evaluateExpression(translationX, 'x', multiplier, fail),
      this.evaluateExpression(translationY, 'y', multiplier, fail),
      this.evaluateExpression(translationZ, 'z', multiplier, fail),
    ]);

    const translation = this.applyFrameToTranslation({ x, y, z }, fail);

    return translation;
  }

  private async evaluateExpression(
    rawValue: Expression | void,
    description: string,
    multiplier: number,
    fail: (failure: StepFailure) => Promise<void>,
  ): Promise<number> {
    if (rawValue === undefined) {
      return 0;
    }

    let evaluatedValue =
      (await this.routineContext.evaluateExpression(rawValue)) ?? 0;

    if (typeof evaluatedValue === 'string') {
      evaluatedValue = parseFloat(evaluatedValue);
    }

    if (typeof evaluatedValue !== 'number') {
      await fail({
        failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
        failureReason: `Value for ${description} should be a number, received ${typeof evaluatedValue}`,
      });

      return 0;
    }

    if (Number.isNaN(evaluatedValue)) {
      await fail({
        failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
        failureReason: `Value for ${description} should be a number`,
      });

      return 0;
    }

    evaluatedValue *= multiplier;

    return evaluatedValue;
  }

  private applyFrameToTranslation(
    baseTranslation: CartesianPosition,
    fail: (failure: StepFailure) => Promise<void>,
  ): CartesianPosition {
    const { frame } = this.args;

    if (frame === 'base') {
      return baseTranslation;
    }

    try {
      const spaceItem = this.routineContext.getSpaceItem(frame);

      const origin = spaceItem.positions[0]?.pose;
      const plusX = spaceItem.positions[1]?.pose;
      const plusY = spaceItem.positions[2]?.pose;

      if (spaceItem.kind !== 'plane' || !origin || !plusX || !plusY) {
        throw new Error('Invalid frame');
      }

      const plane: Plane = {
        origin: new Vector3(origin.x, origin.y, origin.z),
        plusX: new Vector3(plusX.x, plusX.y, plusX.z),
        plusY: new Vector3(plusY.x, plusY.y, plusY.z),
      };

      const frameTranslation = new Vector3(
        baseTranslation.x,
        baseTranslation.y,
        baseTranslation.z,
      );

      frameTranslation.applyQuaternion(getPlaneOrientation(plane));

      return {
        x: frameTranslation.x,
        y: frameTranslation.y,
        z: frameTranslation.z,
      };
    } catch (error) {
      fail({
        failure: { kind: FailureKind.InvalidRoutineLoadedFailure },
        failureReason: 'Invalid add offset rotation frame',
        error,
      });

      return baseTranslation;
    }
  }

  private undoTranslation() {
    this.routineContext.addMovementTranslation({
      x: 0 - this.translation.x,
      y: 0 - this.translation.y,
      z: 0 - this.translation.z,
    });
  }
}
