import * as zod from 'zod';

export const CNCMonitorMachineArguments = zod.object({
  deviceID: zod.string(),
  haasCellSafe: zod.boolean(),
});

export type CNCMonitorMachineArguments = zod.infer<
  typeof CNCMonitorMachineArguments
>;
