import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';

import { CNCOperateWorkholdingArguments } from './Arguments';
import { CNCOperateWorkholdingVariables } from './Variables';

export class CNCOperateWorkholdingStep extends Step<
  CNCOperateWorkholdingArguments,
  CNCOperateWorkholdingVariables
> {
  public static areSubstepsRequired = true;

  public static Arguments = CNCOperateWorkholdingArguments;

  public static Variables = CNCOperateWorkholdingVariables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    try {
      await this.routineContext.equipment.executeCommand({
        kind: 'CNCMachineCommand',
        deviceID: this.args.deviceID,
        subCommand: {
          kind: 'workholding',
          actionKind: this.args.actionKind,
          actionName: this.args.actionKind,
          workholdingID: this.args.workholdingID,
        },
      });
    } catch (error) {
      fail({
        failure: { kind: FailureKind.HaasFailure },
        failureReason: 'Workholding action failed',
        error,
      });
    }
  }
}
