import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';
import {
  IntegrationActionControlBoxIOOutputs,
  IntegrationActionFlangeIOOutputs,
  IntegrationActionControlBoxIOInterface,
  IntegrationActionFlangeIOInterface,
  IntegrationActionKind,
  IntegrationControlBoxIOInterface,
  IntegrationFlangeIOInterface,
  IntegrationSensorControlBoxIOInterface,
  IntegrationSensorFlangeIOInterface,
  IntegrationInterfaceKind,
} from '@sb/integrations/io/types';

export const CustomGripperTooltipConfiguration = zod.object({
  name: zod.string().default('Tooltip'),
  tcpTransform: CartesianPose.default(ZERO_POSE),
});

export type CustomGripperTooltipConfiguration = zod.infer<
  typeof CustomGripperTooltipConfiguration
>;

export const CustomGripperControlInterfaceConfiguration = zod.union([
  IntegrationControlBoxIOInterface,
  IntegrationFlangeIOInterface,
]);

export type CustomGripperControlInterfaceConfiguration = zod.infer<
  typeof CustomGripperControlInterfaceConfiguration
>;

export const IntegrationActionOutputs = zod.union([
  IntegrationActionFlangeIOOutputs,
  IntegrationActionControlBoxIOOutputs,
]);

export type IntegrationActionOutputs = zod.infer<
  typeof IntegrationActionOutputs
>;

export const CustomGripperActionOutputsConfiguration = zod
  .union([
    IntegrationActionControlBoxIOInterface,
    IntegrationActionFlangeIOInterface,
  ])
  .nullable()
  .default(null);

export type CustomGripperActionOutputsConfiguration = zod.infer<
  typeof CustomGripperActionOutputsConfiguration
>;

export const CustomGripperActionConfiguration = zod.object({
  kind: IntegrationActionKind,
  name: zod.string().default('Action'),
  customActionName: zod.string().default(''),
  actionOutputs: CustomGripperActionOutputsConfiguration,
});

export type CustomGripperActionConfiguration = zod.infer<
  typeof CustomGripperActionConfiguration
>;

export const CustomGripperSensorsConfiguration = zod
  .union([
    IntegrationSensorControlBoxIOInterface,
    IntegrationSensorFlangeIOInterface,
  ])
  .nullable()
  .default(null);

export type CustomGripperSensorsConfiguration = zod.infer<
  typeof CustomGripperSensorsConfiguration
>;

export const CustomGripperConfiguration = zod.object({
  kind: zod.literal('CustomGripper'),
  name: zod.string().default('Custom gripper'),
  sensors: zod.array(CustomGripperSensorsConfiguration).default([]),
  actions: zod.array(CustomGripperActionConfiguration).default([
    CustomGripperActionConfiguration.parse({
      name: 'Actuate',
      kind: 'actuate',
      actionOutputs: {
        kind: IntegrationInterfaceKind.Values.controlBoxIO,
        outputs: [],
        pulseSeconds: 0.5,
      },
    }),
    CustomGripperActionConfiguration.parse({
      name: 'Reset',
      kind: 'reset',
      actionOutputs: {
        kind: IntegrationInterfaceKind.Values.controlBoxIO,
        outputs: [],
        pulseSeconds: 0.5,
      },
    }),
  ]),
  controlInterfaces: zod
    .array(CustomGripperControlInterfaceConfiguration)
    .nullable()
    .default(null),
  tooltips: zod
    .array(CustomGripperTooltipConfiguration)
    .default([CustomGripperTooltipConfiguration.parse({})]),
});

export type CustomGripperConfiguration = zod.infer<
  typeof CustomGripperConfiguration
>;
