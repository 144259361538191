import * as zod from 'zod';

import { SafeguardRule } from '@sb/routine-runner';

export const SafetySettingsPreset = zod.enum([
  'none',
  'safest',
  'safer',
  'default',
  'faster',
  'fastest',
]);

export type SafetySettingsPreset = zod.infer<typeof SafetySettingsPreset>;

export const SafetySettingsData = zod.object({
  id: zod.string(),
  robotID: zod.string(),
  factoryPreset: SafetySettingsPreset,
  maxJointSpeeds: zod.number().nullable().array(),
  maxTooltipSpeed: zod.number().nullable(),
  maxJointAccelerations: zod.number().nullable().array(),
  maxJointTorques: zod.number().nullable().array(),
  slowJointSpeeds: zod.number().nullable().array(),
  slowTooltipSpeed: zod.number().nullable(),
  slowJointAccelerations: zod.number().nullable().array(),
  slowJointTorques: zod.number().nullable().array(),
  safeguardRules: SafeguardRule.array(),
  collisionThresholds: zod.number().nullable().array(),
  accelerationThreshold: zod.number().nullable().array(),
  jerkLimit: zod.number().nullable(),
  tabletSafetyEnabled: zod.boolean(),
  updatedAt: zod.string(),
  baseAccelerationScaling: zod.number().nullable(),
  baseVelocityScaling: zod.number().nullable(),
});

export type SafetySettingsData = zod.infer<typeof SafetySettingsData>;
