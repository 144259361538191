/**
 * Binary Condition Operator: Operators for constructing conditionals.
 *
 * These always result in booleans.
 */
import * as zod from 'zod';

export const BINARY_CONDITIONAL_OPERATORS = [
  '>',
  '<',
  '==',
  '!=',
  'AND',
  'OR',
] as const;

export const BinaryConditionalOperator = zod.enum(BINARY_CONDITIONAL_OPERATORS);

export type BinaryConditionalOperator = zod.infer<
  typeof BinaryConditionalOperator
>;
