import { memoize } from 'lodash';

import { getRCL } from './getRCL';
import { rosSettings } from './rosSettings';

export const getNode = memoize(async () => {
  const { nodeName, namespace } = rosSettings;

  if (!nodeName) {
    throw new Error('ROS node name not set');
  }

  const rcl = await getRCL();

  const context = rosSettings.getNodeContext(rcl);
  const options = rosSettings.getNodeOptions(rcl);

  const node = new rcl.Node(nodeName, namespace, context, options);

  node.spin();

  return node;
});
