import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

export namespace AnticipatePayloadStep {
  export const name = 'Set payload';
  export const description = 'Sets the expected payload at the end of the arm';
  export const librarySection = Step.LibrarySection.Basic;
  export const argumentKind = 'AnticipatePayload';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    mass: zod.number().default(0),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'AnticipatePayload',
      args: { payload: { mass: args.mass } },
    };
  };
}

AnticipatePayloadStep satisfies Step.StepKindInfo;
