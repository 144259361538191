/**
 * Safety: A label corresponding to a safety input port on the IO board
 */
import * as zod from 'zod';

export const SAFETY_PORT_IDS = [
  'E-Stop 1',
  'E-Stop 2',
  'Safeguard 1',
  'Safeguard 2',
  'CB E-Stop 1',
  'CB E-Stop 2',
  'Tablet E-Stop 1',
  'Tablet E-Stop 2',
] as const;

export const SafetyPortKind = zod.literal('Safety');

export type SafetyPortKind = zod.infer<typeof SafetyPortKind>;

export const SAFETY_PORT_COUNT = SAFETY_PORT_IDS.length;

export const SafetyPortID = zod.enum(SAFETY_PORT_IDS);

export type SafetyPortID = zod.infer<typeof SafetyPortID>;
