import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { Unsubscribe, User } from '@sb/remote-control/types';

import { convertUserResponse, getUsersService } from './utils';

export function onGetUser(
  userID: string,
  onSnapshot: (settings: User.ConvertedResponse | null) => void,
): Unsubscribe {
  const subscription = getUsersService()
    .watch()
    .find({ query: { id: userID, $limit: 1 } })
    .pipe(
      map((response) => {
        const user = mapPaginated(response, convertUserResponse)[0] ?? null;

        return user;
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
