import { memoize } from 'lodash';
import { Subject } from 'rxjs';
import { filter, map, take, timeout } from 'rxjs/operators';

import { makeLogNamespace } from '@sb/log/log';

import { getNode } from './getNode';
import { getRCL } from './getRCL';
import {
  InferenceState,
  type InferenceCommand,
  type InferenceStateKind,
} from './inferenceTypes';

const log = makeLogNamespace('ROS.inference');

const getInferenceCommandPublisher = memoize(async () => {
  const node = await getNode();

  return node.createPublisher('std_msgs/msg/String', '/inference_node/command');
});

export async function publishInferenceCommand(command: InferenceCommand) {
  const rcl = await getRCL();
  const publisher = await getInferenceCommandPublisher();

  const message = rcl.createMessageObject('std_msgs/msg/String');
  message.data = JSON.stringify(command);

  publisher.publish(message);
}

export async function getInferenceState() {
  const node = await getNode();

  const state$ = new Subject<InferenceState>();

  const subscription = node.createSubscription(
    'std_msgs/msg/String',
    '/inference_node/state',
    (stateMessage: any) => {
      try {
        const json = JSON.parse(stateMessage.data);
        const state = InferenceState.parse(json);
        state$.next(state);
      } catch (error) {
        log.error('subscribe.error', 'Invalid state message', {
          stateMessage,
          error,
        });
      }
    },
  );

  return {
    getInferenceSkills: async (timeoutMS = 5000): Promise<string[]> => {
      const availableInference = await state$
        .pipe(
          map((s) => s.inference_skills),
          take(1),
          timeout(timeoutMS),
        )
        .toPromise();

      return availableInference;
    },
    waitForStateKind: async (
      stateKind: InferenceStateKind,
      timeoutMS = 5000,
    ): Promise<void> => {
      await state$
        .pipe(
          filter((s) => s.state === stateKind),
          take(1),
          timeout(timeoutMS),
        )
        .toPromise();
    },
    destroy: () => {
      state$.complete();
      node.destroySubscription(subscription);
    },
  };
}
