import * as zod from 'zod';

type EndEffectorTCP = `ee-${string}`;

export function isEndEffectorTCP(value: unknown): value is EndEffectorTCP {
  return typeof value === 'string' && value.startsWith('ee-');
}

export const TCPOffsetOption = zod
  .preprocess(
    (value) => {
      // legacy endeffector tcp options were raw numbers
      return typeof value === 'number' ? `ee-${value}` : value;
    },
    // union of valid options
    zod.union([
      zod.literal('wrist'),
      zod.literal('wristCamera'),
      zod.custom<EndEffectorTCP>(isEndEffectorTCP),
    ]),
  )
  // if we add more options later,
  // the catch will let us downgrade with fewer problems
  .catch('wrist');

export type TCPOffsetOption = zod.infer<typeof TCPOffsetOption>;

export const DEFAULT_TCP_OFFSET_OPTION: TCPOffsetOption = 'wrist';

export const TCPOptionOrAuto = zod.literal('auto').or(TCPOffsetOption);
