import { mapPaginated } from '@sb/feathers-types';
import type { ApiAuthData } from '@sb/feathers-types';

import { getService } from '../utils';

export async function loadApiAuthToken(): Promise<ApiAuthData> {
  const response = await getService('apiAuth')().find({
    query: {},
  });

  return mapPaginated(response, (d) => d)[0];
}
