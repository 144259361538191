export const heart = (
  <path
    d="M14 8.5C20.5 4.3869e-05 32 13 14 23.5C-3.99999 13 7.49999 4.95911e-05 14 8.5Z"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
  />
);

export const heartFill = (
  <path
    d="M14.5039 24.3638C23.6713 19.0162 25.8361 12.6917 24.1125 8.49513C23.2678 6.43832 21.7647 5.04834 19.3824 4.78726C17 4.52617 15.5 5.5 14 7.40002C12.5 5.5 11 4.52616 8.61763 4.78726C6.23524 5.04836 4.73225 6.43832 3.88749 8.49513C2.1639 12.6917 4.32874 19.0162 13.4961 24.3638L14 24.6578L14.5039 24.3638Z"
    fill="currentColor"
  />
);
