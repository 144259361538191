import cx from 'classnames';

import styles from './BadgeNumber.module.css';

type BadgeNumberSize = 'small' | 'medium';
type BadgeNumberVariant = 'dark' | 'light';

interface BadgeNumberProps {
  children: React.ReactNode;
  className?: string;
  isCircle?: boolean;
  size?: BadgeNumberSize;
  variant?: BadgeNumberVariant;
}

const BadgeNumber = ({
  children,
  className,
  isCircle,
  size = 'small',
  variant = 'dark',
}: BadgeNumberProps) => {
  return (
    <div
      className={cx(
        styles[size],
        styles.badgeNumber,
        styles[variant],
        { [styles.circle]: isCircle },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default BadgeNumber;
