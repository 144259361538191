export const box = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.4855 8.39001L13.4855 2.39001H14.5145L24.5145 8.39001L25 9.24751V19.2475L24.5145 20.105L14.5145 26.105H13.4855L3.4855 20.105L3 19.2475V9.24751L3.4855 8.39001ZM5 11.0137V18.6813L13 23.4813V15.8137L5 11.0137ZM15 15.8137V23.4813L23 18.6813V11.0137L15 15.8137ZM22.0563 9.24751L14 14.0813L5.94365 9.24751L14 4.4137L22.0563 9.24751Z"
    fill="currentColor"
  />
);

export const shippingBox = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.5145 2.39001H13.4855L3.4855 8.39001L3 9.24751V19.2475L3.4855 20.105L13.4855 26.105H14.5145L24.5145 20.105L25 19.2475V9.24751L24.5145 8.39001L14.5145 2.39001ZM5 18.6813V11.0137L13 15.8137V23.4813L5 18.6813ZM15 23.4813V15.8137L23 11.0137V18.6813L15 23.4813ZM19 11.0813L22.0563 9.24751L14 4.4137L10.9437 6.24751L19 11.0813ZM17.0563 12.2475L9 7.4137L5.94365 9.24751L14 14.0813L17.0563 12.2475Z"
    fill="currentColor"
  />
);

export const viewfinderBox = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.5 4.5L4.5 3.5H10.5V5.5H5.5V10.5H3.5V4.5ZM17.5 3.5H23.5L24.5 4.5V10.5H22.5V5.5H17.5V3.5ZM3.5 17.5V23.5L4.5 24.5H10.5V22.5H5.5V17.5H3.5ZM22.5 22.5V17.5H24.5V23.5L23.5 24.5H17.5V22.5H22.5ZM14.3714 8.07152L14 7.92297L13.6286 8.07152L8.62861 10.0715L8 10.323V11V17V17.677L8.62861 17.9285L13.6286 19.9285L14 20.077L14.3714 19.9285L19.3714 17.9285L20 17.677V17V11V10.323L19.3714 10.0715L14.3714 8.07152ZM10 16.323V12.477L13 13.677V17.523L10 16.323ZM15 17.523V13.677L18 12.477V16.323L15 17.523ZM14 11.923L16.3074 11L14 10.077L11.6926 11L14 11.923Z"
    fill="currentColor"
  />
);

export const archiveBox = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 5L4 4H24L25 5V9L24 10H23V23L22 24H6L5 23V10H4L3 9V5ZM7 10H21V22H7V10ZM23 8H22H6H5V6H23V8ZM10 14H18V12H10V14Z"
    fill="currentColor"
  />
);
