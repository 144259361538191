import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import { HaasControlRegionArguments } from '../steps/HaasControlRegion/Arguments';

import { validateHaasAddress } from './validateHaasAddress';

/**
 * Types matching the routine runner schema for "HaasControlRegion" Steps
 */
export namespace HaasControlRegionStepDatabase {
  export const name = 'Monitor Haas machine';
  export const description =
    'Specify a section of the routine as controlling a Haas machine accessible over Ethernet, ' +
    'being interrupted by errors from the device and sending a Cell Safe command';
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const argumentKind = 'HaasControlRegion';

  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    ...HaasControlRegionArguments.shape,
    shouldSendCellSafe:
      HaasControlRegionArguments.shape.shouldSendCellSafe.default(true),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    validateHaasAddress(args);

    return {
      ...stepData,
      stepKind: 'HaasControlRegion',
      args,
    };
  };
}

HaasControlRegionStepDatabase satisfies Step.StepKindInfo;
