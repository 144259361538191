import * as zod from 'zod';

// blob 2D detection method

export const Blob2D = zod.object({
  x: zod.number(),
  y: zod.number(),
  width: zod.number(),
  height: zod.number(),
  rotation: zod.number(), // radians
  score: zod.number(),
  contour: zod
    .array(
      zod.object({
        x: zod.number(),
        y: zod.number(),
      }),
    )
    .default([]),
});

export type Blob2D = zod.infer<typeof Blob2D>;

export const Blob2DParams = zod.object({
  minArea: zod.number().default(25),
  maxArea: zod.number().default(10000),
  minThreshold: zod.number().default(50),
  maxThreshold: zod.number().default(220),
  minCircularity: zod.number().default(0.1),
  maxCircularity: zod.number().default(1),
  filterByCircularity: zod.boolean().default(false),
  invertImage: zod.boolean().default(false),
});

export type Blob2DParams = zod.infer<typeof Blob2DParams>;

export const BlobDetection2DMethod = zod.object({
  kind: zod.literal('BlobDetection2D'),
  settings: Blob2DParams,
});

export type BlobDetection2DMethod = zod.infer<typeof BlobDetection2DMethod>;

// Shape 2D detection method

export const Shape2DParams = zod.object({
  minScale: zod.number().default(0.8),
  maxScale: zod.number().default(1.2),
  maxRotation: zod.number().default(180),
  matchThreshold: zod.number().default(0.5),
  boundingBox: zod
    .object({
      rotationDegrees: zod.number().default(0),
      scaleX: zod.number().default(1),
      scaleY: zod.number().default(1),
    })
    .default({}),
  filterIntersections: zod.boolean().default(true),
});

export type Shape2DParams = zod.infer<typeof Shape2DParams>;

export const TemplateImage = zod.object({
  storageID: zod.string(),
});

export type TemplateImage = zod.infer<typeof TemplateImage>;

export const ShapeDetection2DMethod = zod.object({
  kind: zod.literal('ShapeDetection2D'),
  templateImage: TemplateImage,
  settings: Shape2DParams,
});

export type ShapeDetection2DMethod = zod.infer<typeof ShapeDetection2DMethod>;

// Chessboard detection method
export const ChessboardDetection2DMethod = zod.object({
  kind: zod.literal('ChessboardDetection2D'),
});

export type ChessboardDetection2DMethod = zod.infer<
  typeof ChessboardDetection2DMethod
>;

// Locate method union

export const LocateMethod = zod.discriminatedUnion('kind', [
  BlobDetection2DMethod,
  ShapeDetection2DMethod,
  ChessboardDetection2DMethod,
]);

export type LocateMethod = zod.infer<typeof LocateMethod>;
