/* eslint-disable @typescript-eslint/require-await, no-underscore-dangle */

import { STILL_GOING_CHECK_INTERVAL_MS } from '@sb/routine-runner/constants';
import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';
import { wait } from '@sb/utilities';

import { ActuateVacuumArguments as Arguments } from './Arguments';
import { ActuateVacuumVariables as Variables } from './Variables';

export class ActuateVacuumStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  private stillGoing: boolean = false;

  private isPaused: boolean = false;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const { gripperCommand } = this.args;
    this.stillGoing = true;

    while (this.stillGoing) {
      if (!this.isPaused) {
        try {
          await this.routineContext.actuateDevice(gripperCommand);
        } catch (error) {
          if (this.isPaused) {
            continue;
          }

          return fail({
            failure: {
              kind: FailureKind.GripperFailure,
              message: error.message,
            },
            failureReason: `Failed to actuate gripper vacuum “Actuate Vacuum” step: ${error.message}`,
            error,
          });
        }

        if (!this.isPaused) {
          break;
        }
      }

      await wait(STILL_GOING_CHECK_INTERVAL_MS);
    }

    this.stillGoing = false;

    return undefined;
  }

  public _pause(): void {
    this.isPaused = true;
    this.routineContext.stopGripper();
  }

  public _resume(): void {
    this.isPaused = false;
  }

  public _stop(): void {
    this.routineContext.stopGripper();
    this.stillGoing = false;
  }
}
