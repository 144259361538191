import * as zod from 'zod';

import { SafeguardKind } from '@sb/routine-runner';

import { RoutineRunnerStateKind } from './routineRunnerState';

const IOPort = zod.object({
  highSignalName: zod.string(),
  kind: zod.enum([
    'Input',
    'Output',
    'Relay',
    'Safety',
    'FlangeOutput',
    'FlangeInput',
  ]),
  lowSignalName: zod.string(),
  name: zod.string(),
  port: zod.number(),
  safeguardKind: SafeguardKind.optional(),
});

export const RobotData = zod.object({
  id: zod.string(),
  ioInputs: zod.array(IOPort).nullable(),
  ioOutputs: zod.array(IOPort).nullable(),
  ioRelays: zod.array(IOPort).nullable(),
  ioFlangeInputs: zod.array(IOPort).nullable(),
  ioFlangeOutputs: zod.array(IOPort).nullable(),
  isDefault: zod.boolean(),
  latestRoutineID: zod.string().nullable(),
  latestRoutineLoadedAt: zod.string().nullable(),
  name: zod.string(),
  robotID: zod.string(),
  routineIsPaused: zod.boolean(),
  lastKnownLocalIP: zod.string().nullable(),
  routineRunnerStateKind: RoutineRunnerStateKind.nullable(),
  serialNumber: zod.string().nullable(),
  speedRestrictionPercentage: zod.number(),
  testRunCompletedAt: zod.string().nullable(),
});

export type RobotData = zod.infer<typeof RobotData>;
