import { defaults } from 'lodash';
import { createContext, useContext, useMemo } from 'react';

import type { ComponentColor, ComponentColorOrDefault } from './types';

interface DesignSystemContextType {
  /* default color for buttons etc. */
  defaultComponentColor: ComponentColor;
  /* container for portaled elements */
  portalContainer: HTMLElement | null;
}

const DesignSystemContext = createContext<DesignSystemContextType>({
  defaultComponentColor: 'Orange',
  portalContainer: null,
});

export function DesignSystemContextProvider({
  children,
  defaultComponentColor,
  portalContainer,
}: React.PropsWithChildren<Partial<DesignSystemContextType>>) {
  const inheritedValue = useContext(DesignSystemContext);

  const value = useMemo<DesignSystemContextType>(
    () => defaults({ defaultComponentColor, portalContainer }, inheritedValue),
    [defaultComponentColor, portalContainer, inheritedValue],
  );

  return (
    <DesignSystemContext.Provider value={value}>
      {children}
    </DesignSystemContext.Provider>
  );
}

export function useComponentColorClassName<T extends string>(
  color: T | ComponentColorOrDefault | undefined,
  classNameMap: Record<T | ComponentColor, string>,
): string {
  const { defaultComponentColor } = useContext(DesignSystemContext);

  const resolvedColor =
    color === undefined || color === 'Default' ? defaultComponentColor : color;

  return classNameMap[resolvedColor];
}

export function usePortalContainer(): HTMLElement {
  const { portalContainer } = useContext(DesignSystemContext);

  return portalContainer ?? document.body;
}
