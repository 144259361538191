import * as zod from 'zod';

import { FailureKind } from './FailureKind';

export const CommandResult = zod.union([
  zod.literal('Success'),
  zod.nativeEnum(FailureKind),
  zod.literal('Interrupted'),
  zod.literal('NonIdle'),
  zod.literal('Braked'),
]);

export type CommandResult = zod.infer<typeof CommandResult>;
