import { clamp } from 'lodash';
import * as zod from 'zod';

import type { ArmJointTorques } from '@sb/motion-planning';
import { ABSOLUTE_MAX_JOINT_TORQUES } from '@sb/motion-planning';
import { Step } from '@sb/remote-control/types';

export namespace PushModeStep {
  export const name = 'Push mode';
  export const description =
    'Enable push mode maintain a pushing force against while performing substeps';
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.Control;
  export const argumentKind = 'PushMode';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    pushingForcePercent: zod.number().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const { pushingForcePercent } = args;

    const collisionThresholds: ArmJointTorques | undefined = (() => {
      if (!pushingForcePercent) {
        return undefined;
      }

      // 10% = 0.1; 100% = 0.6 (as per SW-1886)
      const MIN_FRAC = 0.1;
      const MAX_FRAC = 0.6;
      const fraction = (pushingForcePercent - 10) / 180 + MIN_FRAC;
      const clampedFraction = clamp(fraction, MIN_FRAC, MAX_FRAC);

      return ABSOLUTE_MAX_JOINT_TORQUES.map((torque) => {
        return torque * clampedFraction;
      }) as ArmJointTorques;
    })();

    return {
      ...stepData,
      stepKind: 'PushMode',
      args: { collisionThresholds },
    };
  };
}

PushModeStep satisfies Step.StepKindInfo;
