import { log } from './log';
import { Severity } from './types';

function assertInner(
  severity: Severity,
  condition: boolean,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts condition is true {
  if (!condition) {
    log(severity, key, message, context);
    throw errorFactory ? errorFactory() : new Error(message);
  }
}

function assertNotNullInner<T>(
  severity: Severity,
  value: T,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    log(severity, key, message, context);
    throw errorFactory ? errorFactory() : new Error(message);
  }
}

/**
 * Assert that the condition is true,
 * otherwise throw an error and log with serverity ERROR
 */
export function isTrueError(
  condition: boolean,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts condition is true {
  assertInner(Severity.Error, condition, key, message, context, errorFactory);
}

/**
 *  Assert that the condition is true,
 * otherwise throw an error and log with serverity INFO
 */
export function isTrueInfo(
  condition: boolean,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts condition is true {
  assertInner(
    Severity.Information,
    condition,
    key,
    message,
    context,
    errorFactory,
  );
}

/**
 * Assert that the condition is true,
 * otherwise throw an error and log with serverity WARN
 */
export function isTrueWarn(
  condition: boolean,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts condition is true {
  assertInner(Severity.Warning, condition, key, message, context, errorFactory);
}

/**
 * Assert that the value is not null or undefined,
 * otherwise throw an error and log with serverity ERROR
 */
export function notNullError<T>(
  value: T,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is NonNullable<T> {
  assertNotNullInner(
    Severity.Error,
    value,
    key,
    message,
    context,
    errorFactory,
  );
}

/**
 * Assert that the value is not null or undefined,
 * otherwise throw an error and log with serverity INFO
 */
export function notNullInfo<T>(
  value: T,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is NonNullable<T> {
  assertNotNullInner(
    Severity.Information,
    value,
    key,
    message,
    context,
    errorFactory,
  );
}

/**
 * Assert that the value is not null or undefined,
 * otherwise throw an error and log with serverity WARN
 */
export function notNullWarn<T>(
  value: T,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is NonNullable<T> {
  assertNotNullInner(
    Severity.Warning,
    value,
    key,
    message,
    context,
    errorFactory,
  );
}

/**
 * Assert that the value is not null or undefined,
 * otherwise throw an error and log with serverity ERROR
 */
export function isNullError(
  value: unknown,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is null | undefined {
  assertInner(
    Severity.Error,
    value === null || value === undefined,
    key,
    message,
    context,
    errorFactory,
  );
}

/**
 * Assert that the value is not null or undefined,
 * otherwise throw an error and log with serverity INFO
 */
export function isNullInfo(
  value: unknown,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is null | undefined {
  assertInner(
    Severity.Information,
    value === null || value === undefined,
    key,
    message,
    context,
    errorFactory,
  );
}

/**
 * Assert that the value is not null or undefined,
 * otherwise throw an error and log with serverity WARN
 */
export function isNullWarn(
  value: unknown,
  key: string,
  message: string,
  context?: any,
  errorFactory?: () => Error,
): asserts value is null | undefined {
  assertInner(
    Severity.Warning,
    value === null || value === undefined,
    key,
    message,
    context,
    errorFactory,
  );
}

export const makeAssertNamespace = (nsKey: string) => ({
  isTrueError: (c: boolean, k: string, m: string, ctx?: any) =>
    isTrueError(c, `${nsKey}.${k}`, m, ctx),
  isTrueInfo: (c: boolean, k: string, m: string, ctx?: any) =>
    isTrueInfo(c, `${nsKey}.${k}`, m, ctx),
  isTrueWarn: (c: boolean, k: string, m: string, ctx?: any) =>
    isTrueWarn(c, `${nsKey}.${k}`, m, ctx),
  notNullError: <T>(v: T, k: string, m: string, ctx?: any) =>
    notNullError(v, `${nsKey}.${k}`, m, ctx),
  notNullInfo: <T>(v: T, k: string, m: string, ctx?: any) =>
    notNullInfo(v, `${nsKey}.${k}`, m, ctx),
  notNullWarn: <T>(v: T, k: string, m: string, ctx?: any) =>
    notNullWarn(v, `${nsKey}.${k}`, m, ctx),
  isNullError: (v: unknown, k: string, m: string, ctx?: any) =>
    isNullError(v, `${nsKey}.${k}`, m, ctx),
  isNullInfo: (v: unknown, k: string, m: string, ctx?: any) =>
    isNullInfo(v, `${nsKey}.${k}`, m, ctx),
  isNullWarn: (v: unknown, k: string, m: string, ctx?: any) =>
    isNullWarn(v, `${nsKey}.${k}`, m, ctx),
});
