import * as zod from 'zod';

import { NoGripperConfiguration } from '@sb/integrations/implementations/NoGripper/types';

import { OnRobot2FG7Configuration } from '../../OnRobot2FG7/types';
import { OnRobot3FG15Configuration } from '../../OnRobot3FG15/types';

export const OnRobotDualQuickChangerCompatibleGripper = zod.union([
  OnRobot2FG7Configuration,
  OnRobot3FG15Configuration,
  NoGripperConfiguration,
]);

export type OnRobotDualQuickChangerCompatibleGripper = zod.infer<
  typeof OnRobotDualQuickChangerCompatibleGripper
>;
