import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
} from '@apollo/client';
import { useMemo } from 'react';

import { useStandardBotsSdkToken } from '@sbrc/hooks/useStandardBotsSdk';

type Config = {
  baseUrl: string;
  httpLink: HttpLink;
  cache: InMemoryCache;
};

let confCache: Partial<Config> = {};

const useConfig = (): Config => {
  return useMemo(() => {
    const baseUrl = '/api/v1/api-srv/graphql';

    const filledConfig = {
      cache: confCache.cache || new InMemoryCache(),
      baseUrl,
      httpLink:
        confCache.httpLink ||
        new HttpLink({
          uri: baseUrl,
        }),
    };

    confCache = filledConfig;

    return filledConfig;
  }, []);
};

const authMiddleware = (token: string) => {
  return new ApolloLink((operation, forward) => {
    if (token) {
      operation.setContext({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          robot_kind: 'live',
        },
      });
    }

    return forward(operation);
  });
};

interface ApolloClientProviderProps {
  children: React.ReactNode;
}

export function ApolloClientProvider({ children }: ApolloClientProviderProps) {
  const authToken = useStandardBotsSdkToken();
  const config = useConfig();

  const client = useMemo(
    () =>
      new ApolloClient({
        link: authMiddleware(authToken ?? '').concat(config.httpLink),
        cache: config.cache,
      }),
    [authToken, config],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
