export const eye = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.0028 21C8.98895 21 4.63616 18.1616 2.467 14C4.63616 9.8384 8.98895 7 14.0028 7C19.0167 7 23.3695 9.8384 25.5386 14C23.3695 18.1616 19.0167 21 14.0028 21ZM14.0028 5C8.01761 5 2.85314 8.50549 0.44682 13.5709L0.242981 14L0.446821 14.4291C2.85314 19.4945 8.01761 23 14.0028 23C19.988 23 25.1525 19.4945 27.5588 14.4291L27.7627 14L27.5588 13.5709C25.1525 8.50549 19.988 5 14.0028 5ZM15.5 14C15.5 14.8284 14.8284 15.5 14 15.5C13.1716 15.5 12.5 14.8284 12.5 14C12.5 13.1716 13.1716 12.5 14 12.5C14.8284 12.5 15.5 13.1716 15.5 14ZM19.5 14C19.5 17.0376 17.0376 19.5 14 19.5C10.9624 19.5 8.50001 17.0376 8.50001 14C8.50001 10.9624 10.9624 8.5 14 8.5C17.0376 8.5 19.5 10.9624 19.5 14Z"
    fill="currentColor"
  />
);

export const eyeSlash = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.29291 4.70718L23.2929 24.7072L24.7071 23.293L4.70712 3.29297L3.29291 4.70718ZM22.2733 18.0307L23.6929 19.4503C25.3131 18.0777 26.6379 16.3677 27.5588 14.4292L27.7627 14.0001L27.5588 13.571C25.1525 8.50555 19.988 5.00006 14.0028 5.00006C12.5549 5.00006 11.155 5.20522 9.83056 5.58798L11.4861 7.24348C12.3003 7.08376 13.1418 7.00006 14.0028 7.00006C19.0167 7.00006 23.3695 9.83846 25.5386 14.0001C24.7284 15.5545 23.6135 16.9244 22.2733 18.0307ZM0.44682 13.571C1.36713 11.6337 2.6909 9.92453 4.30972 8.55242L5.7293 9.972C4.39044 11.0778 3.27662 12.4468 2.467 14.0001C4.63616 18.1617 8.98895 21.0001 14.0028 21.0001C14.8621 21.0001 15.702 20.9167 16.5149 20.7576L18.1707 22.4134C16.8476 22.7954 15.4492 23.0001 14.0028 23.0001C8.01761 23.0001 2.85314 19.4946 0.446821 14.4292L0.242981 14.0001L0.44682 13.571ZM8.50001 14.0001C8.50001 13.6095 8.54073 13.2284 8.61814 12.8608L15.1392 19.3819C14.7717 19.4593 14.3906 19.5001 14 19.5001C10.9624 19.5001 8.50001 17.0376 8.50001 14.0001ZM14 8.50006C13.6094 8.50006 13.2283 8.54078 12.8608 8.6182L19.3819 15.1393C19.4593 14.7717 19.5 14.3907 19.5 14.0001C19.5 10.9625 17.0376 8.50006 14 8.50006Z"
    fill="currentColor"
  />
);
