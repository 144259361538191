import * as zod from 'zod';

import { six } from '@sb/utilities';

/**
 * A set of arm joint accelerations in rad/s^2,
 * in order from joint 0 to joint 5.
 */
export const ArmJointAccelerations = zod.tuple([
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
]);

export type ArmJointAccelerations = zod.infer<typeof ArmJointAccelerations>;

// radians per second^2
export const ABSOLUTE_MAX_JOINT_ACCELERATIONS: ArmJointAccelerations = six(5);
export const ABSOLUTE_MAX_JOINT_ACCELERATIONS_HIGH: ArmJointAccelerations =
  six(12);

export const COLLABORATIVE_MAX_JOINT_ACCELERATIONS: ArmJointAccelerations =
  six(1);

export const DEFAULT_MAX_JOINT_ACCELERATIONS =
  COLLABORATIVE_MAX_JOINT_ACCELERATIONS;

export const ABSOLUTE_MAX_ACCELERATION_COLLISION_THRESHOLDS = six(500);

/**
 * The default change in acceleration thresholds for collision detection.
 * Collision threshold is the change in acceleration over a short period of time
 * at which the joint is considered to be in a collision state.
 *
 * This value is arbitrary and temporary until it has been tested + tuned;
 */
export const DEFAULT_ACCELERATION_COLLISION_THRESHOLDS = six(100);
