import cx from 'classnames';

import Typography from '../Typography';

import type { LabelPosition } from './types';

import styles from './InputLabel.module.css';

interface InputLabelProps {
  children?: React.ReactNode;
  className?: string;
  labelPosition?: LabelPosition;
}

const InputLabel = ({
  children,
  className,
  labelPosition = 'top',
}: InputLabelProps) => {
  return (
    <Typography
      className={cx(styles[`${labelPosition}Label`], className)}
      component="label"
    >
      {children}
    </Typography>
  );
};

export default InputLabel;
