import * as cbor from 'cbor';
import * as zod from 'zod';

import { EventEmitter, wait } from '@sb/utilities';

import {
  MotionPlanArmStep,
  MotionPlan,
  MotionPlanGripperStep,
} from './MotionPlan';

interface MotionPlanResponseEvents {
  // The server has acknowledged a successful request and is currently planning
  acknowledged: void;

  // An error has occured
  error: Error | string;

  // A waypoint has come through the connection
  waypoint: MotionPlanArmStep;

  gripper: MotionPlanGripperStep;

  // All waypoints have come through for one plan has been
  // compiled in one motion plan.
  completedMotionPlan: MotionPlan;

  // Emitted when the HTTP response is done (used to determine when to collate waypoints).
  // Externally, listen to `completedMotionPlan` instead of `done`
  done: void;
}

const MotionPlanResponseEvent = zod.union([
  zod.tuple([zod.literal('acknowledged')]),
  zod.tuple([zod.literal('error'), zod.instanceof(Error).or(zod.string())]),
  zod.tuple([zod.literal('waypoint'), MotionPlanArmStep]),
  zod.tuple([zod.literal('gripper'), MotionPlanGripperStep]),
  zod.tuple([zod.literal('completedMotionPlan'), MotionPlan]),
  zod.tuple([zod.literal('done')]),
]);

type MotionPlanResponseEvent = zod.infer<typeof MotionPlanResponseEvent>;

const SerializedMotionPlanResponseEvent = zod.object({
  timestamp: zod.number().positive().int(),
  event: MotionPlanResponseEvent,
});

type SerializedMotionPlanResponseEvent = zod.infer<
  typeof SerializedMotionPlanResponseEvent
>;

/**
 * A MotionPlanResponse represents a response from the motion planner throughout the lifecycle of
 * the request.
 *
 * It emits events that tell the user what stage of motion planning we're in so we can
 * give regular feedback and collect data regarding latency and plan timing.
 */
export class MotionPlanResponse extends EventEmitter<MotionPlanResponseEvents> {
  private completionPromise: Promise<Array<MotionPlan>>;

  /**
   * Play back a captured recording (the result of `capture()`)
   */
  public static deserialize(
    serialized: string,
    useDelay = true,
  ): MotionPlanResponse {
    const events = zod
      .array(SerializedMotionPlanResponseEvent)
      .parse(cbor.decodeFirstSync(Buffer.from(serialized, 'base64')));

    const response = new MotionPlanResponse();

    for (const [index, { timestamp, event }] of Object.entries(events)) {
      setTimeout(
        () => {
          response.emit(event[0], event[1]);
        },
        // if we don't want to use the real delay,
        // just use the index to ensure order
        useDelay ? timestamp : parseInt(index, 10),
      );
    }

    return response;
  }

  // Convert a (potentially multi-part) motion plan into a full MotionPlanResponse
  // usable by tests.
  public static fromMotionPlan(
    plan: Array<MotionPlanArmStep | MotionPlanGripperStep>,
  ): MotionPlanResponse {
    const response = new MotionPlanResponse();

    (async () => {
      await wait(10);
      response.emit('acknowledged');
      await wait(1);

      plan.forEach((waypoint) => {
        if ('joints' in waypoint) {
          response.emit('waypoint', waypoint);
        } else {
          response.emit('gripper', waypoint);
        }
      });

      await wait(1);

      response.emit('done');
    })();

    return response;
  }

  public constructor() {
    super();

    const noWaypointsError = new Error();

    this.completionPromise = new Promise((resolve, reject) => {
      const plans: Array<MotionPlan> = [];
      let accumulatingPlan: Array<MotionPlanArmStep> = [];

      const onError = (error: Error | string) => {
        if (typeof error === 'string') {
          reject(new Error(error));
        } else {
          reject(error);
        }

        removeListeners(); // eslint-disable-line @typescript-eslint/no-use-before-define
      };

      const completePlan = () => {
        const planParse = MotionPlan.safeParse(accumulatingPlan);

        if (planParse.success) {
          plans.push(planParse.data);
          this.emit('completedMotionPlan', planParse.data);
          accumulatingPlan = [];
        }
      };

      const onDone = () => {
        if (accumulatingPlan.length === 0) {
          noWaypointsError.message = 'Motion plan completed with no waypoints';
          onError(noWaypointsError);

          return;
        }

        completePlan();
        resolve(plans);
        removeListeners(); // eslint-disable-line @typescript-eslint/no-use-before-define
      };

      const onWaypoint = (waypoint: MotionPlanArmStep) => {
        accumulatingPlan.push(waypoint);
      };

      const removeListeners = () => {
        this.off('error', onError);
        this.off('waypoint', onWaypoint);
        this.off('done', onDone);
        this.off('gripper', completePlan);
      };

      this.once('done', onDone);
      this.once('error', onError);
      this.on('waypoint', onWaypoint);
      // gripper messages are used to delineate between multiple
      // arm motion plans
      this.on('gripper', completePlan);
    });
  }

  // Set up handlers to capture events and store them serialized
  //
  // Uses cbor/base64 internally for a space improvement of about 2x compared
  // to JSON.
  public capture(rejectOnError: boolean = false): Promise<string> {
    const start = Date.now();

    const record: Array<{
      timestamp: number;
      event: MotionPlanResponseEvent;
    }> = [];

    return new Promise((resolve, reject) => {
      const removeListeners = [
        this.on('gripper', (value) => {
          record.push({
            timestamp: Date.now() - start,
            event: ['gripper', value],
          });
        }),

        this.on('waypoint', (value) => {
          record.push({
            timestamp: Date.now() - start,
            event: ['waypoint', value],
          });
        }),

        this.on('error', (error) => {
          if (rejectOnError) {
            reject(error);
          }

          record.push({
            timestamp: Date.now() - start,
            event: ['error', error],
          });
        }),

        this.on('done', async () => {
          removeListeners.forEach((remove) => remove());

          record.push({
            timestamp: Date.now() - start,
            event: ['done'],
          });

          const encoded = await cbor.encodeAsync(record);
          resolve(encoded.toString('base64'));
        }),

        this.on('completedMotionPlan', (value) => {
          record.push({
            timestamp: Date.now() - start,
            event: ['completedMotionPlan', value],
          });
        }),
      ];
    });
  }

  /**
   * Helper that resolves to the first completed motion plan,
   * or errors, if an error occurs before the connection closes.
   */
  public async complete(): Promise<MotionPlan> {
    const [plan] = await this.completeAll();

    plan.sort((a, b) => a.timestamp - b.timestamp);

    return plan;
  }

  /**
   * Helper that resolves to the all completed motion plans,
   * or errors if an error occurs before the connection closes.
   */
  public completeAll(): Promise<Array<MotionPlan>> {
    return this.completionPromise;
  }
}
