import * as zod from 'zod';

import {
  ActionRequiredError,
  MotionSpeed,
  Step,
} from '@sb/remote-control/types';
import { calculateSpeedProfileFromStepMotionSpeed } from '@sb/remote-control/util/calculateSpeedProfileFromStepMotionSpeed';
import { validateMotionSpeed } from '@sb/remote-control/util/validators';
import type { SpeedProfile } from '@sb/routine-runner';
import { ArmPosition } from '@sb/routine-runner';

export namespace PressButtonStep {
  export const name = 'Press button';
  export const description =
    'Allows the robot to press a button on a machine, e.g. to start the next CNC cycle';
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const argumentKind = 'PressButton';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    motionSpeed: MotionSpeed.optional(),
    prePressTarget: ArmPosition.nullable().default(null),
    pressDurationSeconds: zod.number().default(0.3),
    pressTarget: ArmPosition.nullable().default(null),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    baseSpeedProfile,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.prePressTarget?.jointAngles) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Pre-press position is not configured',
        fieldId: 'prePressTarget',
      });
    }

    if (!args.pressTarget?.jointAngles) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Press position is not configured',
        fieldId: 'pressTarget',
      });
    }

    validateMotionSpeed('Press Button', args.motionSpeed);

    const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed(
      {
        motionSpeed: args.motionSpeed,
        baseSpeedProfile,
      },
    );

    return {
      ...stepData,
      stepKind: 'PressButton',
      args: {
        prePressTarget: args.prePressTarget.jointAngles,
        pressDurationMS: args.pressDurationSeconds * 1000,
        pressTarget: args.pressTarget.jointAngles,
        speedProfile,
      },
    };
  };
}

PressButtonStep satisfies Step.StepKindInfo;
