import * as zod from 'zod';

export const SmoothingType = zod.enum([
  'linear',
  'monotone_cubic',
  'cubic',
  'quintic',
]);

export type SmoothingType = zod.infer<typeof SmoothingType>;
