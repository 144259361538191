import * as zod from 'zod';

import { six } from '@sb/utilities';

// A set of arm joint positions in radians, in order from joint 0 to joint 5.
export const ArmJointPositions = zod.tuple(six(zod.number()));

export type ArmJointPositions = zod.infer<typeof ArmJointPositions>;

export const NullableArmJointPositions = zod.tuple(
  six(zod.number().nullable()),
);

export type NullableArmJointPositions = zod.infer<
  typeof NullableArmJointPositions
>;
