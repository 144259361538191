import * as zod from 'zod';

export const LEDColorPulsingPattern = zod.object({
  kind: zod.literal('pulsing'),
  red: zod.number().min(0).max(255),
  green: zod.number().min(0).max(255),
  blue: zod.number().min(0).max(255),
  brightness: zod.number(),
  cyclesPerMinute: zod.number(),
});

export const LEDColorRingPattern = zod.object({
  kind: zod.literal('ring'),
  red: zod.number().min(0).max(255),
  green: zod.number().min(0).max(255),
  blue: zod.number().min(0).max(255),
  brightness: zod.number(),
  length: zod.number().min(0).max(47),
  cyclesPerMinute: zod.number(),
});

export const LEDColorPattern = zod.union([
  LEDColorPulsingPattern,
  LEDColorRingPattern,
]);

export type LEDColorPattern = zod.infer<typeof LEDColorPattern>;
