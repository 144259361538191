import ErrorPageLayout from '@sbrc/components/layouts/ErrorPageLayout';

export default function Error() {
  return (
    <ErrorPageLayout
      actionButton={{ label: 'Back to Home', link: '/' }}
      message="This page is unknown or does not exist."
    />
  );
}
