import * as zod from 'zod';

import {
  MODBUS_TCP_STEP_ARGS_DEFAULT,
  ModbusTCPServerStepArguments,
} from '@sb/integrations/implementations/ModbusTCPServer/step/constants';
import { ModbusFunctionCodeEnum } from '@sb/integrations/utils/modbus/ModbusFunctionCode';
import { ActionRequiredError, Step } from '@sb/remote-control/types';

export namespace NetworkRequestStep {
  export const name = 'Network request';
  export const description =
    'Send network requests to a device and set environment variable values';
  export const deviceKinds = ['ModbusTCPServer'] as const;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const argumentKind = 'NetworkRequest';

  /** Arguments to be passed to the routine runner */
  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    command: ModbusTCPServerStepArguments.default(MODBUS_TCP_STEP_ARGS_DEFAULT),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (args.command.kind === 'ModbusTCPServer') {
      if (args.command.registerId === '') {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Modbus request requires a register ID',
          fieldId: 'command',
        });
      }

      if (
        args.command.request.kind ===
          ModbusFunctionCodeEnum.ReadHoldingRegisters &&
        args.command.request.variableIDList.length === 0
      ) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Modbus read request requires variables to be set',
          fieldId: 'command',
        });
      }

      const equipmentConfig = equipment.find(
        (e) => e.id === args.command.equipmentId,
      );

      if (!equipmentConfig) {
        throw new ActionRequiredError({
          kind: 'missingEquipment',
          message: `Unable to find device id ${args.command.equipmentId} in equipment manager.`,
        });
      }

      if (
        args.command.kind === 'ModbusTCPServer' &&
        equipmentConfig?.config.kind === 'ModbusTCPServer'
      ) {
        const equipmentRegister = equipmentConfig.config.registers?.find(
          (r) => r.id === args.command.registerId,
        );

        if (equipmentRegister) {
          return {
            ...stepData,
            stepKind: 'NetworkRequest',
            args: {
              command: {
                kind: args.command.kind,
                equipmentId: args.command.equipmentId,
                request: {
                  ...args.command.request,
                  register: equipmentRegister,
                },
              },
            },
          };
        }

        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: `Unable to find register id ${args.command.registerId} in equipment device configuration.`,
        });
      }
    }

    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: `${args.command.kind} is an unsupported command kind`,
    });
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
    includeStepName,
  }) => {
    if (args?.argumentKind !== argumentKind || !routine) {
      return null;
    }

    const { command } = args;
    const { request } = command;

    if (request.kind === ModbusFunctionCodeEnum.ReadHoldingRegisters) {
      const variableId = request.variableIDList[0];

      if (variableId === null) {
        return null;
      }

      const variable = routine.environmentVariables.find(
        (v) => v.id === variableId,
      );

      if (!variable) {
        return null;
      }

      const { register } = request;

      return `${includeStepName ? 'Network request ' : ''}to save ${
        register.name
      } to variable ${variable.name}`;
    }

    if (request.kind === ModbusFunctionCodeEnum.WriteSingleRegister) {
      const { register } = request;
      const value = request.valueList[0];

      if (value === null) {
        return null;
      }

      return `${includeStepName ? 'Network request ' : ''}to set ${
        register.name
      } to ${value}`;
    }

    return null;
  };
}

NetworkRequestStep satisfies Step.StepKindInfo;
