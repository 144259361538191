export const waveform = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.682 4H11.6424L11.9829 5.81571L14.2717 18.0227L17.0513 9.68377L17.4459 8.5H18.368L18.8944 9.55279L20.618 13H26V15H20H19.382L19.1056 14.4472L18.1852 12.6066L14.9487 22.3162L14.5541 23.5H13.2638L13.0171 22.1843L10.6777 9.70743L8.93633 14.3511L8.693 15H8H2V13H7.307L10.0637 5.64888L10.682 4Z"
    fill="currentColor"
  />
);

export const waveContinuous = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 9.5C3 7.567 4.567 6 6.5 6C8.433 6 10 7.567 10 9.5V18.5C10 19.3284 10.6716 20 11.5 20C12.3284 20 13 19.3284 13 18.5V9.5C13 7.567 14.567 6 16.5 6C18.433 6 20 7.567 20 9.5V18.5C20 19.3284 20.6716 20 21.5 20C22.3284 20 23 19.3284 23 18.5V7H25V18.5C25 20.433 23.433 22 21.5 22C19.567 22 18 20.433 18 18.5V9.5C18 8.67157 17.3284 8 16.5 8C15.6716 8 15 8.67157 15 9.5V18.5C15 20.433 13.433 22 11.5 22C9.567 22 8 20.433 8 18.5V9.5C8 8.67157 7.32843 8 6.5 8C5.67157 8 5 8.67157 5 9.5V21H3V9.5Z"
    fill="currentColor"
  />
);

export const waveBroken = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.75 7C7.75 7.69036 7.19036 8.25 6.5 8.25C5.80964 8.25 5.25 7.69036 5.25 7C5.25 6.30964 5.80964 5.75 6.5 5.75C7.19036 5.75 7.75 6.30964 7.75 7ZM3 19V9H5V19H3ZM13 19V9H15V19H13ZM23 9V19H25V9H23ZM8 19V9H10V19H8ZM18 9V19H20V9H18ZM16.5 8.25C17.1904 8.25 17.75 7.69036 17.75 7C17.75 6.30964 17.1904 5.75 16.5 5.75C15.8096 5.75 15.25 6.30964 15.25 7C15.25 7.69036 15.8096 8.25 16.5 8.25ZM12.75 21C12.75 21.6904 12.1904 22.25 11.5 22.25C10.8096 22.25 10.25 21.6904 10.25 21C10.25 20.3096 10.8096 19.75 11.5 19.75C12.1904 19.75 12.75 20.3096 12.75 21ZM21.5 22.25C22.1904 22.25 22.75 21.6904 22.75 21C22.75 20.3096 22.1904 19.75 21.5 19.75C20.8096 19.75 20.25 20.3096 20.25 21C20.25 21.6904 20.8096 22.25 21.5 22.25Z"
    fill="currentColor"
  />
);
