export const listBullet = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.5 9C5.32843 9 6 8.32843 6 7.5C6 6.67157 5.32843 6 4.5 6C3.67157 6 3 6.67157 3 7.5C3 8.32843 3.67157 9 4.5 9ZM25 8.5L8 8.5V6.5L25 6.5V8.5ZM8 15L25 15V13L8 13V15ZM8 21.5L25 21.5V19.5L8 19.5V21.5ZM4.5 15.5C5.32843 15.5 6 14.8284 6 14C6 13.1716 5.32843 12.5 4.5 12.5C3.67157 12.5 3 13.1716 3 14C3 14.8284 3.67157 15.5 4.5 15.5ZM6 20.5C6 21.3284 5.32843 22 4.5 22C3.67157 22 3 21.3284 3 20.5C3 19.6716 3.67157 19 4.5 19C5.32843 19 6 19.6716 6 20.5Z"
    fill="currentColor"
  />
);

export const line3Horizontal = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 8.5L24 8.5V6.5L4 6.5V8.5ZM24 15H4V13L24 13V15ZM24 21.5H4V19.5H24V21.5Z"
    fill="currentColor"
  />
);
