import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';

import { haasCellSafeWrapper } from '../haasCellSafeWrapper';

import { CNCMonitorMachineArguments } from './Arguments';
import { CNCMonitorMachineVariables } from './Variables';

export class CNCMonitorMachineStep extends Step<
  CNCMonitorMachineArguments,
  CNCMonitorMachineVariables
> {
  public static areSubstepsRequired = true;

  public static Arguments = CNCMonitorMachineArguments;

  public static Variables = CNCMonitorMachineVariables;

  public substeps: Array<Step<object, object>> = [];

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({
    playSubSteps,
    hasBeenPreempted,
    fail,
  }: StepPlayArguments): Promise<void> {
    let hasFault = false;

    const play = haasCellSafeWrapper(
      async () => {
        await playSubSteps({
          hasBeenPreempted: () => {
            const deviceState = this.routineContext.equipment
              .getEquipmentState()
              .find((device) => device.id === this.args.deviceID);

            hasFault =
              deviceState !== undefined &&
              'status' in deviceState.state &&
              deviceState.state.status === 'fault';

            return hasFault || hasBeenPreempted();
          },
        });
      },
      this.args,
      this.routineContext.equipment,
      fail,
    );

    await play();

    if (hasFault) {
      fail({
        failure: { kind: FailureKind.HaasFailure },
        failureReason: 'CNC machine reported a fault',
      });
    }
  }
}
