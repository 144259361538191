import { StepDefinitions } from '../registry/steps';

const StepKinds = Object.keys(
  StepDefinitions,
) as (keyof typeof StepDefinitions)[];

export const registerIntegrationSteps = (stepRegistry: any) => {
  for (const stepKind of StepKinds) {
    stepRegistry.registerStepKind(stepKind, StepDefinitions[stepKind]);
  }
};

export const getIntegrationTypes = () => {
  return StepKinds.map((stepKind) => ({
    stepKind,
    Arguments: StepDefinitions[stepKind].Arguments,
    Variables: StepDefinitions[stepKind].Variables,
  }));
};
